import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, InputAdornment, Pagination, Typography, useTheme } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import CheckboxFilterButton, { MultiSelectValueType } from "../../../components/CheckboxFilterButton";
import CustomInputField from "../../../components/CustomInputField";
import QueryCard from "../../../components/QueryCard";
import { getQueries } from "../../../store/analysis/analysisThunk";
import { useAppDispatch } from "../../../store/hooks";
import { removeAsFavoriteQuery, startQuery, stopQuery } from "../../../store/query/queryThunk";
import { queryTypeFilterOptions, solutionFilterOptions } from "../../../utils/constants";

const PAGE_ITEMS_COUNT = 10;

export default function FavoriteQueriesContent() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [page, setPage] = React.useState(1);
  const [queryData, setQueryData] = React.useState<Record<string, any>>([]);

  React.useEffect(() => {
    dispatch(getQueries({ isFavourite: true }))
      .then((res) => {
        if (res?.payload?.status === 200) {
          let queryData = res?.payload?.data;
          setQueryData(queryData.reverse());
        } else {
          toast.error("Failed to get the data.");
        }
      })
      .catch((e) => {
        toast.error("Failed to get the data.");
      });
  }, []);

  const handleRemoveFromFavorites = (queryId: string | number) => {
    dispatch(removeAsFavoriteQuery(queryId))
      .then((res) => {
        if (res.payload.status === 200) {
          toast.success("Successfully removed from favorite query.");
          setQueryData((prev) => {
            return prev.filter((v_: Record<string, any>) => v_.id !== queryId);
          });
        } else {
          toast.error("Oops! Some issues while removing from favorite query.");
        }
      })
      .catch(() => {
        toast.error("Oops! Something went wrong while removing from favorite query.");
      });
  };

  const handleStartQuery = (queryId: string | number) => {
    dispatch(startQuery(queryId))
      .then((res) => {
        if (res.payload.status === 200) {
          toast.success("Successfully started query.");
          setQueryData((prev) => {
            return prev.map((v_: Record<string, any>) => {
              if (v_._id !== queryId) {
                return v_;
              } else {
                v_.is_active = true;
                return v_;
              }
            });
          });
        } else {
          toast.error("Oops! Some issues while starting query.");
        }
      })
      .catch(() => {
        toast.error("Oops! Something went wrong while starting query.");
      });
  };

  const handleStopQuery = (queryId: string | number) => {
    dispatch(stopQuery(queryId))
      .then((res) => {
        if (res.payload.status === 200) {
          toast.success("Successfully stopped query.");
          setQueryData((prev) => {
            return prev.map((v_: Record<string, any>) => {
              if (v_._id !== queryId) {
                return v_;
              } else {
                v_.is_active = false;
                return v_;
              }
            });
          });
        } else {
          toast.error("Oops! Some issues while stopping query.");
        }
      })
      .catch(() => {
        toast.error("Oops! Something went wrong while stopping query.");
      });
  };

  const handleChangeQueryType = (value: MultiSelectValueType[]) => {
    console.log(value);
  };

  const handleChangeSolution = (value: MultiSelectValueType[]) => {
    console.log(value);
  };

  const handlePageChange = (e: any, value: number) => {
    setPage(value);
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={6}>
          <CustomInputField
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
            placeholder="Search"
            size="small"
            variant="outlined"
            autoComplete="off"
            sx={{ width: { lg: "40%", md: "100%" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end" gap={2}>
          <CheckboxFilterButton
            label="Query Type"
            onChange={(value) => handleChangeQueryType(value)}
            options={queryTypeFilterOptions}
            showSearch={false}
          />
          <CheckboxFilterButton
            label="Solution"
            onChange={(value) => handleChangeSolution(value)}
            options={solutionFilterOptions}
            showSearch={false}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          my: 2,
          py: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderTop: "1px solid",
          borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
          background: theme.palette.dashboardContainer.containerBackground,
        }}
      >
        {queryData.length > 0 ? (
          queryData?.slice((page - 1) * PAGE_ITEMS_COUNT, page * PAGE_ITEMS_COUNT)?.map((item: any) => {
            return (
              <QueryCard
                data={item}
                key={item.id}
                isFavorite
                liveMonitoring={true}
                handleDeleteFromFavorites={() => {
                  handleRemoveFromFavorites(item.id);
                }}
                handleStartQuery={() => handleStartQuery(item._id)}
                handleStopQuery={() => {
                  handleStopQuery(item._id);
                }}
              />
            );
          })
        ) : (
          <Typography variant="caption" textAlign="center">
            No Results Found
          </Typography>
        )}
      </Box>
      <Box>
        {
          <Box
            sx={{
              my: 2,
              mr: 1,
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            {queryData?.length / PAGE_ITEMS_COUNT > 0 && (
              <Pagination
                onChange={handlePageChange}
                page={page}
                count={Math.ceil(queryData?.length / PAGE_ITEMS_COUNT)}
                size="small"
                sx={(theme) => ({
                  ...theme.palette.pagination,
                })}
              />
            )}
          </Box>
        }
      </Box>
    </Box>
  );
}
