import { Avatar, Box, Typography, useTheme } from "@mui/material";

interface GridTitleProps {
  title: string;
  count: number;
}
const GridTitle = ({ title, count }: GridTitleProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...theme.typography.semiBold,
        background: theme.palette.background.dark,
        color: theme.palette.text.titleLabel,
        width: "100%",
        height: "3rem",
        display: "flex",
        alignItems: "center",
        pr: 1.5,
      }}
    >
      <Box sx={{ width: "4px", height: "100%", borderRadius: "5px", background: theme.palette.primary.main }}></Box>
      <Typography sx={{ p: 1.5 }}>{title}</Typography>
      <Avatar
        sx={{
          height: "30px",
          px: "-5px",
          bgcolor: theme.palette.background.dark,
          border: `2px solid ${theme.palette.additionalColors.border}`,
          color: theme.palette.text.titleLabel,
          ml: "auto",
        }}
      >
        <Typography variant="caption">{count}</Typography>
      </Avatar>
    </Box>
  );
};

export default GridTitle;