import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, InputAdornment, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import BackButton from "../../../../../../../components/BackButton";
import CustomDivider from "../../../../../../../components/CustomDivider";
import CustomInputField from "../../../../../../../components/CustomInputField";
import CustomRadialBar from "../../../../../../../components/CustomRadial";
import CustomVideoPlayer from "../../../../../../../components/CustomVideoPlayer";
import PrimaryLoadingButton from "../../../../../../../components/PrimaryLoadingButton";
import RadioFilterButton from "../../../../../../../components/RadioFilterButton";
import { routes } from "../../../../../../../routes";
import { useHandleRouteClick } from "../../../../../../../routes/hooks";
import ImageRecognationCard from "../../../../../../CustomAI/components/Settings/Results/ImageRecognationCard";
import VideoDetailPlayer from "./VideoPlayer";

interface ResultData {
  [x: string]: any;
}

const categoryOptions = [
  {
    id: "all",
    text: "All",
  },
  {
    id: "pemenson",
    text: "Person",
  },
  {
    id: "car",
    text: "Car",
  },
  {
    id: "bus",
    text: "Bus",
  },
  {
    id: "cat",
    text: "Cat",
  },
  {
    id: "nature",
    text: "Nature",
  },
];

const resultData = [
  {
    type: "Person",
    entityName: "Women",
    image: "/assets/person.png",
    score: 78,
    count: 124,
  },
  {
    type: "Car",
    entityName: "Men",
    image: "/assets/person.png",
    score: 78,
    count: 89,
  },
  {
    type: "Bus",
    entityName: "Bus",
    image: "/assets/person.png",
    score: 78,
    count: 24,
  },
  {
    type: "Cat",
    entityName: "Cat",
    image: "/assets/person.png",
    score: 78,
    count: 6,
  },
  {
    type: "Nature",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 93,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 45,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 15,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 45,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 15,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 45,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 45,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 15,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 45,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 15,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 45,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 45,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 15,
  },
];

const sceneData = [
  {
    src: "https://www.youtube.com/embed/K0nzQoT6Ymw",
    title: "Scene Name",
    thumbnail: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    src: "https://www.youtube.com/embed/K0nzQoT6Ymw",
    title: "Scene Name",
    thumbnail: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    src: "https://www.youtube.com/embed/K0nzQoT6Ymw",
    title: "Scene Name",
    thumbnail: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    src: "https://www.youtube.com/embed/K0nzQoT6Ymw",
    title: "Scene Name",
    thumbnail: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    src: "https://www.youtube.com/embed/K0nzQoT6Ymw",
    title: "Scene Name",
    thumbnail: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    src: "https://www.youtube.com/embed/K0nzQoT6Ymw",
    title: "Scene Name",
    thumbnail: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    src: "https://www.youtube.com/embed/K0nzQoT6Ymw",
    title: "Scene Name",
    thumbnail: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    src: "https://www.youtube.com/embed/K0nzQoT6Ymw",
    title: "Scene Name",
    thumbnail: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    src: "https://www.youtube.com/embed/K0nzQoT6Ymw",
    title: "Scene Name",
    thumbnail: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
  {
    src: "https://www.youtube.com/embed/K0nzQoT6Ymw",
    title: "Scene Name",
    thumbnail: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
  },
];

export default function RelatedOverview() {
  const theme = useTheme();
  const params = useParams();
  const { state } = useLocation();
  const handleRouteClick = useHandleRouteClick();

  const [category, setCategory] = useState<string>("all");

  const backHandler = () => {
    handleRouteClick(routes.specificVideo.path.replace(":id", String(params?.id)));
  };

  const overviewData: ResultData = { men: 67, women: 35, children: 12, unidentified: 5 };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
  };

  const handleCategory = (value: string) => {
    setCategory(value);
  };

  return (
    <Grid container sx={{ display: "flex", flexFlow: "column", height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          px: "5px",
        }}
      >
        <Typography variant="h3" fontWeight="bold" sx={{ marginLeft: "10px", color: theme.palette.text.titleLabel }} display={"flex"}>
          {state?.title}
        </Typography>
        <PrimaryLoadingButton
          onClick={() => console.log("update")}
          varient="text"
          sx={{
            ...theme.typography.bigButton,
            ml: "auto",
          }}
        >
          Update Model
        </PrimaryLoadingButton>
      </Box>
      <Box>
        <BackButton onClick={backHandler} sx={{ pl: 1, mt: -1 }} />
      </Box>
      <Grid container overflow={{ xs: "auto", xl: "hidden" }} height="100%">
        <Box
          sx={{
            background: theme.palette.background.darkCardBackground,
            border: theme.palette.additionalColors.databasecardBorder,
            borderRadius: 4.5,
            my: 2,
            p: 2,
            width: "100%",
          }}
        >
          <Box display="flex" justifyContent="center">
            {Object.keys(overviewData).map((category, idx) => (
              <Box
                key={`results-${idx}`}
                sx={{
                  width: "80px",
                  height: "100%",
                }}
              >
                <CustomRadialBar value={overviewData[category]} count={`${overviewData[category]}`} subTitle={category} />
              </Box>
            ))}
          </Box>
        </Box>
        <Grid item xs={12} xl={7} pt={0} height={{ xl: "80%" }}>
          <Box
            sx={{
              background: theme.palette.background.default,
              boxShadow: theme.palette.dashboardContainer.insetBoxShadow,
              borderRadius: { md: "18px" },
              px: 2.5,
              py: 3,
              height: "100%",
            }}
          >
            <Typography variant="h3" fontSize={"22px"} color={theme.palette.text.tableHeader} pb={1}>
              Video name 93458
            </Typography>

            <Box height={{ xs: "60vh", xl: "100%" }}>
              <VideoDetailPlayer
                src="https://www.youtube.com/embed/K0nzQoT6Ymw"
                showPlayerControls={true}
                isFullScreen={true}
                refreshIntervalInSec={0}
                thumbnail="https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg"
                values={[1, 2, 3]}
              />
            </Box>
          </Box>
        </Grid>
        <CustomDivider width="100%" sx={{ margin: "20px auto", [theme.breakpoints.only("xl")]: { display: "none" } }} />
        <Grid item xs={12} xl={5} pl={{ xl: 3 }} height={{ xl: "80%" }}>
          <Box
            sx={{
              [theme.breakpoints.up("xl")]: {
                background: theme.palette.background.darkCardBackground,
                border: theme.palette.additionalColors.databasecardBorder,
                borderRadius: 2,
                p: 2,
                height: "100%",
                overflow: "hidden",
              },
            }}
          >
            <Typography variant="body2" color={theme.palette.text.titleLabel}>
              674 entity detected
            </Typography>
            <Box display="flex">
              <CustomInputField
                placeholder="Search"
                size="small"
                variant="outlined"
                autoComplete="off"
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "&.MuiFormControl-root.MuiTextField-root .MuiInputBase-root": {
                    height: "36px",
                  },
                }}
              />
              <RadioFilterButton
                label="Category"
                options={categoryOptions}
                onChange={handleCategory}
                defaultValue={category}
                sx={{ width: "150px", border: "none", ml: "auto" }}
              />
            </Box>
            <Box
              sx={{
                mt: 1,
                height: { xl: "100%" },
                overflow: { xl: "scroll" },
                pb: 8,
              }}
            >
              <Box
                sx={{
                  [theme.breakpoints.down("xl")]: {
                    background: theme.palette.background.darkCardBackground,
                    border: theme.palette.additionalColors.databasecardBorder,
                    borderRadius: 2,
                    px: 2,
                    height: { xs: "60vh", xl: "100%" },
                    overflow: "scroll",
                  },
                }}
              >
                {state?.type === "scene" ? (
                  <Grid container spacing={1} width="100%" display="flex">
                    {sceneData.map((detail: any, idx: number) => (
                      <Grid item xs={12} sm={3} lg={6} xl={3} key={`detail-${idx}`} pb={2}>
                        <CustomVideoPlayer hlsStreamUrl={detail?.src} thumbnail={detail?.thumbnail} height={"162px"} />

                        <Box display="flex" mt={-0.5}>
                          <Typography variant="body4">{detail?.title}</Typography>
                          <Typography variant="body4" color={theme.palette.primary.light} sx={{ ml: "auto" }}>
                            0:00
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Grid container mt={3}>
                    {resultData?.map((modelData, idx: number) => {
                      return (
                        <Grid item xs={4} sm={12 / 5} md={12 / 7} lg={12 / 9} xl={12 / 5} key={`model-${idx}`} sx={{ pl: 2, pb: 4 }}>
                          <ImageRecognationCard resultsData={modelData} showScore={false} />
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
