import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { Box, Grid, Paper, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { TfiExport } from "react-icons/tfi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../../../../components/BackButton";
import CustomSecondaryButton from "../../../../../components/CustomSecondaryButton";
import CustomTab from "../../../../../components/CustomTab";
import PrimaryLoadingButton from "../../../../../components/PrimaryLoadingButton";
import { routes } from "../../../../../routes";
import { useHandleRouteClick } from "../../../../../routes/hooks";
import { getEntityDetailById } from "../../../../../store/analysis/analysisThunk";
import { useAppDispatch } from "../../../../../store/hooks";
import Connections from "../../Overview/RiskSummary/component/Connections";
import EntityInfo, { InfoDetails } from "./components/EntityInfo";
import Mentions from "./components/Mentions";
import OnlineActivity from "./components/OnlineActivity";
import Overview from "./components/Overview";
import RelatedPublicationsForEntity from "./components/RelatedPublicationsForEntity";
interface SpecificEntityProps {
  isDialog?: boolean;
  entityId?: string;
}

export default function SpecificEntity({ isDialog = false, entityId = "" }: SpecificEntityProps) {
  const tabs = [
    {
      id: "overview",
      title: "Overview",
      disabled: false,
      component: <Overview isDialog={isDialog} />,
    },
    {
      id: "connections",
      title: "Connections",
      disabled: false,
      component: <Connections entityId={entityId} />,
    },
    {
      id: "online-activity",
      title: "Online Activity",
      disabled: false,
      component: <OnlineActivity entityId={entityId} />,
    },
    {
      id: "mentions",
      title: "Mentions",
      disabled: false,
      component: <Mentions entityId={entityId} />,
    },
    {
      id: "feeds",
      title: "Feeds",
      disabled: false,
      component: <RelatedPublicationsForEntity entityId={entityId} />,
    },
  ];

  const theme = useTheme();
  const params = useParams();
  const dispatch = useAppDispatch();
  const handleRouteClick = useHandleRouteClick();
  const [infoData, setInfoData] = useState<InfoDetails>({});
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const backHandler = () => {
    handleRouteClick(`${routes.home.path}?tab=analysis&subtab=entities`);
  };

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const id = entityId !== "" ? entityId : params?.id;
    if (id) {
      dispatch(getEntityDetailById(id))
        .then((res) => {
          if (res?.payload?.status === 200) {
            setInfoData(res?.payload?.data);
          } else {
            toast.error("Data fetch failed");
          }
        })
        .catch((e) => {
          toast.error("Data fetch failed");
        });
    }
  }, [params, entityId]);

  return (
    <Grid container sx={{ position: "relative" }}>
      <Grid item xs={12}>
        {!isDialog && (
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "left",
                padding: "5px",
              }}
            >
              <Typography variant="h3" fontWeight="bold" sx={{ marginLeft: "10px", color: theme.palette.text.titleLabel }} display={"flex"}>
                {infoData?.name}
              </Typography>
              <BackButton onClick={backHandler} sx={{ pl: 1 }} />
            </Box>
            <Box display="flex" gap={3} px={1} height="40px">
              <PrimaryLoadingButton
                startIcon={<AutoFixHighIcon />}
                sx={{
                  ...theme.typography.bigButton,
                }}
              >
                Magic Edit
              </PrimaryLoadingButton>
              <CustomSecondaryButton
                variant="contained"
                sx={{
                  ...theme.typography.h6,
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
                startIcon={<TfiExport style={{ width: "12px", height: "12px", marginBottom: "5px" }} />}
              >
                Export Report
              </CustomSecondaryButton>
            </Box>
          </Box>
        )}

        <Paper
          sx={{
            background: theme.palette.background.default,
            boxShadow: theme.palette.dashboardContainer.insetBoxShadow,
            borderRadius: { md: "18px" },
            padding: { xs: "20px 10px", md: "35px" },
            height: "87vh",
            overflowY: "scroll",
          }}
        >
          {"key" in infoData && <EntityInfo infoData={infoData} />}

          <Box
            sx={{
              mt: 3,
              borderTop: "1px solid",
              borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
              background: theme.palette.dashboardContainer.containerBackground,
            }}
          >
            <Box sx={{ display: "flex", mt: 3 }}>
              <CustomTab
                tabs={tabs}
                handleActiveTab={handleActiveTab}
                sx={{ border: `1px solid ${theme.palette.primary.tertiary}`, textAlign: "center", p: "1px" }}
                activeTab={activeTab}
              />
            </Box>

            {tabs.find((tab) => tab.id === activeTab)?.component}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
