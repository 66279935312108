import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import CustomTextButton from "../../../../../../../components/CustomTextButton";
import ModifiedPublicationCard from "../ModifiedPublicationCard";
interface RelatedPublicationProps {
  relatedArticles: Record<string, any>[] | undefined;
  showFull?: boolean;
}
export default function RelatedPublication({ relatedArticles = [], showFull = false }: RelatedPublicationProps) {
  const theme = useTheme();
  const [expand, setExpand] = useState(false);

  const toggleExpand = () => {
    setExpand((prev) => !prev);
  };
  // const publicationData = [
  //   {
  //     query_id: "655749d49a2dbd38ed0a1b3f",
  //     data_id: "655631e260add368dd05a2e1",
  //     sentiment: 0,
  //     prediction:
  //       "Based on the article provided, here are some potential future implications:\n1. Increased Safety Measures: The tragic incident may lead to a re-evaluation of safety protocols and procedures for air races, including more stringent regulations and stricter enforcement to prevent similar accidents in the future.\n2. Decline in Participation: The crash may deter potential participants and spectators, leading to a decline in",
  //     military_movement: 1,
  //     crawlData: {
  //       kind: "news",
  //       source: "www.huffpost.com",
  //       author: "Nina Golgowski",
  //       title: "One Dead After Plane Crashes At Reno Air Race",
  //       content:
  //         "The single-engine plane was participating in the National Championship Air Races on Sunday when it crashed into a field, killing the pilot, officials said.",
  //       short_description:
  //         "The single-engine plane was participating in the National Championship Air Races on Sunday when it crashed into a field, killing the pilot, officials said.",
  //       date: "2022-09-19",
  //       processed: true,
  //       images: null,
  //       url: "https://www.huffpost.com/entry/national-championship-air-races-pilot-killed-crash_n_632860fae4b0ed021dfef140",
  //       category: "U.S. NEWS",
  //       data_source: "kaggle_dataset",
  //     },
  //   },
  //   {
  //     query_id: "655749d49a2dbd38ed0a1b3f",
  //     data_id: "655631e2978859a5b8e521d1",
  //     sentiment: 2,
  //     prediction:
  //       "Based on the article provided, here are some potential future implications for the Las Vegas Aces and the WNBA:\n1. Increased popularity and recognition for the WNBA: The Aces' victory could lead to increased interest and recognition for the WNBA, potentially attracting more fans and sponsors to the league.\n2. Boost for Las Vegas' sports scene: The Aces' championship could help establish Las Veg",
  //     military_movement: 1,
  //     crawlData: {
  //       kind: "news",
  //       source: "www.huffpost.com",
  //       author: "Pat Eaton-Robb, AP",
  //       title: "Las Vegas Aces Win First WNBA Title, Chelsea Gray Named MVP",
  //       content: "Las Vegas never had a professional sports champion — until Sunday.",
  //       short_description: "Las Vegas never had a professional sports champion — until Sunday.",
  //       date: "2022-09-19",
  //       processed: true,
  //       images: null,
  //       url: "https://www.huffpost.com/entry/2022-wnba-finals_n_6327f56fe4b0eac9f4e3144d",
  //       category: "SPORTS",
  //       data_source: "kaggle_dataset",
  //     },
  //   },
  // ];
  // const relatedArticles = publicationData?.related_articles;
  return (
    // <Grid container mt={5} mb={5}>
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
        <Typography variant="h3" color={theme.palette.text.titleLabel} sx={{ fontSize: "22px" }}>
          Related Publications
        </Typography>
        {/* <Typography
        ml="auto"
        variant="link5"
        color={theme.palette.text.default}
        display={"flex"}
        sx={{
          cursor: "pointer",
          fontSize: "16px",
        }}
      >
        View All
        <ChevronRightIcon sx={{ color: theme.palette.text.default, mt: "-6px" }} />
      </Typography> */}
        {!showFull && relatedArticles.length >= 5 && (
          <Box textAlign="end">
            <CustomTextButton onClick={toggleExpand} startIcon={expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
              {expand ? "Collapse" : "Expand"}
            </CustomTextButton>
          </Box>
        )}
      </Box>
      {showFull ? (
        <Grid container spacing={2} mt={1}>
          {relatedArticles &&
            relatedArticles.map((data: any, idx: number) => (
              <Grid key={`image-${idx}`} item xs={12} md={6} display="flex">
                <ModifiedPublicationCard cardData={data} view={"grid"} />
              </Grid>
            ))}
        </Grid>
      ) : (
        <Grid container spacing={2} mt={1}>
          {relatedArticles &&
            relatedArticles.slice(0, Math.min(4, relatedArticles.length)).map((data: any, idx: number) => (
              <Grid key={`image-${idx}`} item xs={12} md={6} display="flex">
                <ModifiedPublicationCard cardData={data} view={"grid"} />
              </Grid>
            ))}
          {expand &&
            relatedArticles.slice(4, relatedArticles.length).map((data: any, idx: number) => (
              <Grid key={`image-${idx}`} item xs={12} md={6} display="flex">
                <ModifiedPublicationCard cardData={data} view={"grid"} />
              </Grid>
            ))}
        </Grid>
      )}
    </>
    // </Grid>
  );
}
