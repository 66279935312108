import { createSlice } from "@reduxjs/toolkit";
import { deletePipeline, getAllModules, getPipeline, getPipelineDetail, savePipeline, updatePipeline } from "./pipelineThunk";

export interface PipelineInitialState {
  isLoading: boolean;
}
const pipelineInitialState: PipelineInitialState = {
  isLoading: false,
};

const pipelineSlice = createSlice({
  name: "pipeline",
  initialState: pipelineInitialState,
  reducers: {},
  extraReducers: (builder) => {
    // get pipeline
    builder
      .addCase(getPipeline.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPipeline.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getPipeline.rejected, (state, action) => {
        state.isLoading = false;
      });
    // save pipeline
    builder
      .addCase(savePipeline.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(savePipeline.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(savePipeline.rejected, (state, action) => {
        state.isLoading = false;
      });

    // update pipeline
    builder
      .addCase(updatePipeline.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updatePipeline.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updatePipeline.rejected, (state, action) => {
        state.isLoading = false;
      });

    // get pipeline details
    builder
      .addCase(getPipelineDetail.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPipelineDetail.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getPipelineDetail.rejected, (state, action) => {
        state.isLoading = false;
      });

    // get all modules
    builder
      .addCase(getAllModules.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllModules.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getAllModules.rejected, (state, action) => {
        state.isLoading = false;
      });

    // delete a pipeline
    builder
      .addCase(deletePipeline.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deletePipeline.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deletePipeline.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const pipelineActions = pipelineSlice.actions;

export default pipelineSlice;
