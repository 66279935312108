import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Checkbox, ListItemText, Select, SelectChangeEvent, Typography, useTheme } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import BaseInputDropdown from "../../../../../components/BaseInputDropdown";
import CustomSelectMenuItem from "../../../../../components/CustomSelectMenuItem";
import CustomStatus from "../../../../../components/CustomStatus";
import { useAppSelector } from "../../../../../store/hooks";
import { ThemeVariantsProps } from "../../../../../theme";

export interface SelectValueType {
  id: string | number;
  text: string;
}
export interface DataType {
  value: string;
  checked: boolean;
}
export interface EntityRadioFilterDropDownProps {
  onChange: (values: any) => any;
  options: SelectValueType[];
  column?: string;
  label?: string;
  startIcon?: React.ReactNode;
  defaultValue?: string;
  sx?: SxProps<Theme>;
  placeholder?: string;
  endIcon?: React.ReactNode;
  textColor?: string;
  placeholderColor?: string;
  selectionData?: string;
  isReset?: boolean;
  pageChange?: number;
  typeChange?: string;
  getValue: (data: DataType) => void;
}

export default function EntityRadioFilterDropDown({
  onChange,
  options,
  column = "",
  label,
  startIcon,
  defaultValue = "",
  sx,
  placeholder,
  endIcon,
  textColor,
  placeholderColor,
  selectionData,
  isReset,
  pageChange,
  typeChange,
  getValue,
}: EntityRadioFilterDropDownProps) {
  const theme = useTheme();
  const { themeMode } = useAppSelector((state: any) => state.theme);
  const isDarkTheme = themeMode === ThemeVariantsProps.dark;
  const [value, setValue] = React.useState<string>(defaultValue);
  const [open, setOpen] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  const handleChange = (event: SelectChangeEvent) => {
    const finalValue = event.target.value as string;
    setOpen(true);
    onChange(finalValue);
    setValue(finalValue);
  };

  useEffect(() => {
    if (isReset) {
      setValue(defaultValue);
    }
  }, [isReset]);

  useEffect(() => {
    setValue(defaultValue);
  }, [pageChange, typeChange]);

  useEffect(() => {
    if (selectionData) {
      setValue(selectionData);
    }
  }, [selectionData]);

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setChecked(event.target.checked);
  };

  const handleExpandClick = () => {
    setOpen(!open);
    getValue({
      value: value,
      checked: checked,
    });
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        handleExpandClick();
      }}
      size="medium"
      displayEmpty
      input={<BaseInputDropdown />}
      sx={{
        marginTop: 0,
        ...sx,
        "& .MuiSelect-select": {
          boxShadow:
            open || value ? theme.palette.dashboardContainer.cardInsetBoxShadow : theme.palette.dashboardContainer.shareMenuDropdownBoxShadow,
          border: `0.5px solid ${theme.palette.additionalColors.border1Light}`,
          paddingRight: "10px !important",
          background: !open && !value ? theme.palette.background.gradientLight1 : theme.palette.background.default,
        },
        "& .MuiSvgIcon-root.MuiSelect-icon": {
          color: theme.palette.text.default,
          display: endIcon ? "block" : "none",
        },
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        PaperProps: {
          sx: { width: "13%", marginTop: 0, pl: 1, background: theme.palette.background.paper },
        },
        disableAutoFocusItem: true,
      }}
      label={label}
      renderValue={(selected) => {
        const selectedOpt: any = options.find((data) => data.id === selected);
        return (
          <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
            {startIcon && <Box sx={{ display: "inline-flex", alignItems: "center", ml: 1 }}>{startIcon}</Box>}
            <Typography sx={{ paddingLeft: "2px" }} variant="link5" color={(theme) => theme.palette.additionalColors.light}>
              {label && `${label}:`}&nbsp;
            </Typography>

            {selected !== "" ? (
              column === "subtype" ? (
                <Typography
                  variant="link5"
                  color={theme.palette.primary.light}
                  sx={{
                    ml: 1,
                    textTransform: "capitalize",
                    whiteSpace: "nowrap",
                    fontSize: "14px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100px",
                  }}
                >
                  {selectedOpt && selectedOpt?.text}
                </Typography>
              ) : (
                <CustomStatus status={selectedOpt && selectedOpt?.text.toLowerCase()} label={selectedOpt?.text} sx={{ width: "5.5rem", py: 0 }} />
              )
            ) : (
              <Typography
                variant="link5"
                color={placeholderColor || theme.palette.additionalColors.placeholder}
                sx={{ textTransform: "capitalize", ml: 3, mr: 5 }}
              >
                -
              </Typography>
            )}
          </Box>
        );
      }}
      IconComponent={ExpandMoreIcon}
      onOpen={() => setOpen(true)}
      open={open}
    >
      {options &&
        options.map((option) => {
          return (
            <CustomSelectMenuItem
              key={option.id}
              value={option.id}
              style={{
                padding: "0px 10px 0 0",
                textTransform: "capitalize",
              }}
              onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                e.stopPropagation();
                setOpen(false);
              }}
              sx={{ p: "0", py: 1 }}
            >
              {value === option.id ? (
                <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
                  <img alt="radio icon" src={isDarkTheme ? "/assets/dropdown_radio.png" : "/assets/light_radio_dropdown.png"} />
                </Box>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
                  <img alt="radio icon" src="/assets/dropdown_radio_unselected.png" />
                </Box>
              )}
              {column === "subtype" ? (
                <ListItemText
                  primary={option.text}
                  primaryTypographyProps={{
                    style: {
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100px",
                      color: theme.palette.text.titleLabel,
                    },
                  }}
                />
              ) : (
                <CustomStatus status={option.text.toLowerCase()} label={option.text} sx={{ width: "5.5rem", py: 0 }} />
              )}
            </CustomSelectMenuItem>
          );
        })}
      <Box mt={1.5}>
        <Checkbox
          checked={checked}
          onChange={handleChecked}
          onClick={(e) => e.stopPropagation()}
          inputProps={{ "aria-label": "controlled" }}
          sx={{
            p: 1,
            fontSize: "14px",
            fontWeight: "400",
            "& .MuiSvgIcon-root": {
              fill: checked ? theme.palette.checkbox.default : theme.palette.checkbox.darkBorder,
            },
            "& .MuiButtonBase-root.MuiCheckbox-root": {
              pl: "5px",
            },
            "&:hover .MuiSvgIcon-root": {
              fill: checked ? theme.palette.checkbox.default : "linear-gradient(135deg, rgba(38, 46, 63, 0.70) 0%, rgba(29, 35, 48, 0.70) 100%)",
              stroke: checked ? "none" : theme.palette.checkbox.darkBorder,
            },
            "&:active .MuiSvgIcon-root": {
              fill: checked ? "#245785" : theme.palette.checkbox.darkBorder,
            },
          }}
        />
        <Typography variant="caption" ml={1} color={theme.palette.text.titleLabel}>
          Apply to similar cases
        </Typography>
      </Box>
    </Select>
  );
}
