import { Box, Grid, useTheme } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import CustomTab from "../../../../../../../components/CustomTab";
import Analytics from "./Analytics";
import GraphView from "./GraphView";
import TableView from "./TableView";

type ConnectionSectionProps = {
  data: { connections?: any[] } | null;
};

export default function ConnectionSection({ data }: ConnectionSectionProps) {
  const { state } = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const tabs = [
    {
      id: "graph",
      title: "Graph View",
      disabled: false,
      component: <GraphView graphData={data?.connections || []} />,
    },
    {
      id: "table",
      title: "Table View",
      disabled: false,
      component: <TableView />,
    },
    {
      id: "analytics",
      title: "Analytics",
      disabled: false,
      component: <Analytics drawerOpen={drawerOpen} />,
    },
  ];
  const theme = useTheme();

  const [activeTab, setActiveTab] = useState(state?.activeTab || tabs[0].id);

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <Box sx={{ borderTop: "1px solid", borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1` }}>
      <Box display="flex" gap={2} width={"100%"} mt={2}>
        <CustomTab
          tabs={tabs}
          handleActiveTab={handleActiveTab}
          sx={{ border: `1px solid ${theme.palette.primary.tertiary}`, textAlign: "center", borderRadius: "6px", p: "0 2px", alignItems: "center" }}
          width="110px"
          activeTab={activeTab}
        />
      </Box>

      <Grid item xs={drawerOpen ? 7 : 12} lg={drawerOpen ? 9 : 12}>
        <Box>{tabs.find((tab) => tab.id === activeTab)?.component}</Box>
      </Grid>
    </Box>
  );
}
