import { Autocomplete, useTheme } from "@mui/material";
import CustomInputField from "./CustomInputField";

interface CustomAutocompleteProps {
  options: { label: string; id: number }[];
  lableName: string;
  [x: string]: any;
}
export default function CustomAutocomplete({ options, labelName, ...props }: CustomAutocompleteProps) {
  const theme = useTheme();
  return (
    <Autocomplete
      options={options}
      sx={{
        root: {
          border: "1px solid #ffffff00",
          marginTop: "-15px",
          background: theme.palette.background.default,
        },
        inputRoot: {
          px: 1.3,
          background: theme.palette.background.default,
          maxHeight: "30vh",
        },
      }}
      clearIcon={null}
      // Apply custom styles here
      renderInput={(params) => <CustomInputField {...params} placeholder={labelName} />}
      {...props}
    />
  );
}
