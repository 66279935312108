import GoogleMapReact from "google-map-react";
import { GOOGLE_MAP_REACT_API_KEY } from "../../../../../utils/constants";

export interface MillitaryPresenceMapProps {
  mapConfig: {
    center: {
      lat: number;
      lng: number;
    };
    zoom: number;
  };
}

export default function MillitaryPresenceMap({ mapConfig }: MillitaryPresenceMapProps) {
  console.log(mapConfig);
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAP_REACT_API_KEY }}
        defaultCenter={mapConfig.center}
        defaultZoom={mapConfig.zoom}
        options={{ mapTypeId: "satellite" }}
      ></GoogleMapReact>
      {/* <GoogleMap
        // apiKey={YOUR_GOOGLE_MAP_API_KEY} // set if you need stats etc ...
        center={mapConfig.center}
        zoom={mapConfig.zoom}
      ></GoogleMap> */}
    </div>
  );
}
