import { Box, SxProps, Theme, Typography, useTheme } from "@mui/material";

type TextPalette = {
  extremeStatus: string;
  highStatus: string;
  moderateStatus: string;
  neutralStatus: string;
  lowStatus: string;
};

interface LegendProps {
  sx?: SxProps<Theme>;
}

export default function ScoreLegend({ sx }: LegendProps) {
  const theme = useTheme();
  const legendData = ["extreme", "high", "moderate", "neutral", "low"];

  return (
    <Box display="flex" gap={2} sx={{ ...sx }}>
      <Typography variant="subtitle2" color={theme.palette.text.titleLabel}>
        Risk score
      </Typography>
      <Box display="flex" gap={3} mt={"-3px"}>
        {legendData.map((data) => {
          const statusColor: any = `${data}Status`;
          return (
            <Box display="flex" gap={1} key={data}>
              <Box sx={{ width: "14px", height: "14px", background: theme.palette.text[statusColor as keyof TextPalette], borderRadius: 1 }} />
              <Typography variant="body5" color={theme.palette.text.titleLabel} mt={0.2}>
                {data}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
