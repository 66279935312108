import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { useTheme } from "@mui/material";
import { useEffect } from "react";
import TokenService from "../axios/tokenservice";
import { getMyDetail } from "../store/auth/authThunk";
import { useAppDispatch } from "../store/hooks";

export interface NoSidebarDashboardBaseLayoutProps {
  children?: React.ReactNode;
}

export default function NoSidebarDashboardBaseLayout({ children }: NoSidebarDashboardBaseLayoutProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const currentUser = TokenService.getCurrentUser();

  useEffect(() => {
    if (Object.keys(currentUser).length === 0 || currentUser === null) {
      dispatch(getMyDetail());
    }
  }, []);

  return (
    <Box>
      <Box
        component="main"
        sx={{
          height: "100vh",
          overflowY: "scroll",
          p: 2,
          background: theme.palette.background.gradientLight2,
        }}
      >
        <Container maxWidth={false} disableGutters sx={{ margin: "0", height: "100%" }}>
          {children}
        </Container>
      </Box>
    </Box>
  );
}
