import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { routes } from "../../routes";
import { useHandleRouteClick } from "../../routes/hooks";
import VideoDetailPlayer from "./Components/Feed/components/Videos/Analysis/VideoPlayer";

export default function VideoFeed() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();
  const [searchParams] = useSearchParams();
  const feedId = searchParams.get("feedId");
  const label = searchParams.get("label");

  const handleBack = () => {
    handleRouteClick(`${routes.home.path}?tab=analysis&subtab=feed`);
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center" py={2} px={1}>
        <Stack direction="column" alignItems="flex-start">
          <Stack direction="row" alignItems="flex-start" gap={3}>
            <Typography variant="h1" color={theme.palette.text.titleLabel}>
              Video Feed:
              {label}
            </Typography>
          </Stack>
          <BackButton onClick={handleBack} sx={{ mt: "-3px" }} />
        </Stack>
      </Stack>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderTop: "1px solid",
          borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
          background: theme.palette.dashboardContainer.containerBackground,
          height: "100%",
          py: 3,
          px: { xs: 3, lg: 10, xl: 15 },
          mt: -1,
          overflow: "auto",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                boxShadow: theme.palette.dashboardContainer.insetBoxShadow,
                background: theme.palette.background.dark,
                borderRadius: 3,
                height: "100%",
                // p: 2,
                // mt: -1,
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                  gap: 2,
                  p: { xs: 10, lg: 5 },
                }}
              >
                <Box height={{ xs: "48vh", xl: "100%" }} width={"90%"}>
                  <VideoDetailPlayer
                    src="/videos/kashmir_riots.mp4"
                    showPlayerControls={true}
                    isFullScreen={true}
                    refreshIntervalInSec={0}
                    thumbnail="/videos/kashmir_riot_thumbnail.png"
                    values={[1, 2, 3]}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
