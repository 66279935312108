import { Box, CardMedia, Grid, Typography, useTheme } from "@mui/material";
import PrimaryLoadingButton from "../../../../components/PrimaryLoadingButton";
import { useHandleRouteClick } from "../../../../routes/hooks";
import WorkspaceMenu from "./WorkspaceMenu";

interface PlanDetailsProps {
  plan: string;
  title: string;
}

export default function PlanDetails({ plan, title }: PlanDetailsProps) {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const handleNavigation = () => {
    // handleRouteClick(routes.paymentDetails.path, { plan: plan, amt: 200, backPath: routes.upgradeInfo.path, isCart: false });
  };

  const CardImage = () => (
    <CardMedia
      component="img"
      image="/assets/marketplace_Info.png"
      alt="info"
      sx={{ borderRadius: 4, height: { xs: "20em", lg: "27em", xl: "20em" }, minHeight: "50%" }}
    />
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: theme.palette.background.paper,
        boxShadow: theme.palette.dashboardContainer.customCardInsetBoxShadow,
        borderRadius: 4.5,
        px: 2,
        mt: 2,
      }}
    >
      <Grid
        container
        sx={{
          [theme.breakpoints.down("lg")]: {
            height: "100%",
            flexDirection: "column",
            overflow: "scroll",
          },
        }}
      >
        <Grid
          item
          xs={12}
          lg={6}
          xl={4}
          order={{ xs: 2, md: 1 }}
          sx={{
            px: 2,
            py: 3,
            [theme.breakpoints.only("lg")]: {
              px: 8,
              py: 6,
            },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <WorkspaceMenu />
          <Typography variant="h2" mt={4} color={theme.palette.text.titleLabel} sx={{ lineHeight: "100%" }}>
            Downgrade to {title}
          </Typography>
          <Box
            mt={2}
            sx={{
              [theme.breakpoints.up("lg")]: {
                pl: 8,
                display: "none",
              },
            }}
          >
            {CardImage()}
          </Box>
          <Box mt={4}>
            <Typography variant="body6" sx={{ color: theme.palette.text.captionColor }}>
              You are going to downgrade to {title} plan. All your previously purchased models or solutions will be retained. All your workspaces will
              become unavailable and you will no longer be able to upload yours Data Sources. You can return to {plan} any time.
            </Typography>
          </Box>
          <Box mt="auto">
            <PrimaryLoadingButton
              sx={{
                ...theme.typography.bigButton,
                px: "6em",
                mt: "2rem",
              }}
              onClick={handleNavigation}
            >
              Downgrade
            </PrimaryLoadingButton>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          xl={8}
          order={{ xs: 2, md: 1 }}
          sx={{
            px: 2,
            py: 3,
            [theme.breakpoints.only("lg")]: {
              px: 8,
              py: 6,
            },
            [theme.breakpoints.up("lg")]: {
              pl: 8,
            },
            [theme.breakpoints.down("lg")]: {
              display: "none",
            },
          }}
        >
          {CardImage()}
        </Grid>
      </Grid>
    </Box>
  );
}
