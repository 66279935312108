import { Box, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import EntitiesSection from "../Components/Entities";
import Title from "../Components/Title";
import { getFormattedDate } from "../../../utils/helper";

export default function EntitiesWrapper() {
  const theme = useTheme();
  const [lastUpdated, setLastUpdated] = useState(getFormattedDate(new Date()));

  const handleRefresh = () => {
    const currentDate = new Date();
    setLastUpdated(getFormattedDate(currentDate) + ' at ' + currentDate.toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit' }));
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  return (
    <Box
      sx={{
        borderTop: "1px solid",
        borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
        background: theme.palette.dashboardContainer.containerBackground,
      }}
      p={2}
    >
      <Title
        title="Entities"
        lastUpdated={lastUpdated}
        refresh={true}
        handleRefresh={handleRefresh}
      />
      <EntitiesSection />
    </Box>
  );
}

