import { Box, Grid, Typography, useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomSecondaryButton from "../../../../../../components/CustomSecondaryButton";
import CustomStatus from "../../../../../../components/CustomStatus";
import { routes } from "../../../../../../routes";
import { useHandleRouteClick } from "../../../../../../routes/hooks";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { textTruncate } from "../../../../../../utils/helper";

interface CardProps {
  [x: string]: any;
  view?: string;
}

export const publicationImage = (source?: string) => {
  switch (source) {
    case "twitter":
      return "/assets/twitter_logo.png";
    case "facebook":
      return "/assets/facebook_logo.png";
    case "instagram":
      return "/assets/instagram_logo.png";
    case "news":
      return "/assets/cnn_logo.png";
    default:
      return "/assets/feed_logo.png";
  }
};

export default function PublicationCard({ cardData, view }: CardProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { showTranslatedFeed } = useAppSelector((state) => state.analysis);
  const { query } = useAppSelector((state: Record<string, any>) => state.analysisQuery);

  const handleRouteClick = useHandleRouteClick();
  // console.log("cardData :", cardData);
  // const date = cardData?.crawlData?.date?.split(" · ");
  const date = cardData?.crawlData?.creation_time?.split(" ");
  const [feedImage, setFeedImage] = useState<null | string>(null);
  const [imageBroken, setImageBroken] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryId = searchParams.get("query") || query?.id;

  useEffect(() => {
    if (cardData?.crawlData?.images?.length > 0) {
      setFeedImage(cardData?.crawlData?.images[0]);
    }
  }, [cardData?.crawlData?.images]);

  const handleNavigation = (id: string) => {
    const publicationURL = routes.publication.path.replace(":queryId", queryId);
    const newURL = publicationURL.replace(":id", id);

    handleRouteClick(newURL);
    // console.log(query)
  };

  const handleMilitaryPresence = () => {
    handleRouteClick(
      routes.millitaryPresenceMap.path + `?lat=${cardData?.location[1]}&lng=${cardData?.location[2]}&label=${cardData?.location[0]}`,
      {}
    );
  };

  const formatHTML = (rawHTML: string) => {
    const formattedHTML = rawHTML?.replace(/\n/g, "<br/>");
    return formattedHTML;
  };

  // const dateTimeString = cardData?.crawlData?.date;
  // const date = dateTimeString && dateTimeString?.split(" ");
  // const time = date && date[1]?.split(":");

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    console.log("Error in Images");
    setImageBroken(true);
    // const imgElement = event.target as HTMLImageElement;

    // if (!imgElement.src.includes(window.location.origin) && cardData?.crawlData?.source === "instagram") {
    //   dispatch(getQueryResultImages({ image_url: imgElement.src }))
    //     .then((res: any) => {
    //       const statusCode = parseInt(res?.error?.message?.split(" ")[5], 10);
    //       if (statusCode === 404) {
    //         setFeedImage(null);
    //       }

    //       if (res?.payload !== undefined) {
    //         if (res?.payload?.status === 201 || res?.payload?.status === 200) {
    //           setFeedImage(`data:image/png;base64,${res.payload.data.image}`);
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("errr", err);
    //     });
    // }
  };

  return (
    <Box
      onClick={() => handleNavigation(cardData?.data_id)}
      sx={{
        cursor: "pointer",
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        p: 2,
        borderRadius: 2,
        flexFlow: "row wrap",
        // display: imageBroken ? "none" : "flex",
        width: "100%",
        // ...(imageBroken ? { my: -2 } : {}),
      }}
    >
      <Box width="100%">
        <Box display="flex" gap={2}>
          <Grid container>
            <Grid item xs={view === "grid" ? 8 : 10} xl={view === "grid" ? 9 : 10}>
              {cardData?.crawlData?.title ? (
                <Typography variant="bigCaption" mb={2} color={theme.palette.text.titleLabel}>
                  {cardData?.crawlData?.title}
                </Typography>
              ) : (
                <Box display="flex" gap={1} mb={2}>
                  <Avatar src={cardData?.crawlData?.user?.profile_image} />
                  <Box mt={1}>
                    <Typography variant="bigCaption" color={theme.palette.text.titleLabel}>
                      {cardData?.crawlData?.user?.name}
                    </Typography>
                    <Typography variant="subtitle2" color={theme.palette.text.tableHeader}>
                      {cardData?.user?.userid}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item xs={view === "grid" ? 4 : 2} xl={view === "grid" ? 3 : 2} textAlign="right">
              {cardData?.crawlData?.creation_time && (
                <Box ml="auto" display="flex" flexDirection="column">
                  {
                    <Typography variant="body4" color={theme.palette.text.tableHeader}>
                      {/* {formatDateIntoDDMMMYYYY(new Date(date[1]))} */}
                      {date[2]}
                    </Typography>
                  }
                  <Typography variant="body4" color={theme.palette.text.tableHeader}>
                    {`${date[0]} ${date[1]}`}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" gap={1}>
          <img src={publicationImage(cardData?.crawlData?.source)} alt="media" width={"20px"} height={"20px"} style={{ borderRadius: "2px" }} />
          <Typography variant="body4" color={theme.palette.text.tableHeader} display="flex">
            Source:
            <Typography variant="body4" color={theme.palette.text.default}>
              {cardData?.crawlData?.source}
            </Typography>
          </Typography>
        </Box>
        <Grid container spacing={2} mt={1.5} display="flex" justifyContent="flex-end">
          <Grid item xs={cardData?.crawlData?.images?.length > 0 && !imageBroken ? (view === "grid" ? 6 : 9) : 12}>
            <Typography
              variant="body4"
              color={theme.palette.text.titleLabel}
              dangerouslySetInnerHTML={{
                __html: formatHTML(
                  textTruncate(
                    String(
                      showTranslatedFeed
                        ? cardData?.crawlData?.short_description
                        : "translated_content" in cardData?.crawlData
                        ? cardData?.crawlData?.content
                        : cardData?.crawlData?.short_description
                    ),
                    144
                  )
                ),
              }}
            />
          </Grid>
          {cardData?.crawlData?.images?.length > 0 && !imageBroken && (
            <Grid item xs={view === "grid" ? 6 : 3} sx={{ position: "relative", m: view === "grid" ? "auto" : 0 }}>
              {feedImage !== null && (
                <Box display={"flex"}>
                  <img
                    src={feedImage}
                    alt="media"
                    width={"100%"}
                    height={"auto"}
                    style={{ borderRadius: "8px", margin: "auto 0 auto auto" }}
                    onError={(e) => handleImageError(e)}
                  />
                  {cardData?.crawlData?.images?.length > 1 && (
                    <Typography
                      variant="caption1"
                      sx={{
                        position: "absolute",
                        zIndex: 1,
                        bottom: "8px",
                        right: "8px",
                        background: theme.palette.text.titleLabel,
                        color: theme.palette.background.dark,
                        width: "30px",
                        height: "16px",
                        borderRadius: "4px",
                        textAlign: "center",
                        p: "3px",
                      }}
                    >
                      +{cardData?.crawlData?.images?.length - 1}
                    </Typography>
                  )}
                </Box>
              )}
            </Grid>
          )}
        </Grid>
      </Box>
      <Box display="flex" gap={1} mt={5} sx={{ width: "100%", alignSelf: "flex-end", flexWrap: "wrap-reverse" }}>
        <Typography variant="body4" color={theme.palette.text.tableHeader} mt={1}>
          Relevance: 97%
        </Typography>
        <Typography variant="body4" color={theme.palette.text.tableHeader} mt={1} ml={2}>
          Authenticity: 97%
        </Typography>
        <Box display="flex" gap={2} ml="auto">
          {cardData?.military_movement === 1 && view !== "grid" && (
            <CustomSecondaryButton
              sx={{ ...theme.typography, height: "28px" }}
              onClick={(e) => {
                e.stopPropagation();
                handleMilitaryPresence();
              }}
            >
              Military Presence
            </CustomSecondaryButton>
          )}
          {cardData?.live_stream && (
            <CustomSecondaryButton
              sx={{ ...theme.typography, height: "28px" }}
              onClick={(e) => {
                e.stopPropagation();
                handleRouteClick(routes.videoFeed.path.replace(":feedId", cardData?.data_id));
              }}
            >
              Check Live Video
            </CustomSecondaryButton>
          )}
          <CustomStatus label={"Moderate"} status={"moderate"} sx={{ width: "88px", height: "28px", pointerEvents: "none" }} />
        </Box>
      </Box>
      {cardData?.military_movement === 1 && view === "grid" && (
        <CustomSecondaryButton sx={{ ...theme.typography, height: "28px", ml: "auto", mt: 2 }} onClick={handleMilitaryPresence}>
          Military Presence
        </CustomSecondaryButton>
      )}
    </Box>
  );
}
