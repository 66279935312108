import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import CustomDivider from "../../../../../components/CustomDivider";
import CustomTextButton from "../../../../../components/CustomTextButton";
import { routes } from "../../../../../routes";
import { useHandleRouteClick } from "../../../../../routes/hooks";
import ResultsDetailCard from "./ResultDetailCard";

interface ResultsData {
  [x: string]: any;
}

interface ResultsProps {
  results: ResultsData;
}

export interface CardData {
  image: string;
  type: string;
  entityName: string;
  score: number;
  count: number;
  description?: string;
}

export default function RecognationOverview({ results }: ResultsProps) {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();
  const { state } = useLocation();

  const handleViewAll = (model: string, id: number, type: string = "all") => {
    const formatModel = model?.split(" ").join("_").toLowerCase();
    let path = routes.recognation.path.replace(":model", formatModel).replace(":id", String(id));
    path = path.replace(":type", type.toLowerCase() || "noname");
    handleRouteClick(path, { type: type || "noname", dataSource: state?.datasource });
  };

  const getType = (type: string, model: string, id: number) => {
    handleViewAll(model, id, type);
  };

  return (
    <Box width="100%">
      {results.map((result: any, idx: number) => {
        const identifiedResults = result?.data?.filter((iData: CardData) => iData.type !== "");
        const unidentifiedResults = result?.data?.filter((iData: CardData) => iData.type === "");

        return (
          <Box sx={{ width: "100%", border: theme.palette.additionalColors.databasecardBorder, mt: 3, p: 2 }} key={idx}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h2" color={theme.palette.text.titleLabel}>
                {result.model}
              </Typography>
              <CustomTextButton onClick={() => handleViewAll(result?.model, 1)}>View All</CustomTextButton>
            </Box>
            <CustomDivider width="100%" />

            <Box mt={3} pb={3}>
              {result?.identified && (
                <Box my={1.5}>
                  <Typography variant="body3" color={theme.palette.text.tableHeader}>
                    Identified: {result?.identified}
                  </Typography>
                </Box>
              )}
              <Grid container spacing={3}>
                {identifiedResults.length > 0 ? (
                  <>
                    {identifiedResults?.map((iData: CardData, idx: number) => (
                      <Grid item xs={6} sm={4} lg={2} key={`identified-${idx}`} onClick={() => getType(iData.type, result?.model, 1)}>
                        <ResultsDetailCard detail={iData} />
                      </Grid>
                    ))}
                  </>
                ) : (
                  <Box width="100%" my={5} textAlign="center">
                    <Typography>No Data</Typography>
                  </Box>
                )}
              </Grid>
              {result?.unidentified && (
                <Box my={1.5}>
                  <Typography variant="body3" color={theme.palette.text.tableHeader}>
                    Unidentified: {result?.unidentified}
                  </Typography>
                </Box>
              )}
              <Grid container spacing={3}>
                {unidentifiedResults.length > 0 ? (
                  <>
                    {unidentifiedResults?.map((iData: CardData, idx: number) => (
                      <Grid item xs={6} sm={4} lg={2} key={`unidentified-${idx}`} onClick={() => getType(iData.type, result?.model, 1)}>
                        <ResultsDetailCard detail={iData} />
                      </Grid>
                    ))}
                  </>
                ) : (
                  <>
                    {result?.unidentified && (
                      <Box width="100%" my={5} textAlign="center">
                        <Typography>No Data</Typography>
                      </Box>
                    )}
                  </>
                )}
              </Grid>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
