import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import analysisQuerySlice from "./analysis/analysisQuerySlice";
import analysisSlice from "./analysis/analysisSlice";
import connectionsSlice from "./analysis/connectionsSlice";
import entitiesSlice from "./analysis/entitiesSlice";
import magicEditSlice from "./analysis/magicEditSlice";
import authSlice from "./auth/authSlice";
import conversationalSlice from "./conversational/chatSlice";
import drawerSlice from "./drawer/drawerSlice";
import pipelineSlice from "./pipeline/pipelineSlice";
import themeSlice from "./ui/themeSlice";
import uiSlice from "./ui/uiSlice";
import userSessionsSlice from "./userSessions/userSessionsSlice";
import usersSlice from "./users/usersSlice";
import workspaceSlice from "./workspace/workspaceSlice";

const reducers = combineReducers({
  auth: authSlice.reducer,
  analysis: analysisSlice.reducer,
  theme: themeSlice.reducer,
  ui: uiSlice.reducer,
  drawer: drawerSlice.reducer,
  magicEdit: magicEditSlice.reducer,
  pipeline: pipelineSlice.reducer,
  users: usersSlice.reducer,
  analysisQuery: analysisQuerySlice.reducer,
  analysisEntities: entitiesSlice.reducer,
  analysisConnections: connectionsSlice.reducer,
  conversational: conversationalSlice.reducer,
  workspace: workspaceSlice.reducer,
  userSessions: userSessionsSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["pipeline", "analysisQuery"],
};

const rootReducer = (state: any, action: AnyAction) => {
  return reducers(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (
    getDefaultMiddleware // To remove response headers should not be non-serializable error while returning promise in action creators.
  ) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
export const persistor = persistStore(store);
