import { createSlice } from "@reduxjs/toolkit";
import { getQueryById } from "./analysisThunk";
export interface AnalysisInitialState {
  isLoading: boolean;
  feedFilter: any;
  connectionFilter: any;
  mapFilter: any;
  query: Record<string, any>;
}

const analysisInitialState: AnalysisInitialState = {
  isLoading: false,
  feedFilter: {},
  connectionFilter: {},
  mapFilter: {},
  query: {},
};

const analysisQuerySlice = createSlice({
  name: "analysis",
  initialState: analysisInitialState,
  reducers: {
    addFeedFilter(state, action) {
      state.feedFilter = action.payload;
    },
    addConnectionFilter(state, action) {
      state.connectionFilter = action.payload;
    },
    addMapFilter(state, action) {
      state.mapFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetch query data using queryId
    builder
      .addCase(getQueryById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getQueryById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.query = action.payload.data;
      })
      .addCase(getQueryById.rejected, (state, action) => {
        state.isLoading = false;
      });
    
  },
});

export const analysisQueryActions = analysisQuerySlice.actions;

export default analysisQuerySlice;
