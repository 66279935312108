import { Box, CardMedia, Typography, useTheme } from "@mui/material";
import { CardData } from "./RecognationOverview";

interface CardProps {
  detail: CardData;
}
export default function ResultsDetailCard({ detail }: CardProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: theme.palette.background.dark,
        border: `1px solid ${theme.palette.additionalColors.lightBorder}`,
        display: "flex",
        p: 1,
        height: "88px",
        borderRadius: 2,
        flexFlow: "row wrap",
        cursor: "pointer",
      }}
    >
      <Box width="100%" display="flex">
        <Box width="40%">
          <CardMedia component="img" image={detail?.image} alt="image" sx={{ height: "72px", borderRadius: 2 }} />
        </Box>

        <Box width="60%" pl={2}>
          <Typography variant="subtitle1" pt={1.5} color={detail?.type ? theme.palette.text.titleLabel : theme.palette.primary.inactiveIcon}>
            {detail?.type || "Noname"}
          </Typography>

          <Box display="flex" gap={1} mt={2.2} sx={{ width: "100%", alignSelf: "flex-end" }}>
            <Typography sx={{ fontSize: "16px", fontWeight: 700, pt: 1, color: theme.palette.text.titleLabel }}>X</Typography>
            <Typography variant="h2" color={theme.palette.text.titleLabel}>
              {detail?.count}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
