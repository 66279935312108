import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import CustomDivider from "../../../../../components/CustomDivider";
import CustomSecondaryButton from "../../../../../components/CustomSecondaryButton";
import CustomStatus from "../../../../../components/CustomStatus";
import SubmitLoadingDeleteButton from "../../../../../components/SubmitLoadingDeleteButton";
import { routes } from "../../../../../routes";
import { useHandleRouteClick } from "../../../../../routes/hooks";
import { CUSTOM_AI_MODEL } from "../../../../../utils/constants";
import ModelDetails from "./ModelDetails";
import NewTestModal from "./NewTestModal";
import Precision from "./Precision";
import Recall from "./Recall";
import Statistics from "./Statistics";
import TestingContainer from "./TestingContainer";
import TrainingScore from "./TrainingScore";

export default function SpecificModelTraining() {
  const theme = useTheme();
  const params = useParams();
  const handleRouteClick = useHandleRouteClick();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleBackNavigation = () => {
    handleRouteClick(routes.customAI.path);
  };

  const modelDetails = CUSTOM_AI_MODEL.find((data) => data.id === Number(params.id));

  const isProcessing: boolean = modelDetails?.status === "processing";

  const statisticsData = [
    { label: "men", value: 67 },
    { label: "women", value: 35 },
    { label: "children", value: 12 },
  ];

  const handleModalOpen = () => {
    setOpenModal((prev) => !prev);
  };

  return (
    <Box
      px={3}
      mt={2}
      width="100%"
      sx={{
        display: "flex",
        flexFlow: "column",
        height: "100%",
      }}
    >
      <Box display="flex" gap={1} justifyContent="space-between">
        <Box display="flex" gap={3}>
          <Typography variant="h1" color={theme.palette.text.titleLabel} textTransform={"capitalize"}>
            {params?.model?.split("_").join(" ")}
          </Typography>
          <CustomStatus
            label={modelDetails?.status || ""}
            status={isProcessing ? "moderate" : "neutral"}
            sx={{ width: "88px", height: "30px", lineHeight: "50px" }}
          />
        </Box>
        <SubmitLoadingDeleteButton
          isLoading={false}
          sx={{
            ...theme.typography.bigButton,
            px: 6,
          }}
        >
          Delete
        </SubmitLoadingDeleteButton>
      </Box>
      <Box>
        <BackButton sx={{ justifyContent: "flex-start", mr: -1 }} onClick={handleBackNavigation} />
      </Box>
      <CustomDivider width={"100%"} />

      <Box sx={{ height: "100%", overflow: "scroll", pb: 5 }}>
        <Box display="flex" mt={5}>
          <Typography variant="h2">Results</Typography>
          <CustomSecondaryButton sx={{ ...theme.typography.bigButton, ml: "auto", px: 3 }}>Retrain Model</CustomSecondaryButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            mt: 2,
          }}
        >
          <ModelDetails />
        </Box>
        <Grid container spacing={3} mt={3} display={"flex"}>
          <Grid item xs={12} sm={6} lg={3} sx={{ display: "flex" }}>
            <TrainingScore value={80} />
          </Grid>
          <Grid item xs={12} sm={6} lg={3} sx={{ display: "flex" }}>
            <Statistics statisticsData={statisticsData} />
          </Grid>
          <Grid item xs={12} sm={6} lg={3} sx={{ display: "flex" }}>
            <Precision
              categories={["0.00", "0.25", "0.50", "0.75", "1.00"]}
              series={[
                {
                  name: "Alerts count bar graph",
                  data: [1.0, 0.25, 0.5, 0.75, 1.0],
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3} sx={{ display: "flex" }}>
            <Recall
              categories={["0.00", "0.25", "0.50", "0.75", "1.00"]}
              series={[
                {
                  name: "Alerts count bar graph",
                  data: [1.0, 0.25, 0.5, 0.75, 1.0],
                },
              ]}
            />
          </Grid>
        </Grid>
        <Box display="flex" mt={5} mb={2.5}>
          <Typography variant="h2">Testing</Typography>
          <CustomSecondaryButton sx={{ ...theme.typography.bigButton, ml: "auto", px: 3 }} onClick={handleModalOpen}>
            New Test
          </CustomSecondaryButton>
        </Box>
        <TestingContainer />
        <NewTestModal open={openModal} handleModalOpen={handleModalOpen} />
      </Box>
    </Box>
  );
}
