import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomIconButton from "../../../components/CustomIconButton";
import CustomInputField from "../../../components/CustomInputField";
import CustomTertiaryButton from "../../../components/CustomTertiaryButton";
import PrimaryLoadingButton from "../../../components/PrimaryLoadingButton";
import { useHandleRouteClick } from "../../../routes/hooks";
import { useAppDispatch } from "../../../store/hooks";
import { createWorkspace, getWorkspaceDetail, updateWorkspace } from "../../../store/workspace/workspaceThunk";
export interface WorkspaceAddModalProps {
  open: boolean;
  isEditable: boolean;
  handleToggle: () => void;
  setWorkspaceData?: (details: Record<string, any>) => void;
}

export default function WorkspaceAddModal({ open, isEditable, handleToggle, setWorkspaceData }: WorkspaceAddModalProps) {
  const [workspaceName, setWorkspaceName] = useState("");
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { workspaceId } = useParams();
  const handleRouteClick = useHandleRouteClick();
  const [editWorkspaceName, setEditWorkspaceName] = useState<string>("");

  useEffect(() => {
    if (isEditable) {
      dispatch(getWorkspaceDetail({ id: workspaceId })).then((res) => {
        if (res?.payload?.status === 200) {
          setEditWorkspaceName(res.payload.data.name);
        } else {
          handleRouteClick("404");
        }
      });
    }
  }, []);

  const handleSubmit = () => {
    dispatch(createWorkspace({ name: workspaceName })).then((res) => {
      if (res?.payload?.status === 201) {
        toast.success("Workspace Created Successfully");
      }
    });
    handleToggle();
  };

  const handleEdit = () => {
    const workspace_id = parseInt(workspaceId ? workspaceId : "");
    dispatch(updateWorkspace({ id: workspace_id, name: editWorkspaceName })).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success("Workspace Updated Successfully");
        setWorkspaceData && setWorkspaceData({ name: editWorkspaceName });
      }
    });
    handleToggle();
  };

  return (
    <Dialog
      onClose={handleToggle}
      open={open}
      PaperProps={{
        sx: {
          background: theme.palette.background.gradientLight1,
          borderRadius: "8px",
          py: 4,
          px: 4,
          minWidth: { xs: "50vw", lg: "50vw", xl: "40vw" },
        },
      }}
    >
      {" "}
      {!isEditable ? (
        <>
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="h2" m="auto" color={theme.palette.text.titleLabel} sx={{ lineHeight: "100%" }}>
              Add New Workspace
            </Typography>
            <CustomIconButton onClick={handleToggle} sx={{ color: theme.palette.error.main }}>
              <CloseIcon />
            </CustomIconButton>
          </Box>

          <DialogContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center", mt: 6 }}>
            <Box>
              <Typography variant="caption1">To add a workspace to your organization, please enter the new workspace name here.</Typography>
              <CustomInputField
                required
                fullWidth
                value={workspaceName}
                onChange={(e) => setWorkspaceName(e.target.value)}
                placeholder="Workspace Name"
                variant="outlined"
                autoComplete="off"
              />
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  mt: 6,
                  justifyContent: "center",
                }}
              >
                <CustomTertiaryButton sx={{ ...theme.typography.bigButton, minWidth: "8.5rem" }} onClick={handleToggle}>
                  Cancel
                </CustomTertiaryButton>
                <PrimaryLoadingButton isLoading={false} onClick={handleSubmit} sx={{ ...theme.typography.bigButton }}>
                  Add Workspace
                </PrimaryLoadingButton>
              </Box>
            </Box>
          </DialogContent>
        </>
      ) : (
        <>
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="h2" m="auto" color={theme.palette.text.titleLabel} sx={{ lineHeight: "100%" }}>
              Edit Workspace
            </Typography>
            <CustomIconButton onClick={handleToggle} sx={{ color: theme.palette.error.main }}>
              <CloseIcon />
            </CustomIconButton>
          </Box>

          <DialogContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center", pb: 0, mt: 6 }}>
            <Box>
              <Typography variant="caption1">Edit your workspace name </Typography>
              <CustomInputField
                required
                fullWidth
                value={editWorkspaceName}
                onChange={(e) => setEditWorkspaceName(e.target.value)}
                placeholder="Workspace Name"
                variant="outlined"
                autoComplete="off"
              />
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  mt: 6,
                  justifyContent: "center",
                }}
              >
                <CustomTertiaryButton sx={{ ...theme.typography.bigButton, minWidth: "8.5rem" }} onClick={handleToggle}>
                  Cancel
                </CustomTertiaryButton>
                <PrimaryLoadingButton isLoading={false} onClick={handleEdit} sx={{ ...theme.typography.bigButton, minWidth: "8.5rem" }}>
                  Save
                </PrimaryLoadingButton>
              </Box>
            </Box>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
