import { createSlice } from "@reduxjs/toolkit";

export interface UiState {
  isDrawerOpen: boolean;
  activeAnalysisTab: string;
}

const initialState: UiState = {
  isDrawerOpen: false,
  activeAnalysisTab: "overview",
};

const uiSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
    setActiveAnalysisTab(state, action) {
      state.activeAnalysisTab = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleDrawer, setActiveAnalysisTab } = uiSlice.actions;
export default uiSlice;
