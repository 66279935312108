import { createSlice } from "@reduxjs/toolkit";

import { createGroup, deleteGroup, deleteUser, groupsList } from "./usersThunk";

export interface UsersInitialState {
  isLoading: boolean;
}

const usersInitialState: UsersInitialState = {
  isLoading: false,
};

const usersSlice = createSlice({
  name: "users",
  initialState: usersInitialState,
  reducers: {},
  extraReducers: (builder) => {
    // For group creation
    builder
      .addCase(createGroup.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createGroup.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createGroup.rejected, (state, action) => {
        state.isLoading = false;
      });

    // get groups list
    builder
      .addCase(groupsList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(groupsList.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(groupsList.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For delete group
    builder
      .addCase(deleteGroup.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteGroup.rejected, (state, action) => {
        state.isLoading = false;
      });

    //For delete user
    builder
      .addCase(deleteUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const userActions = usersSlice.actions;

export default usersSlice;
