import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import PrimaryLoadingButton from "../../../components/PrimaryLoadingButton";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getWorkspaces } from "../../../store/workspace/workspaceThunk";
import WorkspaceAddModal from "./WorkspaceAddModal";
import { WorkspaceCard } from "./WorkspaceCard";

export default function WorkspaceSection() {
  const [openAddWorkspaceModal, setOpenAddWorkspaceModal] = useState(false);
  const { workspaces } = useAppSelector((state) => state.workspace);
  const { currentUser } = useAppSelector((state) => state.auth);

  const is_admin = currentUser ? currentUser.is_admin : false;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getWorkspaces());
  }, []);

  const toggleAddWorkspaceModal = () => {
    setOpenAddWorkspaceModal((prev) => !prev);
  };

  const theme = useTheme();

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} mb={3}>
        <Typography variant="h2" sx={{ color: theme.palette.text.titleLabel }}>
          Workspace
        </Typography>

        {is_admin && openAddWorkspaceModal ? (
          <WorkspaceAddModal open={openAddWorkspaceModal} isEditable={false} handleToggle={toggleAddWorkspaceModal} />
        ) : is_admin ? (
          <Box>
            <PrimaryLoadingButton
              variant="contained"
              sx={{ ...theme.typography.regularButton, fontWeight: "bold" }}
              onClick={toggleAddWorkspaceModal}
            >
              Add New Workspace
            </PrimaryLoadingButton>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        {workspaces.map((workspace, idx) => {
          return (
            <React.Fragment key={`workspace-${idx}`}>
              <WorkspaceCard data={workspace} index={idx} />
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
}
