import React, { useState, useEffect } from "react";
import ReplayIcon from "@mui/icons-material/Replay";
import { Box, FormControlLabel, Typography, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import CustomIconButton from "../../../components/CustomIconButton";
import CustomSwitch from "../../../components/CustomSwitch";
import UiLoading from "../../../layouts/UiLoading";
import { analysisActions } from "../../../store/analysis/analysisSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getFormattedDate } from "../../../utils/helper";

export interface TitleProps {
  title: string;
  refresh: boolean;
  results?: boolean;
  resultLabel?: string;
  lastUpdated?: string;
  isRefresh?: boolean;
  handleRefresh?(): void;
}

export default function Title({ title, lastUpdated, refresh, results = false, resultLabel, isRefresh = false, handleRefresh }: TitleProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { showTranslatedFeed } = useAppSelector((state) => state.analysis);
  const [currentDateTime, setCurrentDateTime] = useState(lastUpdated);

  useEffect(() => {
    setCurrentDateTime(lastUpdated);
  }, [lastUpdated]);

  const handleRefreshClick = () => {
    const newDate = getFormattedDate(new Date()) + ' at ' + new Date().toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit' });
    setCurrentDateTime(newDate);
    if (handleRefresh) {
      handleRefresh();
    }
  };

  return (
    <Box display="flex" justifyContent="space-between" mb={0.5}>
      {isRefresh && <UiLoading height="100vh" />}
      <Typography variant="h2" color={theme.palette.text.titleLabel}>
        {title}
      </Typography>
      {results && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: theme.palette.action.disabled,
            borderRadius: 6,
            px: 2,
            ml: "10%",
          }}
        >
          <Typography variant="h4" color={theme.palette.text.titleLabel}>
            {resultLabel}
          </Typography>
        </Box>
      )}
      <Box display="flex" gap={2}>
        <Typography variant="subtitle2" color={theme.palette.additionalColors.light}>
          {`Last updated ${currentDateTime} (IST)`}
          <CustomIconButton sx={{ ml: 1, width: "30px", height: "30px", color: theme.palette.error.btnText }} onClick={handleRefreshClick}>
            <ReplayIcon sx={{ color: theme.palette.text.default, transform: "rotate(-80deg)" }} />
          </CustomIconButton>
        </Typography>
        {searchParams.get("subtab") === "feed" && (
          <FormControlLabel
            onChange={() => dispatch(analysisActions.toggleTranslatedFeed())}
            checked={showTranslatedFeed}
            control={<CustomSwitch defaultChecked />}
            label="Show Translated"
            labelPlacement="start"
            sx={{ "& .MuiFormControlLabel-label": { ...theme.typography.subtitle2, color: theme.palette.text.titleLabel } }}
          />
        )}
        {refresh && (
          <FormControlLabel
            control={<CustomSwitch defaultChecked />}
            label="Auto Refresh"
            labelPlacement="start"
            sx={{ "& .MuiFormControlLabel-label": { ...theme.typography.subtitle2, color: theme.palette.text.titleLabel } }}
          />
        )}
      </Box>
    </Box>
  );
}


