import { Box, Typography, useTheme } from "@mui/material";
import Chart from "react-apexcharts";

type ChartColors = string[];

interface ChartOptions {
  series: {
    name: string;
    group: string;
    data: number[];
  }[];

  options: {
    chart: {
      type: "bar";
      height: number;
      toolbar: {
        show: boolean;
      };
    };
    stroke: {
      colors: string[];
      width: number;
      enabled: boolean;
    };
    dataLabels: {
      enabled: boolean;
    };
    plotOptions: {
      bar: {
        horizontal: boolean;
        columnWidth: string;
      };
    };
    xaxis: {
      categories: string[];
      labels: {
        show: boolean;
        offsetY: number;
        style: {
          colors: string | undefined;
          fontWeight: number;
          fontSize: string;
        };
      };
      axisBorder: {
        show: boolean;
      };
      axisTicks: {
        show: boolean;
      };
    };
    fill: {
      opacity: number;
    };
    grid: {
      show: boolean;
      xaxis: {
        lines: {
          show: boolean;
        };
      };
    };
    colors: ChartColors;
    yaxis: {
      labels: {
        show: boolean;
      };
    };
    legend: {
      position: "bottom";
      horizontalAlign: "center";
      labels: {
        colors: string | undefined;
        useSeriesColors: boolean;
      };
    };
    states: {
      hover: { filter: { type: string; value: number } };
      active: { filter: { type: string; value: number } };
    };
  };
}

export default function RiskScoreLevel() {
  const theme = useTheme();

  const option: ChartOptions = {
    series: [
      {
        name: "extreme",
        group: "budget",
        data: [44000, 55000, 41000, 67000, 22000],
      },
      {
        name: "high",
        group: "actual",
        data: [48000, 50000, 40000, 65000, 25000],
      },
      {
        name: "moderate",
        group: "budget",
        data: [13000, 36000, 20000, 8000, 13000],
      },
      {
        name: "neutral",
        group: "actual",
        data: [20000, 40000, 25000, 10000, 12000],
      },
      {
        name: "low",
        group: "actual",
        data: [20000, 40000, 25000, 10000, 12000],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 150,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        colors: ["transparent"],
        width: 10,
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "20px",
        },
      },
      xaxis: {
        categories: ["Level 1", "Level 2", "Level 3", "Level 4", "Level 5"],
        labels: {
          show: true,
          offsetY: 0,
          style: {
            colors: theme.palette.text.titleLabel,
            fontWeight: 400,
            fontSize: "14px",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        show: false,
        xaxis: {
          lines: {
            show: false, //or just here to disable only x axis grids
          },
        },
      },
      colors: [
        theme.palette.text.extremeStatus,
        theme.palette.text.highStatus,
        theme.palette.text.moderateStatus,
        theme.palette.text.neutralStatus,
        theme.palette.text.lowStatus,
      ] as ChartColors,
      yaxis: {
        labels: {
          show: false,
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        labels: {
          colors: theme.palette.text.titleLabel,
          useSeriesColors: false,
        },
      },
      states: {
        hover: { filter: { type: "none", value: 0 } },
        active: { filter: { type: "none", value: 0 } },
      },
    },
  };

  return (
    <Box position="relative">
      <Typography variant="h4" color={theme.palette.text.titleLabel}>
        Risk Score by Level
      </Typography>
      <Chart options={option.options} series={option.series} type="bar" height={220} />

      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "85px",
          bottom: "73px",
          borderBottom: "1px solid",
          borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
          background: theme.palette.dashboardContainer.invertedContainerBackground,
        }}
      />
    </Box>
  );
}
