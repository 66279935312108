import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios";
import { deleteAPI, getAPI } from "../../axios/utils";
import { BASE_URL } from "../../utils/constants";

export const getPipeline = createAsyncThunk("pipeline/getPipeline", async () => {
  return await getAPI(`${BASE_URL}/lcproto/pipeline/get-data/`);
});


export const savePipeline = createAsyncThunk("pipeline/savePipeline", async (data: { name: string; pipeline_config: object }) => {
  return await axiosInstance.post(`${BASE_URL}/lcproto/pipeline/save-data/`, data);
});

export const updatePipeline = createAsyncThunk(
  "pipeline/updatePipeline",
  async ({ data, pipelineId }: { data: { name: string; pipeline_config: object }; pipelineId: string }) => {
    return await axiosInstance.patch(`${BASE_URL}/lcproto/pipeline/${pipelineId}/`, data);
  }
);

export const getPipelineDetail = createAsyncThunk("pipeline/getPipelineDetail", async (pipelineId: string | undefined) => {
  return await getAPI(`${BASE_URL}/lcproto/pipeline/get-data/?id=${pipelineId}`);
});

export const getAllModules = createAsyncThunk("pipeline/getAllModules", async () => {
  return await getAPI(`${BASE_URL}/lcproto/modules/get-data/`);
});

export const deletePipeline = createAsyncThunk("pipeline/deletePipeline", async (pipelineId: string) => {
  return deleteAPI(`${BASE_URL}/lcproto/pipeline/delete/${pipelineId}/`);
});
