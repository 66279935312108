import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import PrimaryLoadingButton from "../../../components/PrimaryLoadingButton";
import NewCustomAIModal from "./NewCustomAIModal";

export default function NoAIModels() {
  const theme = useTheme();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleModalOpen = () => {
    setOpenModal((prev) => !prev);
  };

  return (
    <Box display="flex" width={{ xs: "30%", xl: "25%" }} mt={8} flexDirection="column" justifyContent="center" alignItems="center" mx="auto">
      <Box textAlign="center">
        <img src="/assets/no_result.png" alt="no results" />
        <Typography variant="h2" color={theme.palette.text.titleLabel} mt={2}>
          You haven't created any models yet
        </Typography>
        <Typography variant="h4" color={theme.palette.primary.inactiveIcon} mt={2}>
          Custom AI models use your defined training dataset. This enables the AI to learn, analyze, and produce results.
        </Typography>
        <PrimaryLoadingButton
          sx={{
            ...theme.typography.bigButton,
            width: "140px",
            mt: 4,
          }}
          onClick={handleModalOpen}
        >
          Get Started
        </PrimaryLoadingButton>
      </Box>
      <NewCustomAIModal open={openModal} handleModalOpen={handleModalOpen} />
    </Box>
  );
}
