import AddIcon from "@mui/icons-material/Add";
import ChatIcon from "@mui/icons-material/Chat";
import CropFreeIcon from "@mui/icons-material/CropFree";
import { Box, InputAdornment, Link, OutlinedInput, Typography, useTheme } from "@mui/material";
import { ChangeEvent, useState } from "react";
import CustomDivider from "../../../../../components/CustomDivider";
import CustomInputField from "../../../../../components/CustomInputField";
import CustomTextButton from "../../../../../components/CustomTextButton";
import { customToast } from "../../../../../components/CustomToast";
import AddLabel from "./AddLabel";

interface LabelOPtData {
  [x: string]: any;
}
interface ClusterEditProps {
  noCategory: boolean;
  sourceType: string;
  labels: Record<string, any>;
}

export interface FormDataType {
  name: string;
}

const initialFormData: FormDataType = {
  name: "",
};

export default function ClusterModify({ noCategory, sourceType, labels }: ClusterEditProps) {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [newLabels, setLabel] = useState<LabelOPtData>(labels);
  const [isEdit, setisEdit] = useState<boolean>(false);
  const [labelOpt, setLabelOpt] = useState<number>();
  const [formData, setFormData] = useState(initialFormData);

  const handleClose = () => {
    setOpenModal((prev) => !prev);
  };

  const getAddedLabel = (value: string) => {
    if (value && value !== "") {
      let newObj = { [value]: 10 };
      setLabel({ ...newLabels, ...newObj });
    }
  };

  const updateLabel = (e: ChangeEvent<HTMLInputElement>, idx: number) => {
    setisEdit(true);
    setLabelOpt(idx);
    const labelDataArray = Object.entries(newLabels);

    // Create a new array with the updated key-value pair
    labelDataArray[idx] = [e.target.value, labelDataArray[idx][1]];

    // Convert the updated array back to an object
    setLabel(Object.fromEntries(labelDataArray));
  };

  const saveLabel = () => {
    setisEdit(false);
    setLabel(newLabels);
    setLabelOpt(NaN);
    customToast(theme, "New Label Added", "success");
  };

  const handleInputChange = ({ fieldName, value }: { fieldName: string; value: any }) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const getTotalSum = Object.values(newLabels).reduce((acc: number, currentValue) => acc + currentValue, 0);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        borderRadius: 2,
        p: 3,
      }}
      display="flex"
      flexDirection="column"
    >
      <Box height="90%">
        {sourceType === "image" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body1">Cluster Name</Typography>
            {!noCategory && <CustomTextButton sx={{ ...theme.typography.bigButton, mt: -0.5, ml: "auto" }}>Edit</CustomTextButton>}
            {formData.name !== "" && <CustomTextButton sx={{ ...theme.typography.bigButton, mt: -0.5, ml: "auto" }}>Save</CustomTextButton>}
          </Box>
        )}
        {sourceType === "image" && (
          <Box mb={2} mt={2}>
            {noCategory ? (
              <Typography variant="caption2" color={theme.palette.text.captionColor}>
                How would you like to name this cluster?
              </Typography>
            ) : (
              <Typography variant="caption2" color={theme.palette.text.captionColor}>
                Cluster Name
              </Typography>
            )}
            {noCategory ? (
              <>
                <CustomInputField
                  value={formData.name}
                  required
                  name="name"
                  size="small"
                  variant="outlined"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange({ fieldName: e.target.name, value: e.target.value })}
                  autoComplete="off"
                  sx={{ width: "100%", mb: 1 }}
                />
                <Typography variant="body3">
                  Seems like:{" "}
                  <Typography variant="body3" color={theme.palette.text.default}>
                    Road Sign
                  </Typography>
                </Typography>
              </>
            ) : (
              <Typography variant="subtitle1" pt={1}>
                Persons
              </Typography>
            )}
          </Box>
        )}
        {!noCategory && sourceType === "image" && <CustomDivider width="100%" />}

        <Box
          mt={sourceType === "image" ? 4 : 2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box position="relative" display="flex" gap={2}>
            <CropFreeIcon sx={{ color: theme.palette.primary.light }} />
            <AddIcon sx={{ position: "absolute", ml: 0.5, mt: 0.5, fontSize: "16px", color: theme.palette.primary.light }} />
            <Typography variant="body1" pt={0.5}>
              Labels
            </Typography>
          </Box>
          <CustomTextButton sx={{ ...theme.typography.bigButton, mt: -0.7, ml: "auto" }} onClick={isEdit ? saveLabel : handleClose}>
            {isEdit ? "Save" : "Add Label"}
          </CustomTextButton>
        </Box>
        {!noCategory ? (
          <Box sx={{ height: "70%", overflow: "scroll" }}>
            {Object.keys(newLabels).map((label, idx) => {
              return (
                <OutlinedInput
                  name={label}
                  key={idx}
                  id={label}
                  defaultValue={label}
                  fullWidth
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateLabel(e, idx)}
                  endAdornment={
                    <InputAdornment position="end">
                      {idx !== labelOpt && (
                        <Typography variant="body3" color={theme.palette.text.titleLabel}>{`${newLabels[label]}/${getTotalSum}`}</Typography>
                      )}
                    </InputAdornment>
                  }
                  sx={{
                    mt: 2,
                    borderRadius: 2,
                    "& .MuiInputBase-input.MuiOutlinedInput-input": { ...theme.typography.body3, textTransform: "capitalize", height: "12px" },
                    color: idx !== labelOpt ? theme.palette.additionalColors.light : theme.palette.text.titleLabel,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: theme.palette.additionalColors.databasecardBorder,
                    },
                  }}
                />
              );
            })}
          </Box>
        ) : (
          <Box mt={2}>
            <Typography variant="body3" color={theme.palette.text.tableHeader}>
              You can categorize the items by labeling them
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          background: theme.palette.background.dark,
          borderRadius: 2,
          p: 2,
          mt: "auto",
          flexDirection: { sm: "column", md: "row" },
          gap: 3,
        }}
      >
        <Box display="flex" gap={1}>
          <ChatIcon sx={{ color: theme.palette.text.default, fontSize: "18px" }} />
          <Typography variant="body1">Need help?</Typography>
        </Box>
        <Typography variant="body3" pt={0.5}>
          View the{" "}
          <Link href="#" underline="none">
            tutorial
          </Link>
        </Typography>
      </Box>
      <AddLabel open={openModal} handleModalOpen={handleClose} getAddedLabel={getAddedLabel} />
    </Box>
  );
}
