import { Box } from "@mui/material";
import { memo } from "react";
import ModifiedPublicationCard from "./ModifiedPublicationCard";
interface ListProps {
  [x: string]: any;
}

interface ListViewProps {
  publicationData: ListProps[];
  isRefresh: boolean;
  drawerOpen: boolean;
}
const ListView = ({ publicationData, isRefresh, drawerOpen }: ListViewProps) => {
  return (
    <Box>
      {publicationData?.map((data: any, idx) => (
        <Box mb={2} key={`image-${idx}`}>
          <ModifiedPublicationCard cardData={data} />
        </Box>
      ))}
    </Box>
  );
};

export default memo(ListView);
