import { useTheme } from "@mui/material";

export interface InfoWindowContentProps {
  cityName: string;
  twitterPublications: number;
  instagramPublications: number;
  newsPublications: number;
}
const PublicationInfo = ({ cityName, twitterPublications, instagramPublications, newsPublications }: InfoWindowContentProps) => {
  const theme = useTheme();

  const paraStyle = { color: theme.palette.text.titleLabel, fontWeight: 400, fontSize: "12px", lineHeight: "120%" };

  // Using html elements here, because infoWindow don't allow setting sx styles to mui elements.
  return (
    <div style={{ backgroundColor: "#181D27", color: "#C7DDFD", padding: "10px", margin: "0px" }}>
      <strong>{cityName}</strong>
      <br />
      <span
        id="publicationsId"
        style={{
          color: theme.palette.primary.main,
          fontWeight: 500,
          fontSize: "12px",
          margin: "0px",
          lineHeight: "120%",
          display: "block",
          paddingTop: "13px",
          paddingBottom: "7px",
        }}
      >
        {54} publications:
      </span>
      <span style={paraStyle}>{twitterPublications} in Twitter</span>
      <br />
      <span style={paraStyle}>{instagramPublications} in Instagram</span>
      <br />
      <span style={paraStyle}>{newsPublications} in News</span>
    </div>
  );
};

export default PublicationInfo;
