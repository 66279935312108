import { useState } from "react";
import { Navigate } from "react-router-dom";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Grid, IconButton, InputAdornment, Typography, useTheme } from "@mui/material";
import { Field, Formik } from "formik";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import CustomInputField from "../../components/CustomInputField";
import FormikInputField from "../../components/FormikInputField";
import PrimaryLoadingButton from "../../components/PrimaryLoadingButton";
import SkylarkLabsCircle from "../../layouts/SkylarkLabsCircle";
import { routes } from "../../routes";
import { login } from "../../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setActiveAnalysisTab } from "../../store/ui/uiSlice";

const validationSchema = yup.object({
  userName: yup.string().email("Enter a valid email").required("Email is required"),
  password: yup.string().required("Password is required").min(8, "Password should be of minimum 8 characters length"),
});

export default function LogIn() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { isAuthenticated, isLoading } = useAppSelector((state) => state.auth);
  const [showpassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showpassword);
  };

  const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };

  if (isAuthenticated) {
    dispatch(setActiveAnalysisTab("overview"));
    return <Navigate to={routes.home.path} />;
  }

  return (
    <Grid
      container
      px={5}
      py={{ xs: 25, md: 6, lg: 7 }}
      sx={{
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <SkylarkLabsCircle />
      <Box
        sx={{ zIndex: 900, position: "absolute", top: { xs: 30, md: 30, lg: 40 }, left: { xs: 30, md: 30, lg: 40 }, display: "flex", gap: "13px" }}
      >
        <img src="/skylarklab_logo_black.png" alt="logo" style={{ width: "32px", height: "32px" }} />
        <Box>
          <Typography variant="h2" color={(theme) => theme.palette.text.titleLabel}>
            Low Code Platform
          </Typography>
          <Typography variant="h4" color={(theme) => theme.palette.additionalColors.light} sx={{ textTransform: "none" }}>
            by Skylark
          </Typography>
        </Box>
      </Box>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        xs={12}
        sm={9}
        md={6}
        lg={5.5}
        xl={4}
        py={{ xs: 6, lg: 8, xl: 10 }}
        px={{ xs: 10, sm: 6, lg: 7, xl: 9 }}
        sx={(theme) => ({
          background: theme.palette.dashboardContainer.authBackground,
          boxShadow: theme.palette.dashboardContainer.authBoxShadow,
          borderRadius: "20px",
          zIndex: 900,
        })}
      >
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ zIndex: 1, width: "100%" }}>
          <Typography
            variant="h1"
            sx={{
              fontFeatureSettings: "'liga' off",
              color: (theme) => theme.palette.text.titleLabel,
            }}
          >
            Sign in
          </Typography>
          <Typography mt={2} mb={4} variant="body6" color={(theme) => theme.palette.text.titleLabel}>
            Welcome back. Enter your Email address and Password below.
          </Typography>
          <Formik
            initialValues={{
              userName: "",
              password: "",
            }}
            onSubmit={(values) => {
              const credentials = {
                email: values.userName,
                password: values.password,
              };

              dispatch(login(credentials))
                .then((res) => {
                  if (res?.payload?.status !== 200) {
                    toast.error(res.payload.error.data.error);
                  }
                })
                .catch((err) => {
                  toast.error("Oops! Some issues while submitting.");
                });
            }}
            validationSchema={validationSchema}
          >
            {(props) => (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  props.handleSubmit();
                }}
                style={{ width: "100%" }}
              >
                <Field
                  name="userName"
                  as={CustomInputField}
                  placeholder="Enter your email address"
                  size="small"
                  variant="outlined"
                  value={props.values.userName}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  autoComplete="off"
                  error={props.touched.userName && Boolean(props.errors.userName)}
                  helperText={props.touched.userName && props.errors.userName}
                  style={{ width: "100%" }}
                />
                <Box sx={{ mt: 1, width: "100%" }}>
                  <FormikInputField
                    name="password"
                    placeholder="Enter your password"
                    type={showpassword ? "text" : "password"}
                    style={{ width: "100%" }}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    autoComplete="off"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password confirm visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showpassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box display={"flex"} justifyContent="flex-start" my={1}>
                  <Typography variant="body6">
                    <NavLink
                      to="/forgot-password"
                      style={{
                        textDecoration: "none",
                        color: theme.palette.primary.main,
                      }}
                    >
                      Forgot your password?
                    </NavLink>
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <PrimaryLoadingButton sx={{ p: 0, marginTop: 5, px: "3em" }} isLoading={isLoading}>
                    Sign In
                  </PrimaryLoadingButton>
                  <Typography variant="body6" color={(theme) => theme.palette.text.titleLabel} mt={3}>
                    Do you have an account?
                    <NavLink
                      to="/register"
                      style={{
                        ...theme.typography.body6,
                        color: theme.palette.primary.main,
                        textDecoration: "none",
                        marginLeft: "6px",
                      }}
                    >
                      Sign Up
                    </NavLink>
                  </Typography>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Grid>
    </Grid>
  );
}
