import { Box, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import MapSection from "../Components/Map/index";
import Title from "../Components/Title";
import { getFormattedDate } from "../../../utils/helper";

export default function MapWrapper() {
  const theme = useTheme();
  const [pageRefresh, setPageRefresh] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(getFormattedDate(new Date()));

  const handleRefresh = () => {
    const currentDate = new Date();
    setPageRefresh(true);
    setLastUpdated(getFormattedDate(currentDate) + ' at ' + currentDate.toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit' }));
    setTimeout(removePageRefresh, 2000);
  };

  const removePageRefresh = () => {
    setPageRefresh(false);
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  return (
    <Box
      sx={{
        borderTop: "1px solid",
        borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
        background: theme.palette.dashboardContainer.containerBackground,
        height: "100%",
      }}
      p={2}
    >
      <Title
        title="Map"
        lastUpdated={lastUpdated}
        refresh={true}
        handleRefresh={handleRefresh}
        isRefresh={pageRefresh}
      />
      <MapSection />
    </Box>
  );
}

