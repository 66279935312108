import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CustomCloseIconButton from "../../../../../components/CustomCloseIconButton";
import SpecificEntity from "../../Entities/SpecificEntity";
import InfluencerDetail from "../InfluencerDetail";
import { NodeInfo } from "./NetworkGraph";

export interface EntityDetailsDialogProps {
  open: boolean;
  handleToggle: () => void;
  nodeData: NodeInfo | null;
}

export default function EntityDetailsDialog({ open, handleToggle, nodeData }: EntityDetailsDialogProps) {
  const theme = useTheme();

  const renderNodePopup = () => {
    switch (nodeData?.tail_type) {
      case "influencer":
        return <InfluencerDetail isDialog={true} userId={nodeData?.id} />;
      default:
        return <SpecificEntity isDialog={true} entityId={nodeData?.id} />;
    }
  };

  return (
    <Dialog
      onClose={handleToggle}
      open={open}
      PaperProps={{
        sx: {
          background: theme.palette.background.gradientLight1,
          borderRadius: "8px",
          pb: 2,
          px: 3,
          minWidth: "70vw",
        },
      }}
    >
      <CustomCloseIconButton sx={{ marginLeft: "auto", my: 1 }} onClick={handleToggle}>
        <CloseIcon sx={{ width: "16px", height: "16px" }} />
      </CustomCloseIconButton>
      {renderNodePopup()}
    </Dialog>
  );
}
