import { Box, Grid, Link, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { MultiSelectValueType } from "../../../../components/CheckboxFilterButton";
import PrimaryLoadingButton from "../../../../components/PrimaryLoadingButton";
import { routes } from "../../../../routes";
import { useHandleRouteClick } from "../../../../routes/hooks";
import PipelineCreation from "./PipelineCreation";
import QuerySection, { QueryFormData } from "./QuerySection";

export default function SettingSection() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const [sourceType, setSourceType] = useState<string>("");
  const [canvasData, setCanvasData] = useState<QueryFormData>();
  const [model, setModels] = useState<MultiSelectValueType[]>([]);
  const [uploadedData, setUploadedData] = useState([]);
  const [uploadedDatabase, setUploadedDatabase] = useState<string>("");

  const submitCanvasData = (formData: QueryFormData) => {
    setCanvasData(formData);
  };

  const getDataSources = (sources: string) => {
    setSourceType(sources);
    setUploadedData([]);
  };

  const getSelectedModels = (models: MultiSelectValueType[]) => {
    setModels(models);
  };

  const handleNavigation = (id: string | number) => {
    handleRouteClick(routes.resultsOverview.path.replace(":id", id.toString()));
  };

  const getUploadedData = (data: any) => {
    setUploadedData(data);
  };

  const getSelectedDatabase = (data: string) => {
    setUploadedDatabase(data);
  };

  return (
    <Grid container spacing={2} mt={3}>
      <Grid item xs={12} md={4}>
        <Typography variant="h2" pl={1}>
          1. Query
        </Typography>
        <QuerySection
          submitCanvasData={submitCanvasData}
          getDataSources={getDataSources}
          getSelectedModels={getSelectedModels}
          getUploadedData={getUploadedData}
          getSelectedDatabase={getSelectedDatabase}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="h2">2. Pipeline creation</Typography>
          <Typography variant="subtitle2">
            Create pipeline by linking data source and AI Model.{" "}
            <Link href="#" underline="none">
              View an example
            </Link>
          </Typography>
        </Box>
        <PipelineCreation
          canvasData={canvasData}
          models={model}
          sourceType={sourceType}
          uploadedData={uploadedData}
          uploadedDatabase={uploadedDatabase}
        />
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" mt={2}>
            <img src="/exclamation_mark.png" style={{ width: "16px", height: "16px" }} />
            <Typography variant="body6" color={theme.palette.text.titleLabel} pl={1}>
              You need to connect Data Source and AI Models
            </Typography>
          </Box>
          <PrimaryLoadingButton
            sx={{
              ...theme.typography.hugeButton,
              width: "32%",
              float: "right",
              mt: 2,
            }}
            onClick={() => handleNavigation(1)}
          >
            Analyze
          </PrimaryLoadingButton>
        </Box>
      </Grid>
    </Grid>
  );
}
