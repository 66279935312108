import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import CheckboxFilterButton from "../../../../../components/CheckboxFilterButton";
import CustomButton from "../../../../../components/CustomButton";
import CustomDivider from "../../../../../components/CustomDivider";
import CustomPopover from "../../../../../components/CustomPopover";
import CustomTertiaryButton from "../../../../../components/CustomTertiaryButton";
import CustomTextButton from "../../../../../components/CustomTextButton";
import CustomTooltip from "../../../../../components/CustomTooltip";
import PrimaryLoadingButton from "../../../../../components/PrimaryLoadingButton";
import RadioFilterButton from "../../../../../components/RadioFilterButton";
import SubmitLoadingDeleteButton from "../../../../../components/SubmitLoadingDeleteButton";
import { routes } from "../../../../../routes";
import { useHandleRouteClick } from "../../../../../routes/hooks";
import AddLabel from "./AddLabel";
import ClusterModify from "./ClusterModify";
import EntityDetailModal from "./EntityDetailModal";
import ImageRecognationCard from "./ImageRecognationCard";
import { CardData } from "./RecognationOverview";
import { ResultsData, TextData } from "./ResultsData";
import TextRecognationCard from "./TextRecognationCard";

export default function AllImageRecognation() {
  const theme = useTheme();
  const params = useParams();
  const handleRouteClick = useHandleRouteClick();
  const { state } = useLocation();

  const [category, setCategory] = useState<string>(state?.type.toLowerCase());
  const [clusterEdit, setClusterEdit] = useState<boolean>(false);
  const [isSelect, setSelect] = useState<boolean>(false);
  const [selectedRecords, setSelected] = useState<any>([]);
  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [imgModal, setImgModal] = useState<boolean>(false);
  const [currentImgId, setCurrentImgId] = useState<number>(0);

  const handleBackNavigation = () => {
    handleRouteClick(routes.resultsOverview.path.replace(":id", String(params?.id)));
  };

  const categoryOptions = [
    {
      id: "all",
      text: "All",
    },
    {
      id: "person",
      text: "Person",
    },
    {
      id: "car",
      text: "Car",
    },
    {
      id: "bus",
      text: "Bus",
    },
    {
      id: "cat",
      text: "Cat",
    },
    {
      id: "nature",
      text: "Nature",
    },
  ];

  const labelOptions = [
    { id: "all", text: "All" },
    { id: "man", text: "Man" },
    { id: "women", text: "Women" },
    { id: "child", text: "Child" },
  ];

  const topicOptions = [
    {
      id: "all",
      text: "All",
    },
    {
      id: "healthy food",
      text: "Healthy Food",
    },
    {
      id: "lifestyle",
      text: "lifestyle",
    },
    {
      id: "business",
      text: "Business",
    },
    {
      id: "fashion",
      text: "Fashion",
    },
  ];

  let labelData: Record<string, any> = {
    man: 66,
    women: 58,
  };

  let textLabelData: Record<string, any> = {
    "healthy food": 66,
    lifestyle: 58,
    business: 80,
    fashion: 80,
  };

  const handleCategory = (value: string) => {
    setCategory(value);
  };

  const updateCluster = () => {
    setClusterEdit((prev) => !prev);
  };

  const updateModel = () => {
    console.log("update Model");
  };

  const handleSelect = (idx: number) => {
    if (clusterEdit && isSelect) {
      if (!selectedRecords.includes(idx)) {
        setSelected([...selectedRecords, idx]);
      } else {
        let filteredData = selectedRecords.filter((data: number) => data !== idx);
        setSelected(filteredData);
      }
    } else {
      setCurrentImgId(idx);
      handleImgModal();
    }
  };

  const handleOpenMoreOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreOptionsAnchorEl(event.currentTarget);
  };

  const handleCloseMoreOptions = () => {
    setMoreOptionsAnchorEl(null);
  };

  const handleClose = () => {
    setOpenModal((prev) => !prev);
    handleCloseMoreOptions();
  };

  const getAddedLabel = (value: string) => {
    if (value && value !== "") {
      let newObj = { [value]: 10 };
    }
  };

  const handleSave = () => {
    setSelect(false);
    setSelected([]);
    setClusterEdit(false);
  };

  const handleDeselect = () => {
    setSelected([]);
  };

  const handleSelection = () => {
    setSelect((prev) => !prev);
    setSelected([]);
  };

  const handleImgModal = () => {
    setImgModal((prev) => !prev);
  };

  const modelsData = state?.dataSource === "image" ? ResultsData : TextData;

  const filtredData = modelsData.find((modelData) => {
    let modelName = params?.model?.split("_").join(" ");
    return modelName === modelData?.model?.toLowerCase();
  });

  const specificCategoryData = filtredData?.data.filter((mData) => {
    if (category !== "all" && category !== "noname") {
      return mData.type.toLowerCase() === category;
    }
    if (category === "noname") {
      return mData.type === "";
    }
    return mData;
  });

  const isCategory = category === "all";

  const noCategory = category === "noname";

  return (
    <Box
      px={3}
      mt={2}
      width="100%"
      sx={{
        display: "flex",
        flexFlow: "column",
        height: "100%",
      }}
    >
      <Box display="flex" gap={1} justifyContent="space-between">
        <Box display="flex">
          <Typography variant="h1" color={theme.palette.text.titleLabel} textTransform={"capitalize"}>
            {clusterEdit ? "Edit Cluster" : params?.model?.split("_").join(" ")}
          </Typography>
          {!isCategory && !clusterEdit && (
            <Typography variant="h1" color={theme.palette.text.titleLabel}>
              {`/ ${category}`}
            </Typography>
          )}
        </Box>
        {!clusterEdit && (
          <PrimaryLoadingButton
            sx={{
              ...theme.typography.bigButton,
              height: "36px",
              px: 3,
            }}
            onClick={isCategory ? updateModel : updateCluster}
          >
            {isCategory ? "Edit Model" : "Edit Cluster"}
          </PrimaryLoadingButton>
        )}
        {clusterEdit && (
          <PrimaryLoadingButton
            sx={{
              ...theme.typography.bigButton,
              height: "36px",
              px: 3,
            }}
            onClick={handleSave}
          >
            Save
          </PrimaryLoadingButton>
        )}
      </Box>
      <Box>
        <BackButton sx={{ justifyContent: "flex-start" }} onClick={handleBackNavigation} />
      </Box>
      {clusterEdit && <CustomDivider width={"100%"} />}
      {isSelect && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            background: theme.palette.background.dark,
            mt: 2,
            p: 1.5,
            borderRadius: 2,
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              gap: 2,
            },
          }}
        >
          <Box display="flex" gap={3}>
            <Typography variant="body3" color={theme.palette.text.captionColor} pt={1.5}>
              {selectedRecords.length} items selected
            </Typography>
            <CustomTertiaryButton sx={{ height: "36px", px: 1.5 }}>Select All</CustomTertiaryButton>
            <CustomTertiaryButton sx={{ height: "36px", px: 1.5 }} onClick={handleDeselect}>
              Deselect
            </CustomTertiaryButton>
          </Box>

          <Box display="flex" gap={2}>
            <CustomTertiaryButton sx={{ height: "36px", px: 3 }} onClick={handleSelection}>
              Cancel
            </CustomTertiaryButton>
            <CustomButton
              sx={{
                width: "143px",
                height: "36px",
                justifyContent: "space-between",
                pl: 1.5,
                background: theme.palette.background.gradientLight1,
                boxShadow: theme.palette.dashboardContainer.shareMenuDropdownBoxShadow,
                color: theme.palette.text.titleLabel,
              }}
              endIcon={<ExpandMoreOutlinedIcon sx={{ color: theme.palette.text.default, fontSize: "30px !important" }} />}
              onClick={handleOpenMoreOptions}
            >
              Label
            </CustomButton>
            <CustomPopover
              open={Boolean(moreOptionsAnchorEl)}
              anchorEl={moreOptionsAnchorEl}
              onClose={handleCloseMoreOptions}
              sx={{ "& .MuiPopover-paper": { px: 1.2, py: 1.8 } }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  "&>.MuiButtonBase-root": {
                    justifyContent: "start",
                    textAlign: "start",
                  },
                }}
              >
                <CustomTextButton onClick={() => console.log("1")} sx={{ color: theme.palette.text.titleLabel }}>
                  Women
                </CustomTextButton>
                <CustomDivider width={"100%"} />
                <CustomTextButton onClick={() => console.log("1")} sx={{ color: theme.palette.text.titleLabel, mt: 1 }}>
                  Man
                </CustomTextButton>
                <CustomDivider width={"100%"} />
                <CustomTextButton
                  sx={{ color: theme.palette.text.titleLabel, mt: 1 }}
                  startIcon={<AddOutlinedIcon sx={{ color: theme.palette.text.default }} />}
                  onClick={handleClose}
                >
                  Add new label
                </CustomTextButton>
              </Box>
            </CustomPopover>

            <SubmitLoadingDeleteButton
              isLoading={false}
              sx={{
                height: "36px",
                px: 5,
              }}
            >
              Delete
            </SubmitLoadingDeleteButton>
          </Box>
        </Box>
      )}
      <Box mt={2}>
        <Box display="flex">
          <Box display="flex" gap={1}>
            <Typography variant="body2" color={theme.palette.text.titleLabel}>
              674 entity detected
            </Typography>
            {noCategory && (
              <CustomTooltip
                title={<Typography variant="body4">System identified these items like similar based on color/shape/etc</Typography>}
                placement="bottom-end"
              >
                <InfoOutlinedIcon sx={{ color: theme.palette.text.default, fontSize: "18px" }} />
              </CustomTooltip>
            )}
          </Box>

          {!noCategory && (
            <Box display="flex" sx={{ ml: "auto", gap: 2 }}>
              {clusterEdit && !isSelect && (
                <CustomTertiaryButton sx={{ px: 3 }} onClick={handleSelection}>
                  Select
                </CustomTertiaryButton>
              )}
              {!clusterEdit && state?.dataSource === "image" && (
                <RadioFilterButton
                  label="Category"
                  options={categoryOptions}
                  onChange={handleCategory}
                  defaultValue={category}
                  sx={{ width: "150px", border: "none" }}
                />
              )}
              {state?.dataSource !== "image" && (
                <RadioFilterButton
                  label="Topic"
                  options={topicOptions}
                  onChange={handleCategory}
                  defaultValue={category}
                  sx={{ width: "150px", border: "none" }}
                />
              )}
              {!isCategory && state?.dataSource === "image" && (
                <CheckboxFilterButton
                  label="Labels"
                  options={labelOptions}
                  onChange={() => console.log("label")}
                  sx={{ width: "120px", border: "none" }}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          overflow: "hidden",
          pb: 4,
          mt: 2,
        }}
      >
        <Grid container spacing={2} overflow="hidden" height="100%">
          <Grid item sm={!clusterEdit ? 12 : 7} md={!clusterEdit ? 12 : 8} xl={!clusterEdit ? 12 : 9} height="100%">
            <Box
              pl={1}
              pr={3}
              pb={6}
              sx={{
                background: theme.palette.background.darkCardBackground,
                border: theme.palette.additionalColors.databasecardBorder,
                borderRadius: 2,
                height: "100%",
                overflow: "scroll",
              }}
            >
              {state?.dataSource === "image" ? (
                <Grid container mt={3}>
                  {specificCategoryData?.map((modelData: CardData, idx: number) => {
                    return (
                      <Grid
                        item
                        xs={4}
                        sm={clusterEdit ? 6 : 3}
                        md={clusterEdit ? 4 : 12 / 7}
                        xl={clusterEdit ? 12 / 7 : 12 / 9}
                        key={`model-${idx}`}
                        sx={{ pl: 2, pb: 4 }}
                      >
                        <ImageRecognationCard
                          resultsData={modelData}
                          handleSelect={() => handleSelect(idx)}
                          select={isSelect}
                          checked={selectedRecords?.includes(idx) ? true : false}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <Grid container mt={3}>
                  {specificCategoryData?.map((modelData: CardData, idx: number) => {
                    return (
                      <Grid
                        item
                        xs={4}
                        sm={12}
                        md={clusterEdit ? 12 : 6}
                        xl={clusterEdit ? 12 : 6}
                        key={`model-${idx}`}
                        sx={{ pl: 2, pb: 4 }}
                        display="flex"
                      >
                        <TextRecognationCard
                          resultsData={modelData}
                          handleSelect={() => handleSelect(idx)}
                          select={isSelect}
                          checked={selectedRecords?.includes(idx) ? true : false}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Box>
          </Grid>
          {clusterEdit && (
            <Grid item sm={5} md={4} xl={3} height="100%">
              <ClusterModify
                noCategory={noCategory}
                sourceType={state?.dataSource}
                labels={state?.dataSource === "image" ? labelData : textLabelData}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <AddLabel open={openModal} handleModalOpen={handleClose} getAddedLabel={getAddedLabel} />
      <EntityDetailModal
        open={imgModal}
        handleModalOpen={handleImgModal}
        data={filtredData}
        index={currentImgId}
        sourceType={state?.dataSource}
        labels={state?.dataSource === "image" ? labelData : textLabelData}
      />
    </Box>
  );
}
