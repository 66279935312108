import { createSlice } from "@reduxjs/toolkit";
import { sendChatMessage } from "./chatThunk";

export type MessageType = {
  role: string;
  content: Record<string, any>;
};

export type ConversationalChatInitialStateType = {
  isLoading: boolean;
  messages: Array<MessageType>;
};

const conversationalChatInitialState: ConversationalChatInitialStateType = {
  isLoading: false,
  messages: [
    // {
    //   role: "system",
    //   content: "You are a helpful assistant, you provide answers to user's questions",
    // },
    // {
    //   role: "user",
    //   content: "Is russia going to attack ukraine in coming days",
    // },
    // {
    //   role: "assistant",
    //   content: "It appears russia can attack ukraine in coming days given the increased troop deployment near desputed territories",
    // },
    // {
    //   role: "user",
    //   content: "What can Ukraine do to mitigate the attack",
    // },
  ],
};

const conversationalSlice = createSlice({
  name: "conversational",
  initialState: conversationalChatInitialState,
  reducers: {
    pushMessages(state, action) {
      state.messages = [...state.messages, action.payload];
    },
  },
  extraReducers: (builder) => {
    // Send message
    builder
      .addCase(sendChatMessage.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(sendChatMessage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.messages = [...state.messages, {role: "assistant", content: action.payload?.data}];
      })
      .addCase(sendChatMessage.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const conversationalActions = conversationalSlice.actions;

export default conversationalSlice;
