import { createSlice } from "@reduxjs/toolkit";
import { endUserSession, getUserSessions } from "./userSessionsThunk";

interface InitialStateInterface {
  isLoading: boolean;
  userSessions: Array<Record<string, any>>;
}

const initialState: InitialStateInterface = {
  isLoading: false,
  userSessions: [],
};

const userSessionsSlice = createSlice({
  name: "userSessionsSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get all user sessions
    builder
      .addCase(getUserSessions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserSessions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userSessions = action.payload.data;
      })
      .addCase(getUserSessions.rejected, (state, action) => {
        state.isLoading = false;
      });

    // End a user session
    builder
      .addCase(endUserSession.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(endUserSession.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(endUserSession.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const aiServiceActions = userSessionsSlice.actions;
export default userSessionsSlice;
