import { createAsyncThunk } from "@reduxjs/toolkit";

import { deleteAPI, getAPI, patchAPI, postAPI } from "../../axios/utils";
import { AUTH_SERVICE_BASE_URL } from "../../utils/constants";

//List All Workspace of a organization
export const getWorkspaces = createAsyncThunk("workspace/getWorkspaces", async () => {
  return getAPI(`${AUTH_SERVICE_BASE_URL}/auth/groups/`);
});

//List All Users of a workspace
export const getAllWorkspaceUsers = createAsyncThunk("workspace/getAllWorkspaceUsers", async (data: Record<string, any>) => {
  const { group_id } = data;
  return getAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/staff/?group_id=${group_id}`);
});

export const getWorkspaceUser = createAsyncThunk("workspace/getWorkspaceUser", async (data: Record<string, any>, thunkAPI) => {
  console.log("data at getWorkspaceUser", data);
  return getAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/staff/${data.id}/`);
});

//Add User to a workspace
export const addUserToWorkspace = createAsyncThunk("workspace/addUserToWorkspace", async (data: Record<string, any>, thunkAPI) => {
  return postAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/staff/`, data, {}, true);
});

// update user Profile in a workspace
export const updateUserProfile = createAsyncThunk("workspace/updateUserProfile", async (data: Record<string, any>, thunkAPI) => {
  //console.log("Data at patch ", data);
  return patchAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/staff/${data.id}/`, data, {}, true);
});

export const deleteUserFromWorkspace = createAsyncThunk("workspace/deleteUserFromWorkspace", async (data: Record<string, any>, thunkAPI) => {
  const id = data.id;
  const response: Record<string, any> = await deleteAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/staff/${data.id}/`);
  return { response, id };
});

export const deactivateUser = createAsyncThunk("workspace/deactivateUser", async (data: Record<string, any>, thunkAPI) => {
  await patchAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/staff/${data.id}/deactivate-user/`, data, {}, true);
  return data.id;
});

export const activateUser = createAsyncThunk("workspace/activateUser", async (data: Record<string, any>, thunkAPI) => {
  await patchAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/staff/${data.id}/activate-user/`, data, {}, true);
  return data.id;
});
//Create Workspace for an organization
export const createWorkspace = createAsyncThunk("workspace/createWorkspace", async (data: Record<string, any>, thunkAPI) => {
  return postAPI(`${AUTH_SERVICE_BASE_URL}/auth/groups/`, data);
});

//Update Workspace Details
export const updateWorkspace = createAsyncThunk("workspace/updateWorkspace", async (data: Record<string, any>, thunkAPI) => {
  //console.log(data);
  return patchAPI(`${AUTH_SERVICE_BASE_URL}/auth/groups/${data.id}/`, data, {}, true);
});

//Delete Workspace
export const deleteWorkspace = createAsyncThunk("workspace/deleteWorkspace", async (id: number, thunkAPI) => {
  const response: Record<string, any> = await deleteAPI(`${AUTH_SERVICE_BASE_URL}/auth/groups/${id}/`);
  return { id, response };
});

export const setAdmin = createAsyncThunk("workspace/setAdmin", async (data: Record<string, any>, thunkAPI) => {
  return patchAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/staff/${data.id}/set-admin-status/`, data, {}, true);
});

export const removeAdmin = createAsyncThunk("workspace/removeAdmin", async (data: Record<string, any>, thunkAPI) => {
  return patchAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/staff/${data.id}/remove-admin-status/`, data, {}, true);
});

export const getWorkspaceDetail = createAsyncThunk("workspace/getWorkspaceDetail", async (data: Record<string, any>, thunkAPI) => {
  return getAPI(`${AUTH_SERVICE_BASE_URL}/auth/groups/${data.id}`);
});
