import TabLayout from "../../layouts/TabLayout";
import HomeContent from "./components/HomeContent";

const tabs = [
  {
    id: "pipelines",
    title: "Pipelines",
    disabled: false,
    component: <HomeContent />,
  },
];

export default function Home() {
  return <TabLayout tabs={tabs} />;
}
