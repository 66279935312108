import FavoriteIcon from "@mui/icons-material/Favorite";
import MessageIcon from "@mui/icons-material/Message";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PersonIcon from "@mui/icons-material/Person";
import PlaceIcon from "@mui/icons-material/Place";
import ThreePIcon from "@mui/icons-material/ThreeP";
import { Box, Grid, Link, Typography, useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useEffect, useState } from "react";
import { HiMiniCursorArrowRipple } from "react-icons/hi2";
import CustomCarousel from "../../../../../../../components/CustomCarousel";
import CustomChip from "../../../../../../../components/CustomChip";
import CustomIconButton from "../../../../../../../components/CustomIconButton";
import CustomSecondaryButton from "../../../../../../../components/CustomSecondaryButton";
import CustomTextButton from "../../../../../../../components/CustomTextButton";
import RedflagChip from "../../../../../../../components/RedflagChip";
import { publicationImage } from "../PublicationCard";
import EditTagsDialog, { TagsData } from "./EditTagsDialog";
import Interactions from "./Interaction";
import PublicationDetails from "./PublicationDetails";

interface Info {
  [x: string]: any;
}

// export interface PublicationData {
//   data_id: string;
//   prediction: string;
//   crawlData: {
//     kind: string;
//     source: string;
//     name?: string;
//     author?: string;
//     title: string;
//     content?: string;
//     original_content?: string;
//     translated_content?: string;
//     short_description: string;
//     date: string;
//     processed: boolean;
//     images: null | any[];
//     url: string;
//     category: string;
//     data_source: string;
//     user?: {
//       name?: string;
//       [x: string]: any;
//     };
//   };
//   query_result: {
//     data_id: string;
//     detected_redflags: string[];
//     detected_redflags_count: number;
//     id: number;
//     sentiment: any;
//     threat: any;
//   };
// }

export interface PublicationData {
  _id: string;
  kind: string;
  source: string;
  data_source: string;
  url: string;
  title: string;
  date: string;
  language: string;
  content: string;
  creation_time: string;
  handle: string;
  name: string;
  profile_image: string;
  followers: number;
  total_posts: number;
  user: any;
  authors: any[];
  likes: number;
  comments: number;
  images: string[];
  videos: any[];
  video_paths: any[];
  video_url: string;
  video_thumbnail: string;
  video_duration: number;
  video_views: number;
  related_articles: Record<string, any>[];
  query_id: string;
  data_result: any;
}
interface InfoProps {
  infoData: Info;
  publicationData: PublicationData | null;
  getData: (data: TagsData) => void;
  editing: boolean;
  // redFlags:string[]
}

export default function PublicationInfo({ infoData, publicationData, getData, editing }: InfoProps) {
  const theme = useTheme();
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [openInteractions, setOpenInteractions] = useState<boolean>(false);
  const [tab, setActiveTab] = useState<string>("");
  const [tags, setTags] = useState<string[]>(infoData?.tag);
  const [editingTags, setEditingTags] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [id, setId] = useState<string>("");

  useEffect(() => setOpen(anchorEl ? true : false), [anchorEl]);

  useEffect(() => setId(open ? "simple-popover" : ""), [open]);

  const handleModalOpen = () => {
    setOpenDetails((prev) => !prev);
  };

  const handleInteractionOpen = () => {
    setActiveTab("likes");
    setOpenInteractions((prev) => !prev);
  };

  const handlecommentInteractionOpen = () => {
    setActiveTab("comments");
    setOpenInteractions((prev) => !prev);
  };

  const handleTagsBoxClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setEditingTags(true);
    setAnchorEl(event.currentTarget);
  };
  const getDataHandler = (data: TagsData) => {
    setTags(data.tags);
    getData(data);
  };

  const handleEditTagsClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setEditingTags(false);
    setOpen(false);
    setAnchorEl(null);
  };

  const likesData = [
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: true },
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: true },
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: true },
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: true },
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
  ];
  const commentsData = [
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: true },
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
    { profile: "", name: "Ashlynn Baptista", userId: "@rattletrap1776", risky: false },
  ];

  const handleOpenSource = () => {
    window.open(publicationData?.url, "_blank");
  };

  const renderContent = () => {
    if (publicationData?.content && publicationData?.data_result?.translated_content) {
      return (
        <>
          <Box mb={3}>
            <Typography variant="h6">Content:</Typography>
            <Typography variant="body3" color={theme.palette.text.titleLabel}>
              {publicationData?.content || publicationData?.data_result?.content}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography variant="h6">Translated Content:</Typography>
            <Typography variant="body3" color={theme.palette.text.titleLabel}>
              {publicationData?.data_result?.translated_content}
            </Typography>
          </Box>
        </>
      );
    } else {
      return (
        <Typography variant="body3" color={theme.palette.text.titleLabel}>
          {publicationData?.content || publicationData?.data_result?.content}
        </Typography>
      );
    }
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={3}>
        <Box display="flex" mb={3} gap={1.5}>
          <Avatar
            src={
              (publicationData?.authors && publicationData.authors.length > 0 && publicationData.authors[0]?.profile_image) ||
              (publicationData?.user && publicationData?.user?.profile_image)
            }
          />
          <Typography variant="subtitle1" color={theme.palette.text.titleLabel} mt={1.5}>
            {publicationData?.user?.name}
          </Typography>
        </Box>
        <img src={publicationImage(publicationData?.source)} alt="media" style={{ width: "138px", height: "138px", borderRadius: "8px" }} />
        <br />
        <Link href={`https://www.${publicationData?.source}.com`} underline="none" sx={{ ...theme.typography.body3 }}>
          {publicationData?.source}
        </Link>
        <Typography variant="h1" mt={5} color={theme.palette.text.titleLabel}>
          {publicationData?.kind}
        </Typography>
        {publicationData?.kind === "news" ? (
          <Typography variant="body5" color={theme.palette.text.titleLabel} mt={3}>
            {publicationData?.data_result?.content}
          </Typography>
        ) : (
          <Box>
            <Box mt={2}>
              {(publicationData?.followers || publicationData?.user?.followers) && (
                <Box display="flex" gap={1}>
                  <PersonIcon sx={{ fontSize: "18px", mt: -0.5, color: theme.palette.text.tableHeader }} />
                  <Typography variant="subtitle2" color={theme.palette.text.titleLabel}>
                    {`Followers :${publicationData?.followers || publicationData?.user?.followers}`}
                  </Typography>
                </Box>
              )}
              {(publicationData?.total_posts || publicationData?.user?.total_posts) && (
                <Box display="flex" gap={1} mt={1.5}>
                  <ThreePIcon sx={{ fontSize: "18px", mt: -0.5, color: theme.palette.text.tableHeader }} />
                  <Typography variant="subtitle2" color={theme.palette.text.titleLabel}>
                    {`Posts :${publicationData?.total_posts || publicationData?.user?.total_posts}`}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box mt={2}>
              <Link href={"#"} underline="none" sx={{ ...theme.typography.body4 }}>
                {publicationData?.data_result?.hashtags.join(" ")}
              </Link>
            </Box>
          </Box>
        )}
        <Box color={theme.palette.text.default} display="flex" mt={5}>
          <PlaceIcon sx={{ fontSize: "1em", mt: -1.8 }} />
          <Typography variant="body5" mt={-1}>
            Atlanta, Georgia, USA
          </Typography>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="flex-start"
          alignItems="flex-start"
          mt={3}
          sx={
            editing
              ? {
                  height: " 80px",
                  border: "2px solid transparent",
                  borderRadius: "4px",
                  backgroundImage: editingTags
                    ? theme.palette.additionalColors.cardActiveBorderImage
                    : theme.palette.additionalColors.cardDefaultBorderImage,
                  backgroundOrigin: "border-box",
                  backgroundClip: "content-box, border-box",
                  boxShadow: theme.palette.dashboardContainer.cardInsetBoxShadow,
                  position: "relative",
                }
              : {}
          }
          onClick={handleTagsBoxClick}
        >
          {tags.map((data: string, index: number) => (
            <CustomChip
              key={index}
              label={data}
              sx={{
                color: theme.palette.error.btnText,
                height: "24px",
                border: "none",
                mx: "4px",
                my: "4px",
                borderRadius: "4px",
              }}
              onDelete={() => {}}
              variant="outlined"
              deleteIcon={<></>}
            />
          ))}
          {editingTags && (
            <HiMiniCursorArrowRipple size={30} color={theme.palette.text.titleLabel} style={{ position: "absolute", bottom: 0, right: 4 }} />
          )}
          <EditTagsDialog anchor={anchorEl} id={id} open={open} defaultTags={tags} getData={getDataHandler} handleClose={handleEditTagsClose} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "left",
            padding: "5px",
          }}
        >
          <Typography variant="body3" color={theme.palette.error.main} mt={1}>
            Red Flags
          </Typography>
          <Box display="flex" flexWrap="wrap" justifyContent="flex-start" alignItems="flex-start" mt={1}>
            {publicationData?.data_result?.detected_redflags?.map((data: string, index: number) => (
              <RedflagChip
                key={index}
                label={data}
                sx={{
                  color: theme.palette.error.btnText,
                  height: "24px",
                  border: "none",
                  mx: "4px",
                  my: "4px",
                  borderRadius: "4px",
                }}
                onDelete={() => {}}
                variant="outlined"
                deleteIcon={<></>}
              />
            ))}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Typography variant="body4" color={theme.palette.text.tableHeader}>
          {publicationData?.date}
        </Typography>
        {publicationData?.title && publicationData?.title !== "NA" && (
          <Box mt={2}>
            <Typography variant="h2" color={theme.palette.text.titleLabel}>
              {publicationData?.title}
            </Typography>
          </Box>
        )}
        <Box mt={3}>{renderContent()}</Box>
        <Box mt={2}>
          {publicationData?.images && publicationData?.images.length > 0 && publicationData?.images[0].length > 1 && (
            <CustomCarousel
              images={publicationData?.images.map((img, index) => ({
                id: index + 1,
                img: img,
              }))}
              source={publicationData?.source}
            />
          )}
        </Box>
        <Box display="flex" mt={3}>
          <Box display="flex" gap={2} sx={{ color: theme.palette.text.tableHeader }}>
            {publicationData?.likes !== undefined && (
              <>
                <CustomIconButton sx={{ width: "40px", height: "40px" }} onClick={handleInteractionOpen}>
                  <FavoriteIcon sx={{ color: theme.palette.text.tableHeader }} />
                </CustomIconButton>
                <Typography variant="h6" mt={1.8}>
                  {`${publicationData?.likes} Likes`}
                </Typography>
              </>
            )}
            {publicationData?.comments !== undefined && (
              <>
                <CustomIconButton sx={{ width: "40px", height: "40px" }} onClick={handlecommentInteractionOpen}>
                  <MessageIcon sx={{ color: theme.palette.text.tableHeader }} />
                </CustomIconButton>
                <Typography variant="h6" mt={1.8}>
                  {`${publicationData?.comments} Comments`}
                </Typography>
              </>
            )}
          </Box>
          <Box ml="auto" display="flex" gap={1.5}>
            <CustomTextButton sx={{ ml: -2, mr: 1 }} onClick={handleModalOpen}>
              View Details
            </CustomTextButton>
            <CustomSecondaryButton endIcon={<OpenInNewIcon />} onClick={handleOpenSource}>
              Open Source
            </CustomSecondaryButton>
          </Box>
        </Box>
      </Grid>
      <PublicationDetails open={openDetails} handleClose={handleModalOpen} />
      <Interactions
        open={openInteractions}
        handleClose={tab === "likes" ? handleInteractionOpen : handlecommentInteractionOpen}
        interactionData={{ likesData: likesData, commentsData: commentsData }}
        activeTabOpt={tab}
      />
    </Grid>
  );
}
