import BusinessIcon from "@mui/icons-material/Business";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import SourceIcon from "@mui/icons-material/Source";
import { Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getEntityConnections } from "../../../../../../store/analysis/analysisThunk";
import { useAppDispatch } from "../../../../../../store/hooks";
import { capitalize } from "../../../../../../utils/helper";
import ConnectionCard from "./ConnectionsCard";
interface ConnectionsProps {
  entities?: Record<string, any>[] | undefined;
  entityId?: string;
}

export default function Connections({ entities = [], entityId = "" }: ConnectionsProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const params = useParams();
  // const { entityConnections } = useAppSelector((state) => state.analysisConnections);
  const [currEntities , setCurrentEntities] = useState<Record<string,any>[]>(entities);
  
  const persons = currEntities?.filter((e) => e.type === "person");
  const organizations = currEntities?.filter((e) => e.type === "organization");
  const topics = currEntities?.filter((e) => e.type === "topic");
  const locations = currEntities?.filter((e) => e.type === "location");
  const events = currEntities?.filter((e) => e.type === "event");
  const others = currEntities?.filter((e) => e.type === null);
  console.log("currEntities ", currEntities);
  React.useEffect(() => {
    let id = params.entity_id || entityId;
    if (id) {
      dispatch(getEntityConnections(id)).then((res)=>{
        if(res?.payload?.status===200){
          setCurrentEntities(res?.payload?.data?.entities);
        }
      });
    }
  }, [params]);

  useEffect(()=>{
    if (entities && entities.length > 0) {
      setCurrentEntities(entities);
    }
  },[entities])

  const PersonData = {
    icon: <PersonIcon sx={{ m: "auto" }} />,
    title: `${persons?.length} Persons`,
    data: persons?.map((v) => ({ name: v.name ? capitalize(v.name) : "", description: v.description ? capitalize(v.description) : "" })),
  };

  const orgData = {
    icon: <BusinessIcon sx={{ m: "auto" }} />,
    title: `${organizations?.length} Organizations`,
    // data: organizations?.map((v) => ({ name: (v?.name), description: (v?.connection_type) })),
    data: organizations?.map((v) => ({ name: v.name ? capitalize(v.name) : "", description: v.description ? capitalize(v.description) : "" })),
  };

  const locationData = {
    icon: <LocationOnIcon sx={{ m: "auto" }} />,
    title: `${locations?.length} Locations`,
    data: locations?.map((v) => ({ name: v.name ? capitalize(v.name) : "", description: v.description ? capitalize(v.description) : "" })),
  };

  const topicData = {
    icon: <SourceIcon sx={{ m: "auto" }} />,
    title: `${topics?.length} Topics`,
    data: topics?.map((v) => ({ name: v.name ? capitalize(v.name) : "", description: v.description ? capitalize(v.description) : "" })),
  };

  const eventsData = {
    icon: <CalendarTodayIcon sx={{ m: "auto" }} />,
    title: `${events?.length} Events`,
    data: events?.map((v) => ({ name: v.name ? capitalize(v.name) : "", description: v.description ? capitalize(v.description) : "" })),
  };

  const othersData = {
    icon: <CalendarTodayIcon sx={{ m: "auto" }} />,
    title: `${others?.length} Miscellaneous`,
    data: others?.map((v) => ({ name: v.name ? capitalize(v.name) : "", description: v.description ? capitalize(v.description) : "" })),
  };

  return (
    <Grid container mt={3} mb={5}>
      <Typography variant="h3" color={theme.palette.text.titleLabel} sx={{ fontSize: "22px" }}>
        Connections
      </Typography>
      <Typography
        ml="auto"
        variant="link5"
        color={theme.palette.text.default}
        display={"flex"}
        sx={{
          cursor: "pointer",
          fontSize: "16px",
        }}
      >
        View Connections
        <ChevronRightIcon sx={{ color: theme.palette.text.default, mt: "-6px" }} />
      </Typography>
      <Grid mt={"-10px"} container spacing={3}>
        <Grid item xs={12} sm={6} md={4} display="flex">
          <ConnectionCard cardData={PersonData} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} display="flex">
          <ConnectionCard cardData={orgData} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} display="flex">
          <ConnectionCard cardData={locationData} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} display="flex">
          <ConnectionCard cardData={topicData} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} display="flex">
          <ConnectionCard cardData={eventsData} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} display="flex">
          <ConnectionCard cardData={othersData} />
        </Grid>
      </Grid>
    </Grid>
  );
}
