import { SxProps, Theme } from "@mui/material";

export const sxTableCell: SxProps<Theme> = {
  fontSize: "14px",
  color: (theme) => theme.palette.text.tableHeader,
  borderBottom: (theme) => `1px solid${theme.palette.dashboardContainer.tableBorder}`,
  textAlign: "left",
};

export const sxTableCellBody: SxProps<Theme> = {
  borderBottom: (theme) => `1px solid ${theme.palette.dashboardContainer.tableBorder}`,
  textAlign: "left",
  fontSize: "16px",
  fontWeight: 400,
};

export const sxTableCellCenter: SxProps<Theme> = {
  fontSize: "14px",
  color: "#5A80AA",
  borderBottom: (theme) => `1px solid ${theme.palette.dashboardContainer.tableBorder}`,
  textAlign: "center",
};

export const sxTableCellBodyCenter: SxProps = {
  borderBottom: "1px solid #41527D",
  textAlign: "center",
  fontSize: "16px",
};

export const sxCheckbox = (selected: boolean) => {
  return {
    fontSize: "14px",
    fontWeight: "400",
    "& .MuiSvgIcon-root": {
      fill: (theme: Theme) => (selected ? theme.palette.checkbox.default : theme.palette.checkbox.darkBorder),
    },
    "& .MuiButtonBase-root.MuiCheckbox-root": {
      pl: "5px",
    },
    "&:hover .MuiSvgIcon-root": {
      fill: (theme: Theme) => (selected ? theme.palette.checkbox.selectedHover : theme.palette.checkbox.hover),
      stroke: (theme: Theme) => (selected ? "none" : theme.palette.checkbox.darkBorder),
    },
    "&:active .MuiSvgIcon-root": {
      fill: (theme: Theme) => (selected ? theme.palette.checkbox.active : theme.palette.checkbox.darkBorder),
      strokeWidth: "0px",
    },
  };
};
