import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";

import FormikInputField from "../../components/FormikInputField";
import PrimaryLoadingButton from "../../components/PrimaryLoadingButton";

const initialValues = {
  password: "",
  confirmPassword: "",
};

const formField = {
  password: {
    name: "user_password1",
    placeholder: "Password",
    requiredErrorMsg: "Password is required",
  },
  confirmPassword: {
    name: "user_password2",
    placeholder: "Confirm Password",
    requiredErrorMsg: "Confirm Password is required",
  },
};

const validationSchema = Yup.object().shape({
  [formField.password.name]: Yup.string()
    .min(8, "Password should be of minimum 8 characters length")
    .required(`${formField.password.requiredErrorMsg}`),
  [formField.confirmPassword.name]: Yup.string()
    .oneOf([Yup.ref(formField.password.name), undefined], "Password don't match")
    .required(`${formField.confirmPassword.requiredErrorMsg}`),
});

export interface PasswordFormProps {
  handleSubmit: (values: any) => any;
  handleInputChange: (params: { fieldName: string; value: string }) => void;
  isLoading: boolean;
}

const PasswordForm: React.FC<PasswordFormProps> = ({ handleSubmit, handleInputChange, isLoading }) => {
  const theme = useTheme();
  const [showpassword, setShowPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showpassword);
  };
  const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleMouseDownConfirmPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ zIndex: 1, width: "100%" }}
      py={{ xs: 2, sm: 1, lg: 1, xl: 0 }}
      px={{ xs: 6, sm: 1, lg: 1, xl: 0 }}
    >
      <Typography
        variant="h1"
        sx={{
          fontFeatureSettings: "'liga' off",
          color: (theme) => theme.palette.text.titleLabel,
        }}
      >
        Sign Up
      </Typography>
      <Typography mt={2} mb={3} variant="body6" textAlign="center" color={(theme) => theme.palette.text.titleLabel}>
        Create a password. It must consist of at least 8 symbols.
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
        }}
      >
        {(formikProps) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formikProps.handleSubmit();
            }}
            style={{ width: "100%" }}
          >
            <Box sx={{ width: "100%" }}>
              <FormikInputField
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formikProps.handleChange(e);
                  handleInputChange({
                    fieldName: formField.password.name,
                    value: e.target.value,
                  });
                }}
                name={formField.password.name}
                placeholder={formField.password.placeholder}
                type={showpassword ? "text" : "password"}
                // initialhelper="At least 8 characters"
                style={{ width: "100%" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showpassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormikInputField
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formikProps.handleChange(e);
                  handleInputChange({
                    fieldName: formField.confirmPassword.name,
                    value: e.target.value,
                  });
                }}
                name={formField.confirmPassword.name}
                placeholder={formField.confirmPassword.placeholder}
                type={showConfirmPassword ? "text" : "password"}
                // initialhelper="Both passwords must match"
                style={{ width: "100%" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password confirm visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box display="flex" flexDirection="column" alignItems="center">
                <PrimaryLoadingButton
                  sx={{
                    p: 0,
                    marginTop: "3.5rem",
                    width: { xs: "60%", sm: "76%", md: "83%", lg: "66%" },
                  }}
                  isLoading={isLoading}
                >
                  Next
                </PrimaryLoadingButton>
                <Typography variant="body6" mt={3} color={theme.palette.text.titleLabel}>
                  Already have an account?
                  <NavLink
                    to="/login"
                    style={{
                      textDecoration: "none",
                      fontWeight: theme.typography.fontWeightMedium,
                      color: theme.palette.primary.main,
                      marginLeft: "4px",
                    }}
                  >
                    Sign In
                  </NavLink>
                </Typography>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default PasswordForm;
