import { Box, Stack, Typography, useTheme } from "@mui/material";
import BackButton from "../../components/BackButton";
import CheckboxFilterButton, { MultiSelectValueType } from "../../components/CheckboxFilterButton";
import CustomDateRangePickerButton from "../../components/CustomDateRangePickerButton";
import { routes } from "../../routes";
import { useHandleRouteClick } from "../../routes/hooks";
import { notificationCategoryFilterOptions } from "../../utils/constants";
import NotificationsContainer from "./components/NotificationsContainer";

export default function Notifications() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const handleChangeNotificationCategory = (value: MultiSelectValueType[]) => {
    console.log(value);
  };

  return (
    <Box display="flex" flexDirection="column" gap={0}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end" py={2} px={1}>
        <Stack direction="column" alignItems="flex-start" gap={1}>
          <Typography variant="h1">Notifications</Typography>
          <BackButton onClick={() => handleRouteClick(routes.home.path)} />
        </Stack>
      </Stack>
      <Box
        sx={{
          borderRadius: 2,
          // borderTop: "1px solid",
          borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
          background: theme.palette.dashboardContainer.containerBackground,
          // background: theme.palette.background.paper,
          boxShadow: theme.palette.dashboardContainer.customCardInsetBoxShadow,
          p: 2,
          minHeight: "70vh",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <CheckboxFilterButton
              label="Category"
              onChange={(value) => handleChangeNotificationCategory(value)}
              options={notificationCategoryFilterOptions}
              showSearch={false}
            />
            <CustomDateRangePickerButton onChange={(value) => console.log(value)} />
          </Box>
        </Box>
        <Box sx={{ my: 2, py: 2, borderTop: "1px solid", borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1` }}>
          <NotificationsContainer />
        </Box>
      </Box>
    </Box>
  );
}
