import HeadphonesIcon from "@mui/icons-material/Headphones";
import { Box, Card, Typography, useTheme } from "@mui/material";
import CustomAudioPlayer from "../../../../../../components/CustomAudioPlayer";
import CustomIconButton from "../../../../../../components/CustomIconButton";
import CustomStatus from "../../../../../../components/CustomStatus";
import { routes } from "../../../../../../routes";
import { useHandleRouteClick } from "../../../../../../routes/hooks";

interface CardProps {
  [x: string]: any;
  view?: string;
  index: number;
}

export default function AudioCard({ cardData, view, index }: CardProps) {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const handleNavigation = (id: number) => {
    handleRouteClick(routes.specificAudio.path.replace(":id", String(id)));
  };
  return (
    <Card
      sx={{
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        borderRadius: 2,
        flexFlow: "row wrap",
        display: "flex",
        cursor: "pointer",
      }}
    >
      <Box sx={{ p: 1.5, width: "100%" }}>
        <Box display="flex" gap={2} onClick={() => handleNavigation(cardData?.id)}>
          <CustomIconButton
            sx={{
              border: "none",
              pointerEvent: "none",
              width: "35px",
              minWidth: "35px",
              height: "35px",
              borderRadius: "50%",
              background: theme.palette.primary.tertiaryGradient,
              boxShadow: "none",
            }}
          >
            <HeadphonesIcon sx={{ color: theme.palette.text.default, fontSize: "14px" }} />
          </CustomIconButton>
          <Typography variant="body4" color={theme.palette.text.titleLabel}>
            {cardData?.title}
          </Typography>
          <Box ml="auto" display="flex" flexDirection="column">
            <Typography variant="body4" color={theme.palette.text.tableHeader}>
              {cardData?.created_at.split(" ")[0]}
            </Typography>
            <Typography variant="body4" color={theme.palette.text.tableHeader} sx={{ textAlign: "right" }}>
              {cardData?.created_at.split(" ")[1]}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" gap={1} mt={1}>
          <img src={cardData?.media_image} alt="media" width={"20px"} height={"20px"} style={{ borderRadius: "50%" }} />
          <Typography variant="body4" color={theme.palette.text.tableHeader} display="flex">
            Source:
            <Typography variant="body4" color={theme.palette.text.default}>
              {" "}
              {cardData?.source_link}
            </Typography>
          </Typography>
        </Box>
        <CustomAudioPlayer url={cardData?.src} />
      </Box>
      <Box display="flex" sx={{ p: 1.5, width: "100%", alignSelf: "flex-end" }}>
        <Typography variant="body4" color={theme.palette.text.tableHeader} mt={1}>
          Relevance: {cardData?.relevance}
        </Typography>
        <Typography variant="body4" color={theme.palette.text.tableHeader} mt={1} ml={2}>
          Authenticity: {cardData?.authenticity}
        </Typography>
        <CustomStatus label={cardData?.status} status={cardData?.status} sx={{ width: "88px", height: "28px", marginLeft: "auto" }} />
      </Box>
    </Card>
  );
}
