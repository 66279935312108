const paths = [
  {
    _id: {
      $oid: "6682f79aefe8a8d8b15e0f99",
    },
    head: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c950d",
      },
      key: "russia",
      name: "russia",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682d24989a0a588529b55ba",
      },
      key: "israel",
      name: "israel",
      type: "location",
      subtype: "country",
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6682f7c5efe8a8d8b15e0fff",
    },
    head: {
      _id: {
        $oid: "6682d24989a0a588529b55ba",
      },
      key: "israel",
      name: "israel",
      type: "location",
      subtype: "country",
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682cd111688fc26a7451d82",
      },
      key: "russias",
      name: "russias",
      type: null,
      subtype: null,
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6683d68975864dc1329a7501",
    },
    head: {
      _id: {
        $oid: "6682cd111688fc26a7451d82",
      },
      key: "russias",
      name: "russias",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f404efe8a8d8b15e0c82",
      },
      key: "vladimir putin",
      name: "vladimir putin",
      type: "person",
      subtype: "person",
      description: null,
    },
    type: "head of state",
  },
  {
    _id: {
      $oid: "6682f5b1efe8a8d8b15e0de0",
    },
    head: {
      _id: {
        $oid: "6682f404efe8a8d8b15e0c82",
      },
      key: "vladimir putin",
      name: "vladimir putin",
      type: "person",
      subtype: "person",
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f5b1efe8a8d8b15e0dd9",
      },
      key: "president",
      name: "president",
      type: null,
      subtype: null,
      description: null,
    },
    type: "position held",
  },
  {
    _id: {
      $oid: "6682f8b1efe8a8d8b15e11b6",
    },
    head: {
      _id: {
        $oid: "6682f5b1efe8a8d8b15e0dd9",
      },
      key: "president",
      name: "president",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f404efe8a8d8b15e0c87",
      },
      key: "putin",
      name: "putin",
      type: "person",
      subtype:
        ' region\n\nThe "Republic of Altai" is a region in Russia, located in the southern part of the country. Therefore, the subtype of entity is "region".',
      description: null,
    },
    type: "officeholder",
  },
  {
    _id: {
      $oid: "6683d6e075864dc1329a758d",
    },
    head: {
      _id: {
        $oid: "6682f404efe8a8d8b15e0c87",
      },
      key: "putin",
      name: "putin",
      type: "person",
      subtype:
        ' region\n\nThe "Republic of Altai" is a region in Russia, located in the southern part of the country. Therefore, the subtype of entity is "region".',
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f773efe8a8d8b15e0f21",
      },
      key: "russia ukraine war",
      name: "russia ukraine war",
      type: null,
      subtype: null,
      description: null,
    },
    type: "conflict",
  },
  {
    _id: {
      $oid: "6682f8b1efe8a8d8b15e11b0",
    },
    head: {
      _id: {
        $oid: "6682f773efe8a8d8b15e0f21",
      },
      key: "russia ukraine war",
      name: "russia ukraine war",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f415efe8a8d8b15e0caa",
      },
      key: "kyiv",
      name: "kyiv",
      type: "location",
      subtype: 'city\nKyiv is a city located in Ukraine, so the subtype is "city".',
      description: null,
    },
    type: "location",
  },
  {
    _id: {
      $oid: "6682f579efe8a8d8b15e0daf",
    },
    head: {
      _id: {
        $oid: "6682f415efe8a8d8b15e0caa",
      },
      key: "kyiv",
      name: "kyiv",
      type: "location",
      subtype: 'city\nKyiv is a city located in Ukraine, so the subtype is "city".',
      description: null,
    },
    tail: {
      _id: {
        $oid: "667b1b987cadb4115baef887",
      },
      key: "ukraine",
      name: "ukraine",
      type: null,
      subtype: null,
      description: null,
    },
    type: "country",
  },
  {
    _id: {
      $oid: "6682f6dbefe8a8d8b15e0e5f",
    },
    head: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c950d",
      },
      key: "russia",
      name: "russia",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f44eefe8a8d8b15e0d00",
      },
      key: "north korea",
      name: "north korea",
      type: "location",
      subtype: " country",
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6682f6dbefe8a8d8b15e0e65",
    },
    head: {
      _id: {
        $oid: "6682f44eefe8a8d8b15e0d00",
      },
      key: "north korea",
      name: "north korea",
      type: "location",
      subtype: " country",
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f6dbefe8a8d8b15e0e56",
      },
      key: "people's republic of china",
      name: "people's republic of china",
      type: null,
      subtype: null,
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6682f6dbefe8a8d8b15e0e63",
    },
    head: {
      _id: {
        $oid: "6682f6dbefe8a8d8b15e0e56",
      },
      key: "people's republic of china",
      name: "people's republic of china",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f6dbefe8a8d8b15e0e4a",
      },
      key: "iran",
      name: "iran",
      type: null,
      subtype: null,
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6682f773efe8a8d8b15e0f32",
    },
    head: {
      _id: {
        $oid: "6682f6dbefe8a8d8b15e0e4a",
      },
      key: "iran",
      name: "iran",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f773efe8a8d8b15e0f21",
      },
      key: "russia ukraine war",
      name: "russia ukraine war",
      type: null,
      subtype: null,
      description: null,
    },
    type: "participant in",
  },
  {
    _id: {
      $oid: "6683c0fc779bcc806b62430f",
    },
    head: {
      _id: {
        $oid: "6682f773efe8a8d8b15e0f21",
      },
      key: "russia ukraine war",
      name: "russia ukraine war",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f4f2efe8a8d8b15e0d5d",
      },
      key: "ukraine war",
      name: "ukraine war",
      type: null,
      subtype: null,
      description: null,
    },
    type: "part of",
  },
  {
    _id: {
      $oid: "6682f97fefe8a8d8b15e1315",
    },
    head: {
      _id: {
        $oid: "6682f4f2efe8a8d8b15e0d5d",
      },
      key: "ukraine war",
      name: "ukraine war",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f404efe8a8d8b15e0c82",
      },
      key: "vladimir putin",
      name: "vladimir putin",
      type: "person",
      subtype: "person",
      description: null,
    },
    type: "participant",
  },
  {
    _id: {
      $oid: "6682f6dbefe8a8d8b15e0e58",
    },
    head: {
      _id: {
        $oid: "6682f404efe8a8d8b15e0c82",
      },
      key: "vladimir putin",
      name: "vladimir putin",
      type: "person",
      subtype: "person",
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f484efe8a8d8b15e0d18",
      },
      key: "russian president",
      name: "russian president",
      type: "person",
      subtype: "person",
      description: null,
    },
    type: "position held",
  },
  {
    _id: {
      $oid: "6682f8f5efe8a8d8b15e1251",
    },
    head: {
      _id: {
        $oid: "6682f484efe8a8d8b15e0d18",
      },
      key: "russian president",
      name: "russian president",
      type: "person",
      subtype: "person",
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f404efe8a8d8b15e0c87",
      },
      key: "putin",
      name: "putin",
      type: "person",
      subtype:
        ' region\n\nThe "Republic of Altai" is a region in Russia, located in the southern part of the country. Therefore, the subtype of entity is "region".',
      description: null,
    },
    type: "officeholder",
  },
  {
    _id: {
      $oid: "6683c0b5779bcc806b62424d",
    },
    head: {
      _id: {
        $oid: "6682f404efe8a8d8b15e0c87",
      },
      key: "putin",
      name: "putin",
      type: "person",
      subtype:
        ' region\n\nThe "Republic of Altai" is a region in Russia, located in the southern part of the country. Therefore, the subtype of entity is "region".',
      description: null,
    },
    tail: {
      _id: {
        $oid: "6683c0b5779bcc806b62424c",
      },
      key: "war with russia",
      name: "war with russia",
      type: null,
      subtype: null,
      description: null,
    },
    type: "conflict",
  },
  {
    _id: {
      $oid: "6683c0b5779bcc806b62424e",
    },
    head: {
      _id: {
        $oid: "6683c0b5779bcc806b62424c",
      },
      key: "war with russia",
      name: "war with russia",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682d25589a0a588529b55d6",
      },
      key: "eu",
      name: "eu",
      type: null,
      subtype: null,
      description: null,
    },
    type: "participant",
  },
  {
    _id: {
      $oid: "6682f3fcefe8a8d8b15e0c80",
    },
    head: {
      _id: {
        $oid: "6682d25589a0a588529b55d6",
      },
      key: "eu",
      name: "eu",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "667b1b987cadb4115baef887",
      },
      key: "ukraine",
      name: "ukraine",
      type: null,
      subtype: null,
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6682f415efe8a8d8b15e0cce",
    },
    head: {
      _id: {
        $oid: "6682f415efe8a8d8b15e0caa",
      },
      key: "kyiv",
      name: "kyiv",
      type: "location",
      subtype: 'city\nKyiv is a city located in Ukraine, so the subtype is "city".',
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f415efe8a8d8b15e0cb2",
      },
      key: "kiev",
      name: "kiev",
      type: "location",
      subtype: "military",
      description: null,
    },
    type: "twinned administrative body",
  },
  {
    _id: {
      $oid: "6683c103779bcc806b62431a",
    },
    head: {
      _id: {
        $oid: "6682f415efe8a8d8b15e0cb2",
      },
      key: "kiev",
      name: "kiev",
      type: "location",
      subtype: "military",
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f499efe8a8d8b15e0d20",
      },
      key: "kharkiv",
      name: "kharkiv",
      type: "location",
      subtype: "person",
      description: null,
    },
    type: "twinned administrative body",
  },
  {
    _id: {
      $oid: "6682f499efe8a8d8b15e0d23",
    },
    head: {
      _id: {
        $oid: "6682f499efe8a8d8b15e0d20",
      },
      key: "kharkiv",
      name: "kharkiv",
      type: "location",
      subtype: "person",
      description: null,
    },
    tail: {
      _id: {
        $oid: "667b1b987cadb4115baef887",
      },
      key: "ukraine",
      name: "ukraine",
      type: null,
      subtype: null,
      description: null,
    },
    type: "country",
  },
  {
    _id: {
      $oid: "6682f6dbefe8a8d8b15e0e66",
    },
    head: {
      _id: {
        $oid: "6682f44eefe8a8d8b15e0d00",
      },
      key: "north korea",
      name: "north korea",
      type: "location",
      subtype: " country",
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f6dbefe8a8d8b15e0e4a",
      },
      key: "iran",
      name: "iran",
      type: null,
      subtype: null,
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6682f6dbefe8a8d8b15e0e60",
    },
    head: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c950d",
      },
      key: "russia",
      name: "russia",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f6dbefe8a8d8b15e0e4a",
      },
      key: "iran",
      name: "iran",
      type: null,
      subtype: null,
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6682f800efe8a8d8b15e108a",
    },
    head: {
      _id: {
        $oid: "6682f415efe8a8d8b15e0cb2",
      },
      key: "kiev",
      name: "kiev",
      type: "location",
      subtype: "military",
      description: null,
    },
    tail: {
      _id: {
        $oid: "667b1b987cadb4115baef887",
      },
      key: "ukraine",
      name: "ukraine",
      type: null,
      subtype: null,
      description: null,
    },
    type: "country",
  },
  {
    _id: {
      $oid: "6682f82cefe8a8d8b15e10f5",
    },
    head: {
      _id: {
        $oid: "6682f773efe8a8d8b15e0f21",
      },
      key: "russia ukraine war",
      name: "russia ukraine war",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f44aefe8a8d8b15e0cff",
      },
      key: "war",
      name: "war",
      type: null,
      subtype: null,
      description: null,
    },
    type: "instance of",
  },
  {
    _id: {
      $oid: "6683d69a75864dc1329a752f",
    },
    head: {
      _id: {
        $oid: "6682f44aefe8a8d8b15e0cff",
      },
      key: "war",
      name: "war",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682d24989a0a588529b55ba",
      },
      key: "israel",
      name: "israel",
      type: "location",
      subtype: "country",
      description: null,
    },
    type: "participant",
  },
  {
    _id: {
      $oid: "6682f983efe8a8d8b15e131c",
    },
    head: {
      _id: {
        $oid: "6682f484efe8a8d8b15e0d18",
      },
      key: "russian president",
      name: "russian president",
      type: "person",
      subtype: "person",
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f7eeefe8a8d8b15e103f",
      },
      key: "vladimir zelensky",
      name: "vladimir zelensky",
      type: null,
      subtype: null,
      description: null,
    },
    type: "officeholder",
  },
  {
    _id: {
      $oid: "6682f7eeefe8a8d8b15e104d",
    },
    head: {
      _id: {
        $oid: "6682f7eeefe8a8d8b15e103f",
      },
      key: "vladimir zelensky",
      name: "vladimir zelensky",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f6dbefe8a8d8b15e0e51",
      },
      key: "ukrainian president",
      name: "ukrainian president",
      type: null,
      subtype: null,
      description: null,
    },
    type: "position held",
  },
  {
    _id: {
      $oid: "6682f86befe8a8d8b15e115f",
    },
    head: {
      _id: {
        $oid: "6682f6dbefe8a8d8b15e0e51",
      },
      key: "ukrainian president",
      name: "ukrainian president",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f6cbefe8a8d8b15e0e1e",
      },
      key: "volodymyr zelensky",
      name: "volodymyr zelensky",
      type: "person",
      subtype: "person",
      description: null,
    },
    type: "officeholder",
  },
  {
    _id: {
      $oid: "6682f6cbefe8a8d8b15e0e21",
    },
    head: {
      _id: {
        $oid: "6682f6cbefe8a8d8b15e0e1e",
      },
      key: "volodymyr zelensky",
      name: "volodymyr zelensky",
      type: "person",
      subtype: "person",
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f5b1efe8a8d8b15e0dd9",
      },
      key: "president",
      name: "president",
      type: null,
      subtype: null,
      description: null,
    },
    type: "position held",
  },
  {
    _id: {
      $oid: "6682f4f2efe8a8d8b15e0d5e",
    },
    head: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c950d",
      },
      key: "russia",
      name: "russia",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f4f2efe8a8d8b15e0d5d",
      },
      key: "ukraine war",
      name: "ukraine war",
      type: null,
      subtype: null,
      description: null,
    },
    type: "participant in",
  },
  {
    _id: {
      $oid: "6682f7c5efe8a8d8b15e0ffc",
    },
    head: {
      _id: {
        $oid: "6682cd111688fc26a7451d82",
      },
      key: "russias",
      name: "russias",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "667b1b987cadb4115baef887",
      },
      key: "ukraine",
      name: "ukraine",
      type: null,
      subtype: null,
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6682f773efe8a8d8b15e0f2e",
    },
    head: {
      _id: {
        $oid: "6682f773efe8a8d8b15e0f21",
      },
      key: "russia ukraine war",
      name: "russia ukraine war",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682d24989a0a588529b55ba",
      },
      key: "israel",
      name: "israel",
      type: "location",
      subtype: "country",
      description: null,
    },
    type: "participant",
  },
  {
    _id: {
      $oid: "6682f6dbefe8a8d8b15e0e5e",
    },
    head: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c950d",
      },
      key: "russia",
      name: "russia",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f6dbefe8a8d8b15e0e56",
      },
      key: "people's republic of china",
      name: "people's republic of china",
      type: null,
      subtype: null,
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6682f6dbefe8a8d8b15e0e62",
    },
    head: {
      _id: {
        $oid: "6682f6dbefe8a8d8b15e0e56",
      },
      key: "people's republic of china",
      name: "people's republic of china",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f44eefe8a8d8b15e0d00",
      },
      key: "north korea",
      name: "north korea",
      type: "location",
      subtype: " country",
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6683c0c9779bcc806b624273",
    },
    head: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c950d",
      },
      key: "russia",
      name: "russia",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f50defe8a8d8b15e0d6c",
      },
      key: "ukrainian",
      name: "ukrainian",
      type: "person",
      subtype: "person",
      description: null,
    },
    type: "shares border with",
  },
  {
    _id: {
      $oid: "6682f50defe8a8d8b15e0d6d",
    },
    head: {
      _id: {
        $oid: "6682f50defe8a8d8b15e0d6c",
      },
      key: "ukrainian",
      name: "ukrainian",
      type: "person",
      subtype: "person",
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f50defe8a8d8b15e0d6b",
      },
      key: "kharkiv oblast",
      name: "kharkiv oblast",
      type: "location",
      subtype: 'region\nKharkiv Oblast is a region in Ukraine, so the subtype is "region".',
      description: null,
    },
    type: "contains administrative territorial entity",
  },
  {
    _id: {
      $oid: "6682f5cbefe8a8d8b15e0df0",
    },
    head: {
      _id: {
        $oid: "6682f50defe8a8d8b15e0d6b",
      },
      key: "kharkiv oblast",
      name: "kharkiv oblast",
      type: "location",
      subtype: 'region\nKharkiv Oblast is a region in Ukraine, so the subtype is "region".',
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f5cbefe8a8d8b15e0ded",
      },
      key: "donetsk oblast",
      name: "donetsk oblast",
      type: null,
      subtype: null,
      description: null,
    },
    type: "shares border with",
  },
  {
    _id: {
      $oid: "6682f912efe8a8d8b15e1279",
    },
    head: {
      _id: {
        $oid: "6682f5cbefe8a8d8b15e0ded",
      },
      key: "donetsk oblast",
      name: "donetsk oblast",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f912efe8a8d8b15e1272",
      },
      key: "kharkiv-luhansk oblast",
      name: "kharkiv-luhansk oblast",
      type: null,
      subtype: null,
      description: null,
    },
    type: "shares border with",
  },
  {
    _id: {
      $oid: "6682f912efe8a8d8b15e1276",
    },
    head: {
      _id: {
        $oid: "6682f912efe8a8d8b15e1272",
      },
      key: "kharkiv-luhansk oblast",
      name: "kharkiv-luhansk oblast",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "667b1b987cadb4115baef887",
      },
      key: "ukraine",
      name: "ukraine",
      type: null,
      subtype: null,
      description: null,
    },
    type: "country",
  },
  {
    _id: {
      $oid: "6682f79aefe8a8d8b15e0f9c",
    },
    head: {
      _id: {
        $oid: "6682d24989a0a588529b55ba",
      },
      key: "israel",
      name: "israel",
      type: "location",
      subtype: "country",
      description: null,
    },
    tail: {
      _id: {
        $oid: "667b1b987cadb4115baef887",
      },
      key: "ukraine",
      name: "ukraine",
      type: null,
      subtype: null,
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6682f820efe8a8d8b15e10e1",
    },
    head: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c950d",
      },
      key: "russia",
      name: "russia",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f404efe8a8d8b15e0c82",
      },
      key: "vladimir putin",
      name: "vladimir putin",
      type: "person",
      subtype: "person",
      description: null,
    },
    type: "head of state",
  },
  {
    _id: {
      $oid: "6683c103779bcc806b62431d",
    },
    head: {
      _id: {
        $oid: "6682f773efe8a8d8b15e0f21",
      },
      key: "russia ukraine war",
      name: "russia ukraine war",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "667b1b987cadb4115baef887",
      },
      key: "ukraine",
      name: "ukraine",
      type: null,
      subtype: null,
      description: null,
    },
    type: "participant",
  },
  {
    _id: {
      $oid: "6682f773efe8a8d8b15e0f36",
    },
    head: {
      _id: {
        $oid: "6682d24989a0a588529b55ba",
      },
      key: "israel",
      name: "israel",
      type: "location",
      subtype: "country",
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f773efe8a8d8b15e0f21",
      },
      key: "russia ukraine war",
      name: "russia ukraine war",
      type: null,
      subtype: null,
      description: null,
    },
    type: "participant in",
  },
  {
    _id: {
      $oid: "6682f705efe8a8d8b15e0eab",
    },
    head: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c950d",
      },
      key: "russia",
      name: "russia",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682d25589a0a588529b55d6",
      },
      key: "eu",
      name: "eu",
      type: null,
      subtype: null,
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6683d6e575864dc1329a759e",
    },
    head: {
      _id: {
        $oid: "6682f44aefe8a8d8b15e0cff",
      },
      key: "war",
      name: "war",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "667b1b987cadb4115baef887",
      },
      key: "ukraine",
      name: "ukraine",
      type: null,
      subtype: null,
      description: null,
    },
    type: "location",
  },
  {
    _id: {
      $oid: "6683d6ce75864dc1329a7569",
    },
    head: {
      _id: {
        $oid: "6682f773efe8a8d8b15e0f21",
      },
      key: "russia ukraine war",
      name: "russia ukraine war",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f404efe8a8d8b15e0c87",
      },
      key: "putin",
      name: "putin",
      type: "person",
      subtype:
        ' region\n\nThe "Republic of Altai" is a region in Russia, located in the southern part of the country. Therefore, the subtype of entity is "region".',
      description: null,
    },
    type: "participant",
  },
  {
    _id: {
      $oid: "6682f77aefe8a8d8b15e0f4a",
    },
    head: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c950d",
      },
      key: "russia",
      name: "russia",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f499efe8a8d8b15e0d20",
      },
      key: "kharkiv",
      name: "kharkiv",
      type: "location",
      subtype: "person",
      description: null,
    },
    type: "contains administrative territorial entity",
  },
  {
    _id: {
      $oid: "6683c103779bcc806b62431e",
    },
    head: {
      _id: {
        $oid: "6682f499efe8a8d8b15e0d20",
      },
      key: "kharkiv",
      name: "kharkiv",
      type: "location",
      subtype: "person",
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f415efe8a8d8b15e0cb2",
      },
      key: "kiev",
      name: "kiev",
      type: "location",
      subtype: "military",
      description: null,
    },
    type: "twinned administrative body",
  },
  {
    _id: {
      $oid: "6682f8aeefe8a8d8b15e1199",
    },
    head: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c950d",
      },
      key: "russia",
      name: "russia",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f6d0efe8a8d8b15e0e27",
      },
      key: "united states",
      name: "united states",
      type: "location",
      subtype: "country",
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6682f878efe8a8d8b15e116a",
    },
    head: {
      _id: {
        $oid: "6682f6d0efe8a8d8b15e0e27",
      },
      key: "united states",
      name: "united states",
      type: "location",
      subtype: "country",
      description: null,
    },
    tail: {
      _id: {
        $oid: "667b1b987cadb4115baef887",
      },
      key: "ukraine",
      name: "ukraine",
      type: null,
      subtype: null,
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6682f773efe8a8d8b15e0f3a",
    },
    head: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c950d",
      },
      key: "russia",
      name: "russia",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c9505",
      },
      key: "china",
      name: "china",
      type: null,
      subtype: null,
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6682f773efe8a8d8b15e0f3d",
    },
    head: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c9505",
      },
      key: "china",
      name: "china",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "667b1b987cadb4115baef887",
      },
      key: "ukraine",
      name: "ukraine",
      type: null,
      subtype: null,
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6682f46aefe8a8d8b15e0d0e",
    },
    head: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c950d",
      },
      key: "russia",
      name: "russia",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c9507",
      },
      key: "germany",
      name: "germany",
      type: null,
      subtype: null,
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6682f46aefe8a8d8b15e0d11",
    },
    head: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c9507",
      },
      key: "germany",
      name: "germany",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "667b1b987cadb4115baef887",
      },
      key: "ukraine",
      name: "ukraine",
      type: null,
      subtype: null,
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6682f40defe8a8d8b15e0ca2",
    },
    head: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c950d",
      },
      key: "russia",
      name: "russia",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f40defe8a8d8b15e0c9e",
      },
      key: "polish",
      name: "polish",
      type: null,
      subtype: null,
      description: null,
    },
    type: "shares border with",
  },
  {
    _id: {
      $oid: "6682f40defe8a8d8b15e0ca6",
    },
    head: {
      _id: {
        $oid: "6682f40defe8a8d8b15e0c9e",
      },
      key: "polish",
      name: "polish",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "667b1b987cadb4115baef887",
      },
      key: "ukraine",
      name: "ukraine",
      type: null,
      subtype: null,
      description: null,
    },
    type: "shares border with",
  },
  {
    _id: {
      $oid: "6682f40defe8a8d8b15e0ca1",
    },
    head: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c950d",
      },
      key: "russia",
      name: "russia",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "667b1b987cadb4115baef887",
      },
      key: "ukraine",
      name: "ukraine",
      type: null,
      subtype: null,
      description: null,
    },
    type: "shares border with",
  },
  {
    _id: {
      $oid: "6682f6fbefe8a8d8b15e0e9f",
    },
    head: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c950d",
      },
      key: "russia",
      name: "russia",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f50defe8a8d8b15e0d6b",
      },
      key: "kharkiv oblast",
      name: "kharkiv oblast",
      type: "location",
      subtype: 'region\nKharkiv Oblast is a region in Ukraine, so the subtype is "region".',
      description: null,
    },
    type: "contains administrative territorial entity",
  },
  {
    _id: {
      $oid: "6682f912efe8a8d8b15e1278",
    },
    head: {
      _id: {
        $oid: "6682f5cbefe8a8d8b15e0ded",
      },
      key: "donetsk oblast",
      name: "donetsk oblast",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "667b1b987cadb4115baef887",
      },
      key: "ukraine",
      name: "ukraine",
      type: null,
      subtype: null,
      description: null,
    },
    type: "country",
  },
  {
    _id: {
      $oid: "6682f6fbefe8a8d8b15e0ea2",
    },
    head: {
      _id: {
        $oid: "6682f50defe8a8d8b15e0d6b",
      },
      key: "kharkiv oblast",
      name: "kharkiv oblast",
      type: "location",
      subtype: 'region\nKharkiv Oblast is a region in Ukraine, so the subtype is "region".',
      description: null,
    },
    tail: {
      _id: {
        $oid: "667b1b987cadb4115baef887",
      },
      key: "ukraine",
      name: "ukraine",
      type: null,
      subtype: null,
      description: null,
    },
    type: "country",
  },
  {
    _id: {
      $oid: "6682f6ddefe8a8d8b15e0e6d",
    },
    head: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c950d",
      },
      key: "russia",
      name: "russia",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f40defe8a8d8b15e0c98",
      },
      key: "poland",
      name: "poland",
      type: "location",
      subtype: "country",
      description: null,
    },
    type: "diplomatic relation",
  },
  {
    _id: {
      $oid: "6682f6ddefe8a8d8b15e0e70",
    },
    head: {
      _id: {
        $oid: "6682f40defe8a8d8b15e0c98",
      },
      key: "poland",
      name: "poland",
      type: "location",
      subtype: "country",
      description: null,
    },
    tail: {
      _id: {
        $oid: "667b1b987cadb4115baef887",
      },
      key: "ukraine",
      name: "ukraine",
      type: null,
      subtype: null,
      description: null,
    },
    type: "shares border with",
  },
  {
    _id: {
      $oid: "6682f415efe8a8d8b15e0ccf",
    },
    head: {
      _id: {
        $oid: "6682f415efe8a8d8b15e0cb2",
      },
      key: "kiev",
      name: "kiev",
      type: "location",
      subtype: "military",
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f415efe8a8d8b15e0caa",
      },
      key: "kyiv",
      name: "kyiv",
      type: "location",
      subtype: 'city\nKyiv is a city located in Ukraine, so the subtype is "city".',
      description: null,
    },
    type: "twinned administrative body",
  },
  {
    _id: {
      $oid: "6683d68975864dc1329a74f2",
    },
    head: {
      _id: {
        $oid: "667ab8e7e37a9e4a5a1c950d",
      },
      key: "russia",
      name: "russia",
      type: null,
      subtype: null,
      description: null,
    },
    tail: {
      _id: {
        $oid: "6682f4b1efe8a8d8b15e0d2f",
      },
      key: "crimea",
      name: "crimea",
      type: "location",
      subtype:
        ' region\nCrimea is a region located on the Crimean Peninsula in Eastern Europe. It is currently annexed by Russia, but its legal status is disputed by Ukraine and the international community. Therefore, the subtype of entity for "Crimea" is "region".',
      description: null,
    },
    type: "contains administrative territorial entity",
  },
  {
    _id: {
      $oid: "6682f4dfefe8a8d8b15e0d55",
    },
    head: {
      _id: {
        $oid: "6682f4b1efe8a8d8b15e0d2f",
      },
      key: "crimea",
      name: "crimea",
      type: "location",
      subtype:
        ' region\nCrimea is a region located on the Crimean Peninsula in Eastern Europe. It is currently annexed by Russia, but its legal status is disputed by Ukraine and the international community. Therefore, the subtype of entity for "Crimea" is "region".',
      description: null,
    },
    tail: {
      _id: {
        $oid: "667b1b987cadb4115baef887",
      },
      key: "ukraine",
      name: "ukraine",
      type: null,
      subtype: null,
      description: null,
    },
    type: "country",
  },
];
export function parseDataToGraph(data) {
  const nodeTypes = {
    person: "#ffcc00", // yellow
    organization: "#66c2a5", // green
    location: "#fc8d62", // orange
    event: "#8da0cb", // purple
    topic: "#e78ac3", // pink
    other: "#a6d854", // light green
  };

  let nodes = [];
  let edges = [];

  // Iterate over each item in data
  data.forEach((item) => {
    // Extract relevant information
    const { head, tail } = item;
    const headType = head.type || "other";
    const tailType = tail.type || "other";

    // Add nodes if they don't exist
    if (!nodes.find((node) => node.id === head._id.$oid)) {
      nodes.push({
        id: head._id.$oid,
        label: head.name || head.key,
        title: head.name || head.key,
        color: nodeTypes[headType],
        font: { size: 12, color: nodeTypes[tailType], face: "arial" },
      });
    }
    if (!nodes.find((node) => node.id === tail._id.$oid)) {
      nodes.push({
        id: tail._id.$oid,
        label: tail.name || tail.key,
        title: tail.name || tail.key,
        color: nodeTypes[tailType],
        font: { size: 12, color: nodeTypes[tailType], face: "arial" },
      });
    }

    // Add edge
    edges.push({
      from: head._id.$oid,
      to: tail._id.$oid,
      title: item.type,
    });
  });

  return { nodes, edges };
}

// Usage
export const parsedGraphData = parseDataToGraph(paths);
