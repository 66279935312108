import CloseIcon from "@mui/icons-material/Close";
import { Box, FormControl, Typography, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import CustomIconButton from "../../../components/CustomIconButton";
import CustomInputField from "../../../components/CustomInputField";
import PrimaryLoadingButton from "../../../components/PrimaryLoadingButton";

export interface AddNewKeywordDialogProps {
  open: boolean;
  handleToggle: () => void;
  handleSubmit: (text: string) => void;
}

export interface FormDataType {
  text: string;
}

const initialFormData: FormDataType = {
  text: "",
};

export default function AddNewKeywordDialog({ open, handleToggle, handleSubmit }: AddNewKeywordDialogProps) {
  const theme = useTheme();
  const [formData, setFormData] = React.useState(initialFormData);

  const handleInputChange = ({ fieldName, value }: { fieldName: string; value: any }) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <Dialog
      onClose={handleToggle}
      open={open}
      PaperProps={{
        sx: {
          background: theme.palette.background.gradientLight1,
          borderRadius: "8px",
          py: 5,
          px: 3,
          minWidth: { xs: "50vw", lg: "50vw", xl: "40vw" },
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" px="20px">
        <Typography variant="h2" m="auto" color={theme.palette.text.titleLabel} sx={{ lineHeight: "100%" }}>
          Adding red-flagged words
        </Typography>
        <CustomIconButton onClick={handleToggle} sx={{ color: theme.palette.error.main }}>
          <CloseIcon />
        </CustomIconButton>
      </Box>
      <DialogContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center", pb: 0 }}>
        <Box mt={5}>
          <FormControl fullWidth>
            <Typography variant="caption1" color={theme.palette.text.captionColor} sx={{ lineHeight: "120%" }}>
              Write your words separated by Comma(,)
            </Typography>
            <CustomInputField
              multiline
              rows={5}
              value={formData.text}
              required
              name="text"
              placeholder="Text here..."
              size="small"
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange({ fieldName: e.target.name, value: e.target.value })}
              autoComplete="off"
            />
          </FormControl>
        </Box>
        <PrimaryLoadingButton
          isLoading={false}
          onClick={() => {
            handleSubmit(formData.text);
            handleToggle();
            setFormData(initialFormData);
          }}
          sx={{ ...theme.typography.bigButton, width: "16rem", mx: "auto", mt: 6, mb: 1.5 }}
        >
          Add & Save
        </PrimaryLoadingButton>
      </DialogContent>
    </Dialog>
  );
}
