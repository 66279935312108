import { Typography, useTheme } from "@mui/material";
import { NodeInfo } from "../NetworkGraph";

interface NodeDataProps {
  nodeData: NodeInfo;
}

const NodePopoverContent = ({ nodeData }: NodeDataProps) => {
  const theme = useTheme();
  const nodeType = nodeData?.type;

  return (
    <>
      <Typography variant="caption" color={theme.palette.text.titleLabel}>
        {nodeType.charAt(0).toUpperCase() + nodeType.slice(1)}
      </Typography>
    </>
  );
};

export default NodePopoverContent;
