import { Box, CircularProgress, Typography } from "@mui/material";
import ContentUILoader from "../../../../../../components/ContentUILoader";
import CustomButton from "../../../../../../components/CustomButton";
import GridView from "./GridView";
import ListView from "./ListView";

interface PublicationProps {
  view: boolean;
  drawerOpen: boolean;
  isRefresh: boolean;
  loading: boolean;
  feedData: Record<string, any>;
  loadMore: () => void;
}

export default function Publication({ view, drawerOpen, isRefresh, feedData, loading, loadMore }: PublicationProps) {
  return (
    <>
      {!loading && !isRefresh ? (
        <>
          {feedData?.results?.length > 0 ? (
            <Box mt={3}>
              {view ? (
                <ListView publicationData={feedData?.results} drawerOpen={drawerOpen} isRefresh={isRefresh} />
              ) : (
                <GridView publicationData={feedData?.results} drawerOpen={drawerOpen} isRefresh={isRefresh} />
              )}
              <Box textAlign="center" my={2}>
                {feedData?.next_page && <CustomButton onClick={loadMore}>Load More</CustomButton>}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                textAlign: "center",
                height: "100%",
                my: "5rem",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
              <Typography variant="caption" sx={{ my: 2 }}>
                Processing...
              </Typography>
            </Box>
            // <CustomNoResults />
          )}
        </>
      ) : (
        <ContentUILoader type="publication" count={5} drawerOpen={drawerOpen} />
      )}
    </>
  );
}
