import BusinessIcon from "@mui/icons-material/Business";
import EventIcon from "@mui/icons-material/Event";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import Person from "@mui/icons-material/Person";
import TopicIcon from "@mui/icons-material/Topic";
import { Box, Typography, useTheme } from "@mui/material";

export default function Legend() {
  const theme = useTheme();
  const legendData = [
    { text: "Person", icon: <Person sx={{ fontSize: "18px", color: theme.palette.text.tableHeader }} /> },
    { text: "Organization", icon: <BusinessIcon sx={{ fontSize: "18px", color: theme.palette.text.tableHeader }} /> },
    { text: "Location", icon: <FmdGoodIcon sx={{ fontSize: "18px", color: theme.palette.text.tableHeader }} /> },
    { text: "Event", icon: <EventIcon sx={{ fontSize: "18px", color: theme.palette.text.tableHeader }} /> },
    { text: "Topic", icon: <TopicIcon sx={{ fontSize: "18px", color: theme.palette.text.tableHeader }} /> },
  ];

  return (
    <Box display="flex" gap={2}>
      <Typography variant="subtitle2" color={theme.palette.text.titleLabel}>
        Legend
      </Typography>
      <Box display="flex" gap={1} mt={"-3px"}>
        {legendData.map((data) => (
          <Box display="flex" gap={0.5} key={data?.text}>
            <Box>{data?.icon}</Box>
            <Typography variant="body5" color={theme.palette.text.titleLabel} mt={0.7}>
              {data?.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
