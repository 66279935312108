import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CustomIconButton from "../../../components/CustomIconButton";

export interface SavedToFavoritesDialogProps {
  open: boolean;
  handleToggle: () => void;
  queryId: string | number;
}

export default function SavedToFavoritesDialog({ open, handleToggle, queryId }: SavedToFavoritesDialogProps) {
  const theme = useTheme();

  return (
    <Dialog
      onClose={handleToggle}
      open={open}
      PaperProps={{
        sx: {
          background: theme.palette.background.gradientLight1,
          borderRadius: "8px",
          py: 3,
          px: 3,
          minWidth: "30vw",
        },
      }}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.039)",
        },
      }}
    >
      <Box display="flex" justifyContent="flex-end">
        <CustomIconButton onClick={handleToggle} sx={{ color: theme.palette.error.main }}>
          <CloseIcon />
        </CustomIconButton>
      </Box>
      <DialogContent sx={{ margin: 0, padding: 0, mt: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Box sx={{ my: 2, mx: "auto" }}>
          <img height="120" src="/assets/check_dialog_icon.png" />
        </Box>
        <Typography variant="h2" textAlign="center" color={theme.palette.text.titleLabel} mt={3} mb={1}>
          Query is saved to favorites
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
