import { Box, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";

import FormikInputField from "../../components/FormikInputField";
import PrimaryLoadingButton from "../../components/PrimaryLoadingButton";

const initialValues = {
  email: "",
};

const formField = {
  email: {
    name: "email",
    placeholder: "Email Address",
    requiredErrorMsg: "Email is required",
  },
};

const validationSchema = Yup.object().shape({
  [formField.email.name]: Yup.string().email("Enter a valid email").required(`${formField.email.requiredErrorMsg}`),
});

export interface EmailFormProps {
  handleSubmit: (params: any) => any;
  handleInputChange: (params: any) => void;
  isLoading: boolean;
}

export default function EmailForm({ handleSubmit, handleInputChange, isLoading }: EmailFormProps) {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ zIndex: 1, width: "100%" }}
      py={{ xs: 2, sm: 1, lg: 2, xl: 3 }}
      px={{ xs: 6, sm: 1, lg: 1, xl: 3 }}
    >
      <Typography
        variant="h1"
        sx={{
          fontFeatureSettings: "'liga' off",
          color: (theme) => theme.palette.text.titleLabel,
        }}
      >
        Forgot Password
      </Typography>
      <Typography mt={2} mb={1} variant="body6" color={(theme) => theme.palette.text.titleLabel}>
        Enter your Email address below
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
        }}
      >
        {(formikProps) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formikProps.handleSubmit();
            }}
            style={{ width: "100%" }}
          >
            <Box sx={{ width: "100%", mt: 4 }}>
              <FormikInputField
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formikProps.handleChange(e);
                  handleInputChange(e.target.value);
                }}
                style={{ width: "100%" }}
                name={formField.email.name}
                placeholder={formField.email.placeholder}
              />
              <Box display="flex" flexDirection="column" alignItems="center">
                <PrimaryLoadingButton
                  isLoading={isLoading}
                  sx={{
                    p: 0,
                    marginTop: 5,
                    width: { xs: "50%", sm: "55%", md: "60%", lg: "46%", xl: "13vw" },
                  }}
                >
                  Send Code
                </PrimaryLoadingButton>

                <Typography variant="body6" color={(theme) => theme.palette.text.titleLabel} mt={4}>
                  Have any issues?
                  <NavLink
                    to="/login"
                    style={{
                      ...theme.typography.body6,
                      color: theme.palette.primary.main,
                      textDecoration: "none",
                      marginLeft: "6px",
                    }}
                  >
                    Contact Us
                  </NavLink>
                </Typography>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}
