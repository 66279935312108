import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import { Box, SxProps, Typography, useTheme } from "@mui/material";

// import React from "react";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
// import AddNewCaseDialog from "./AddNewCaseDialog";

export default function AddNewCaseCard() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  // const [openDialog, setOpenDialog] = React.useState(false);

  // const handleToggle = () => {
  //   setOpenDialog((prev) => !prev);
  // };

  const handleNavigation = () => {
    handleRouteClick(routes.customCase.path);
  };

  const boxStyle: SxProps = {
    height: "100%",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 1,
    background: `linear-gradient(to right, ${theme.palette.text.default} 50%, transparent 0%) top repeat-x, linear-gradient(${theme.palette.text.default} 50%, transparent 0%) right repeat-y, linear-gradient(to right, ${theme.palette.text.default} 50%, transparent 0%) bottom repeat-x, linear-gradient(${theme.palette.text.default} 50%, transparent 0%) left repeat-y`,
    backgroundSize: "20px 1px, 1px 20px",
    gap: 2,
  };

  return (
    <>
      {/* <AddNewCaseDialog handleToggle={handleToggle} open={openDialog} /> */}
      <Box
        sx={{
          p: 2,
          height: "100%",
          backgroundColor: theme.palette.background.dark,
          borderRadius: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {/* <Box onClick={() => handleToggle()} sx={boxStyle}>
          <AiOutlinePlus size={30} color={theme.palette.text.default} />
          <Typography variant="body1" color={theme.palette.text.default} fontWeight="bold">
            New Custom Case
          </Typography>
        </Box> */}
        <Box onClick={handleNavigation} sx={boxStyle}>
          <TopicOutlinedIcon sx={{ color: theme.palette.text.default, fontSize: "1.6em" }} />
          <Typography variant="body1" color={theme.palette.text.default} fontWeight="bold">
            Custom Pipelines
          </Typography>
        </Box>
      </Box>
    </>
  );
}
