import BusinessIcon from "@mui/icons-material/Business";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PersonIcon from "@mui/icons-material/Person";
import PlaceIcon from "@mui/icons-material/Place";
import TopicIcon from "@mui/icons-material/Topic";
import { Box, Typography, useTheme } from "@mui/material";
import { MultiSelectValueType } from "../../../../../../components/CheckboxFilterButton";
import CustomButton from "../../../../../../components/CustomButton";
import { EntitySubType, Persons } from "../../../../../../utils/constants";

interface EntitiesProps {
  handleOpenEntities(): void;
  getSubEntities(title: string, entities: any, options: any): void;
}

export default function EntitiesOptions({ handleOpenEntities, getSubEntities }: EntitiesProps) {
  const theme = useTheme();

  const CustomFilterButton = (label: string, count: number, entities: MultiSelectValueType[], options: string[], handleClick: () => void) => {
    const openFilterData = () => {
      handleClick();
      getSubEntities(label, entities, options);
    };

    const icon = () => (
      <>
        {label === "Persons" && <PersonIcon sx={{ color: theme.palette.text.tableHeader }} />}
        {label === "Organizations" && <BusinessIcon sx={{ color: theme.palette.text.tableHeader }} />}
        {label === "Topics" && <TopicIcon sx={{ color: theme.palette.text.tableHeader }} />}
        {label === "Locations" && <PlaceIcon sx={{ color: theme.palette.text.tableHeader }} />}
        {label === "Events" && <CalendarTodayIcon sx={{ color: theme.palette.text.tableHeader }} />}
      </>
    );

    return (
      <CustomButton
        startIcon={icon()}
        sx={{
          width: "100%",
          background: theme.palette.dashboardContainer.authBackground,
          boxShadow: theme.palette.dashboardContainer.customFilterOptionShadow,
          justifyContent: "flex-start",
          color: theme.palette.text.titleLabel,
          height: "44px",
          mt: label === "Persons" ? "0" : "10px",
          "& .MuiButton-endIcon": {
            marginLeft: "auto",
          },
          "&:active": {
            border: "0.5px solid #2EA3FF",
          },
        }}
        onClick={openFilterData}
        endIcon={<KeyboardArrowRightIcon sx={{ color: theme.palette.text.default, fontSize: "26px" }} />}
      >
        {label} ({count})
      </CustomButton>
    );
  };

  const count = Persons.length;
  return (
    <Box mt={3}>
      <Typography variant="h4" color={theme.palette.text.tableHeader} mb={1}>
        Entities
      </Typography>
      {CustomFilterButton("Persons", count, EntitySubType, Persons, handleOpenEntities)}
      {CustomFilterButton("Organizations", count, EntitySubType, Persons, handleOpenEntities)}
      {CustomFilterButton("Locations", count, EntitySubType, Persons, handleOpenEntities)}
      {CustomFilterButton("Events", count, EntitySubType, Persons, handleOpenEntities)}
      {CustomFilterButton("Topics", count, EntitySubType, Persons, handleOpenEntities)}
    </Box>
  );
}
