import { Box, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import FeedSection from "../Components/Feed";
import Title from "../Components/Title";
import { getFormattedDate } from "../../../utils/helper";

export default function FeedWrapper() {
  const theme = useTheme();
  const [isRefresh, setIsRefresh] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(getFormattedDate(new Date()));

  const handleRefresh = () => {
    const currentDate = new Date();
    setIsRefresh(true);
    setLastUpdated(getFormattedDate(currentDate) + ' at ' + currentDate.toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit' }));
    setTimeout(removePageRefresh, 2); // Added setTimeout to remove refresh indicator
  };

  const removePageRefresh = () => {
    setIsRefresh(false);
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  return (
    <Box
      sx={{
        borderTop: "1px solid",
        borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
        background: theme.palette.dashboardContainer.containerBackground,
      }}
      p={{ xs: 1, md: 2 }}
    >
      <Title
        title="Feed"
        lastUpdated={lastUpdated}
        refresh={true}
        handleRefresh={handleRefresh}
      />
      <FeedSection isRefresh={isRefresh} removePageRefresh={removePageRefresh} />
    </Box>
  );
}

