import { Grid, Typography, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import PrimaryLoadingButton from "../../../../../../components/PrimaryLoadingButton";
import { useAppDispatch } from "../../../../../../store/hooks";
import { setActiveAnalysisTab } from "../../../../../../store/ui/uiSlice";
import DemographicsMap from "../../../Map/Components/DemographicsMap";

const hotspotsData = [
  { id: "A", count: 54, position: { lat: 55.683708, lng: 38.028615 }, status: "extreme", legend: "person" },
  { id: "B", count: 552, position: { lat: 46.454048, lng: 1.849527 }, status: "high", legend: "topic" },
  { id: "C", count: 4, position: { lat: 50.917913, lng: 9.165937 }, status: "neutral", legend: "event" },
  { id: "D", count: 140, position: { lat: 52.609411, lng: -8.12626 }, status: "moderate", legend: "location" },
  { id: "E", count: 5, position: { lat: 46.881829, lng: 12.836233 }, status: "low", legend: "organization" },
];

export default function UserDemographics() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams({ tab: "home" });

  const handleClick = () => {
    dispatch(setActiveAnalysisTab("map"));
    setSearchParams((params) => {
      params.set("page", "map");
      return params;
    });
  };

  return (
    <Grid container mt={5}>
      <Typography variant="h3" color={theme.palette.text.titleLabel} sx={{ fontSize: "22px" }}>
        User Demographics
      </Typography>
      <PrimaryLoadingButton
        sx={{
          ...theme.typography.bigButton,
          ml: "auto",
          width: "122px",
        }}
        onClick={handleClick}
      >
        Open Map
      </PrimaryLoadingButton>
      <Grid mt={"-10px"} container spacing={3} mx="auto" sx={{ mt: 1 }} width="100%" height="45vh">
        <DemographicsMap hotspotsData={hotspotsData} showLegend={false} defaultZoom={1} />
      </Grid>
    </Grid>
  );
}
