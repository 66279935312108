import { Grid, Stack, Typography, useTheme } from "@mui/material";
import BorderedCard from "../../../components/BorderedCard";
import CustomDivider from "../../../components/CustomDivider";
import CustomSwitch from "../../../components/CustomSwitch";

export default function EmailSettings() {
  const theme = useTheme();

  return (
    <BorderedCard>
      <Typography variant="h4" sx={{ color: theme.palette.text.titleLabel }}>
        Email Settings
      </Typography>
      <CustomDivider />
      <>
        <Grid container spacing={2} my={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" color={theme.palette.text.main}>
              New Results
            </Typography>
            <Typography variant="caption" color={theme.palette.primary.inactiveIcon}>
              Get notified if there are new results in live monitoring mode{" "}
            </Typography>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="right">
            <Stack direction="row" alignItems="center">
              <Typography
                variant="caption"
                sx={{
                  color: true ? theme.palette.dashboardContainer.cardTitleColorActive : theme.palette.dashboardContainer.cardTitleColor,
                }}
              >
                Off
              </Typography>
              <CustomSwitch name="theme" onChange={() => {}} checked={false} />
              <Typography
                variant="caption"
                sx={{
                  color: false ? theme.palette.dashboardContainer.cardTitleColorActive : theme.palette.dashboardContainer.cardTitleColor,
                }}
              >
                On
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <CustomDivider />
      </>
      <>
        <Grid container spacing={2} my={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" color={theme.palette.text.main}>
              Product Updates
            </Typography>
            <Typography variant="caption" color={theme.palette.primary.inactiveIcon}>
              We’ll send you regular updates with the latest news and features
            </Typography>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="right">
            <Stack direction="row" alignItems="center">
              <Typography
                variant="caption"
                sx={{
                  color: true ? theme.palette.dashboardContainer.cardTitleColorActive : theme.palette.dashboardContainer.cardTitleColor,
                }}
              >
                Off
              </Typography>
              <CustomSwitch name="theme" onChange={() => {}} checked={false} />
              <Typography
                variant="caption"
                sx={{
                  color: false ? theme.palette.dashboardContainer.cardTitleColorActive : theme.palette.dashboardContainer.cardTitleColor,
                }}
              >
                On
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <CustomDivider />
      </>
      <>
        <Grid container spacing={2} my={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" color={theme.palette.text.main}>
              Workspace Membership Notifications
            </Typography>
            <Typography variant="caption" color={theme.palette.primary.inactiveIcon}>
              Get notified when new users auto-join workspaces you admin
            </Typography>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="right">
            <Stack direction="row" alignItems="center">
              <Typography
                variant="caption"
                sx={{
                  color: true ? theme.palette.dashboardContainer.cardTitleColorActive : theme.palette.dashboardContainer.cardTitleColor,
                }}
              >
                Off
              </Typography>
              <CustomSwitch name="theme" onChange={() => {}} checked={false} />
              <Typography
                variant="caption"
                sx={{
                  color: false ? theme.palette.dashboardContainer.cardTitleColorActive : theme.palette.dashboardContainer.cardTitleColor,
                }}
              >
                On
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </>
    </BorderedCard>
  );
}
