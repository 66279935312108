// Import NPM
import { Box, Grid, Typography, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CustomTextButton from "../../../components/CustomTextButton";
import { logout } from "../../../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { endUserSession, getUserSessions } from "../../../store/userSessions/userSessionsThunk";

export interface SessionDetail {
  device_name: string;
  browser_version: string;
  location: string;
  browser_name: string;
  device_type: string;
  is_active: boolean;
}

export interface SessionCardProps {
  session: Record<string, any>;
  deviceIcon: React.ReactNode;
  active: boolean;
}

export default function SessionCard({ session, deviceIcon, active }: SessionCardProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.auth);

  const handleEndSession = (sessionId: string) => {
    console.log(sessionId);
    dispatch(endUserSession(sessionId)).then(() => {
      if (token?.refresh === session.refresh_token) {
        // If this is user's own session we need to logout automatically
        dispatch(logout());
      } else {
        dispatch(getUserSessions());
      }
    });
  };

  return (
    <Grid
      container
      rowSpacing={1}
      spacing={4}
      sx={{
        backgroundColor: active ? theme.palette.dashboardContainer.sessionCardActive : theme.palette.background.darkSecondary,
        borderRadius: 2,
        p: 2,
        margin: "auto",
        width: "100%",
        mt: 2,
      }}
    >
      <Grid
        item
        xs={12}
        sm={3}
        md={2}
        lg={1}
        p={0}
        sx={{
          "&.MuiGrid-item": {
            padding: "5px 0",
          },
          "&&.MuiGrid-item": {
            padding: "5px 0",
          },
        }}
        id="grid2"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "50px",
            height: "50px",
            background: theme.palette.background.paper,
            borderRadius: "4px",
            margin: { xs: "auto", sm: 0 },
          }}
        >
          <IconButton
            sx={{
              "& svg": {
                fontSize: { xs: 20, sm: 25 },
                width: "32px",
                height: "25px",
                color: active ? theme.palette.primary.activeIcon : theme.palette.primary.inactiveIcon,
              },
            }}
          >
            {deviceIcon}
          </IconButton>
        </Box>
      </Grid>
      <Grid
        item
        xs={9}
        sm={9}
        md={8}
        lg={9}
        sx={{
          "&.MuiGrid-item": {
            padding: "12px 0 5px",
            marginLeft: { md: "0", xl: "-44px" },
          },
          "&&.MuiGrid-item": {
            padding: "12px 0 5px",
            marginLeft: { md: "0", xl: "-44px" },
          },
        }}
      >
        <Typography
          variant="subtitle1"
          color={theme.palette.text.titleLabel}
        >{`${session.device_name} (${session.browser_version} ${session.location})`}</Typography>
        <Typography variant="caption1" color={theme.palette.text.captionColor}>
          {session.browser_name}, {session.device_type}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={2}
        lg={2}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-start",
        }}
      >
        <CustomTextButton
          disabled={!session.is_active}
          onClick={() => {
            session.is_active && handleEndSession(session.id);
          }}
          sx={{ color: theme.palette.error.text }}
        >
          End Session
        </CustomTextButton>
      </Grid>
    </Grid>
  );
}
