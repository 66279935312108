import CloseIcon from "@mui/icons-material/Close";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Box, Typography, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React, { useState } from "react";
import CSVUpload from "../../../../components/CSVUploadNew";
import CustomDivider from "../../../../components/CustomDivider";
import CustomIconButton from "../../../../components/CustomIconButton";
import CustomInputField from "../../../../components/CustomInputField";
import CustomList, { ListItemType } from "../../../../components/CustomList";
import CustomTab from "../../../../components/CustomTab";
import PrimaryLoadingButton from "../../../../components/PrimaryLoadingButton";
import RadioFilterButton from "../../../../components/RadioFilterButton";
import { DATABASE_SOURCES, DATA_SOURCES } from "../../../../utils/constants";

export interface ImportDataSourceDialogProps {
  open: boolean;
  handleToggle: () => void;
  getFile: (file: File | null) => void;
  getDatabase: (database: string) => void;
}

export interface FormDataType {
  name: string;
  saveTheCase: boolean;
}

const initialFormData: FormDataType = {
  name: "",
  saveTheCase: false,
};

const tabs = [
  {
    id: "upload",
    title: "Upload data",
    disable: false,
    component: <Box>Upload data</Box>,
  },
  {
    id: "link",
    title: "Link database",
    disable: false,
    component: <Box>Link database</Box>,
  },
];

export default function ImportDataSourceDialog({ open, handleToggle, getFile, getDatabase }: ImportDataSourceDialogProps) {
  const theme = useTheme();
  const [fileType, setFileType] = React.useState("");
  const [activeTab, setActiveTab] = useState<string>(tabs[0].id);
  const [file, setFile] = useState<any>(null);
  const [url, setUrl] = useState<string>("");
  const [db, setDb] = useState<ListItemType | null>(null);

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };
  const handleSources = (value: string) => {
    setFileType(value);
  };
  const handleFile = (values: any) => {
    setFile(values);
    setDb(null);
    setUrl("");
  };
  const handleRemoveFiles = () => {
    setFile(null);
  };
  const handleInputChange = (value: string) => {
    setUrl(value);
    setDb(null);
    setFile(null);
  };
  const handleGetSelectedDatabase = (database: ListItemType | null) => {
    setDb(database);
    setUrl("");
    setFile(null);
  };
  const addSourceHandler = () => {
    if (db) {
      getDatabase(db.name);
      getFile(null);
    } else if (url !== "" && url !== undefined) {
      getDatabase(url);
      getFile(null);
    } else if (file) {
      getDatabase("");
      getFile(file);
    }
    handleToggle();
  };

  return (
    <Dialog
      onClose={handleToggle}
      open={open}
      PaperProps={{
        sx: {
          background: theme.palette.background.gradientLight1,
          borderRadius: "8px",
          p: 8,
          minWidth: "40vw",
          minHeight: "50vh",
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography variant="h2" color={theme.palette.text.titleLabel} m="auto" pl="20px">
          Import Data Source
        </Typography>
        <CustomIconButton onClick={handleToggle} sx={{ color: theme.palette.error.main }}>
          <CloseIcon />
        </CustomIconButton>
      </Box>
      <CustomDivider sx={{ width: "100%" }} />
      <DialogContent
        sx={{
          margin: "auto",
          padding: "0px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          mt: 3.5,
        }}
      >
        <CustomTab
          tabs={tabs}
          handleActiveTab={handleActiveTab}
          sx={{ border: `1px solid ${theme.palette.primary.tertiary}`, textAlign: "center", width: "100%", px: "0px" }}
          width="100%"
          height="32px"
          activeTab={activeTab}
        />
        <Typography variant="caption" my={3} color={theme.palette.text.titleLabel}>
          You can upload and analyse your own data
        </Typography>
        {activeTab === "upload" ? (
          <Box width="100%">
            <Box width="100%">
              <Typography variant="caption2" color={theme.palette.text.captionColor}>
                Type of Data
              </Typography>
              <RadioFilterButton
                options={DATA_SOURCES}
                placeholder="Select the type of your Data"
                onChange={handleSources}
                sx={{ width: "100%" }}
                endIcon={true}
                textColor={theme.palette.text.titleLabel}
              />
            </Box>
            <Box mt={3} width="100%">
              <CSVUpload getFile={handleFile} label="Select archive files" multiple={true} fileType={fileType} />
            </Box>
            <Box mt={2} display="flex" flexDirection="column" alignItems="center" width="100%">
              <Typography variant="body4" sx={{ mb: 3 }} color={theme.palette.text.titleLabel}>
                {activeTab === "upload"
                  ? "You can upload several files. Please make sure that your file have one format"
                  : "You can link your own database"}
              </Typography>
              {file && <CustomDivider sx={{ width: "100%" }} />}
              {file && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: 1,
                    border: theme.palette.additionalColors.databasecardBorder,
                    p: 1,
                    borderRadius: 2,
                    mt: 2,
                    mb: 1,
                  }}
                >
                  <InsertDriveFileIcon sx={{ color: theme.palette.text.tableHeader, fontSize: "18px" }} />
                  <Typography variant="caption" color={theme.palette.text.captionColor} pt={0.4}>{`${
                    Object.keys(file).length
                  } files downloaded`}</Typography>
                  <CloseIcon sx={{ width: "16px", height: "16px", ml: "auto", color: theme.palette.error.main }} onClick={handleRemoveFiles} />
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box width="100%" display="flex" flexDirection="column" alignItems="center">
            <Box width="100%" display="flex" flexDirection="column">
              <Typography variant="caption2" color={theme.palette.text.captionColor}>
                URL
              </Typography>
              <CustomInputField
                value={url}
                required
                name="url"
                placeholder="Type or paste the URL of your source"
                size="small"
                variant="outlined"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.value)}
                autoComplete="off"
              />
            </Box>
            <Typography variant="caption" my={2} color={theme.palette.text.titleLabel}>
              Or Select Database
            </Typography>
            <Box width="100%" mb={4}>
              <CustomList
                databaseList={DATABASE_SOURCES}
                getSelectedDatabase={handleGetSelectedDatabase}
                sx={{ height: "10rem", overflowY: "scroll" }}
              />
            </Box>
          </Box>
        )}
        <Box textAlign="center" width="100%" mt={4}>
          <PrimaryLoadingButton
            sx={{
              ...theme.typography.bigButton,
              width: "40%",
            }}
            onClick={addSourceHandler}
          >
            Add Source
          </PrimaryLoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
