import { createAsyncThunk } from "@reduxjs/toolkit";
import { patchAPI } from "../../axios/utils";
import { BASE_URL } from "../../utils/constants";

export const markAsFavoriteQuery = createAsyncThunk("query/markAsFavoriteQuery", async (queryId: string | number) => {
  return patchAPI(`${BASE_URL}/lcproto/new-query/favourite-query/${queryId}/`);
});

export const removeAsFavoriteQuery = createAsyncThunk("query/removeAsFavoriteQuery", async (queryId: string | number) => {
  return patchAPI(`${BASE_URL}/lcproto/new-query/unfavourite-query/${queryId}/`);
});

export const startQuery = createAsyncThunk("query/startQuery", async (queryId: string | number) => {
  return patchAPI(`${BASE_URL}/lcproto/new-query/start-processing/${queryId}/`);
});

export const stopQuery = createAsyncThunk("query/stopQuery", async (queryId: string | number) => {
  return patchAPI(`${BASE_URL}/lcproto/new-query/stop-processing/${queryId}/`);
});
