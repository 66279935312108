import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import CustomCloseIconButton from "../../../../../components/CustomCloseIconButton";
import CustomTextButton from "../../../../../components/CustomTextButton";
import Classes from "../../../../../components/Scrollbar.module.css";
import PublicationCard from "../../Feed/components/Publications/PublicationCard";
interface EntityProps {
  openDrawer(): void;
}

const publicationData = [
  {
    query_id: "655749d49a2dbd38ed0a1b3f",
    data_id: "655631e260add368dd05a2e1",
    sentiment: 0,
    prediction:
      "Based on the article provided, here are some potential future implications:\n1. Increased Safety Measures: The tragic incident may lead to a re-evaluation of safety protocols and procedures for air races, including more stringent regulations and stricter enforcement to prevent similar accidents in the future.\n2. Decline in Participation: The crash may deter potential participants and spectators, leading to a decline in",
    military_movement: 1,
    crawlData: {
      kind: "news",
      source: "www.huffpost.com",
      author: "Nina Golgowski",
      title: "One Dead After Plane Crashes At Reno Air Race",
      content:
        "The single-engine plane was participating in the National Championship Air Races on Sunday when it crashed into a field, killing the pilot, officials said.",
      short_description:
        "The single-engine plane was participating in the National Championship Air Races on Sunday when it crashed into a field, killing the pilot, officials said.",
      date: "2022-09-19",
      processed: true,
      images: null,
      url: "https://www.huffpost.com/entry/national-championship-air-races-pilot-killed-crash_n_632860fae4b0ed021dfef140",
      category: "U.S. NEWS",
      data_source: "kaggle_dataset",
    },
  },
  {
    query_id: "655749d49a2dbd38ed0a1b3f",
    data_id: "655631e2978859a5b8e521d1",
    sentiment: 2,
    prediction:
      "Based on the article provided, here are some potential future implications for the Las Vegas Aces and the WNBA:\n1. Increased popularity and recognition for the WNBA: The Aces' victory could lead to increased interest and recognition for the WNBA, potentially attracting more fans and sponsors to the league.\n2. Boost for Las Vegas' sports scene: The Aces' championship could help establish Las Veg",
    military_movement: 1,
    crawlData: {
      kind: "news",
      source: "www.huffpost.com",
      author: "Pat Eaton-Robb, AP",
      title: "Las Vegas Aces Win First WNBA Title, Chelsea Gray Named MVP",
      content: "Las Vegas never had a professional sports champion — until Sunday.",
      short_description: "Las Vegas never had a professional sports champion — until Sunday.",
      date: "2022-09-19",
      processed: true,
      images: null,
      url: "https://www.huffpost.com/entry/2022-wnba-finals_n_6327f56fe4b0eac9f4e3144d",
      category: "SPORTS",
      data_source: "kaggle_dataset",
    },
  },
  {
    query_id: "655749d49a2dbd38ed0a1b3f",
    data_id: "655631e260add368dd05a2e4",
    sentiment: 2,
    prediction:
      'Based on the article, here are some potential future implications for Viola Davis and the sports industry as a whole:\n1. Increased Demand for Fitness Trainers: With Davis\' dedication to training for her role in "The Woman King," there may be an increased demand for fitness trainers who can help actors and actresses prepare for physically demanding roles.\n2. Greater Awareness of the Importance of F',
    military_movement: 0,
    crawlData: {
      kind: "news",
      source: "www.huffpost.com",
      author: "Marco Margaritoff",
      title: "Viola Davis Feared A Heart Attack During 'The Woman King' Training",
      content: "The Oscar winner said she worked out for five hours a day for her role in the new action movie.",
      short_description: "The Oscar winner said she worked out for five hours a day for her role in the new action movie.",
      date: "2022-09-15",
      processed: true,
      images: null,
      url: "https://www.huffpost.com/entry/viola-davis-woman-king-heart-attack_n_6322f795e4b000d988585229",
      category: "ENTERTAINMENT",
      data_source: "kaggle_dataset",
    },
  },
  {
    query_id: "655749d49a2dbd38ed0a1b3f",
    data_id: "655669b4edcc5da2167a7fe6",
    sentiment: 2,
    military_movement: 0,
    prediction:
      "The future implications of this article are likely to be significant and far-reaching, particularly in the realm of sports and LGBTQ+ representation. Here are some possible implications:\n1. Increased visibility and acceptance of LGBTQ+ athletes: Stefanie Dolson's victory in the Olympics and the attention surrounding her identity as a queer athlete may lead to greater visibility and acceptance of LGBTQ+ athlet",
    crawlData: {
      kind: "tweet",
      source: "twitter",
      author: "Margie",
      user_location: "#ßŔŐŃЖ, #ηєωуσяк",
      title: null,
      content: "Proud queer basketballer Stefanie Dolson makes Olympics history with gold medal victory https://t.co/GHAF1vRcsJ #politics #feedly",
      short_description:
        "Proud queer basketballer Stefanie Dolson makes Olympics history with gold medal victory https://t.co/GHAF1vRcsJ #politics #feedly",
      date: "2021-07-30 18:29:08",
      images: null,
      processed: true,
      url: null,
      category: null,
      data_source: "political_tweets",
    },
  },
  {
    query_id: "655749d49a2dbd38ed0a1b3f",
    data_id: "655631e6883f2fba5fd1bcb5",
    sentiment: 2,
    prediction:
      "The future implications of this article are:\n1. Increased excitement for the NBA playoffs: The buzzer-beating layup by Tatum has already generated a lot of buzz and excitement for the NBA playoffs, and this win is likely to carry over to future games in the series and beyond.\n2. Higher stakes for the Celtics-Nets series: With the Celtics winning the first game in dramatic",
    military_movement: 0,
    crawlData: {
      kind: "news",
      source: "www.huffpost.com",
      author: "KYLE HIGHTOWER, AP",
      title: "Tatum's Layup At Buzzer Gives Celtics 115-114 Win Over Nets",
      content:
        "Jayson Tatum made a layup at the buzzer on a pass from Marcus Smart to give the Boston Celtics a dramatic 115-114 victory over the Brooklyn Nets in the Game 1 of their first-round playoff series",
      short_description:
        "Jayson Tatum made a layup at the buzzer on a pass from Marcus Smart to give the Boston Celtics a dramatic 115-114 victory over the Brooklyn Nets in the Game 1 of their first-round playoff series",
      date: "2022-04-17",
      processed: true,
      images: null,
      url: "https://www.huffpost.com/entry/boston-celtics-brooklyn-nets-nba-basketball_n_625c9fe6e4b0e97a351fab78",
      category: "SPORTS",
      data_source: "kaggle_dataset",
    },
  },
  {
    query_id: "655749d49a2dbd38ed0a1b3f",
    data_id: "655631e77932d11a87f54e06",
    sentiment: 0,
    prediction:
      "Based on the article provided, here are some potential future implications for sports enthusiasts:\n1. Increased emphasis on weather forecasting: With high winds causing chaos at the Winter Olympics, there may be a greater focus on accurate weather forecasting in the future. This could lead to more advanced weather prediction technologies and better preparedness for athletes and organizers.\n2. Greater emphasis on adaptability: The closure",
    military_movement: 0,
    crawlData: {
      kind: "news",
      source: "www.huffpost.com",
      author: "JAKE SEINER, AP",
      title: "High Winds Cause Chaos On Penultimate Day Of Winter Olympics",
      content: "Beijing Games organizers closed the ski slopes, then shortened the cross-country course by 40%.",
      short_description: "Beijing Games organizers closed the ski slopes, then shortened the cross-country course by 40%.",
      date: "2022-02-19",
      processed: true,
      images: null,
      url: "https://www.huffpost.com/entry/high-winds-beijing-winter-olympics_n_6210f196e4b0f93b26190975",
      category: "SPORTS",
      data_source: "kaggle_dataset",
    },
  },
  {
    query_id: "655749d49a2dbd38ed0a1b3f",
    data_id: "655631e786d05606e399817e",
    sentiment: 0,
    prediction:
      "Based on the article, here are some potential future implications for the world of sports:\n1. Increased focus on athlete welfare: The strange and messy nature of the Winter Olympics may lead to a greater emphasis on athlete welfare and safety. This could result in stricter regulations and protocols being put in place to protect athletes from harsh weather conditions, poor facilities, and other challenges.\n2.",
    military_movement: 0,
    crawlData: {
      kind: "news",
      source: "www.huffpost.com",
      author: "",
      title: "Winter Olympics Closing Ceremony Caps A Weird And Messy Games",
      content: "The show burst with color and enthusiasm -- unlike much of the competition in Beijing.",
      short_description: "The show burst with color and enthusiasm -- unlike much of the competition in Beijing.",
      date: "2022-02-20",
      processed: true,
      images: null,
      url: "https://www.huffpost.com/entry/winter-olympics-closing-ceremony-beijing_n_62123410e4b0f93b2619c27b",
      category: "WORLD NEWS",
      data_source: "kaggle_dataset",
    },
  },
  {
    query_id: "655749d49a2dbd38ed0a1b3f",
    data_id: "655669b5ceeb6dc748b99cd0",
    sentiment: 2,
    prediction:
      "Based on the article, here are some potential future implications:\n1. Increased representation of transgender athletes in sports: With the IOC's stance on transgender athletes, it is likely that more transgender athletes will feel comfortable competing in the Olympics and other major sporting events. This could lead to a more diverse and inclusive representation of athletes in sports.\n2. Greater awareness and acceptance of",
    military_movement: 0,
    crawlData: {
      kind: "tweet",
      source: "twitter",
      author: "The Wildcard",
      user_location: "",
      title: null,
      content:
        "The Olympic medical director affirmed the IOC's view that 'transwomen are women.' #Culture #LGBT #NewZealand #Olympics #politics #transgender #USnews #womeninsports https://t.co/qzHhvVcm8c",
      short_description:
        "The Olympic medical director affirmed the IOC's view that 'transwomen are women.' #Culture #LGBT #NewZealand #Olympics #politics #transgender #USnews #womeninsports https://t.co/qzHhvVcm8c",
      date: "2021-07-30 13:48:02",
      images: null,
      processed: true,
      url: null,
      category: null,
      data_source: "political_tweets",
    },
  },
  {
    query_id: "655749d49a2dbd38ed0a1b3f",
    data_id: "655631e813ed3ec865f617d3",
    sentiment: 2,
    prediction:
      "Based on the article, here are some potential future implications for Nathan Chen and the world of figure skating:\n1. Increased Expectations: Nathan Chen's impressive performance in his first skate at the Beijing Winter Olympics has set high expectations for his future competitions. He will be expected to continue to deliver high-quality performances and potentially win more medals for the U.S. team.\n2. Rise in",
    military_movement: 0,
    crawlData: {
      kind: "news",
      source: "www.huffpost.com",
      author: "Ron Dicker",
      title: "Nathan Chen Brings His A-Game In First Skate At Beijing Winter Olympics",
      content: "The American figure skating star's personal best short program helped the U.S. take the lead in the team competition.",
      short_description: "The American figure skating star's personal best short program helped the U.S. take the lead in the team competition.",
      date: "2022-02-04",
      processed: true,
      images: null,
      url: "https://www.huffpost.com/entry/nathan-chen-first-skate-team-competition_n_61fcf829e4b06abdc42e7d0b",
      category: "SPORTS",
      data_source: "kaggle_dataset",
    },
  },
];

export default function Publications({ openDrawer }: EntityProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "430px",
        height: "100%",
        position: "absolute",
        overflow: "hidden",
        right: "16px",
        top: "216px",
        zIndex: 10000,
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        borderRadius: 2,
        p: 2,
      }}
    >
      <Box display="flex" gap={5}>
        <Typography variant="h3" fontSize="22px" color={theme.palette.text.titleLabel}>
          Location Name
        </Typography>
        <CustomCloseIconButton onClick={openDrawer} sx={{ ml: "auto" }}>
          <CloseIcon sx={{ width: "16px", height: "16px" }} />
        </CustomCloseIconButton>
      </Box>
      <Typography variant="body5" color={theme.palette.text.titleLabel}>
        Description
      </Typography>
      <Box display="flex" gap={2} mt={3}>
        <Typography variant="h4" color={theme.palette.text.titleLabel}>{`Publications (${publicationData.length})`}</Typography>
        <CustomTextButton sx={{ ml: "auto" }}>View All</CustomTextButton>
      </Box>
      <Box
        mt={2}
        sx={{
          height: "65%",
          overflow: "auto",
        }}
        className={Classes.scroll_dark}
      >
        {publicationData.map((data: any, idx) => (
          <Grid key={`image-${idx}`} item xs={12} display="flex" mb={2} mr={1}>
            <PublicationCard cardData={data} view={"grid"} />
          </Grid>
        ))}
      </Box>
    </Box>
  );
}
