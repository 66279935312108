import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import CustomDivider from "../../../../../../components/CustomDivider";
import RedFlagged from "../../../Feed/components/Publications/SpecificPublication/RedFlagged";

interface RedFlaggedOptions {
  key: string;
  value: number;
}

interface RedFlaggedProps {
  redFlaggedData: RedFlaggedOptions[];
  enableViewAll?: boolean;
  enableBorder?: boolean;
}

export default function RedFlaggedCard({ redFlaggedData, enableViewAll = true, enableBorder = false }: RedFlaggedProps) {
  const theme = useTheme();
  const optionData = redFlaggedData.slice(0, 5);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleModalOpen = () => {
    setOpenModal((prev) => !prev);
  };

  const boxStyle = enableBorder
    ? {
        background: `${theme.palette.text.titleLabel}1A`,
        border: `1px solid ${theme.palette.text.titleLabel}`,
        borderRadius: 1,
        width: "40px",
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }
    : {};
  return (
    <Box
      sx={{
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        p: 2,
        width: "100%",
        borderRadius: 2,
        flexFlow: "row wrap",
      }}
    >
      <Box display="flex" mb={1}>
        <Typography variant="h4" fontSize={"18px"} color={theme.palette.text.titleLabel}>
          Red Flagged Words
        </Typography>
        <InfoOutlinedIcon sx={{ color: theme.palette.text.tableHeader, ml: 1, fontSize: "16px" }} />
        {enableViewAll && (
          <Typography
            ml="auto"
            variant="link5"
            color={theme.palette.text.default}
            display={"flex"}
            sx={{
              cursor: "pointer",
              fontSize: "16px",
            }}
            onClick={handleModalOpen}
          >
            View All
          </Typography>
        )}
      </Box>
      <CustomDivider />
      {optionData.map((data: any, idx) => {
        return (
          <Box display="flex" gap={10} mt={2} key={`sentiment-${idx}`}>
            <Typography variant="h6" sx={{ textTransform: "capitalize", fontSize: "14px", color: theme.palette.text.titleLabel }}>
              {data?.key}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                textTransform: "capitalize",
                fontSize: "14px",
                color: theme.palette.text.titleLabel,
                ...boxStyle,
              }}
              ml="auto"
            >
              {data?.value}
            </Typography>
          </Box>
        );
      })}
      <RedFlagged open={openModal} handleClose={handleModalOpen} />
    </Box>
  );
}
