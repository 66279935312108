import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../../components/BackButton";
import CustomTertiaryButton from "../../../components/CustomTertiaryButton";
import SubmitLoadingDeleteButton from "../../../components/SubmitLoadingDeleteButton";
import SubTabLayout from "../../../layouts/SubTabLayout";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { deleteWorkspace, getWorkspaceDetail } from "../../../store/workspace/workspaceThunk";
import WorkspaceAddModal from "../component/WorkspaceAddModal";
import WorkspaceDeleteModal from "../component/WorkspaceDeleteModal";
import Billing from "./Billing";
import Plan from "./Plan";
import Users from "./Users";

const tabs = [
  {
    id: "users",
    title: "Users",
    disabled: false,
    component: <Users />,
  },
  {
    id: "billing",
    title: "Billing",
    disabled: false,
    component: <Billing />,
  },
  {
    id: "plan",
    title: "Plan",
    disabled: false,
    component: <Plan />,
  },
];

export default function WorkspaceDetail() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { workspaceId } = useParams();
  const handleRouteClick = useHandleRouteClick();
  const { workspaces } = useAppSelector((state) => state.workspace);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [workspaceData, setWorkspaceData] = useState<Record<string, any>>({});
  const { currentUser } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getWorkspaceDetail({ id: workspaceId })).then((res) => {
      if (res?.payload?.status === 200) {
        setWorkspaceData(res.payload?.data);
        if (res.payload?.data.name === "") {
          // no such workspace exists
          handleRouteClick("404");
          return;
        }
      }
    });
  }, []);

  const toggleDeleteDialog = () => {
    setOpenDeleteDialog((prev) => !prev);
  };

  const toggleEditDialog = () => {
    setOpenEditDialog((prev) => !prev);
  };

  const handleDelete = () => {
    const workspace_id = parseInt(workspaceId ? workspaceId : "");
    dispatch(deleteWorkspace(workspace_id)).then((res: Record<string, any>) => {
      if (res?.payload?.response?.status === 204) {
        toast.success("Workspace Deleted Successfully");
        handleRouteClick(`${routes.settings.path}?tab=settings&subtab=workspace`);
      }
    });
    toggleDeleteDialog();
  };

  return (
    <div>
      <Box display="flex" flexDirection="column" gap={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end" py={2} px={1}>
          <Stack direction="column" alignItems="flex-start" gap={1}>
            <Typography variant="h1" color={theme.palette.text.titleLabel}>
              {workspaceData?.name}
            </Typography>
            <BackButton onClick={() => handleRouteClick(`${routes.settings.path}?tab=settings&subtab=workspace`)} />
          </Stack>
          {!currentUser?.is_admin ? (
            <></>
          ) : !openDeleteDialog && !openEditDialog ? (
            <Box>
              <Stack direction="row" gap={2}>
                <CustomTertiaryButton onClick={toggleEditDialog} sx={{ py: 0.5, width: "8rem" }}>
                  {" "}
                  Edit Name
                </CustomTertiaryButton>
                <SubmitLoadingDeleteButton
                  onClick={toggleDeleteDialog}
                  isLoading={false}
                  sx={{ py: 0.5, background: theme.palette.error.text, color: theme.palette.error.btnText }}
                >
                  Delete Workspace
                </SubmitLoadingDeleteButton>
              </Stack>
            </Box>
          ) : openDeleteDialog ? (
            <WorkspaceDeleteModal open={openDeleteDialog} handleToggle={toggleDeleteDialog} handleDelete={handleDelete} />
          ) : (
            <WorkspaceAddModal open={openEditDialog} isEditable={true} handleToggle={toggleEditDialog} setWorkspaceData={setWorkspaceData} />
          )}
        </Stack>
        <Box
          sx={{
            borderRadius: 2,
            background: theme.palette.background.paper,
            boxShadow: theme.palette.dashboardContainer.customCardInsetBoxShadow,
            p: 2,
            minHeight: "70vh",
          }}
        >
          <Box sx={{ position: "relative" }}>
            <SubTabLayout
              tabs={tabs}
              sx={{
                border: `1px solid ${theme.palette.primary.dark}`,
                borderRadius: "6px",
                boxShadow: "none",
              }}
            ></SubTabLayout>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
