import { Box, Chip } from "@mui/material";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import CustomInputField from "../../../../components/CustomInputField";

export interface KeywordProps {
  onHandleChange: (values: string[]) => void;
}

const CustomChipInput = ({ onHandleChange }: KeywordProps) => {
  const [chips, setChips] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ",") {
      event.preventDefault();
      const value = inputValue.trim();

      if (value) {
        const newChips= [...chips, value]
        setChips(newChips);
        setInputValue("");
        onHandleChange(newChips);
      }
    }
  };

  useEffect(() => {
    onHandleChange(chips);
  }, [chips]);

  const handleDelete = (chipToDelete: string) => () => {
    setChips((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <CustomInputField
        fullWidth
        placeholder="Comma separated keywords"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        size="small"
        sx={{ width: "100%" }}
      />
      <Box
        sx={{
          marginTop: "2%",
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
        }}
      >
        {chips.map((chip, index) => (
          <Chip key={index} label={chip} onDelete={handleDelete(chip)} />
        ))}
      </Box>
    </Box>
  );
};

export default CustomChipInput;
