import { Box } from "@mui/material";
import CustomTable from "../../../../../components/CustomTable";
import { routes } from "../../../../../routes";
import { useHandleRouteClick } from "../../../../../routes/hooks";
import { useAppSelector } from "../../../../../store/hooks";

export default function TableView() {
  const handleRouteClick = useHandleRouteClick();
  const { connections } = useAppSelector((state) => state.analysisConnections);
  const tableData = connections.map((conn) => {
    console.log(conn);
    return {
      entityA: conn["head"]["name"],
      entityB: conn["tail"]["name"],
      type: conn["type"],
      entityAID: conn["head"]["id"],
      entityBID: conn["tail"]["id"],
    };
  });

  const header = [
    { id: "entityA", label: "Entity A", searchEnable: true, minWidth: 150 },
    { id: "type", label: "Type", searchEnable: true, minWidth: 300 },
    { id: "entityB", label: "Entity B", searchEnable: true, minWidth: 100 },
  ];

  const handleCellClick = (_e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, data: Record<string, any>) => {
    console.log("handleCellClick", data["entityA"], data["entityB"], data["type"], _e.currentTarget.textContent);
    if (data["entityA"] === _e.currentTarget.textContent) {
      handleRouteClick(routes.specificEntity.path.replace(":id", String(data["entityAID"])));
    } else if (data["entityB"] === _e.currentTarget.textContent) {
      handleRouteClick(routes.specificEntity.path.replace(":id", String(data["entityBID"])));
    }
  };

  return (
    <Box pb={12}>
      <CustomTable header={header} tableData={tableData} rows={10} pagination={true} search={true} handleCellClick={handleCellClick} />
    </Box>
  );
}
