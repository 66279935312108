import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, CardMedia, Grid, Link, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CustomDivider from "../../../../components/CustomDivider";
import CustomTertiaryButton from "../../../../components/CustomTertiaryButton";
import { SPECIFIC_ARTICLE } from "../../../../utils/constants";
import SubPoints from "./SubPoints";

export default function SpecificArticle() {
  const theme = useTheme();
  const articleRef = useRef<HTMLInputElement>(null);

  const [activeTopic, setActiveTopic] = useState(SPECIFIC_ARTICLE?.topics[0]);
  const [activeId, setActiveId] = useState("1.1");
  const [expand, setExpand] = useState<boolean>(false);

  const article = SPECIFIC_ARTICLE;

  const points = article?.topics.map((point) => {
    const subPoints = point?.subTopics?.map((data) => ({ id: data?.id, name: data?.title }));
    return { id: point?.id, name: point?.title, subPoints: subPoints || [] };
  });

  const getActivePoint = (id: number, activeId: string) => {
    const allTopics = SPECIFIC_ARTICLE.topics.flatMap((topic) => [topic, ...(topic.subTopics || [])]);
    const activeData = allTopics?.find((data) => data?.id === id);
    if (activeData) {
      setActiveTopic(activeData);
      setActiveId(activeId);
    }
    setExpand(false);
  };

  const handleExpand = () => {
    setExpand((prev) => !prev);
  };

  useEffect(() => {
    if (articleRef?.current) {
      articleRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [activeId]);

  return (
    <Box display="flex" flexDirection="column" mt={{ xs: 2, lg: 5 }} height="100%" overflow="hidden">
      <Grid spacing={2} container height="100%">
        <Grid item xs={12} lg={8} order={{ xs: 2, lg: 1 }} sx={{ height: "100%", overflow: "scroll" }} ref={articleRef}>
          <Typography variant="h1" color={theme.palette.primary.light}>
            {article?.title}
          </Typography>
          <Typography variant="body2" fontSize="24px" color={theme.palette.primary.light} mt={1.6}>
            {article?.summary}
          </Typography>
          <Typography variant="h2" color={theme.palette.text.titleLabel} mt={5} mb={1}>
            {`${activeId} ${activeTopic?.title}`}
          </Typography>
          <Typography variant="body3" color={theme.palette.text.captionColor} mt={2}>
            {activeTopic?.description}
          </Typography>
          <CardMedia component="img" image={activeTopic?.image} alt="article" sx={{ height: "19.5em", mt: 3, mb: 5 }} />
          <Typography variant="body3" color={theme.palette.text.titleLabel} dangerouslySetInnerHTML={{ __html: activeTopic?.content }} />
          <Box mt={10}>
            <Typography variant="h2" color={theme.palette.text.titleLabel} textTransform="none">
              Was this article helpful?
            </Typography>
            <Box display="flex" gap={3} my={3}>
              <CustomTertiaryButton sx={{ ...theme.typography.bigButton, height: "36px", px: "1.8em" }}>Yes</CustomTertiaryButton>
              <CustomTertiaryButton sx={{ ...theme.typography.bigButton, height: "36px", px: "1.8em" }}>No</CustomTertiaryButton>
            </Box>
            <Typography variant="bigCaption" color={theme.palette.text.titleLabel}>
              Have more questions? Let’s talk in <Link href="#">a chat.</Link>
            </Typography>
          </Box>
          <Box mt={10}>
            <Typography variant="h4" color={theme.palette.text.titleLabel} mb={0.5}>
              Related Articles
            </Typography>
            <Box
              sx={{
                borderTop: "1px solid",
                borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
              }}
            >
              {article?.relatedArticles?.map((related) => (
                <Box>
                  <Typography variant="h5" color={theme.palette.text.titleLabel} my={1.5}>
                    {related?.title}
                  </Typography>
                  <CustomDivider width="100%" />
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={4} order={{ xs: 1, lg: 2 }}>
          <Box
            sx={{
              [theme.breakpoints.down("lg")]: {
                display: "none",
              },
            }}
          >
            <SubPoints points={points} getActivePoint={getActivePoint} />
          </Box>
          <Accordion
            expanded={expand}
            onChange={handleExpand}
            sx={{
              background: theme.palette.background.darkCardBackground,
              borderRadius: 2,
              [theme.breakpoints.up("lg")]: {
                display: "none",
              },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>On these page</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SubPoints points={points} getActivePoint={getActivePoint} />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  );
}
