import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Typography, useTheme } from "@mui/material";
import CustomDivider from "../../../../../../components/CustomDivider";
import CustomStatus from "../../../../../../components/CustomStatus";

// interface sentimentOptions {
//   key: string;
//   value: number;
// }

interface sentimentProps {
  sentimentData: Record<string, any>;
}
export default function SentimentAnalysisCard({ sentimentData }: sentimentProps) {
  const theme = useTheme();
  const modifiedSentimateData = Object.entries(sentimentData).map(([key, value]) => ({ key: key, value: value }));
  return (
    <Box
      sx={{
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        px: 2,
        pt: 2,
        pb: 2.5,
        width: "100%",
        borderRadius: 2,
        flexFlow: "row wrap",
      }}
    >
      <Box display="flex" mb={1}>
        <Typography variant="h4" fontSize={"18px"} color={theme.palette.text.titleLabel}>
          Sentiment analysis
        </Typography>
        <InfoOutlinedIcon sx={{ color: theme.palette.text.tableHeader, ml: 1, fontSize: "16px" }} />
      </Box>
      <CustomDivider />
      <Box sx={{ height: "6px", width: "100%", display: "flex", borderRadius: "8px", my: 3 }}>
        {modifiedSentimateData.map((data: any, idx) => {
          const barColor =
            data?.key === "positive"
              ? theme.palette.text.neutralStatus
              : data?.key === "neutral"
              ? theme.palette.text.lowStatus
              : theme.palette.text.extremeStatus;
          return <Box key={idx} id="gray" sx={{ width: `${100 - data?.value}%`, background: barColor }}></Box>;
        })}
      </Box>
      {modifiedSentimateData.map((data: any, idx) => {
        const status = data?.key === "positive" ? "neutral" : data?.key === "negative" ? "extreme" : "low";
        return (
          <Box display="flex" justifyContent="space-between" gap={10} mt={2} key={`sentiment-${idx}`}>
            <Typography variant="h6" sx={{ textTransform: "capitalize", fontSize: "14px", color: theme.palette.text.titleLabel }}>
              {data?.key}
            </Typography>
            <CustomStatus status={status} label={`${data?.value}%`} sx={{ height: "20px", minWidth: "40px" }} />
          </Box>
        );
      })}
    </Box>
  );
}
