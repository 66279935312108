import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { FaRegEdit, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import BackButton from "../../../../components/BackButton";
import CustomButton from "../../../../components/CustomButton";
import CustomIconButton from "../../../../components/CustomIconButton";
import CustomInputField from "../../../../components/CustomInputField";
import CustomSecondaryButton from "../../../../components/CustomSecondaryButton";
import { routes } from "../../../../routes";
import { useHandleRouteClick } from "../../../../routes/hooks";
import { useAppDispatch } from "../../../../store/hooks";
import { deletePipeline, getPipeline } from "../../../../store/pipeline/pipelineThunk";

interface CustomCase {
  id: number;
  name: string;
}

export default function CustomCaseList() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const handleRouteClick = useHandleRouteClick();
  const [searchList, setSearchList] = useState<CustomCase[]>([]);
  const [pipeline, setPipeline] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleGetAllPipelines();
  }, []);

  const handleGetAllPipelines = () => {
    dispatch(getPipeline())
      .then((res) => {
        setLoading(false);
        if (res?.payload?.status === 200) {
          setPipeline(res?.payload?.data);
          setSearchList(res?.payload?.data);
        } else {
          toast.error("Data fetched failed");
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error("Data fetched failed");
      });
  };

  const handleBack = () => {
    handleRouteClick(routes.pipelines.path);
  };

  const handleNavigation = (id: string, name: string) => {
    handleRouteClick(routes.specificCustomCase.path.replace(":id", id), { name: name });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filtered = pipeline.filter((item: any) => {
      return Object.values(item).some((value) => {
        return String(value).toLowerCase().includes(e.target.value);
      });
    });
    setSearchList(filtered);
  };

  const handleEdit = (id: string, name: string) => {
    handleRouteClick(routes.modifyCustomCase.path.replace(":pipelineId", id), { backPath: "/customcase", name: name });
  };

  const handleDeletePipeline = (id: string) => {
    dispatch(deletePipeline(id)).then((res) => {
      if (res.payload.status === 204) {
        toast.success("Deleted the Pipeline.");
        handleGetAllPipelines();
      } else {
        toast.error("Some issue while deleting the pipeline.");
      }
    });
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Stack direction={{ xs: "column", md: "row" }} alignItems={{ xs: "start", md: "center" }} py={2} px={1}>
        <Stack direction="column" alignItems="flex-start">
          <Stack direction="row" alignItems="flex-start" gap={3}>
            <Typography variant="h1" color={theme.palette.text.titleLabel}>
              Custom Pipelines:
            </Typography>
          </Stack>

          <BackButton onClick={handleBack} sx={{ mt: "-3px" }} />
        </Stack>
        <Stack direction="row" alignItems="center" ml="auto">
          <CustomInputField
            placeholder=" Search"
            size="small"
            variant="outlined"
            autoComplete="off"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              "&.MuiFormControl-root.MuiTextField-root .MuiInputBase-root": {
                height: "36px",
              },
              "&.MuiTextField-root": {
                mt: 0,
              },
            }}
          />
          <CustomButton
            onClick={() => handleRouteClick(routes.pipelineCreation.path, { backPath: "/customcase" })}
            sx={{ ...theme.typography.bigButton, height: "36px", color: theme.palette.text.tableHeader }}
            endIcon={<AddIcon sx={{ color: theme.palette.text.default }} />}
          >
            New Custom Pipeline
          </CustomButton>
        </Stack>
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          borderTop: "1px solid",
          borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
          background: theme.palette.dashboardContainer.containerBackground,
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", lg: "70%", xl: "60%" },
            height: "100%",
            overflow: "auto",
            px: 2,
          }}
        >
          {loading ? (
            <Box sx={{ textAlign: "center", mt: 5 }}>
              <Typography variant="semiBold" sx={{ color: theme.palette.text.titleLabel }}>
                Loading...
              </Typography>
            </Box>
          ) : (
            <>
              {searchList?.length > 0 ? (
                <>
                  {searchList.map((customCase: any, index) => {
                    console.log(customCase.id);
                    return (
                      <Box
                        key={`customCase${index}`}
                        sx={{
                          display: "flex",
                          background: theme.palette.background.dark,
                          px: 3,
                          py: 5,
                          borderRadius: 2,
                          alignItems: "center",
                          my: 2,
                        }}
                      >
                        <Typography variant="semiBold" sx={{ color: theme.palette.text.default }}>
                          {customCase?.name}
                        </Typography>
                        <Box display="flex" gap={1} ml="auto">
                          <CustomSecondaryButton
                            onClick={() => handleNavigation(customCase?._id, customCase?.name)}
                            endIcon={
                              <SearchIcon
                                sx={{
                                  ml: 4,
                                  color: theme.palette.text.default,
                                  "&:active": {
                                    color: theme.palette.text.active,
                                  },
                                }}
                              />
                            }
                            sx={{ height: "4vh" }}
                          >
                            Query
                          </CustomSecondaryButton>
                          <CustomIconButton sx={{ width: "3em", height: "4vh" }} onClick={() => handleEdit(customCase?.id, customCase?.name)}>
                            <FaRegEdit style={{ fontSize: "1em" }} color={theme.palette.text.default} />
                          </CustomIconButton>
                          <CustomIconButton
                            sx={{ width: "3em", height: "4vh" }}
                            onClick={() => {
                              handleDeletePipeline(customCase.id);
                            }}
                          >
                            <FaTrashAlt style={{ fontSize: "1em" }} color={theme.palette.text.default} />
                          </CustomIconButton>
                        </Box>
                      </Box>
                    );
                  })}
                </>
              ) : (
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <Typography variant="semiBold" sx={{ color: theme.palette.text.titleLabel }}>
                    No results found!
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
