import FlagIcon from "@mui/icons-material/Flag";
import HexagonIcon from "@mui/icons-material/Hexagon";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import PersonIcon from "@mui/icons-material/Person";
import { Box, Grid, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomStatics from "../../../components/CustomStatics";
import UiLoading from "../../../layouts/UiLoading";
import { getLatestQuery, getQueryAnalyticsData } from "../../../store/analysis/analysisThunk";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getFormattedDate } from "../../../utils/helper";
import RiskSummary from "../Components/Overview/RiskSummary";
import Title from "../Components/Title";

export default function OverviewWrapper() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { analyticsData, isLoading } = useAppSelector((state) => state.analysis);
  const queryId = searchParams.get("query");
  const [lastUpdated, setLastUpdated] = useState(getFormattedDate(new Date()));

  const statisticData = [
    { label: "Sources", count: analyticsData.sources_count, icon: <HexagonIcon sx={{ color: theme.palette.additionalColors.light }} /> },
    { label: "Results", count: analyticsData.results_count, icon: <InsertDriveFileIcon sx={{ color: theme.palette.additionalColors.light }} /> },
    { label: "Mentions", count: analyticsData.mentions_count, icon: <MarkChatUnreadIcon sx={{ color: theme.palette.additionalColors.light }} /> },
    { label: "Entities", count: analyticsData.entities_count, icon: <PersonIcon sx={{ color: theme.palette.additionalColors.light }} /> },
    { label: "Red Flags", count: analyticsData.red_flags_count, icon: <FlagIcon sx={{ color: theme.palette.additionalColors.light }} /> },
  ];

  useEffect(() => {
    if (queryId) {
      dispatch(getQueryAnalyticsData(queryId)).then((res) => {
        if (res?.payload?.status !== 200) {
          dispatch(getLatestQuery()).then((latestRes) => {
            if (latestRes.payload.status === 200 && latestRes.payload.data._id !== undefined) {
              dispatch(getQueryAnalyticsData(latestRes.payload.data._id));
            }
          });
        }
      });
    } else {
      dispatch(getLatestQuery()).then((res) => {
        if (res.payload.status === 200 && res.payload.data._id !== undefined) {
          dispatch(getQueryAnalyticsData(res.payload.data._id));
        }
      });
    }
    handleRefresh();
  }, [queryId]);

  const handleRefresh = () => {
    const currentDate = new Date();
    setLastUpdated(getFormattedDate(currentDate) + " at " + currentDate.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" }));
  };

  return (
    <Box
      sx={{
        borderTop: "1px solid",
        borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
        background: theme.palette.dashboardContainer.containerBackground,
      }}
      p={2}
    >
      {isLoading ? (
        <UiLoading height="100%" />
      ) : (
        <>
          <Title title="Overview" lastUpdated={lastUpdated} refresh={true} handleRefresh={handleRefresh} />
          <Grid
            container
            mt={2}
            spacing={2}
            sx={{ borderTop: "1px solid", borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1` }}
          >
            {statisticData.map((staticData: any, idx) => (
              <Grid item xs={12} sm={4} lg={2.4} key={`statics-${idx}`} display="flex" justifyContent="space-between" gap={1} width="100%">
                <CustomStatics label={staticData?.label} count={staticData?.count} icon={staticData?.icon} />
              </Grid>
            ))}
          </Grid>
          <Grid mt={4}>
            <RiskSummary />
          </Grid>
        </>
      )}
    </Box>
  );
}
