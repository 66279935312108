import { Close } from "@mui/icons-material";
import { Box, Chip, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import BorderedCard from "../../../components/BorderedCard";
import CustomDivider from "../../../components/CustomDivider";
import CustomTextButton from "../../../components/CustomTextButton";
import { addGlobalRedFlags, deleteGlobalRedFlag, getGlobalRedFlags, getGlobalRedFlagsWithId } from "../../../store/analysis/analysisThunk";
import { useAppDispatch } from "../../../store/hooks";
import AddNewKeywordDialog from "./AddNewKeywordDialog";

// const allredFlagwords = [
//   { id: 1, label: "missile attack" },
//   { id: 2, label: "weapons" },
//   { id: 3, label: "weapons" },
//   { id: 4, label: "weapons" },
//   { id: 5, label: "weapons" },
//   { id: 6, label: "weapons" },
//   { id: 7, label: "weapons" },
//   { id: 8, label: "weapons" },
//   { id: 9, label: "weapons" },
//   { id: 10, label: "weapons" },
// ];

export interface RedFlagwordData {
  id: number;
  keyword: string;
}

export default function RedFlaggedWordsSettings() {
  const theme = useTheme();
  const [editable, setEditable] = React.useState<boolean>(false);
  const [redFlagwords, setredFlagwords] = React.useState<string[]>([]);
  const [redFlagwordsWithId, setredFlagwordsWithId] = React.useState<RedFlagwordData[]>([]);
  const [openAddDialog, setOpenAddDialog] = React.useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getGlobalRedFlags()).then((res) => {
      console.log("res redFlagwords", res);
      if (res?.payload?.status === 200) {
        setredFlagwords(res.payload?.data?.keywords);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getGlobalRedFlagsWithId()).then((res) => {
      if (res?.payload?.status === 200) {
        setredFlagwordsWithId(res.payload.data);
      }
    });
  }, [redFlagwords]);

  const toggleAddDialog = () => {
    setOpenAddDialog((prev) => !prev);
  };

  const handleEditSave = () => {
    if (editable) {
      //   handleProfileUpdate(updatedProfile);
    }
    setEditable(!editable);
  };

  const handleDeleteredFlagwords = (keywordId: string) => {
    // get the id of the keyword string
    const result = redFlagwordsWithId.find((keyword) => keyword.keyword === keywordId);
    if (result) {
      dispatch(deleteGlobalRedFlag(result?.id)).then((res) => {
        if (res.payload.status === 204) {
          setredFlagwords((prev) => {
            return prev.filter((kw) => kw !== keywordId);
          });
        }
      });
    } else {
      toast.error(`Keyword ${keywordId} not found`);
    }
  };

  const handleAddKeyword = (value: string) => {
    const newValues: string[] = value.split(",").filter((v) => v.length >= 1);

    console.log("newValues :", newValues);
    console.log("redFlagwords.length :", redFlagwords.length);
    if (!redFlagwords.length) {
      setredFlagwords(newValues);
      dispatch(addGlobalRedFlags({ keywords: value.split(",") }));
    } else {
      dispatch(addGlobalRedFlags({ keywords: newValues })).then((res) => {
        if (res?.payload?.status === 201) {
          const updatedredFlagwords = [...redFlagwords, ...newValues];
          setredFlagwords(updatedredFlagwords);
        }
      });
    }
  };

  return (
    <BorderedCard>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h4" color={theme.palette.text.titleLabel} mb={0.2}>
            Red Flagged Words
          </Typography>
          <Typography variant="caption" color={theme.palette.primary.inactiveIcon}>
            This is the base of words that are identified by the system as “red flagged”. You can edit this list by deleting the words or adding ones.
          </Typography>
        </Box>
        {editable ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <CustomTextButton onClick={handleEditSave} sx={{ color: theme.palette.error.text }}>
              Cancel
            </CustomTextButton>
            <CustomTextButton onClick={toggleAddDialog}>Add New</CustomTextButton>
            <CustomTextButton onClick={handleEditSave}>Save</CustomTextButton>
            <AddNewKeywordDialog open={openAddDialog} handleToggle={toggleAddDialog} handleSubmit={(text) => handleAddKeyword(text)} />
          </Box>
        ) : (
          <CustomTextButton onClick={handleEditSave}>Edit</CustomTextButton>
        )}
      </Box>
      <CustomDivider />
      <>
        <Box
          sx={{
            boxShadow: theme.palette.dashboardContainer.cardInsetBoxShadow,
            borderRadius: 1,
            // border: `0.1px solid ${theme.palette.additionalColors.border}`,
            background: theme.palette.background.paper,
            p: 2,
            minHeight: "127px",
            mt: 3,
          }}
        >
          <Stack direction="row" flexWrap="wrap" gap={1.5}>
            {redFlagwords.length > 0 ? (
              redFlagwords.map((word, index) => (
                <Chip
                  deleteIcon={editable ? <Close /> : <></>}
                  color="error"
                  key={index}
                  label={word}
                  onDelete={() => handleDeleteredFlagwords(word)}
                  sx={{
                    borderRadius: 1,
                    background: theme.palette.error.text,
                    height: "28px",
                  }}
                />
              ))
            ) : (
              <Typography variant="caption1" textAlign="center">
                No redFlagwords
              </Typography>
            )}
          </Stack>
        </Box>
      </>
    </BorderedCard>
  );
}
