import { useTheme } from "@mui/material";
import React from "react";
import { BiEdit } from "react-icons/bi";
import { BsThreeDots } from "react-icons/bs";
import { useParams } from "react-router-dom";
import CustomIconButton from "../../../components/CustomIconButton";
import CustomPopover from "../../../components/CustomPopover";
import CustomTextButton from "../../../components/CustomTextButton";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";

export interface MoreUserOptionsTooltipProps {
  userId: string | number;
  details: any;
}

export default function MoreUserOptionsTooltip({ userId, details }: MoreUserOptionsTooltipProps) {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();
  const params = useParams();
  const {workspaceId} = useParams();
  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleOpenMoreOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreOptionsAnchorEl(event.currentTarget);
  };

  const handleCloseMoreOptions = () => {
    setMoreOptionsAnchorEl(null);
  };

  const handleUserUpdate = () => {
    handleRouteClick(routes.specificUser.path.replace(':workspaceId', String(workspaceId)).replace(":userId", String(userId)), { workspaceId: params.workspaceId, userDetails: details });
  };

  return (
    <>
      <CustomIconButton onClick={handleOpenMoreOptions} sx={{ color: theme.palette.text.default }}>
        <BsThreeDots />
      </CustomIconButton>
      <CustomPopover open={Boolean(moreOptionsAnchorEl)} anchorEl={moreOptionsAnchorEl} onClose={handleCloseMoreOptions}>
        <CustomTextButton onClick={handleUserUpdate} startIcon={<BiEdit size={16} />}>
          Edit
        </CustomTextButton>
      </CustomPopover>
    </>
  );
}
