import { Box, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import BackButton from "../../../../../../../components/BackButton";
import CustomStatus from "../../../../../../../components/CustomStatus";
import CustomTranscript, { Transcript } from "../../../../../../../components/CustomTranscript";
import PrimaryLoadingButton from "../../../../../../../components/PrimaryLoadingButton";
import { routes } from "../../../../../../../routes";
import { useHandleRouteClick } from "../../../../../../../routes/hooks";
import { useAppSelector } from "../../../../../../../store/hooks";
import RedFlaggedCard from "../../../../Overview/RiskSummary/component/RedFlaggedCard";
import RiskFactorCard from "../../../../Overview/RiskSummary/component/RiskFactorCard";
import SentimentAnalysisCard from "../../../../Overview/RiskSummary/component/SentimentAnalysisCard";
import RelatedDetails from "./RelatedDetails";
import VideoDetailPlayer from "./VideoPlayer";

const relatedDetails = [
  {
    title: "Scene Understanding",
    type: "scene",
    data: [
      {
        src: "https://www.youtube.com/embed/K0nzQoT6Ymw",
        title: "Scene Name",
        thumbnail: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
      },
      {
        src: "https://www.youtube.com/embed/K0nzQoT6Ymw",
        title: "Scene Name",
        thumbnail: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
      },
      {
        src: "https://www.youtube.com/embed/K0nzQoT6Ymw",
        title: "Scene Name",
        thumbnail: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
      },
      {
        src: "https://www.youtube.com/embed/K0nzQoT6Ymw",
        title: "Scene Name",
        thumbnail: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
      },
      {
        src: "https://www.youtube.com/embed/K0nzQoT6Ymw",
        title: "Scene Name",
        thumbnail: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
      },
      {
        src: "https://www.youtube.com/embed/K0nzQoT6Ymw",
        title: "Scene Name",
        thumbnail: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
      },
      {
        src: "https://www.youtube.com/embed/K0nzQoT6Ymw",
        title: "Scene Name",
        thumbnail: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
      },
      {
        src: "https://www.youtube.com/embed/K0nzQoT6Ymw",
        title: "Scene Name",
        thumbnail: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
      },
    ],
  },
  {
    title: "Face Recognition",
    type: "face",
    data: [
      { src: "/assets/person.png", title: "Entity name" },
      { src: "/assets/person.png", title: "Entity name" },
      { src: "/assets/person.png", title: "Entity name" },
      { src: "/assets/person.png", title: "Entity name" },
      { src: "/assets/person.png", title: "Entity name" },
      { src: "/assets/person.png", title: "Entity name" },
      { src: "/assets/person.png", title: "Entity name" },
      { src: "/assets/person.png", title: "Entity name" },
      { src: "/assets/person.png", title: "Entity name" },
    ],
  },
  {
    title: "Entity Recognition",
    type: "entity",
    data: [
      { src: "/assets/person.png", title: "Entity name" },
      { src: "/assets/person.png", title: "Entity name" },
      { src: "/assets/person.png", title: "Entity name" },
      { src: "/assets/person.png", title: "Entity name" },
      { src: "/assets/person.png", title: "Entity name" },
      { src: "/assets/person.png", title: "Entity name" },
      { src: "/assets/person.png", title: "Entity name" },
      { src: "/assets/person.png", title: "Entity name" },
      { src: "/assets/person.png", title: "Entity name" },
    ],
  },
];
export default function SpecificVideo() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const backHandler = () => {
    handleRouteClick(`${routes.home.path}?tab=analysis&page=feed`, { activeTab: "video" });
  };
  const { analyticsData } = useAppSelector((state) => state.analysis);

  const sentimentData = [
    { key: "positive", value: 77 },
    { key: "neutral", value: 5 },
    { key: "negative", value: 18 },
  ];

  const redFlaggedData = [
    { key: "Terrorism", value: 590 },
    { key: "Weapon", value: 467 },
    { key: "Kill", value: 239 },
    { key: "Violence", value: 127 },
  ];

  const riskFactorData = [
    {
      title: "Behavioral Risk Factor",
      status: "high",
      subData: [
        {
          label: "Unusual online activities",
          count: 14,
        },
        {
          label: "Unusual online activities",
          count: 24,
        },
        {
          label: "Unusual online activities",
          count: 0,
        },
        {
          label: "Unusual online activities",
          count: 56,
        },
      ],
    },
    {
      title: "Behavioral Risk Factor",
      status: "high",
      subData: [
        {
          label: "Unusual online activities",
          count: 14,
        },
        {
          label: "Unusual online activities",
          count: 24,
        },
        {
          label: "Unusual online activities",
          count: 0,
        },
        {
          label: "Unusual online activities",
          count: 56,
        },
      ],
    },
    {
      title: "Content Risk Factor",
      status: "neutral",
      subData: [
        {
          label: "Unusual online activities",
          count: 14,
        },
        {
          label: "Unusual online activities",
          count: 24,
        },
        {
          label: "Unusual online activities",
          count: 0,
        },
        {
          label: "Unusual online activities",
          count: 56,
        },
      ],
    },
  ];

  const videoTextSummary: Transcript[] = [
    {
      time: "00:00",
      text: "The balloon first crossed into US airspace over Alaska in late January before passing through Canada and down into Montana, where it hovered for a few days, leading the US to believe it was trying to surveil sensitive military sites, like Malmstrom Air Force Base in Montana.",
    },
    {
      time: "01:00",
      text: "The balloon first crossed into US airspace over Alaska in late January before passing through Canada and down into Montana, where it hovered for a few days, leading the US to believe it was trying to surveil sensitive military sites, like Malmstrom Air Force Base in Montana.",
    },
    {
      time: "02:00",
      text: "The balloon first crossed into US airspace over Alaska in late January before passing through Canada and down into Montana, where it hovered for a few days, leading the US to believe it was trying to surveil sensitive military sites, like Malmstrom Air Force Base in Montana.",
    },
    {
      time: "03:00",
      text: "The balloon first crossed into US airspace over Alaska in late January before passing through Canada and down into Montana, where it hovered for a few days, leading the US to believe it was trying to surveil sensitive military sites, like Malmstrom Air Force Base in Montana.",
    },
  ];

  return (
    <Grid container sx={{ display: "flex", flexFlow: "column", height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          padding: "5px",
        }}
      >
        <Typography variant="h3" fontWeight="bold" sx={{ marginLeft: "10px", color: theme.palette.text.titleLabel }} display={"flex"}>
          Video Analysis
        </Typography>

        <PrimaryLoadingButton
          onClick={() => console.log("update")}
          varient="text"
          sx={{
            ...theme.typography.bigButton,
            ml: "auto",
          }}
        >
          Update Model
        </PrimaryLoadingButton>
      </Box>
      <Box>
        <BackButton onClick={backHandler} sx={{ pl: 1, mt: -2 }} />
      </Box>
      <Grid container overflow={{ xs: "auto", xl: "hidden" }} height="100%" mt={2}>
        <Grid item xs={12} xl={7} pt={0}>
          <Box
            sx={{
              background: theme.palette.background.default,
              boxShadow: theme.palette.dashboardContainer.insetBoxShadow,
              borderRadius: { md: "18px" },
              px: 2,
              pt: 2,
              pb: 3,
              height: "100%",
            }}
          >
            <Typography variant="h3" fontSize={"22px"} color={theme.palette.text.tableHeader} pb={1}>
              Video name 93458
            </Typography>

            <Box height={{ xs: "48vh", xl: "100%" }}>
              <VideoDetailPlayer
                src="https://www.youtube.com/embed/K0nzQoT6Ymw"
                showPlayerControls={true}
                isFullScreen={true}
                refreshIntervalInSec={0}
                thumbnail="https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg"
                values={[1, 2, 3]}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} xl={5} height="100%" pl={{ xl: 3 }}>
          <Box
            sx={{
              background: { xl: theme.palette.background.darkCardBackground },
              border: { xl: theme.palette.additionalColors.databasecardBorder },
              borderRadius: { xl: 2 },
              height: { xl: "100%" },
              overflow: { xl: "hidden" },
              p: 2,
            }}
          >
            <Box
              sx={{
                height: { xl: "100%" },
                overflow: { xl: "scroll" },
              }}
            >
              <Box mt={{ xs: 2, xl: 0 }}>
                <Typography variant="h3" fontSize={"22px"} color={theme.palette.text.titleLabel}>
                  Video Summary
                </Typography>
                <Grid container spacing={2} mt={0.1} display="flex">
                  <Grid item xs={12} sm={6} display="flex">
                    <SentimentAnalysisCard sentimentData={analyticsData?.sentiment_summary} />
                  </Grid>
                  <Grid item xs={12} sm={6} display="flex">
                    <RedFlaggedCard redFlaggedData={redFlaggedData} enableViewAll={false} enableBorder={true} />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={3}>
                <Box display="flex" gap={3} mb={-2}>
                  <Typography variant="h3" fontSize={"22px"} color={theme.palette.text.titleLabel}>
                    Risk Score
                  </Typography>
                  <CustomStatus label="high" status="high" sx={{ width: "88px", height: "24px" }} />
                </Box>
                <Box mt={2} width="100%">
                  <Grid container mt={2} spacing={2}>
                    {riskFactorData.map((riskData, idx) => {
                      const count = riskFactorData.length === idx + 1 && idx % 2 === 0 ? 12 : 6;
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={4}
                          xl={count}
                          key={`statics-${idx}`}
                          display="flex"
                          justifyContent="space-between"
                          gap={1}
                          width="100%"
                        >
                          <RiskFactorCard riskFactorData={riskData} checkBox={true} />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>

              <Box mt={3} width="100%">
                <Box display="flex" gap={3}>
                  <Typography variant="h3" fontSize={"22px"} color={theme.palette.text.titleLabel}>
                    Video-to-Text Summary
                  </Typography>
                </Box>

                <Box
                  mt={2}
                  sx={{
                    background: theme.palette.background.darkCardBackground,
                    border: theme.palette.additionalColors.databasecardBorder,
                    p: 2,
                    width: "100%",
                    borderRadius: 2,
                  }}
                >
                  <Typography variant="body3" color={theme.palette.text.titleLabel}>
                    The balloon first crossed into US airspace over Alaska in late January before passing through Canada and down into Montana, where
                    it hovered for a few days, leading the US to believe it was trying to surveil sensitive military sites, like Malmstrom Air Force
                    Base in Montana. It was eventually shot down by the US off the East Coast on February 4, and the incident further escalated
                    tensions between Washington and Beijing, including the postponement of a diplomatic visit by US Secretary of State Antony Blinken
                    to China. A senior State Department official said in February that, as the balloon floated across the US, it “was capable of
                    conducting signals intelligence collection operations.”
                  </Typography>
                </Box>
              </Box>

              <Box mt={3} width="100%">
                <Box display="flex" gap={3}>
                  <Typography variant="h3" fontSize={"22px"} color={theme.palette.text.titleLabel}>
                    Video Text Summary
                  </Typography>
                  <Typography
                    ml="auto"
                    variant="bigCaption"
                    color={theme.palette.text.default}
                    display={"flex"}
                    sx={{
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                    onClick={() => {
                      console.log("view All");
                    }}
                  >
                    View All
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    background: theme.palette.background.darkCardBackground,
                    border: theme.palette.additionalColors.databasecardBorder,
                    p: 3,
                    mt: 2,
                    borderRadius: 2,
                  }}
                >
                  <CustomTranscript transcript={videoTextSummary} />
                </Box>
              </Box>
              {relatedDetails.map((data: any, idx: number) => {
                return (
                  <React.Fragment key={`relatedData-${idx}`}>
                    <RelatedDetails relatedDetails={data} />
                  </React.Fragment>
                );
              })}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
