import { Box } from "@mui/material";
import TabLayout from "../../layouts/TabLayout";
import { useAppSelector } from "../../store/hooks";
import AnalysisContainer from "./AnalysisContainer";
import { useSearchParams } from "react-router-dom";
const tabs = [
  {
    id: "home",
    title: "Home",
    disabled: false,
    component: <AnalysisContainer />,
  },
];

export default function Analytics() {
  const { query } = useAppSelector((state: Record<string, any>) => state.analysisQuery);
  const { activeAnalysisTab } = useAppSelector((state: Record<string, any>) => state.ui);
  const [searchParams , setSearchParams] = useSearchParams();  
  return (
    <Box>
      <TabLayout tabs={tabs} subTab={true} primarySubTab={activeAnalysisTab} queryId={searchParams.get("query")||query?._id} />
    </Box>
  );
}
