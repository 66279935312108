import { Box, Grid, List, ListItem, Typography, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";
import BorderedCard from "../../../components/BorderedCard";
import CustomDivider from "../../../components/CustomDivider";
import SubmitLoadingDeleteButton from "../../../components/SubmitLoadingDeleteButton";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";

export default function CurrentPlanDetails() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const PlanInfo = (keyName: string, value: string) => (
    <Grid
      container
      spacing={1}
      sx={{
        display: "flex",
        alignItems: "center",
        mt: 0.5,
      }}
    >
      <Grid item xs={4}>
        <Typography variant="body3" color={theme.palette.text.captionColor}>
          {keyName}:
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body3" color={theme.palette.text.titleLabel}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );

  const CurrentPreferences = (options: string[]) => (
    <>
      {options?.map((opt, idx) => (
        <List
          key={idx}
          sx={{
            listStyleType: "disc",
            pl: 2,
            "&.MuiList-root": {
              py: 0,
            },
          }}
        >
          <ListItem sx={{ display: "list-item", px: 0 }}>
            <Typography variant="caption" sx={{ color: theme.palette.text.titleLabel }}>
              {opt}
            </Typography>
          </ListItem>
        </List>
      ))}
    </>
  );

  const cancelPlanHandler = () => {
    handleRouteClick(routes.downgradeInfo.path, { plan: "pro" });
  };

  return (
    <BorderedCard
      sx={{
        display: "flex",
        gap: 4,
        flexDirection: "column",
        borderRadius: "16px",
        background: theme.palette.background.gradientLight1,
        border: `2px solid ${theme.palette.additionalColors.border}`,
      }}
    >
      <Box>
        <Typography variant="h2" sx={{ color: theme.palette.text.titleLabel }}>
          You’re currently on the Professional plan.
        </Typography>
        <Typography variant="caption" color={theme.palette.text.captionColor}>
          You’re currently on the Professional plan. Upgrade your plan to upload your own data or customize the AI Models.
        </Typography>
        <CustomDivider />
        <Box mt={4}>
          <Typography variant="h4" sx={{ color: theme.palette.text.titleLabel }}>
            Plan information
          </Typography>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} md={5}>
              {PlanInfo("First payment date", "Jule 17th, 2023")}
              {PlanInfo("Next payment date", "Jule 17th, 2023")}
              {PlanInfo("Amount due", "$300")}
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="caption" color={theme.palette.primary.inactiveIcon}>
                Your subscription will automatically renew 17.07.2024. You will be charged 300$ per year for Professional Plan. You can upgrade or
                cancel your plan at any time.
              </Typography>
              <Typography variant="caption" color={theme.palette.primary.inactiveIcon} mt={2}>
                If you have any questions please contact us&nbsp;
                <NavLink
                  to="/login"
                  style={{
                    color: theme.palette.primary.main,
                    textDecoration: "none",
                    fontWeight: theme.typography.fontWeightMedium,
                  }}
                >
                  lowcode_support@uk
                </NavLink>
              </Typography>
            </Grid>
          </Grid>
          <SubmitLoadingDeleteButton
            isLoading={false}
            sx={{ py: 0.5, width: "12rem", background: theme.palette.error.text, color: theme.palette.error.btnText, mt: 4 }}
            onClick={cancelPlanHandler}
          >
            Cancel Plan
          </SubmitLoadingDeleteButton>
        </Box>
      </Box>
      <Box>
        <Typography variant="h4" sx={{ color: theme.palette.text.titleLabel }}>
          Current Preferences
        </Typography>
        <CustomDivider />
        <Grid container spacing={4} mt={1}>
          <Grid item xs={12} md={4} mt={-2}>
            <BorderedCard sx={{ background: theme.palette.background.paper, height: "100%" }}>
              <Typography variant="subtitle1" color={theme.palette.primary.light}>
                Solutions Available
              </Typography>
              <CustomDivider />
              {CurrentPreferences(["Social Media Analysis", "News Analysis", "Predictable Analysis"])}
            </BorderedCard>
          </Grid>
          <Grid item xs={12} md={4} mt={-2}>
            <BorderedCard sx={{ background: theme.palette.background.paper, height: "100%" }}>
              <Typography variant="subtitle1" color={theme.palette.primary.light}>
                AI Models available
              </Typography>
              <CustomDivider />
              {CurrentPreferences(["Social Media Analysis", "News Analysis", "Predictable Analysis"])}
            </BorderedCard>
          </Grid>
          <Grid item xs={12} md={4} mt={-2}>
            <BorderedCard sx={{ background: theme.palette.background.paper, height: "100%" }}>
              <Typography variant="subtitle1" color={theme.palette.primary.light}>
                Data Sources Available
              </Typography>
              <CustomDivider />
              {CurrentPreferences(["Twitter", "News Blogs", "News Articles", "Instagram", "Tik Tok"])}
            </BorderedCard>
          </Grid>
        </Grid>
      </Box>
    </BorderedCard>
  );
}
