/* eslint-disable react-hooks/exhaustive-deps */

import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ReactFlow, {
  Controls,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  Position,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  updateEdge,
} from "reactflow";
import "reactflow/dist/style.css";
import BackButton from "../../../../components/BackButton";
import CustomInputField from "../../../../components/CustomInputField";
import CustomSecondaryButton from "../../../../components/CustomSecondaryButton";
import CustomTextButton from "../../../../components/CustomTextButton";
import PrimaryLoadingButton from "../../../../components/PrimaryLoadingButton";
import { routes } from "../../../../routes";
import { useHandleRouteClick } from "../../../../routes/hooks";
import { useAppDispatch } from "../../../../store/hooks";
import { getAllModules, getPipeline, getPipelineDetail, savePipeline, updatePipeline } from "../../../../store/pipeline/pipelineThunk";
import CustomNode from "./CustomNode";
import ModuleCard from "./ModuleCard";

interface PipeLineCustomProps {
  isEdit: boolean;
}
interface AvailableModulesType {
  id: string;
  label: string;
  inputs: string[];
  outputs: string[];
  type: string;
  subtype?: string;
}

export default function PipelineCreation({ isEdit }: PipeLineCustomProps) {
  const theme = useTheme();
  const params = useParams();
  const dispatch = useAppDispatch();
  const handleRouteClick = useHandleRouteClick();

  const { state } = useLocation();
  const edgeUpdateSuccessful = useRef(true);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [caseName, setCaseName] = useState<string>(state?.name);
  const [removeNode, setRemoveNode] = useState("");
  const [availableModules, setAvailableModules] = useState<AvailableModulesType[]>([]);
  const [onlineSourceOptions, setOnlineSourceOptions] = useState<string[]>([]);
  const [physicalSourceOptions, setPhysicalSourceOptions] = useState<string[]>([]);
  const [textModelsOptions, setTextModelsOptions] = useState<string[]>([]);
  const [mediaModelsOptions, setMediaModelsOptions] = useState<string[]>([]);
  const [outputOptions, setOutputOptions] = useState<string[]>([]);

  useEffect(() => {
    dispatch(getAllModules())
      .then((res) => {
        if (res?.payload !== undefined) {
          if (res?.payload?.status === 201 || res?.payload?.status === 200) {
            setAvailableModules(res?.payload?.data);
          }
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);

  useEffect(() => {
    let onlineSources: string[] = [];
    let physicalSources: string[] = [];
    let textModels: string[] = [];
    let mediaModels: string[] = [];
    let outputs: string[] = [];
    availableModules.forEach((module) => {
      if (module.type === "sources") {
        if (module.subtype === "online") {
          onlineSources.push(module.label);
        } else if (module.subtype === "physical") {
          physicalSources.push(module.label);
        }
        // if (!sources.includes(module.label)) {
        //   sources.push(module.label);
        // }
      } else if (module.type === "models") {
        if (!textModels.includes(module.label) && !mediaModels.includes(module.label)) {
          if (module.subtype === "text") {
            textModels.push(module.label);
          } else if (module.subtype === "media") {
            mediaModels.push(module.label);
          }
        }
      } else if (module.type === "output") {
        if (!outputs.includes(module.label)) {
          outputs.push(module.label);
        }
      }
    });
    setOnlineSourceOptions(onlineSources);
    setPhysicalSourceOptions(physicalSources);
    setTextModelsOptions(textModels);
    setMediaModelsOptions(mediaModels);
    setOutputOptions(outputs);
  }, [availableModules]);

  const handleCaseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCaseName(e.target.value);
  };

  const onNodeClick = (nodeId: string) => {
    setRemoveNode(nodeId);
  };

  const nodeTypes = useMemo(
    () => ({
      selectorNode: (props: any) => (
        <CustomNode onNodeClick={onNodeClick} onlineSource={onlineSourceOptions} physicalSource={physicalSourceOptions} {...props} />
      ),
    }),
    [availableModules]
  );

  const onNodesChange = useCallback((changes: NodeChange[]) => setNodes((nds) => applyNodeChanges(changes, nds) as Node<any>[]), []);
  const onEdgesChange = useCallback((changes: EdgeChange[]) => setEdges((eds) => applyEdgeChanges(changes, eds)), []);

  const calcXDistance = () => {
    let maxHeight = 5;

    let totalChars = nodes.reduce((total: number, node: any) => {
      const pageSize = window.innerWidth;
      if (pageSize - 400 < node?.position?.x) {
        maxHeight = maxHeight + 60;
        return 0;
      } else {
        return total + node?.width + 60;
      }
    }, 0);
    let totalXDist = totalChars + nodes.length;
    return { x: totalXDist, y: maxHeight };
  };

  const addNodeHandler = (option: string) => {
    let presentNode = nodes.find((node) => node?.id === option);
    if (!presentNode) {
      var node = availableModules.find((module) => module?.label === option);
      var new_node = {
        id: option,
        type: "selectorNode",
        position: calcXDistance(),
        data: { label: node?.label, inputs: node?.inputs, outputs: node?.outputs },
        sourcePosition: Position.Left,
        targetPosition: Position.Right,
        inputs: node?.inputs,
        outputs: node?.outputs,
      };
      setNodes((nodes: any) => [...nodes, new_node]);
    }
  };

  const onEdgeUpdate = useCallback((oldEdge: any, newConnection: any) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdateEnd = useCallback((_: any, edge: any) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e?.id !== edge?.id));
    }
    edgeUpdateSuccessful.current = true;
  }, []);

  const onConnect = useCallback(
    (params: any) => {
      if (params?.source !== params?.target) {
        setEdges((edges) => addEdge(params, edges));
      }
    },
    [setEdges]
  );

  const savePipelineHandler = () => {
    if (caseName === undefined || caseName === "") {
      toast.warning("Please fill the pipeline name");
    } else {
      let pipeline = {
        name: caseName,
        pipeline_config: {
          nodes: nodes,
          edges: edges,
        },
      };
      dispatch(savePipeline(pipeline))
        .then((res: any) => {
          if (res?.payload !== undefined) {
            if (res?.payload?.status === 201 || res?.payload?.status === 200) {
              toast.success("Pipeline saved successfully!");
              clearPipeline();
              handleRouteClick(routes.specificCustomCase.path.replace(":id", res?.payload?.data?.id), { name: caseName });
            }
            if (res?.payload?.status !== 201 && res?.payload?.status !== 200) {
              toast.error(res?.payload?.error?.data?.detail);
            }
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const updatePipelineHandler = () => {
    if (caseName === undefined || caseName === "") {
      toast.warning("Please fill the pipeline name");
    } else if (params?.pipelineId) {
      let pipeline = {
        data: {
          name: caseName,
          pipeline_config: {
            nodes: nodes,
            edges: edges,
          },
        },
        pipelineId: params?.pipelineId,
      };
      dispatch(updatePipeline(pipeline))
        .then((res: any) => {
          if (res?.payload !== undefined) {
            if (res?.payload?.status === 201 || res?.payload?.status === 200) {
              toast.success("Pipeline updated successfully!");
              clearPipeline();
              handleRouteClick(routes.specificCustomCase.path.replace(":id", res?.payload?.data?.id), { name: caseName });
            }
            if (res?.payload?.status !== 201 && res?.payload?.status !== 200) {
              toast.error(res?.payload?.error?.data?.detail);
            }
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const handleBack = () => {
    handleRouteClick(state?.backPath);
    clearPipeline();
  };

  const clearPipeline = () => {
    setNodes([]);
    setEdges([]);
    setCaseName("");
    localStorage.removeItem("pipelineData");
  };

  useEffect(() => {
    if (nodes.length !== 0) {
      localStorage.setItem("pipelineData", JSON.stringify({ casename: caseName, nodes: nodes, edges: edges }));
    }
  }, [nodes, edges, caseName]);

  useEffect(() => {
    if (isEdit) {
      dispatch(getPipelineDetail(params?.pipelineId)).then((res) => {
        const pipelineData = res.payload.data
        setNodes(pipelineData?.pipeline_config?.nodes || []);
        setEdges(pipelineData?.pipeline_config?.edges || []);
        setCaseName(pipelineData?.name || "");
      });
    } else {
      const storedData = localStorage.getItem("pipelineData");
      const parsedData = storedData && JSON.parse(storedData);
      setNodes(parsedData?.nodes || []);
      setEdges(parsedData?.edges || []);
      setCaseName(state?.name || "");
    }
  }, []);

  useEffect(() => {
    if (removeNode !== "") {
      const filterData = nodes.filter((node) => node?.data?.label !== removeNode);
      const filterEdges = edges.filter((edge) => edge?.target !== removeNode && edge?.source !== removeNode);
      setNodes(filterData);
      setEdges(filterEdges);
      setRemoveNode("");
    }
  }, [removeNode]);

  const selectedNode = nodes?.map((node) => node?.data?.label);

  const handleNavigation = (id: string, name: string) => {
    handleRouteClick(routes.specificCustomCase.path.replace(":id", id), { name: name });
  };

  return (
    <Box sx={{ display: "flex", width: "100%", height: "100%", flexDirection: "column" }}>
      <Box justifyContent="space-between" py={2} px={1}>
        <Box>
          <Box gap={3} display="flex" justifyContent="space-between">
            <Box display="flex" gap={2}>
              <Typography variant="h1" color={theme.palette.text.titleLabel}>
                Custom Pipelines:
              </Typography>
              <CustomInputField
                placeholder="Pipeline Name"
                size="small"
                variant="outlined"
                autoComplete="off"
                defaultValue={caseName}
                value={caseName}
                onChange={handleCaseChange}
                sx={{
                  "&.MuiOutlinedInput-input": {
                    ...theme.typography.body4,
                    color: theme.palette.primary.inactiveIcon,
                  },
                  "&.MuiFormControl-root.MuiTextField-root .MuiInputBase-root": {
                    height: "36px",
                  },
                  "&.MuiTextField-root": {
                    mt: 0,
                  },
                }}
              />
            </Box>
            <Box display="flex" flexWrap="wrap" gap={2}>
              {isEdit && (
                <CustomSecondaryButton
                  onClick={() => params.pipelineId && handleNavigation(params.pipelineId, caseName)}
                  endIcon={
                    <SearchIcon
                      sx={{
                        ml: 4,
                        color: theme.palette.text.default,
                        "&:active": {
                          color: theme.palette.text.active,
                        },
                      }}
                    />
                  }
                >
                  Query
                </CustomSecondaryButton>
              )}
              <PrimaryLoadingButton
                isLoading={false}
                sx={{
                  ...theme.typography.bigButton,
                  // height: "36px",
                  px: "1em",
                  ml: "auto",
                }}
                onClick={isEdit ? updatePipelineHandler : savePipelineHandler}
              >
                {isEdit ? "Update Pipeline" : "Save Pipeline"}
              </PrimaryLoadingButton>
            </Box>
          </Box>

          <BackButton onClick={handleBack} sx={{ mt: "-6px" }} />
        </Box>
      </Box>
      <Box sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
        <Box sx={{ width: "100%", height: "100%", overflow: "scroll" }}>
          <Box px={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h2" color={theme.palette.text.titleLabel} mb={2}>
                Pipeline Creation
              </Typography>
              <Box display="flex">
                <Typography variant="subtitle2" color={theme.palette.text.titleLabel} mb={2}>
                  Create pipeline by linking data source and AI Model.
                </Typography>
                <CustomTextButton
                  onClick={() => {}}
                  sx={{
                    ...theme.typography.bigButton,
                    lineHeight: "100%",
                    cursor: "pointer",
                    marginTop: "5px",
                    pl: 1,
                    mt: -2,
                  }}
                >
                  View an example
                </CustomTextButton>
              </Box>
            </Box>

            <Box
              sx={{
                ...theme.typography.semiBold,
                display: "flex",
                borderRadius: "12px 12px 0 0",
                background: theme.palette.background.dark,
                color: theme.palette.text.titleLabel,
                mb: 2,
                p: 1.5,
              }}
            >
              Canvas
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: 10,
                p: 2,
                mt: -1,
                overflowX: "scroll",
                border: `2px solid ${theme.palette.additionalColors.border}`,
                borderRadius: 5,
                height: "50vh",
                background: theme.palette.background.dark,
              }}
            >
              {nodes?.length > 0 && (
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                  nodeTypes={nodeTypes}
                  onEdgeUpdateStart={onEdgeUpdateStart}
                  onEdgeUpdateEnd={onEdgeUpdateEnd}
                  onEdgeUpdate={onEdgeUpdate}
                  snapToGrid={true}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 2,
                    padding: 2,
                    marginTop: 2,
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                  panOnScroll
                >
                  <Controls showInteractive={false} showZoom={true} showFitView={false} />
                </ReactFlow>
              )}
              {nodes?.length === 0 && (
                <Box sx={{ ...theme.typography.body3, margin: "auto", color: theme.palette.text.captionColor }}>
                  Select modules to customize pipeline
                </Box>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              ...theme.typography.semiBold,
              display: "flex",
              borderRadius: "12px 12px 0 0",
              background: theme.palette.background.dark,
              color: theme.palette.text.titleLabel,
              my: 2,
              p: 1.5,
            }}
          >
            Available Modules
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={3} lg={2.5} display="flex" sx={{ [theme.breakpoints.down("lg")]: { mt: 5 } }}>
              <ModuleCard
                title="Online Sources"
                icon={<DiamondOutlinedIcon sx={{ mt: "-3px", color: theme.palette.text.titleLabel }} />}
                options={onlineSourceOptions}
                addNode={addNodeHandler}
                selectedNode={selectedNode}
              />
            </Grid>
            <Grid item xs={6} sm={3} lg={2.5} display="flex" sx={{ [theme.breakpoints.down("lg")]: { mt: 5 } }}>
              <ModuleCard
                title="Physical Sources"
                icon={<DiamondOutlinedIcon sx={{ mt: "-3px", color: theme.palette.text.titleLabel }} />}
                options={physicalSourceOptions}
                addNode={addNodeHandler}
                selectedNode={selectedNode}
              />
            </Grid>
            <Grid item xs={6} sm={3} lg={2.5} display="flex" sx={{ [theme.breakpoints.down("lg")]: { mt: 5 } }}>
              <ModuleCard
                title="Models (Text)"
                icon={<DiamondOutlinedIcon sx={{ mt: "-3px", color: theme.palette.text.titleLabel }} />}
                options={textModelsOptions}
                addNode={addNodeHandler}
                selectedNode={selectedNode}
              />
            </Grid>
            <Grid item xs={6} sm={3} lg={2.5} display="flex" sx={{ [theme.breakpoints.down("lg")]: { mt: 5 } }}>
              <ModuleCard
                title="Models (Media)"
                icon={<DiamondOutlinedIcon sx={{ mt: "-3px", color: theme.palette.text.titleLabel }} />}
                options={mediaModelsOptions}
                addNode={addNodeHandler}
                selectedNode={selectedNode}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={2} display="flex" sx={{ [theme.breakpoints.down("lg")]: { mt: 5 } }}>
              <ModuleCard
                title="Output"
                icon={<DiamondOutlinedIcon sx={{ mt: "-3px", color: theme.palette.text.titleLabel }} />}
                options={outputOptions}
                addNode={addNodeHandler}
                selectedNode={selectedNode}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
