import { Box, Typography, useTheme } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { FiDownload } from "react-icons/fi";
import BorderedCard from "../../../components/BorderedCard";
import CustomDataTable from "../../../components/CustomDataTable";
import CustomDivider from "../../../components/CustomDivider";
import CustomIconButton from "../../../components/CustomIconButton";
import { formatDateTimeIntoCurrentTimeZone } from "../../../utils/helper";

export default function Invoices() {
  const theme = useTheme();

  const columns: GridColDef[] = [
    { field: "id", headerName: "", width: 90, sortable: false },
    {
      field: "date",
      headerName: "Date",
      sortable: false,
      width: 160,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => formatDateTimeIntoCurrentTimeZone(new Date()),
    },
    {
      field: "product",
      headerName: "Product",
      width: 150,
      editable: false,
      sortable: false,
      flex: 2,
    },
    {
      field: "price",
      headerName: "Price",
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: "invoice",
      headerName: "Invoice",
      sortable: false,
      width: 160,
      flex: 1,
    },
    {
      field: "downloadAndSave",
      headerName: "",
      width: 120,
      sortable: false,
      renderCell: (params) => {
        let saved: boolean = false;
        const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
          saved = !saved;
          console.log(saved);
        };

        return (
          <CustomIconButton sx={{ color: theme.palette.text.default }}>
            <FiDownload />
          </CustomIconButton>
        );
      },
    },
  ];

  const rows = [
    { id: 1, product: "Social Media Solution", price: "$ 100.67", status: "Payment successful", invoice: "Invoice" },
    { id: 2, product: "Social Media Solution", price: "$ 100.67", status: "Payment successful", invoice: "Invoice" },
    { id: 3, product: "Social Media Solution", price: "$ 100.67", status: "Payment successful", invoice: "Invoice" },
    { id: 4, product: "Social Media Solution", price: "$ 100.67", status: "Payment successful", invoice: "Invoice" },
    { id: 5, product: "Social Media Solution", price: "$ 100.67", status: "Payment successful", invoice: "Invoice" },
    { id: 6, product: "Social Media Solution", price: "$ 100.67", status: "Payment successful", invoice: "Invoice" },
    { id: 7, product: "Social Media Solution", price: "$ 100.67", status: "Payment successful", invoice: "Invoice" },
    { id: 8, product: "Social Media Solution", price: "$ 100.67", status: "Payment successful", invoice: "Invoice" },
    { id: 9, product: "Social Media Solution", price: "$ 100.67", status: "Payment successful", invoice: "Invoice" },
    { id: 10, product: "Social Media Solution", price: "$ 100.67", status: "Payment successful", invoice: "Invoice" },
    { id: 11, product: "Social Media Solution", price: "$ 100.67", status: "Payment successful", invoice: "Invoice" },
    { id: 12, product: "Social Media Solution", price: "$ 100.67", status: "Payment successful", invoice: "Invoice" },
    { id: 13, product: "Social Media Solution", price: "$ 100.67", status: "Payment successful", invoice: "Invoice" },
    { id: 14, product: "Social Media Solution", price: "$ 100.67", status: "Payment successful", invoice: "Invoice" },
    { id: 15, product: "Social Media Solution", price: "$ 100.67", status: "Payment successful", invoice: "Invoice" },
  ];

  return (
    <BorderedCard>
      <Typography variant="h4" sx={{ color: theme.palette.text.titleLabel }}>
        Invoices
      </Typography>
      <CustomDivider />
      <>
        <Box sx={{}}>
          <CustomDataTable
            rows={rows}
            columns={columns}
            columnVisibilityModel={{
              id: false,
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            checkboxSelection={false}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            disableVirtualization
            disableRowSelectionOnClick
            isCellEditable={() => false}
            rowSelection={false}
            disableEval
            pagination
            sx={{
              "& .MuiDataGrid-main": {
                p: 0,
                mb: 1,
                mt: 1,
                border: `none`,
                background: theme.palette.background.paper,
                backgroundImage: "none",
              },
            }}
          />
        </Box>
      </>
    </BorderedCard>
  );
}
