import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import CustomStatus from "../../../../../../components/CustomStatus";
import { routes } from "../../../../../../routes";
import { useHandleRouteClick } from "../../../../../../routes/hooks";
import { useAppSelector } from "../../../../../../store/hooks";
import HashtagCard from "../../../Overview/RiskSummary/component/HasgtagCard";
import RedFlaggedCard from "../../../Overview/RiskSummary/component/RedFlaggedCard";
import RiskFactorCard from "../../../Overview/RiskSummary/component/RiskFactorCard";
import SentimentAnalysisCard from "../../../Overview/RiskSummary/component/SentimentAnalysisCard";
import Relevance from "../../../Relevance";

const riskFactorData = [
  {
    title: "Behavioral Risk Factor",
    status: "high",
    subData: [
      {
        label: "Unusual online activities",
        count: 14,
      },
      {
        label: "Unusual online activities",
        count: 24,
      },
      {
        label: "Unusual online activities",
        count: 0,
      },
      {
        label: "Unusual online activities",
        count: 56,
      },
    ],
  },
  {
    title: "Content Risk Factor",
    status: "moderate",
    subData: [
      {
        label: "Unusual online activities",
      },
      {
        label: "Unusual online activities",
        count: 24,
      },
      {
        label: "Unusual online activities",
        count: 0,
      },
      {
        label: "Unusual online activities",
        count: 56,
      },
    ],
  },
  {
    title: "Communication Risk Factor",
    status: "high",
    subData: [
      {
        label: "Unusual online activities",
        count: 14,
      },
      {
        label: "Unusual online activities",
        count: 24,
      },
      {
        label: "Unusual online activities",
        count: 0,
      },
      {
        label: "Unusual online activities",
        count: 56,
      },
    ],
  },
  {
    title: "Financial Risk Factor",
    status: "high",
    subData: [
      {
        label: "Unusual online activities",
        count: 14,
      },
      {
        label: "Unusual online activities",
        count: 24,
      },
      {
        label: "Unusual online activities",
        count: 0,
      },
      {
        label: "Unusual online activities",
        count: 56,
      },
    ],
  },
  {
    title: "Network Risk Factor",
    status: "high",
    subData: [
      {
        label: "Unusual online activities",
        count: 14,
      },
      {
        label: "Unusual online activities",
        count: 24,
      },
      {
        label: "Unusual online activities",
        count: 0,
      },
      {
        label: "Unusual online activities",
        count: 56,
      },
    ],
  },
  {
    title: "Privacy Risk Factor",
    status: "low",
    subData: [
      {
        label: "Unusual online activities",
        count: 14,
      },
      {
        label: "Unusual online activities",
        count: 24,
      },
      {
        label: "Unusual online activities",
        count: 0,
      },
      {
        label: "Unusual online activities",
        count: 56,
      },
    ],
  },
];

const sentimentData = [
  { key: "positive", value: 77 },
  { key: "neutral", value: 5 },
  { key: "negative", value: 18 },
];

// const hashtagData = [
//   { key: "#Tag1", value: 590 },
//   { key: "#Tag2", value: 467 },
//   { key: "#Tag3", value: 239 },
//   { key: "#Tag4", value: 127 },
//   { key: "#Tag5", value: 89 },
//   { key: "#Tag6", value: 89 },
//   { key: "#RussiaisaTerroristState", value: 590 },
//   { key: "#NATO", value: 4377 },
//   { key: "#SaveUkraine", value: 239 },
//   { key: "#WarInUkraine", value: 788 },
//   { key: "#SaveAnimals", value: 5990 },
//   { key: "#Terrorism", value: 590 },
//   { key: "#Weapon", value: 4377 },
//   { key: "#Kill", value: 239 },
//   { key: "#RussiaisaTerroristState", value: 590 },
//   { key: "#NATO", value: 4377 },
//   { key: "#SaveUkraine", value: 239 },
//   { key: "#WarInUkraine", value: 788 },
//   { key: "#SaveAnimals", value: 5990 },
//   { key: "#Terrorism", value: 590 },
//   { key: "#Weapon", value: 4377 },
//   { key: "#Kill", value: 239 },
//   { key: "#SaveUkraine", value: 239 },
//   { key: "#WarInUkraine", value: 788 },
//   { key: "#SaveAnimals", value: 5990 },
//   { key: "#Terrorism", value: 590 },
//   { key: "#Weapon", value: 4377 },
//   { key: "#Kill", value: 239 },
// ];

const redFlaggedData = [
  { key: "Terrorism", value: 590 },
  { key: "Weapon", value: 467 },
  { key: "Kill", value: 239 },
  { key: "Violence", value: 127 },
  { key: "Hate", value: 89 },
  { key: "Hate1", value: 89 },
];

interface OverviewProps {
  isDialog?: boolean;
}

export default function Overview({ isDialog = false }: OverviewProps) {
  const theme = useTheme();
  const params = useParams();
  const handleRouteClick = useHandleRouteClick();
  
  const { publicationData, analyticsData } = useAppSelector((state) => state.analysis);
  let statusStyle: React.CSSProperties = {};
  const status = params?.score?.toLowerCase();
  switch (status) {
    case "high":
      statusStyle = {
        color: theme.palette.text.highStatus,
      };
      break;
    case "neutral":
      statusStyle = {
        color: theme.palette.text.neutralStatus,
      };
      break;
    case "moderate":
      statusStyle = {
        color: theme.palette.text.moderateStatus,
      };
      break;
    case "extreme":
      statusStyle = {
        color: theme.palette.text.extremeStatus,
      };
      break;
    case "low":
      statusStyle = {
        color: theme.palette.text.lowStatus,
      };
      break;
    case "default":
      break;
  }

  const relevanceData = [
    {
      title: "Relevance",
      score: 97,
      data: [],
    },
    {
      title: "Authenticity",
      score: 97,
      data: ["No fake detection", "No bot activity"],
    },
    {
      title: "Reputation score",
      score: 69,
      data: ["Reputation isn`t good"],
    },
    {
      title: "Reliability",
      score: 19,
      data: ["Fact-checking is bad", "Not reliable references"],
    },
  ];

  const backHandler = () => {
    handleRouteClick(routes.home.path);
  };

  return (
    <Grid container sx={{ position: "relative" }}>
      <Typography variant="h3" sx={{ fontSize: "22px", color: theme.palette.text.titleLabel }} pt={3}>
        Overview
      </Typography>
      <Grid item xs={12}>
        <Relevance relevanceData={relevanceData} />
        <Box mt={4}>
          <Box display="flex" gap={3}>
            <Typography variant="h2" color={theme.palette.text.titleLabel}>
              Risk Score
            </Typography>
            <CustomStatus status="high" label="High" sx={{ width: "88px", height: "24px" }} />
          </Box>
          <Box mt={-1}>
            <Box mt={2}>
              <Grid container mt={2} spacing={2}>
                {riskFactorData.map((riskData, idx) => {
                  return (
                    <Grid item xs={12} sm={6} lg={4} key={`statics-${idx}`} display="flex" justifyContent="space-between" gap={1} width="100%">
                      <RiskFactorCard riskFactorData={riskData} checkBox={true} />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box my={4}>
          <Typography variant="h2" color={theme.palette.text.titleLabel}>
            Publication Summary
          </Typography>
          <Grid mt={1} container spacing={2}>
            <Grid item xs={12} sm={6} md={isDialog ? 6 : 4} lg={4} display="flex">
              <SentimentAnalysisCard sentimentData={analyticsData?.sentiment_summary} />
            </Grid>
            <Grid item xs={12} sm={6} md={isDialog ? 6 : 4} lg={4} display="flex">
              <HashtagCard hashtagData={publicationData?.data_result?.hashtags} />
            </Grid>
            <Grid item xs={12} sm={12} md={isDialog ? 12 : 4} lg={4} display="flex">
              <RedFlaggedCard redFlaggedData={redFlaggedData} />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
