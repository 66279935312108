import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { BsDot, BsThreeDots } from "react-icons/bs";
import { toast } from "react-toastify";
import CustomIconButton from "../../../components/CustomIconButton";
import CustomPopover from "../../../components/CustomPopover";
import CustomTextButton from "../../../components/CustomTextButton";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { deleteWorkspace } from "../../../store/workspace/workspaceThunk";
import WorkspaceDeleteModal from "./WorkspaceDeleteModal";

export interface WorkspaceProps {
  workspaceId: string | number;
  name: string;
  members: number;
  planName: string;
  image?: string;
}


export function WorkspaceCard({ data, index }: Record<string, any>) {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();
  const [openDeleteWorkspaceModal, setOpenDeleteWorkspaceModal] = React.useState(false);
  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);

  const handleOpenMoreOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreOptionsAnchorEl(event.currentTarget);
  };

  const handleCloseMoreOptions = () => {
    setMoreOptionsAnchorEl(null);
  };

  const handleRedirectToDetail = () => {
    handleRouteClick(routes.workspaceDetail.path.replace(":workspaceId", String(data.id)));
  };

  const handleDeleteWorkspace = () => {
    dispatch(deleteWorkspace(data.id)).then((res:Record<string,any>) => {
      if (res?.payload?.response?.status === 204) {
        toast.success("Workspace Deleted Successfully");
        handleRouteClick(`${routes.settings.path}?tab=settings&subtab=workspace`);
      }
    });
    toggleDeleteWorkspace();
  };

  const toggleDeleteWorkspace = () => {
    handleCloseMoreOptions();
    setOpenDeleteWorkspaceModal((prev) => !prev);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: 2,
        alignItems: "center",
        borderRadius: 1,
        p: 2,
        background: theme.palette.background.gradientLight1,
        boxShadow: theme.palette.dashboardContainer.workspaceCardBoxShadow,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleRedirectToDetail}
        >
          <img src={data.image || "/assets/workspace-icon.png"} alt="Workspace" style={{ height: "auto", maxHeight: "48px", maxWidth: "48px" }} />
        </Box>
        <Box>
          <Box onClick={handleRedirectToDetail} sx={{ cursor: "pointer" }}>
            <Typography variant="h4" color={theme.palette.text.titleLabel}>
              {data.name}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption" color={theme.palette.primary.inactiveIcon}>
              {data.user_count} members <BsDot /> Plan: {data.planName}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        {!openDeleteWorkspaceModal && currentUser?.is_admin && !(index === 0) ? (
          <>
            <CustomIconButton onClick={handleOpenMoreOptions} sx={{ color: theme.palette.text.default }}>
              <BsThreeDots />
            </CustomIconButton>
            <CustomPopover open={Boolean(moreOptionsAnchorEl)} anchorEl={moreOptionsAnchorEl} onClose={handleCloseMoreOptions}>
              <CustomTextButton onClick={toggleDeleteWorkspace} startIcon={<CloseIcon color="error" />}>
                Delete
              </CustomTextButton>
            </CustomPopover>
          </>
        ) : (
          <WorkspaceDeleteModal open={openDeleteWorkspaceModal} handleToggle={toggleDeleteWorkspace} handleDelete={handleDeleteWorkspace} />
        )}
      </Box>
    </Box>
  );
}
