import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, Grid, Typography, useTheme } from "@mui/material";
import CustomDivider from "../../../../../../components/CustomDivider";
import CustomIconButton from "../../../../../../components/CustomIconButton";
import Classes from "../../../../../../components/Scrollbar.module.css";

// interface HashtagOptions {
//   key: string;
//   value: number;
// }

interface DialogProps {
  open: boolean;
  handleClose: () => void;
  hashtagData: string[];
}

const HashtagDialog = ({ open, handleClose, hashtagData }: DialogProps) => {
  const theme = useTheme();

  const renderColumn = (data: any) => (
    <Box display="flex" gap={10} mt={3} flexDirection="row">
      <Typography variant="h6" sx={{ textTransform: "capitalize", fontSize: "14px", color: theme.palette.text.default }}>
        {data}
      </Typography>
      {/* <Typography variant="h6" sx={{ textTransform: "capitalize", fontSize: "14px", color: theme.palette.text.titleLabel }} ml="auto">
        {data?.value}
      </Typography> */}
    </Box>
  );

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          background: (theme) => theme.palette.background.default,
          borderRadius: "10px",
          width: "100%",
          textAlign: "center",
          minHeight: "50vh",
        },
      }}
    >
      <DialogContent sx={{ p: 4 }}>
        <Grid container>
          <Box display={"flex"} width="100%" mb={3}>
            <Typography variant="h2" color={theme.palette.text.titleLabel} sx={{ m: "auto", mr: 0 }}>
              Hashtags
            </Typography>
            <CustomIconButton variant="contained" handleClick={() => {}} sx={{ ml: "auto" }}>
              <CloseIcon onClick={handleClose} sx={{ color: theme.palette.error.main }} />
            </CustomIconButton>
          </Box>
          <CustomDivider width="100%" />
          <CustomDivider
            flexItem
            orientation="vertical"
            sx={{
              margin: "10px auto 0",
              height: "75%",
              position: "absolute",
              top: "18%",
              left: "50%",
              [theme.breakpoints.down("sm")]: {
                display: "none",
              },
            }}
          />
          <Box
            className={Classes.scroll_dark}
            mt={1}
            display="flex"
            width="100%"
            sx={{
              height: "40vh",
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
              },
            }}
          >
            <Box width="100%" pr={{ sm: 3 }}>
              {hashtagData?.map((list: any, index) => {
                return (
                  index % 2 === 0 && (
                    <Grid item xs={12} key={`hashtag-col1-${index}`}>
                      {renderColumn(list)}
                    </Grid>
                  )
                );
              })}
            </Box>

            <Box width="100%" pl={{ sm: 3 }} pr={3}>
              {hashtagData.map((list: any, index) => {
                return (
                  index % 2 !== 0 && (
                    <Grid item xs={12} key={`hashtag-col2-${index}`}>
                      {renderColumn(list)}
                    </Grid>
                  )
                );
              })}
            </Box>
          </Box>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default HashtagDialog;
