import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { HiLocationMarker } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { routes } from "../../routes";
import { useHandleRouteClick } from "../../routes/hooks";
import MillitaryPresenceMap from "./Components/Feed/components/MillitaryPresenceMap";

export default function MillitaryPresence() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();
  const [searchParams] = useSearchParams();
  const latitude = searchParams.get("lat");
  const longitude = searchParams.get("lng");
  const label = searchParams.get("label");

  const handleBack = () => {
    handleRouteClick(`${routes.home.path}?tab=analysis&subtab=feed`);
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center" py={2} px={1}>
        <Stack direction="column" alignItems="flex-start">
          <Stack direction="row" alignItems="flex-start" gap={3}>
            <Typography variant="h1" color={theme.palette.text.titleLabel}>
              Millitary Presence: <HiLocationMarker size={23} />
              {label}
            </Typography>
          </Stack>

          <BackButton onClick={handleBack} sx={{ mt: "-3px" }} />
        </Stack>
      </Stack>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderTop: "1px solid",
          borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
          background: theme.palette.dashboardContainer.containerBackground,
          height: "100%",
          py: 3,
          px: { xs: 3, lg: 10, xl: 15 },
          mt: -1,
          overflow: "auto",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                boxShadow: theme.palette.dashboardContainer.insetBoxShadow,
                background: theme.palette.background.dark,
                borderRadius: 3,
                height: "100%",
                p: 2,
                mt: -1,
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                  background: `linear-gradient(to right, ${theme.palette.text.default} 50%, transparent 0%) top repeat-x, linear-gradient(${theme.palette.text.default} 50%, transparent 0%) right repeat-y, linear-gradient(to right, ${theme.palette.text.default} 50%, transparent 0%) bottom repeat-x, linear-gradient(${theme.palette.text.default} 50%, transparent 0%) left repeat-y`,
                  backgroundSize: "20px 1px, 1px 20px",
                  gap: 2,
                  p: { xs: 10, lg: 5 },
                }}
              >
                {latitude && longitude ? (
                  <MillitaryPresenceMap
                    mapConfig={{
                      center: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
                      zoom: 13,
                    }}
                  />
                ) : (
                  <Typography>Latitude or Longitude is missing</Typography>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
