import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { Avatar, Box, Button, Typography, useTheme } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Mention, MentionsInput, OnChangeHandlerFunc } from "react-mentions";
import CustomDivider from "../../../../components/CustomDivider";
import { conversationalActions } from "../../../../store/conversational/chatSlice";
import { sendChatMessage } from "../../../../store/conversational/chatThunk";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import ChatBox from "../../../support/component/Chatbot/component/ChatBox";
import classNames from "../../../../styles/Mention.module.css"

interface User {
  id: string;
  display: string;
}


export default function ChatBotChatSection({ entities, queryId }: {entities: any[], queryId: string}) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const chatMessagesRef = React.useRef(null);
  const { messages, isLoading } = useAppSelector((state) => state.conversational);
  const [conversationInitiated, setConversationInitiated] = React.useState(false);
  const [extractedEntities, setExtractedEntities] = useState<any[]>([]);
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    const entitiesArray = entities.map((entity) => {
      return {
        id: entity?._id,
        display: entity?.key,
      };
    });
    setExtractedEntities(entitiesArray);
  }, [entities]);

  const mentionsInputStyle = {};
  const handleChange: OnChangeHandlerFunc = (event, newValue) => {
    setMessage(newValue);
  };
  const handleSendMessage = (messageText: string) => {
    dispatch(conversationalActions.pushMessages({ role: "user", content: {message:messageText} }));
    dispatch(sendChatMessage({ query_id: queryId, prompt: messageText })).then(() => setConversationInitiated(true));
    setMessage("");
  };

  React.useEffect(() => {
    // Scroll to the bottom of the chat messages container when it's filled
    if (chatMessagesRef.current) {
      const scrollContainer = chatMessagesRef.current as HTMLDivElement;
      scrollContainer.scrollTo(0, scrollContainer.scrollHeight);
    }
  }, [messages]); // Run the effect whenever the messages array changes

  const renderMessages = () => {
    return messages.map((m, index) => {
      switch (m.role) {
        case "user":
          return <ChatBox showUserIcons={true} type="outgoing" message={m.content} customSentBoxIcon={<Avatar />} />;
        case "assistant":
          return (
            <ChatBox
              showUserIcons={true}
              type="incoming"
              message={m.content}
              typing={conversationInitiated && index === messages.length - 1}
              customRecieveBoxIcon={<Avatar src="/skylarklabs_logo.png" sx={{ p: 1 }} />}
            />
          );
        default:
          return null;
      }
    });
  };

  return (
    <Box
      sx={{
        borderTop: "1px solid",
        pt: 2,
        mt: 2,
        height:"calc(100% - 100px)",
        // height:"100%",
        borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
        display: "flex",
        flexDirection: "column",
        // overflow: "hidden",
      }}
    >
      <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <Typography variant="body3" color={theme.palette.primary.baseDisabled} textAlign="center">
          Your chat history will be displayed here until chat is finished
        </Typography>

        <Box
          sx={{
            width: "100%",
            height: "100%",
            overflow: "scroll",
            flex: 1,
            mt: 2,
          }}
          ref={chatMessagesRef}
        >
          {renderMessages()}
          {isLoading && <ChatBox type="incoming" loader={<LinearProgress />} />}
        </Box>

        <Box
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CustomDivider width="100%" />
          <Box display="flex" mt={2} alignItems="center">
            <Formik
              initialValues={{
                replyText: message,
              }}
              onSubmit={(values) => {
                const commentData = {
                  replyText: values.replyText,
                };
                handleSendMessage(message);
              }}
            >
              {(props) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.handleSubmit();
                  }}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* <FormikInputField
                    name="replyText"
                    placeholder="Type a Message.."
                    // size="small"
                    variant="standard"
                    value={props.values.replyText}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    autoComplete="off"
                    sx={{
                      width: "100%",
                      "& .MuiInput-root": {
                        ...theme.typography.body2,
                        color: theme.palette.primary.inactiveIcon,
                      },
                      "&.MuiFormControl-root.MuiTextField-root .MuiInputBase-root": { background: "transparent", boxShadow: "none" },
                      "& .MuiInput-root:before": {
                        borderBottom: "none",
                      },
                      "& .MuiInput-root:after": {
                        borderBottom: "none",
                      },
                      "& .MuiInputBase-root": {
                        ":hover": {
                          ":before": {
                            borderBottom: "none",
                          },
                        },
                      },
                    }}
                    inputProps={{
                      style: {
                        // height: "15px",
                        borderRadius: "6px 6px 6px 6px",
                        background: "transparent",
                        color: theme.palette.text.titleLabel,
                      },
                    }}
                    error={props.touched.replyText && Boolean(props.errors.replyText)}
                    helperText={props.touched.replyText && props.errors.replyText ? props.errors.replyText : null}
                  /> */}

                  <MentionsInput
                    style={{
                      width: "100%",
                      minHeight: "40px",
                      borderRadius: "10px",
                    }}
                    className="mentions"
                    classNames={classNames}
                    // style={mentionsInputStyle}
                    name="replyText"
                    placeholder="Type a Message.."
                    value={message}
                    onChange={handleChange}
                    onKeyDown={(e)=>{
                      if (e.key === "Enter") {
                        e.preventDefault();
                        props.handleSubmit();
                      }
                    }}
                  >
                    <Mention
                      renderSuggestion={(suggestion, search, highlightedDisplay) => (
                        <Box m={2} color={theme.palette.text.default}>
                          {highlightedDisplay}
                        </Box>
                      )}
                      trigger="@"
                      data={extractedEntities}
                      // displayTransform={(id) => `@${id}`}
                      className={classNames.mentions__mention}
                    />
                  </MentionsInput>

                  <Box display="flex" gap={1.5} ml="auto">
                    {/* <AttachFile sx={{ color: theme.palette.text.default, fontSize: "0.8em", cursor: "pointer" }} /> */}
                    <CustomDivider orientation="vertical" sx={{ height: "1.2em" }} />
                    <Button type="submit" sx={{ minWidth: 0, p: 0 }}>
                      <SendRoundedIcon
                        sx={{ color: theme.palette.text.default, fontSize: "1.8em", cursor: "pointer", transform: "rotate(-45deg)" }}
                      />
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
