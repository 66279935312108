import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { routes } from "../../routes";
import { useHandleRouteClick } from "../../routes/hooks";

import SignUpBaseLayout from "../../layouts/SignUpBaseLayout";
import { forgotPasswordComplete } from "../../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import PasswordForm from "./PasswordForm";

export default function ResetPassword() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const handleRouteClick = useHandleRouteClick();
  const { isLoading, isPasswordResetTokenVerified } = useAppSelector((state) => state.auth);
  const [formData, setFormData] = React.useState({
    password1: "",
    password2: "",
  });

  const handleSubmit = () => {
    dispatch(
      forgotPasswordComplete({
        ...formData,
        uidb64: params.uidb64,
        token: params.token,
      })
    )
      .then((res) => {
        if (res.payload.status === 200) {
          toast.success("Successfully changed the password");
          handleRouteClick(routes.login.path);
        } else {
          toast.error("Oops! password reset link has expired. Please start the password reset process again");
        }
      })
      .catch((err) => {
        toast.error("Oops! Some issues while changing the password.");
        handleRouteClick(routes.forgotPassword.path);
      });
  };

  const handleInputChange = ({ fieldName, value }: { fieldName: string; value: any }) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <SignUpBaseLayout isLoading={isLoading}>
      <PasswordForm
        initialValues={formData}
        handleFormSubmit={handleSubmit}
        handleInputChange={handleInputChange}
        isLoading={isLoading}
        disabledSubmitButton={(formData?.password1 === "" && formData?.password2 === "") || formData?.password1 !== formData?.password2}
      />
    </SignUpBaseLayout>
  );
}
