import { Box, Typography, useTheme } from "@mui/material";
import { Navigate, NavLink } from "react-router-dom";
import PrimaryLoadingButton from "../../components/PrimaryLoadingButton";
import { routes } from "../../routes";
import { useHandleRouteClick } from "../../routes/hooks";
import { useAppSelector } from "../../store/hooks";
import { ThemeVariantsProps } from "../../theme";
import Classes from "./StartPage.module.css";

const StartPage = () => {
  const theme = useTheme();
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const { themeMode } = useAppSelector((state) => state.theme);
  const isDarkTheme = themeMode === ThemeVariantsProps.dark;
  const handleRouteClick = useHandleRouteClick();

  const signUpHandler = () => {
    handleRouteClick(routes.register.path);
  };

  if (isAuthenticated) {
    return <Navigate to={routes.home.path} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        overflowX: "hidden",
        flexDirection: "column",
        position: "absolute",
        top: 0,
        right: 0,
      }}
    >
      <img src="/skylarklabs_full_ripple.png" alt="logo" className={Classes.circle} />
      <img src={isDarkTheme ? "/skylarklabs_logo.png" : "/skylarklabs_logo_light.png"} alt="logo" className={Classes.brandlogo} />
      <Box
        sx={{
          zIndex: 1,
          width: "29rem",
          position: "absolute",
          top: "44%",
          left: "50%",
          transform: "translate(-50%, 0%)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" color={(theme) => theme.palette.text.titleLabel}>
          Low Code Platform
        </Typography>
        <Typography variant="h2" color={(theme) => theme.palette.additionalColors.light} sx={{ textTransform: "none" }}>
          by Skylark
        </Typography>
      </Box>
      <Typography
        variant="body6"
        align="center"
        mt={3}
        sx={{
          zIndex: 1,
          width: "29rem",
          position: "absolute",
          top: "53%",
          left: "50%",
          transform: "translate(-50%, 0%)",
        }}
      >
        The Provided system develops solutions that analyse feeds to predict the potential threats that others can’t see and prevent the incidents
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ width: "40%", position: "absolute", top: "67%", left: "50%", transform: "translate(-50%, 0%)" }}
      >
        <PrimaryLoadingButton sx={{ p: 0, marginTop: 5, width: { xs: "50%", sm: "87%", md: "66%", lg: "48%", xl: "42%" } }} onClick={signUpHandler}>
          Sign Up
        </PrimaryLoadingButton>
        <Typography variant="body6" color={(theme) => theme.palette.text.titleLabel} mt={3}>
          Already you have an account?
          <NavLink
            to="/login"
            style={{
              ...theme.typography.body6,
              color: theme.palette.primary.main,
              textDecoration: "none",
              marginLeft: "6px",
            }}
          >
            Sign In
          </NavLink>
        </Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "5%",
          left: "50%",
          transform: "translate(-50%, 0%)",
          width: "13rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <NavLink to="" style={{ textDecoration: "none", zIndex: 100 }}>
          <Typography
            variant="link5"
            sx={(theme) => ({
              borderBottom: `1px solid ${theme.palette.primary.main}`,
              textDecoration: "none",
              color: theme.palette.primary.main,
            })}
          >
            Terms of service
          </Typography>
        </NavLink>
        <NavLink to="" style={{ textDecoration: "none", zIndex: 100 }}>
          <Typography
            variant="link5"
            sx={(theme) => ({
              borderBottom: `1px solid ${theme.palette.primary.main}`,
              textDecoration: "none",
              color: theme.palette.primary.main,
            })}
          >
            Privacy Policy
          </Typography>
        </NavLink>
      </Box>
    </Box>
  );
};

export default StartPage;
