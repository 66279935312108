import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

interface ImageViewerProps {
  open: boolean;
  onClose: () => void;
  images: Array<{ src: string }>;
  index?: number;
}

export default function ImageViewer({ open, onClose, images, index = 0 }: ImageViewerProps) {
  return (
    <Lightbox
      index={index}
      open={open}
      close={onClose}
      slides={images}
      plugins={[Zoom]}
      zoom={{
        scrollToZoom: true,
        maxZoomPixelRatio: 500,
      }}
    />
  );
}
