import { Box, useTheme } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { ForceGraph2D } from "react-force-graph";
import * as THREE from "three";
import ZoomController from "../../../../../components/ZoomController";
import ConnectionsPopover from "./ConnectionsPopover/ConnectionsPopover";
import LinkPopoverContent from "./ConnectionsPopover/LinkPopoverContent";
import NodePopoverContent from "./ConnectionsPopover/NodePopoverContent";
import EntityDetailsDialog from "./EntityDetailsDialog";

interface NetworkGraphProps {
  items: boolean[];
  connectionsData: Record<string, any>[];
}

interface NodeProps {
  id: string;
  type: string; // e.g., "location", "person"
  // ... other relevant properties
  image?: string; // optional path to image resource
}

export interface NodeInfo {
  id: string;
  x: number;
  y: number;
  text: string;
  [x: string]: any;
}

const MAX_NODE_RADIUS = 35;
const MIN_NODE_RADIUS = 5;

const NetworkGraph = ({ connectionsData, items }: NetworkGraphProps) => {
  const theme = useTheme();
  const graphRef = useRef<any>();
  const items_list: string[] = ["location", "person", "topic", "event", "organization", "other"];
  const [zoom, setZoom] = useState<number>(3);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const [currentLink, setCurrentLink] = useState<Record<string, any> | null>(null);
  const [currentNode, setCurrentNode] = useState<NodeInfo | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [coords, setCoords] = useState<number[]>([0, 0]);
  console.log("connectionsData :", connectionsData);
  const handleClosePopover = () => {
    setOpenPopover(false);
  };
  const handleToggleDialog = () => setOpenDialog((prev) => !prev);

  const handleZoomOut = () => {
    //max zoom = 7
    if (zoom < 20) setZoom((prev) => prev + 1);
  };
  const handleZoomIn = () => {
    //min zoom = 3
    if (zoom > 1) setZoom((prev) => prev - 1);
  };

  useEffect(() => {
    graphRef?.current?.zoom(zoom);

    if (graphRef) {
      graphRef.current.d3Force("charge").distanceMax(100);
    }
  }, [zoom]);

  useEffect(() => {
    if (graphRef.current) {
      graphRef.current.d3Force("link").distance(70);
    }
  }, [currentLink]);

  const getImage = (nodeType: string): any => {
    // Implement logic to map node types to image paths
    switch (nodeType) {
      case "location":
        return "/assets/connections_location_icon.png";
      case "person":
        return "/assets/connections_user_icon.png";
      case "topic":
        return "/assets/topic.png";
      case "event":
        return "/assets/event_solid.png";
      case "organization":
        return "/assets/organisation_icon.png";
      default:
        return "/assets/placeholder.png";
    }
  };

  const getNodeColor = (nodeType: string, items: boolean[]): string => {
    // Implement logic to map node types to image paths
    switch (nodeType) {
      case "location": {
        if (items[0] === true) return theme.palette.error.light;
        else return theme.palette.text.dark || "grey";
      }
      case "person": {
        if (items[1] === true) return theme.palette.text.highStatus || "blue";
        else return theme.palette.text.dark || "grey";
      }
      case "topic": {
        if (items[2] === true) return theme.palette.text.highStatus || "blue";
        else return theme.palette.text.dark || "grey";
      }
      case "event": {
        if (items[3] === true) return theme.palette.success.status || "blue";
        else return theme.palette.text.dark || "grey";
      }
      case "organization": {
        if (items[4] === true) return theme.palette.primary.secondary || "blue";
        else return theme.palette.text.dark || "grey";
      }
      default: {
        if (items[5] === true) return theme.palette.success.status || "blue";
        else return theme.palette.text.dark || "grey";
      }
    }
  };

  const processData = (data: Record<string, any>[], items: boolean[]) => {
    const nodes = new Map();
    const links: Record<string, any>[] = [];

    data.forEach((item) => {

      const headId = item.head._id['$oid'];
      const tailId = item.tail._id['$oid'];

      const relation = item.type;

      if (!nodes.has(headId)) {
        nodes.set(headId, {
          id: headId,
          image: getImage(item.head.type),
          color: getNodeColor(item.head.type, items),
          type: item.head.type,
          name: item.head.key,
          relativeRadius: item.head.connection_count,
          // Add other relevant properties from head here...
        });
      }

      if (!nodes.has(tailId)) {
        nodes.set(tailId, {
          id: tailId,
          image: getImage(item.tail.type),
          color: getNodeColor(item.tail.type, items),
          type: item.tail.type,
          name: item.tail.key,
          tail_type: item.tail?.tail_type || null,
          relativeRadius: item.tail.connection_count,
          // Add other relevant properties from tail here...
        });
      }

      links.push({
        source: headId,
        target: tailId,
        relation: relation,
      });
    });

    return {
      nodes: Array.from(nodes.values()),
      links,
    };
  };

  const nodeThreeObject = (node: Record<string, any>) => {
    const imgTexture = new THREE.TextureLoader().load(`${node.image}`);
    imgTexture.colorSpace = THREE.SRGBColorSpace;
    const material = new THREE.SpriteMaterial({ map: imgTexture });
    const sprite = new THREE.Sprite(material);
    sprite.scale.set(12, 12, 12);
    return sprite;
  };

  function nodePaint(node: NodeInfo, ctx: CanvasRenderingContext2D): void {
    // Calculate radius with some padding
    var bgColor = theme.palette.text.moderateStatus;
    var textColor = theme.palette.text.nodeText || "#F5F9FF";

    // Calculate radius with some padding
    let radius = MIN_NODE_RADIUS; // adjust padding as needed
    if (node.relativeRadius > 30 && node.relativeRadius < 100) {
      radius = node.relativeRadius / 10;
      if (radius <= MIN_NODE_RADIUS) {
        radius = MIN_NODE_RADIUS;
      }
    } else if (node.relativeRadius >= 100) {
      radius = node.relativeRadius / 20;
      if (radius >= MAX_NODE_RADIUS) {
        radius = MAX_NODE_RADIUS;
      }
    }

    for (var i = 0; i < 6; i++) {
      if (i == 5 && items[5] == false && node.color === (theme.palette.text.dark || "grey")) {
        textColor = theme.palette.text.dark || "grey";
        ctx.globalAlpha = 0.5;
      } else if (i != 5 && items[i] === false && items_list[i] === node.type) {
        bgColor = theme.palette.text.dark || "grey";
        textColor = theme.palette.text.dark || "grey";
        ctx.globalAlpha = 0.5;
      }
    }
    // Draw the circular background with opacity

    ctx.beginPath();
    ctx.arc(node.x, node.y, radius, 0, 2 * Math.PI, false);
    if (node.relativeRadius >= 100) ctx.fillStyle = bgColor + "50";
    else ctx.fillStyle = node.color + "70";
    ctx.fill();

    // Draw the white border
    ctx.beginPath();
    ctx.arc(node.x, node.y, radius, 0, 2 * Math.PI, false);
    if (node.relativeRadius >= 100) ctx.strokeStyle = bgColor + "50";
    else ctx.strokeStyle = node.color;
    ctx.lineWidth = 0.1; // adjust border width as needed
    ctx.stroke();

    const image = new Image();
    image.src = node.image;
    ctx.drawImage(image, node.x - radius / 2, node.y - radius / 2 - 0.2, radius, radius);
    ctx.globalAlpha = 1;
    // Draw the text outside the circle
    ctx.font = "2px Sans-Serif";
    ctx.textAlign = "center";
    ctx.fillStyle = textColor;
    ctx.textBaseline = "middle";
    ctx.fillText(node.name, node.x, node.y + radius + 2); // adjust offset based on text size and desired position
  }

  const memoizedGraphData = useMemo(() => {
    return processData(connectionsData, items);
  }, [connectionsData, items]);


  const handleLinkClick = (link: Record<string, any>, e: MouseEvent) => {
    setCurrentNode(null);
    setCurrentLink(link);
    const localX = e.clientX;
    const localY = e.clientY;
    setCoords([localX, localY]);

    setOpenPopover((prev) => !prev);
  };

  const handleNodeClick = (node: NodeInfo, e: MouseEvent) => {
    setCurrentLink(null);
    setCurrentNode(node);
    setOpenDialog(true);

    //If want to show popover on node click
    // const localX = e.clientX;
    // const localY = e.clientY;
    // setCoords([localX, localY]);

    // setOpenPopover((prev) => !prev);
  };

  return (
    <Box>
      <ForceGraph2D
        height={522}
        // nodeOpacity={0.8}
        // nodeResolution={2400}
        // nodeThreeObject={nodeThreeObject}
        // nodeColor={(node) => getNodeColor(node.type)}
        linkWidth={1}
        linkColor={() => theme.palette.additionalColors.light}
        graphData={memoizedGraphData}
        backgroundColor={theme.palette.background.default}
        nodeCanvasObject={nodePaint}
        cooldownTicks={100}
        enableZoomInteraction={true}
        ref={graphRef}
        onLinkClick={handleLinkClick}
        onNodeClick={handleNodeClick}
        nodeLabel={() => ""}
        // onEngineStop={() => graphRef.current.zoomToFit(400)}
      />
      <ZoomController sx={{ position: "absolute", right: 20, bottom: 20 }} zoomOut={handleZoomOut} zoomIn={handleZoomIn} />
      {(currentLink || currentNode?.type) && (
        <ConnectionsPopover open={openPopover} coords={coords} handleClose={handleClosePopover}>
          {currentLink && <LinkPopoverContent linkData={currentLink} />}
          {currentNode && <NodePopoverContent nodeData={currentNode} />}
        </ConnectionsPopover>
      )}
      <EntityDetailsDialog open={openDialog} handleToggle={handleToggleDialog} nodeData={currentNode} />
    </Box>
  );
};

export default NetworkGraph;
