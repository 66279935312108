import { Box, useMediaQuery } from "@mui/material";
import ContentUILoader from "../../../../../../components/ContentUILoader";
import CustomNoResults from "../../../../../../components/CustomNoResult";
import ImageCard from "./ImageCard";

interface ImageDataProps {
  created_at: string;
  media_image: string;
  source_link: string;
  relevance: string;
  authenticity: string;
  status: string;
  image: string;
  author: string;
}

interface ImageProps {
  drawerOpen: boolean;
  isRefresh: boolean;
  loading: boolean;
  feedData: Record<string, any>;
}

export default function Images({ drawerOpen, isRefresh, loading, feedData }: ImageProps) {
  const matches = useMediaQuery("(min-width:1200px)") ? 2 : 4;
  const imagesData = feedData?.filter((feed: any) => feed?.images !== null);

  const filterData = imagesData
    ?.filter((d: Record<string, any>) => d?.images)
    ?.map((data: Record<string, any>) => {
      return data?.images?.map((image: string) => {
        return {
          created_at: data?.creation_time,
          media_image:
            data?.user?.profile_image || (data?.authors && data?.authors.length > 0 && data?.authors[0]?.profile_image) || data?.kind === "news"
              ? "/assets/news.png"
              : "/assets/twitter.png",
          source_link: data?.url,
          relevance: "97%",
          authenticity: "97%",
          status: "moderate",
          image: image,
          source: data?.source,
          author: data?.user?.name || (data?.authors && data?.authors.length > 0 && data.authors[0]?.name),
          dataId: data?._id,
        };
      });
    })
    .flat();
  const removeDuplicates = (array: ImageDataProps[]) => {
    const seen = new Set();
    return array.filter((item) => {
      const keyValue = item?.image;
      return seen.has(keyValue) ? false : seen.add(keyValue);
    });
  };
  const uniqueFiltered = removeDuplicates(filterData);
  // console.log("uniqueFiltered :", uniqueFiltered);
  let mergedColumnIds: number[] = [];

  for (let i = matches; i <= uniqueFiltered.length; i += 10) {
    mergedColumnIds.push(i);
  }

  for (let i = 5; i <= uniqueFiltered.length; i += 10) {
    mergedColumnIds.push(i);
  }

  const matchedData = useMediaQuery("(min-width:768px)") ? mergedColumnIds : [];
  return (
    <>
      {!loading && !isRefresh ? (
        <>
          {uniqueFiltered?.length > 0 ? (
            <Box sx={{ width: 1, mt: 3, cursor: "pointer" }}>
              <Box display="grid" gridTemplateColumns="repeat(12, 4fr)" width="100%" gap={2}>
                {uniqueFiltered?.map((data: ImageDataProps, idx: number) => {
                  return <ImageCard cardData={data} mergedColumnIds={matchedData} index={idx} drawerOpen={drawerOpen} />;
                })}
              </Box>
            </Box>
          ) : (
            <CustomNoResults />
          )}
        </>
      ) : (
        <ContentUILoader type="image" count={5} drawerOpen={drawerOpen} />
      )}
    </>
  );
}
