import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiUpload } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BorderedCard from "../../../components/BorderedCard";
import CustomAutocomplete from "../../../components/CustomAutocomplete";
import CustomCheckbox from "../../../components/CustomCheckbox";
import CustomDivider from "../../../components/CustomDivider";
import CustomInputField from "../../../components/CustomInputField";
import CustomTextButton from "../../../components/CustomTextButton";
import StaticValueDisplay from "../../../components/StaticValueDisplay";
import UiLoading from "../../../layouts/UiLoading";
import { getTimezoneList } from "../../../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getWorkspaceUser, removeAdmin, setAdmin, updateUserProfile } from "../../../store/workspace/workspaceThunk";
import classes from "../../../styles/AccountDetails.module.css";
import { USER_PERMISSION, roles } from "../../../utils/constants";

interface ModifyUserDetailsProps {
  isEditable: boolean;
  toggleEdit: () => void;
}

export default function ModifyUserDetails({ isEditable, toggleEdit }: ModifyUserDetailsProps) {
  const theme = useTheme();
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { timezonesList } = useAppSelector((state) => state.auth);
  const { isLoading } = useAppSelector((state) => state.workspace);
  const { workspaceId, userId } = useParams();

  const [imgSrc, setImgSrc] = React.useState<string>();
  const [updatedProfile, setUpdatedProfile] = React.useState<Record<string, any>>({});
  const [details, setDetails] = useState<Record<string, any>>({});
  const [role, setRole] = useState<string>("");
  const [userPermission, setPermission] = useState<number[]>([]);

  useEffect(() => {
    dispatch(getWorkspaceUser({ id: userId })).then((res) => {
      if (res?.payload?.status === 200) {
        setDetails(res.payload.data);
        setRole(res.payload.data.is_admin ? "Admin" : "User");
        setPermission(res.payload.data.permissions);
      }
    });
    dispatch(getTimezoneList());
  }, []);

  useEffect(() => {
    setRole(details.is_admin ? "Admin" : "User");
    setDetails(details);
    setPermission(details.permissions);
  }, [details]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    const fileUrl = URL.createObjectURL(file);
    setImgSrc(fileUrl);
    setUpdatedProfile((prev) => ({
      ...prev,
      profile_photo: file,
    }));
  };

  const handleTimeZone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { textContent: value } = e.target;

    setUpdatedProfile((prev) => ({
      ...prev,
      time_zone: value,
    }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "role") {
      setRole(value);
      setUpdatedProfile((prev) => ({
        ...prev,
        is_admin: value === "Admin" ? true : false,
      }));
      return;
    }
    setUpdatedProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    toggleEdit();
    setUpdatedProfile({});
    setPermission(details.permissions);
    setRole(details.is_admin ? roles.admin : roles.user);
  };

  const handleSave = () => {
    //handle edit and toggleEdit
    dispatch(updateUserProfile({ id: userId, ...updatedProfile })).then((res) => {
      if (res?.payload?.status === 200) {
        if (updatedProfile.is_admin !== undefined) {
          if (updatedProfile.is_admin) dispatch(setAdmin({ id: res.payload.data.id }));
          else dispatch(removeAdmin({ id: res.payload.data.id }));
        }
      }
      toast.success("User updated Successfully");
      setDetails((prev) => {
        return { ...prev, ...updatedProfile };
      });
      setUpdatedProfile({});
    });
    toggleEdit();
  };

  const handlePermission = (value: number) => {
    if (!userPermission.includes(value)) {
      console.log("userPermission", userPermission);
      setPermission([...userPermission, value]);
      setUpdatedProfile((prev) => ({
        ...prev,
        permissions: [...userPermission, value],
      }));
    } else {
      const restItem = userPermission.filter((p) => p !== value);
      setPermission(restItem);
      setUpdatedProfile((prev) => ({
        ...prev,
        permissions: restItem,
      }));
    }
  };

  return (
    <div>
      {isLoading && <UiLoading height="100vh" />}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          boxShadow: theme.palette.dashboardContainer.insetBoxShadow,
          background: theme.palette.dashboardContainer.containerBackground,
          borderRadius: "18px 18px 18px 18px",
          height: "100%",
          p: 3,
          mt: -1,
        }}
      >
        <BorderedCard title="">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h4" color={theme.palette.text.titleLabel}>
              Personal info
            </Typography>
            {isEditable ? (
              <Box sx={{ display: "flex", gap: 2 }}>
                <CustomTextButton onClick={handleCancel} sx={{ color: theme.palette.error.text }}>
                  Cancel
                </CustomTextButton>
                <CustomTextButton onClick={handleSave}>Save</CustomTextButton>
              </Box>
            ) : (
              <CustomTextButton onClick={handleCancel}>Edit</CustomTextButton>
            )}
          </Box>
          <CustomDivider />
          <>
            <Grid container mt={3}>
              <Grid
                item
                xs={12}
                sm={3}
                lg={2}
                sx={{
                  margin: { xs: "auto", sm: "auto", md: 0 },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                <input
                  style={{ display: "none" }}
                  id="profilePicture"
                  name="profile_photo"
                  className="profilePicture"
                  type="file"
                  onChange={handleFileChange}
                  disabled={!isEditable}
                />
                <label
                  htmlFor="profilePicture"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    width="100%"
                    height="100%"
                    src={imgSrc || details?.profile_photo || "/assets/profile.png"}
                    alt="profile"
                    className={classes.profileimg}
                  />
                  {isEditable && (
                    <Box sx={{ position: "absolute" }}>
                      <FiUpload size={32} />
                    </Box>
                  )}
                </label>
              </Grid>
              <Grid
                item
                xs={12}
                sm={9}
                lg={9}
                sx={{
                  margin: { sm: "auto", md: "auto 0 auto auto" },
                }}
              >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12} sm={6} md={4}>
                    {isEditable ? (
                      <FormControl fullWidth>
                        <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                          User Name
                        </Typography>
                        <CustomInputField
                          defaultValue={details.name || "NA"}
                          required
                          name="name"
                          size="small"
                          placeholder="User Name"
                          onChange={handleInputChange}
                          autoComplete="off"
                          disabled={!isEditable}
                          sx={{ "& .MuiOutlinedInput-input": { ...theme.typography.caption } }}
                        />
                      </FormControl>
                    ) : (
                      <StaticValueDisplay label="User Name" value={details["name"]} />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    {isEditable ? (
                      <FormControl fullWidth>
                        <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                          Email Address
                        </Typography>
                        <CustomInputField
                          defaultValue={details.email || "NA"}
                          required
                          name="email"
                          size="small"
                          placeholder="Email Address"
                          onChange={handleInputChange}
                          autoComplete="off"
                          disabled={!isEditable}
                          sx={{ "& .MuiOutlinedInput-input": { ...theme.typography.caption } }}
                        />
                      </FormControl>
                    ) : (
                      <StaticValueDisplay label="Email Address" value={details["email"]} />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    {currentUser?.is_admin && isEditable ? (
                      <FormControl fullWidth>
                        <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                          Role
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                          {Object.keys(roles).map((key) => (
                            <RadioGroup onChange={handleInputChange} key={key} name="role" value={roles[key]}>
                              <FormControlLabel
                                value={roles[key]}
                                label={roles[key]}
                                control={
                                  <Radio
                                    checked={role.toLowerCase() === roles[key].toLowerCase()}
                                    sx={{
                                      "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
                                        color: "#1A1B1E",
                                      },
                                      "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                                        color: "#2EA3FF",
                                      },
                                    }}
                                  />
                                }
                                sx={{
                                  "&.MuiFormControlLabel-root .MuiFormControlLabel-label": {
                                    ...theme.typography.caption,
                                    color: theme.palette.text.titleLabel,
                                    textTransform: "capitalize",
                                  },
                                }}
                              />
                            </RadioGroup>
                          ))}
                        </Box>
                      </FormControl>
                    ) : (
                      <StaticValueDisplay label="Role" value={details["is_admin"] ? roles.admin : roles.user} />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    {isEditable ? (
                      <FormControl fullWidth>
                        <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                          Phone Number
                        </Typography>
                        <CustomInputField
                          defaultValue={details.phone_no || "NA"}
                          required
                          name="phone_no"
                          size="small"
                          placeholder="Phone Number"
                          onChange={handleInputChange}
                          autoComplete="off"
                          disabled={!isEditable}
                          sx={{ "& .MuiOutlinedInput-input": { ...theme.typography.caption } }}
                        />
                      </FormControl>
                    ) : (
                      <StaticValueDisplay label="Phone Number" value={details["phone_no"]} />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    {isEditable ? (
                      <FormControl fullWidth>
                        <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                          Country
                        </Typography>
                        <CustomInputField
                          defaultValue={details.country || "USA"}
                          required
                          name="country"
                          size="small"
                          placeholder="Country"
                          onChange={handleInputChange}
                          autoComplete="off"
                          disabled={!isEditable}
                          sx={{ "& .MuiOutlinedInput-input": { ...theme.typography.caption } }}
                        />
                      </FormControl>
                    ) : (
                      <StaticValueDisplay label="Country" value={details["country"] || "USA"} />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    {isEditable ? (
                      <FormControl fullWidth>
                        <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                          Timezone
                        </Typography>
                        <CustomAutocomplete
                          defaultValue={details.time_zone || "NA"}
                          name="time_zone"
                          size="small"
                          placeholder="Timezone"
                          onChange={handleTimeZone}
                          disabled={!isEditable}
                          options={timezonesList.map((v, index) => ({ label: v, id: parseInt(v) + 1 }))}
                          lableName="Timezone"
                          sx={{ "& .MuiOutlinedInput-input": { ...theme.typography.caption } }}
                        ></CustomAutocomplete>
                      </FormControl>
                    ) : (
                      // <StaticValueDisplay label="Timezone" value={details["time_zone"] || "jGMT+00:00) UTC"} />
                      <StaticValueDisplay label="Timezone" value={details["time_zone"] || "jGMT+00:00) UTC"} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        </BorderedCard>

        <Box>
          {currentUser?.is_admin && (
            <BorderedCard>
              <Typography variant="h4" color={theme.palette.text.titleLabel}>
                Permissions
              </Typography>
              <CustomDivider width="100%" />
              <Grid container mt={2}>
                {USER_PERMISSION?.map((permission) => {
                  const selected = userPermission?.includes(permission?.id);

                  return (
                    <Grid item xs={6} md={4} lg={12 / 5} key={permission?.id}>
                      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        <CustomCheckbox
                          checked={selected}
                          label={permission?.text}
                          disabled={!isEditable}
                          onChange={() => handlePermission(permission?.id)}
                        />
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </BorderedCard>
          )}
        </Box>
      </Box>
    </div>
  );
}
