import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import PrimaryLoadingButton from "../../components/PrimaryLoadingButton";
import { forgotPassword } from "../../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

const maxSeconds: number = 60;

export interface EmailVerificationProps {
  email: string;
}

export default function EmailVerification({ email }: EmailVerificationProps) {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.auth);
  const [disabledResendButton, setDisabledResendButton] = React.useState<boolean>(true);
  const [seconds, setSeconds] = React.useState(maxSeconds);

  React.useEffect(() => {
    let interval: any = null;
    interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
    if (seconds <= 0) {
      setDisabledResendButton(() => {
        clearInterval(interval);
        return false;
      });
    }
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ zIndex: 1, width: "100%" }}
      py={{ xs: 2, sm: 1, lg: 1, xl: 2 }}
      px={{ xs: 6, sm: 1, lg: 1, xl: 2 }}
    >
      <Typography
        variant="h1"
        textAlign="center"
        sx={{
          fontFeatureSettings: "'liga' off",
          color: theme.palette.text.titleLabel,
        }}
      >
        Email Verification
      </Typography>
      <Typography variant="body6" sx={{ mt: "2rem", textAlign: "center", color: theme.palette.text.titleLabel }}>
        We have sent email to {email} to confirm the validity of email address. After receiving the email follow the provided link to reset password.
      </Typography>
      <Box sx={{ width: "100%" }} display="flex" flexDirection="column" alignItems="center">
        <PrimaryLoadingButton
          disabled={disabledResendButton}
          onClick={() => {
            dispatch(forgotPassword({ email })).then((res) => {
              if (res?.payload?.status === 200) {
                toast.success("Sent the email successfully.");
              }
            });
          }}
          sx={{
            p: 0,
            marginTop: "4rem",
            width: { xs: "60%", sm: "76%", md: "83%", lg: "66%" },
          }}
          isLoading={isLoading}
        >
          {disabledResendButton ? `Resend Code In ${seconds} Seconds` : "Resend Code"}
        </PrimaryLoadingButton>
        <Typography variant="body6" color={(theme) => theme.palette.text.titleLabel} mt={4}>
          Have any issues?
          <NavLink
            to="/login"
            style={{
              ...theme.typography.body6,
              color: theme.palette.primary.main,
              textDecoration: "none",
              marginLeft: "6px",
            }}
          >
            Contact Us
          </NavLink>
        </Typography>
      </Box>
    </Box>
  );
}
