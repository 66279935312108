import { Box, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import BorderedCard from "../../../components/BorderedCard";
import CustomTextButton from "../../../components/CustomTextButton";
import FeatureList from "../../../components/FeatureList";
import PlanButton from "../../../components/PlanButton";
import PrimaryLoadingButton from "../../../components/PrimaryLoadingButton";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";

export default function CurrentPlanUpgrades() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const toggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  const handleNavigation = (plan: string) => {
    handleRouteClick(routes.upgradeInfo.path, { plan: plan });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        padding: "32px",
        borderRadius: "8px",
        background: theme.palette.dashboardContainer.sessionCardActive,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="body3" color={theme.palette.text.main}>
          You’re currently on the Basic plan. Upgrade your plan to upload your own data or customize the AI Models.
        </Typography>
        <CustomTextButton onClick={toggleCollapse}>{isCollapsed ? "Expand" : "Collapse"}</CustomTextButton>
      </Box>
      {!isCollapsed && (
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <BorderedCard
              sx={{
                background: theme.palette.dashboardContainer.otherPlanCardBackground,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 2,
                minHeight: "22rem",
                height: "100%",
                borderRadius: 2,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <PlanButton type="basic">Basic</PlanButton>
                <Typography variant="body6" color={theme.palette.text.captionColor}>
                  Quick start. You can buy any AI Models and Solutions
                </Typography>
                <Typography variant="h2" color={theme.palette.text.default}>
                  Free
                </Typography>
                <Box>
                  <FeatureList features={["1 AI Model", "1 Data Source"]} />
                </Box>
              </Box>
              <Box>
                <PrimaryLoadingButton sx={{ ...theme.typography.bigButton, width: "100%" }} variant="contained" disabled>
                  Current
                </PrimaryLoadingButton>
              </Box>
            </BorderedCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <BorderedCard
              sx={{
                background: theme.palette.dashboardContainer.otherPlanCardBackground,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 2,
                minHeight: "22rem",
                height: "100%",
                borderRadius: 2,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <PlanButton type="pro">Pro</PlanButton>
                <Typography variant="body6" color={theme.palette.text.captionColor}>
                  Optimal variant. Analyze everything, including your own data{" "}
                </Typography>
                <Typography variant="h2" color={theme.palette.text.default}>
                  +300$/year
                </Typography>
                <Box>
                  <FeatureList features={["All Data sources", "Own data sourses", "Workspace"]} />
                </Box>
              </Box>
              <Box>
                <PrimaryLoadingButton
                  sx={{ ...theme.typography.bigButton, width: "100%" }}
                  variant="contained"
                  onClick={() => handleNavigation("pro")}
                >
                  Upgrade
                </PrimaryLoadingButton>
              </Box>
            </BorderedCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <BorderedCard
              sx={{
                background: theme.palette.dashboardContainer.otherPlanCardBackground,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                justifyContent: "space-between",
                minHeight: "22rem",
                height: "100%",
                borderRadius: 2,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <PlanButton type="enterprise">Enterprise</PlanButton>
                <Typography variant="body6" color={theme.palette.text.captionColor}>
                  Customize your models with your own data{" "}
                </Typography>
                <Typography variant="h2" color={theme.palette.text.default}>
                  +700$/year
                </Typography>
                <Box>
                  <FeatureList features={["All Data sources", "Own data sourses", "Workspace", "Custom AI Models"]} />
                </Box>
              </Box>
              <Box>
                <PrimaryLoadingButton
                  sx={{ ...theme.typography.bigButton, width: "100%" }}
                  variant="contained"
                  onClick={() => handleNavigation("enterprise")}
                >
                  Upgrade
                </PrimaryLoadingButton>
              </Box>
            </BorderedCard>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
