import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, CardMedia, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import CustomIconButton from "../../../../components/CustomIconButton";
import CustomPopover from "../../../../components/CustomPopover";
import WorkspaceMenuItem, { Workspace } from "./WorkspaceMenuItem";

const workspaceDetails = [
  {
    id: 1,
    name: "Workspace one",
    member: 1,
    plan: "Free",
    active: true,
  },
  {
    id: 2,
    name: "Workspace two",
    member: 2,
    plan: "Free",
    active: false,
  },
  {
    id: 3,
    name: "Workspace three",
    member: 1,
    plan: "Free",
    active: false,
  },
];

export default function WorkspaceMenu() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace>(workspaceDetails[0]);
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen((prev) => !prev);
    setAnchorEl(event.currentTarget.parentElement);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleItemClick = (item: Workspace) => {
    setSelectedWorkspace(item);
    handleClose();
  };

  return (
    <div>
      <Box mb={4}>
        <Box
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          sx={{
            display: "flex",
            gap: 2,
            background: theme.palette.background.gradientLight1,
            p: 2,
            width: { xs: "100%", lg: "80%", xl: "100%" },
            borderRadius: 2,
            boxShadow: theme.palette.dashboardContainer.workspaceCardBoxShadow,
          }}
        >
          <CardMedia component="img" image="/assets/workspace-icon.png" alt="info" sx={{ width: "48px", height: "48px" }} />
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="h4" sx={{ color: theme.palette.text.titleLabel }}>
              {selectedWorkspace.name}
            </Typography>
            <Box display="flex" gap={1}>
              <Typography variant="body3" color={theme.palette.primary.inactiveIcon}>
                {selectedWorkspace.member}
              </Typography>
              <Typography variant="body3" color={theme.palette.primary.inactiveIcon} sx={{ mt: "-3px" }}>
                .
              </Typography>
              <Typography variant="body3" color={theme.palette.primary.inactiveIcon}>
                {selectedWorkspace.plan}
              </Typography>
            </Box>
          </Box>
          <CustomIconButton sx={{ ml: "auto", mt: 1.4 }} onClick={handleClick}>
            {open ? <ExpandLessIcon sx={{ color: theme.palette.text.default }} /> : <ExpandMoreIcon sx={{ color: theme.palette.text.default }} />}
          </CustomIconButton>
        </Box>
      </Box>
      <CustomPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root.MuiPopover-paper": {
            background: theme.palette.background.gradientLight1,
            boxShadow: theme.palette.dashboardContainer.workspaceMenuBoxShadow,
            py: -3,
            px: 2,
            mt: 1,
            borderRadius: "12px",
            border: `0.5px solid ${theme.palette.additionalColors.lightBorder}`,
            // width: { xs: "87.5%", sm: "84.5%", md: "87.5%", lg: "30.2%", xl: "30.5%" },
            width: anchorEl && anchorEl.offsetWidth,
          },
        }}
      >
        {workspaceDetails?.map((workspace, idx) => (
          <WorkspaceMenuItem key={idx} workspace={workspace} selectedWorkspaceId={selectedWorkspace.id} open={open} itemClick={handleItemClick} />
        ))}
      </CustomPopover>
    </div>
  );
}
