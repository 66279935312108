// import "leaflet.heat";
import { Box, Typography, useTheme } from "@mui/material";
import { isDeepEqual } from "@mui/x-data-grid/internals";
import "leaflet/dist/leaflet.css";
import React from "react";
import ConnectionSlider, { CustomConnectionSlider } from "../../../../../components/ConnectionSlider";
import { useAppSelector } from "../../../../../store/hooks";
import FilterLegend from "../../Map/Components/FilterLegend";
import ScoreLegend from "../../Map/Components/ScoreLegend";
import NetworkGraph from "./NetworkGraph";

const connectionTierMarks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];

const GraphView = () => {
  const theme = useTheme();
  const { tieredConnections } = useAppSelector((state) => state.analysisConnections, isDeepEqual);
  const [connectionDepth, setConnectionDepth] = React.useState<number>(1);
  const [items, setItems] = React.useState<boolean[]>([true, true, true, true, true, true]);
  //[location,person,topic,event,organization,other]
  const handleConnectionTierChange = (event: Event, newValue: number | number[]) => {
    setConnectionDepth(newValue as number);
  };
  return (
    <Box mt={2}>
      <Box
        width="100%"
        height="54vh"
        borderRadius={2}
        overflow="scroll"
        position="relative"
        sx={{ background: theme.palette.background.darkCardBackground, border: theme.palette.additionalColors.databasecardBorder }}
      >
        {connectionDepth >= 1 && tieredConnections && tieredConnections.length > 0 && (
          <NetworkGraph items={items} connectionsData={tieredConnections[connectionDepth - 1]} />
        )}
        <Box
          width="236px"
          ml={2}
          sx={{ position: "absolute", background: "#1D2330", borderRadius: 2, pt: 2, pb: 0.5, px: 1.5, bottom: "20px", zIndex: 1000 }}
        >
          <Typography variant="body4" color={theme.palette.text.tableHeader}>
            Connection level
          </Typography>
          <br />
          <CustomConnectionSlider
            sx={{
              "&.MuiSlider-root": {
                padding: "12px 0",
              },
              "& .MuiSlider-mark": { display: "none" },
              "& .MuiSlider-markLabel": {
                color: theme.palette.text.titleLabel,
                fontSize: "12px",
                fontWeight: 400,
              },
            }}
            slots={{ thumb: ConnectionSlider }}
            min={1}
            max={5}
            step={1}
            value={connectionDepth}
            onChange={handleConnectionTierChange}
            marks={connectionTierMarks}
            defaultValue={1}
            getAriaValueText={(value: number) => String(value)}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          mt: 2,
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            gap: 1,
          },
        }}
      >
        <FilterLegend items={items} setItems={setItems} />
        <ScoreLegend sx={{ [theme.breakpoints.up("md")]: { ml: "auto" } }} />
      </Box>
    </Box>
  );
};

export default GraphView;
