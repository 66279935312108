import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { getFormattedDate } from "../../../../../../../utils/helper";
import Title from "../../../../Title";
import ConnectionSection from "./ConnectionSection";

export default function ConnectionsWrapper({ connectionData }: Record<string, any>) {
  const theme = useTheme();
  const [lastUpdated, setLastUpdated] = useState(getFormattedDate(new Date()));

  const handleRefresh = () => {
    const currentDate = new Date();
    setLastUpdated(getFormattedDate(currentDate) + " at " + currentDate.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" }));
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  // useEffect(() => {
  //   let dataId = params?.id;
  //   if (dataId) {
  //     dispatch(getQueryDetail(dataId))
  //       .then((res) => {
  //         setPublicationConnectionData(res?.payload?.data?.data_result);
  //         console.log(res?.payload?.data?.data_result);
  //       })
  //       .catch((e) => {
  //         toast.error("Failed to get query details");
  //       });
  //   }
  // }, [params]);

  return (
    <Box
      sx={{
        borderTop: "1px solid",
        borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
        background: theme.palette.dashboardContainer.containerBackground,
        height: "100%",
      }}
      p={2}
    >
      <Title title="Connections" lastUpdated={lastUpdated} refresh={false} handleRefresh={handleRefresh} />
      <ConnectionSection data={connectionData} />
    </Box>
  );
}
