import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import BackButton from "../../../../components/BackButton";
import CustomTextButton from "../../../../components/CustomTextButton";
import PrimaryLoadingButton from "../../../../components/PrimaryLoadingButton";
import StaticValueDisplay from "../../../../components/StaticValueDisplay";
import SkylarkLabsCircle from "../../../../layouts/SkylarkLabsCircle";
import { useHandleRouteClick } from "../../../../routes/hooks";
import { useAppSelector } from "../../../../store/hooks";
import { ThemeVariantsProps } from "../../../../theme";
import CartPlanInfo from "./CartPlanInfo";
import PaymentConfirmation from "./Confirmation";
import PaymentCard from "./PaymentCard";
import PaymentDetailField from "./PaymentDetailField";
import PlanInfo from "./PlanInfo";

export interface FormDataType {
  name: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
}

const initialFormData: FormDataType = {
  name: "",
  email: "",
  address: "",
  city: "",
  state: "",
  zipcode: "",
};

export default function PaymentDetails() {
  const theme = useTheme();
  const { themeMode } = useAppSelector((state) => state.theme);
  const isDarkTheme = themeMode === ThemeVariantsProps.dark;

  const { state } = useLocation();
  const handleRouteClick = useHandleRouteClick();
  const [formData, setFormData] = useState(initialFormData);
  const [paymentOption, setPaymentOption] = useState("stripe");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [editable, setEditable] = useState(false);

  const handleBack = () => {
    handleRouteClick(state?.backPath);
  };

  const handleInputChange = ({ fieldName, value }: { fieldName: string; value: any }) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handlePaymentMethod = (option: string) => {
    setPaymentOption(option);
  };

  const handlePaymentConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleEdit = () => {
    setEditable(true);
  };

  const handleSave = () => {
    setEditable(false);
    console.log(formData);
  };

  const planName = state?.plan === "enterprise" ? "Enterprise" : "Professional";

  const isBillingAddress = true;

  const PaymentDetailsInput = (label: string, name: string, value: string, text: string) => (
    <Box mb={editable ? 0 : 1} width="100%">
      {editable ? (
        <PaymentDetailField
          label={label}
          name={name}
          placeholder={text}
          defaultValue={value}
          handleInputChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange({ fieldName: e.target.name, value: e.target.value })}
        />
      ) : (
        <StaticValueDisplay label={label} value={value} />
      )}
    </Box>
  );

  return (
    <>
      {!openConfirmation && (
        <Box p={2} display="flex" flexDirection="column" height="100%">
          <Box>
            <Typography variant="h1" color={theme.palette.text.titleLabel} sx={{ textTransform: "none" }}>
              Upgrade to {planName}
            </Typography>
            <BackButton onClick={handleBack}></BackButton>
          </Box>

          <Grid
            container
            spacing={4}
            height="100%"
            sx={{
              [theme.breakpoints.down("lg")]: {
                height: "100%",
                overflow: "scroll",
              },
              [theme.breakpoints.up("xl")]: {
                px: 30,
              },
            }}
          >
            <Grid item xs={12} md={6} lg={7} order={{ xs: 2, md: 1 }}>
              <Box
                sx={{
                  width: "100%",
                  background: theme.palette.background.paper,
                  boxShadow: theme.palette.dashboardContainer.customCardInsetBoxShadow,
                  borderRadius: 4.5,
                  p: 4,
                  mt: 2,
                }}
              >
                <Box>
                  <Box display="flex" mb={1.5}>
                    <Typography variant="h2" color={theme.palette.text.titleLabel}>
                      Billing Address
                    </Typography>
                    {isBillingAddress && (
                      <>
                        {editable ? (
                          <CustomTextButton sx={{ ...theme.typography.bigButton, ml: "auto" }} onClick={handleSave}>
                            Save
                          </CustomTextButton>
                        ) : (
                          <CustomTextButton sx={{ ...theme.typography.bigButton, ml: "auto" }} onClick={handleEdit}>
                            Edit Billing Details
                          </CustomTextButton>
                        )}
                      </>
                    )}
                  </Box>
                  {PaymentDetailsInput("Full Name", "name", formData["name"] || "James Richter", "Text")}
                  {PaymentDetailsInput("Email", "email", formData["email"] || "lowcode@gmail.co", "Text")}
                  {PaymentDetailsInput("Address", "address", formData["address"] || "75 West Hudson Street", "Text")}
                  {PaymentDetailsInput("City", "city", formData["city"] || "Brooklyn, NY", "Text")}
                  <Box display="flex" gap={2} width="100%">
                    {PaymentDetailsInput("State", "state", formData["state"] || "NY", "Text")}
                    {PaymentDetailsInput("Zip Code", "zipcode", formData["zipcode"] || "11218", "Text")}
                  </Box>
                </Box>
                <Box my={5}>
                  <Typography variant="h2" color={theme.palette.text.titleLabel} pb={3}>
                    Payment Method
                  </Typography>
                  <PaymentCard label="Stripe" logo="/assets/payment_logo/stripe.png" handlePaymentMethod={handlePaymentMethod} />
                </Box>

                <PrimaryLoadingButton
                  sx={{
                    ...theme.typography.hugeButton,
                    width: "100%",
                    mb: 2,
                  }}
                  onClick={handlePaymentConfirmation}
                >
                  Proceed Payment
                </PrimaryLoadingButton>
                <Box display="flex" justifyContent="center" gap={0.5} pb={0.5}>
                  <Typography variant="caption1">By clicking the button you agree with</Typography>
                  <Typography variant="body3" sx={{ textDecoration: "underline" }}>
                    Terms and Conditions
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={5} order={{ xs: 1, md: 2 }} sx={{}}>
              {state?.isCart ? <CartPlanInfo amount={state?.amt} /> : <PlanInfo amount={state?.amt} />}
            </Grid>
          </Grid>
        </Box>
      )}
      {openConfirmation && (
        <Box
          sx={{
            height: "100vh",
            overflowY: "scroll",
            overflowX: "hidden",
            position: "relative",
            m: -2,
          }}
        >
          <SkylarkLabsCircle />
          <Box display="table" position="absolute" width="100%" height="100%">
            <PaymentConfirmation />
          </Box>
        </Box>
      )}
    </>
  );
}
