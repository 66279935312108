import { Box, Grid, TextField, useTheme } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import UiLoading from "../../../layouts/UiLoading";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getFormattedDate } from "../../../utils/helper";
import Title from "../Components/Title";
import ChatInput from "./MentionTextfield";
import ChatBotChatSection from "../Components/Chat/ChatBotChatSection";
import { getUniqueEntities } from "../../../store/analysis/analysisThunk";

export default function ChatBot() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoading } = useAppSelector((state) => state.analysis);
  const queryId = searchParams.get("query");
  const [lastUpdated, setLastUpdated] = useState(getFormattedDate(new Date()));
  const [entities, setEntities] = useState([]);
  useEffect(() => {
   if(queryId){
      dispatch(getUniqueEntities(queryId)).then((res)=>{
        setEntities(res.payload?.data)
      }).catch((err)=>{
        console.log("Error in getting unique entities", err);
      })
   }
  }, [queryId]);

  const handleRefresh = () => {
    const currentDate = new Date();
    setLastUpdated(getFormattedDate(currentDate) + " at " + currentDate.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" }));
  };

  return (
    <Box
      sx={{
        borderTop: "1px solid",
        borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
        background: theme.palette.dashboardContainer.containerBackground,
        height : "100%",
      }}
      p={2}
    >
      {isLoading ? (
        <UiLoading height="100%" />
      ) : (
        <Box height="100%"display={"flex"} flexDirection={"column"} width={"100%"}  justifyContent="space-between">
          <Title title="Chat" lastUpdated={lastUpdated} refresh={true} handleRefresh={handleRefresh} />
            <Box width={"100%"}>
              <ChatBotChatSection entities={entities} queryId={queryId || ""}/>
              {/* <ChatInput /> */}
              {/* <TextField
                id="message"
                // label={label}
                variant="outlined"
                size="small"
                value={message}
                onChange={handleMessageChange}
                placeholder={"Type your message here..."}
                // fullWidth
                style={{ marginBottom: '20px',  position: 'fixed', bottom: 30, width: 'calc(100% - 175px)'}}
                InputProps={{
                    sx: { borderRadius: '20px' }, // Rounded border for input area
                  }}

              /> */}
            </Box>
        </Box>
      )}
    </Box>
  );
}
