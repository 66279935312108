import { createSlice } from "@reduxjs/toolkit";

export interface MagicEditInitialState {
  entitiesPage: {
    isMagicEdit: boolean;
    isUpdate: boolean;
    isCancel: boolean;
  };
  magiceditPage: string;
}

const magicEditInitialState: MagicEditInitialState = {
  entitiesPage: {
    isMagicEdit: false,
    isUpdate: false,
    isCancel: false,
  },
  magiceditPage: "",
};

const magicEditSlice = createSlice({
  name: "magicEdit",
  initialState: magicEditInitialState,
  reducers: {
    handleMagicEdit(state, action) {
      switch (action.payload) {
        case "entities":
          state.entitiesPage = {
            isMagicEdit: true,
            isUpdate: false,
            isCancel: false,
          };
          state.magiceditPage = "entities";
          break;
        default:
          break;
      }
    },
    handleCloseMagicEdit(state, action) {
      switch (action.payload) {
        case "entities":
          state.entitiesPage = {
            isMagicEdit: false,
            isUpdate: false,
            isCancel: true,
          };
          state.magiceditPage = "";
          break;
        default:
          break;
      }
    },
    handleUpdateMagicEdit(state, action) {
      switch (action.payload) {
        case "entities":
          state.entitiesPage = {
            isMagicEdit: false,
            isUpdate: true,
            isCancel: false,
          };
          state.magiceditPage = "";
          break;
        default:
          break;
      }
    },
  },
  extraReducers: (builder) => {},
});

export const magicEditActions = magicEditSlice.actions;

export default magicEditSlice;
