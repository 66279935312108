import { Box, Grid, Typography, useTheme } from "@mui/material";
import { BiSolidSearch } from "react-icons/bi";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import BorderedCard from "../../../components/BorderedCard";
import CustomTextButton from "../../../components/CustomTextButton";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
export interface NotificationPopupCardProps {
  label: string;
  text: string;
  timestamp: string;
  category: "alert" | "system" | "query";
  read: boolean;
}

export default function NotificationPopupCard({ label, text, timestamp, category, read }: NotificationPopupCardProps) {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const renderNotificationIcon = (cat: "alert" | "system" | "query") => {
    switch (cat) {
      case "alert":
        return <BsFillExclamationTriangleFill color={theme.palette.additionalColors.dark2} />;
      case "system":
        return <FaUserAlt color={theme.palette.additionalColors.dark2} />;
      case "query":
        return <BiSolidSearch color={theme.palette.additionalColors.dark2} />;
      default:
        return <BsFillExclamationTriangleFill color={theme.palette.additionalColors.dark2} />;
    }
  };

  return (
    <BorderedCard
      sx={{
        p: 1,
        borderRadius: "8px",
        background: theme.palette.dashboardContainer.sessionCardActive,
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={1.5}>
          <Box
            sx={{
              height: "30px",
              width: "30px",
              background: theme.palette.additionalColors.light,
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {renderNotificationIcon(category)}
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start" gap={0.1}>
            <Typography variant="subtitle2" color={theme.palette.text.titleLabel}>
              {label}
            </Typography>
            <Typography variant="body4" color={theme.palette.text.captionColor}>
              {text}
            </Typography>
            <CustomTextButton sx={{ fontSize: "12px" }} onClick={() => handleRouteClick(routes.notifications.path)}>
              Go To Results
            </CustomTextButton>
          </Box>
        </Grid>
        <Grid item xs={1.5}>
          <Typography variant="caption2" color={theme.palette.additionalColors.light}>
            {timestamp}
          </Typography>
        </Grid>
      </Grid>
    </BorderedCard>
  );
}
