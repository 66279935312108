import { Box, useTheme } from "@mui/material";
import ChatWidget from "./Components/Chat/ChatWidget";
import SubHeader from "./Components/SubHeader";

export default function AnalysisContainer() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: "82vh",
      }}
    >
      <SubHeader />
      {/* <ChatWidget /> */}
    </Box>
  );
}
