import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, Typography, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomIconButton from "../../../components/CustomIconButton";
import CustomTertiaryButton from "../../../components/CustomTertiaryButton";
import SubmitLoadingDeleteButton from "../../../components/SubmitLoadingDeleteButton";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { useAppDispatch } from "../../../store/hooks";
import { deleteUserFromWorkspace } from "../../../store/workspace/workspaceThunk";

interface ConfirmationProps {
  userdetail: any;
  open: boolean;
  handleDelete(): void;
}

export default function DeleteUserConfirmation({ userdetail, open, handleDelete }: ConfirmationProps) {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();
  const dispatch = useAppDispatch();
  const { workspaceId, userId } = useParams();

  const removeUser = (id: string) => {
    //Used static Id for testing
    dispatch(deleteUserFromWorkspace({ id: id })).then((res: Record<string, any>) => {
      console.log(res);
      if (res?.payload?.response?.status === 204) {
        toast.success("User Deleted Successfully");
        handleRouteClick(routes.workspaceDetail.path.replace(":workspaceId", workspaceId ? workspaceId : "0"));
      }
    });
    handleDelete();
  };

  return (
    <Dialog
      onClose={handleDelete}
      open={open}
      // maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          background: theme.palette.background.gradientLight1,
          borderRadius: "8px",
          py: 4,
          px: 4,
          // minHeight: "35vh",
          // textAlign: "center",
        },
      }}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.039)",
        },
      }}
    >
      <Box display="flex" justifyContent="flex-end">
        <CustomIconButton onClick={handleDelete} sx={{ color: theme.palette.error.main }}>
          <CloseIcon />
        </CustomIconButton>
      </Box>
      <DialogContent sx={{ margin: 0, padding: 0, display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Box sx={{ my: 2, mx: "auto" }}>
          <img height="120" src="/assets/cross_dialog_icon.png" />
        </Box>
        <Typography variant="h1" textAlign="center" my={2} color={theme.palette.text.titleLabel}>
          Confirm Delete {userdetail?.name}
        </Typography>
        <Typography variant="body4" textAlign="center" mt={1} color={theme.palette.text.captionColor}>
          Are you sure you want to delete the User ?
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            mt: 4,
            justifyContent: "center",
          }}
        >
          <CustomTertiaryButton sx={{ ...theme.typography.bigButton, minWidth: "8.5rem" }} onClick={handleDelete}>
            Cancel
          </CustomTertiaryButton>
          <SubmitLoadingDeleteButton isLoading={false} onClick={() => removeUser(userId ? userId : "0")} color="error" sx={{ minWidth: "8.5rem" }}>
            Delete
          </SubmitLoadingDeleteButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
