import { DiamondOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, useTheme } from "@mui/material";
import { memo } from "react";
import { Handle, Position } from "reactflow";
import CustomIconButton from "../../../../components/CustomIconButton";

interface CustomNodeProps {
  data: any;
  onlineSource: any;
  physicalSource: any;
  isCancel?: boolean;
  onNodeClick(id: string): void;
}

export default memo(({ data, onlineSource, physicalSource, isCancel = true, onNodeClick }: CustomNodeProps) => {
  const theme = useTheme();
  let isOnlineSource = onlineSource?.indexOf(data?.label);
  let isPhysicalSource = physicalSource?.indexOf(data?.label);
  const removeNode = (id: string) => {
    onNodeClick(id);
  };

  return (
    <>
      {isOnlineSource === -1 &&
        isPhysicalSource === -1 &&
        data.inputs?.map((input: any, index: number) => <Handle type="target" position={Position.Left} id={input} key={index} />)}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 0.8,
          border: `1px solid ${theme.palette.text.tableHeader}`,
          borderRadius: 5,
          px: 1,
          py: 1,
        }}
      >
        <DiamondOutlined sx={{ color: theme.palette.primary.light, fontSize: "14px" }} />
        <Typography variant="body3" color={theme.palette.primary.light}>
          {data?.label}
        </Typography>
        {isCancel && (
          <CustomIconButton sx={{ background: "transparent", border: "none", boxShadow: "none" }} onClick={() => removeNode(data?.label)}>
            <CloseIcon sx={{ color: theme.palette.text.titleLabel, fontSize: "14px" }} />
          </CustomIconButton>
        )}
      </Box>
      {data.outputs?.map((output: any, index: number) => (
        <Handle type="source" position={Position.Right} id={output} key={index} />
      ))}
    </>
  );
});
