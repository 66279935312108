import { Box, useTheme } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import React from "react";
import { useSearchParams } from "react-router-dom";
import CardTitle from "../components/CardTitle";

export interface TabRecord {
  id: string | number;
  title: string;
  disabled?: boolean;
  component: React.ReactNode;
}

export interface SubTabLayoutProps {
  children?: React.ReactNode;
  tabs: TabRecord[];
  sx?: SxProps<Theme>;
}

export default function SubTabLayout({ children, tabs, sx }: SubTabLayoutProps) {
  const theme = useTheme();
  const [activeTab, setActiveTab] = React.useState<TabRecord>(tabs[0]);
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    handleChangeActiveTab(searchParams.get("subtab") || String(tabs[0].id));
  }, [searchParams]);

  const handleChangeActiveTab = (tabId: string | number) => {
    setActiveTab(tabs.find((tab) => tab.id === tabId) || tabs[0]);
    setSearchParams((prev) => {
      const prevParams: Record<string, any> = {};
      prev.forEach((value, key) => {
        prevParams[key] = value;
      });
      return { ...prevParams, subtab: String(tabId) };
    });
  };

  const renderTabContent = (tabId: string | number) => {
    return (
      <Box
        sx={{
          my: 2,
          py: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderTop: "1px solid",
          borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
          background: theme.palette.dashboardContainer.containerBackground,
        }}
      >
        {tabs.find((tab) => tab.id === tabId)?.component}
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          background: theme.palette.text.secondaryText,
          boxShadow: theme.palette.background.primaryShadow,
          display: "flex",
          justifyContent: "center",
          width: "max-content",
          p: 0.5,
          ...sx,
        }}
      >
        {tabs.map((tab) => {
          return (
            <React.Fragment key={`tab-${tab?.id}`}>
              <Box
                onClick={() => {
                  if (!tab.disabled) handleChangeActiveTab(tab.id);
                }}
                sx={{
                  cursor: activeTab.id === tab.id ? "default" : "pointer",
                  color: theme.palette.text.secondaryText,
                  background: activeTab.id === tab.id ? theme.palette.background.secondaryBgColor : "transparent",
                  borderRadius: "3px",
                  px: 1,
                  py: 1,
                  minWidth: "128px",
                  position: "relative",
                  boxShadow: "none",
                  height: "28px",
                  textAlign: "center",
                }}
              >
                <CardTitle
                  isOpen={activeTab.id === tab.id}
                  title={tab.title}
                  sx={{
                    ...theme.typography.body4,
                    lineHeight: "16px",
                    color:
                      activeTab.id === tab.id
                        ? theme.palette.text.secondaryText
                        : tab.disabled
                        ? theme.palette.text.dark
                        : theme.palette.additionalColors.light,
                  }}
                />
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
      <Box height="100%">{renderTabContent(activeTab.id)}</Box>
      {children}
    </>
  );
}
