import { DiamondOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { FaArchive, FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaTwitter } from "react-icons/fa";
import { FaSquareThreads } from "react-icons/fa6";

interface ModuleProps {
  options: string[];
  title: string;
  icon: React.ReactNode;
  borderColor?: boolean;
  customIcon?: React.ReactNode;
  addNode: (option: string) => void;
  selectedNode: string[];
}

export default function ModuleCard({ title, icon, options, borderColor = false, customIcon = false, addNode, selectedNode }: ModuleProps) {
  const theme = useTheme();

  const handleOptionSelect = (option: string) => {
    addNode(option);
  };

  const colors = [
    theme.palette.text.highStatus,
    theme.palette.text.neutralStatus,
    theme.palette.text.extremeStatus,
    theme.palette.text.moderateStatus,
    theme.palette.text.primaryDark,
  ];

  return (
    <Box width="100%">
      <Box display="flex" gap={1} mb={1}>
        {icon}
        <Typography variant="h4" color={theme.palette.text.titleLabel}>
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          background: `linear-gradient(to right, ${theme.palette.primary.baseDisabled} 50%, transparent 0%) top repeat-x, linear-gradient(${theme.palette.primary.baseDisabled} 50%, transparent 0%) right repeat-y, linear-gradient(to right, ${theme.palette.primary.baseDisabled} 50%, transparent 0%) bottom repeat-x, linear-gradient(${theme.palette.primary.baseDisabled} 50%, transparent 0%) left repeat-y`,
          backgroundSize: "10px 1px, 1px 10px",
          borderRadius: 2,
          p: 2,
          height: "100%",
        }}
      >
        <Box display="flex" alignContent="baseline" sx={{ flexFlow: "wrap", gap: 1.5 }}>
          {options?.map((opt, idx) => {
            const labelColor = selectedNode.includes(opt) ? theme.palette.text.captionColor : theme.palette.primary.light;
            const iconStyle = { color: labelColor, fontSize: "0.8em", marginTop: "-3px" };

            return (
              <Box
                key={`opt-${idx}`}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.8,
                  border: borderColor ? `1px solid ${colors[idx % colors.length]}` : `1px solid ${theme.palette.text.tableHeader}`,
                  borderRadius: 5,
                  px: 1,
                  py: 1,
                  mb: 1.5,
                  background: selectedNode.includes(opt) ? theme.palette.additionalColors.border : theme.palette.background.dark,
                  cursor: "pointer",
                  pointerEvents: selectedNode.includes(opt) ? "none" : "auto",
                }}
                onClick={() => handleOptionSelect(opt)}
              >
                {!customIcon && <DiamondOutlined sx={iconStyle} />}
                {customIcon && opt === "Linkedin" && <FaLinkedin style={iconStyle} />}
                {customIcon && opt === "Treads" && <FaSquareThreads style={iconStyle} />}
                {customIcon && opt === "Twitter" && <FaTwitter style={iconStyle} />}
                {customIcon && opt === "Instagram" && <FaInstagramSquare style={iconStyle} />}
                {customIcon && opt === "Facebook" && <FaFacebookSquare style={iconStyle} />}
                {customIcon && opt === "Data Lake" && <FaArchive style={iconStyle} />}
                <Typography variant="subtitle1" color={labelColor}>
                  {opt}
                </Typography>
                <AddIcon
                  sx={{ color: selectedNode.includes(opt) ? theme.palette.text.captionColor : theme.palette.text.default, fontSize: "14px" }}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
