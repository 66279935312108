import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { RiShoppingBag3Fill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import BorderedCard from "../../components/BorderedCard";
import CustomDivider from "../../components/CustomDivider";
import CustomTertiaryButton from "../../components/CustomTertiaryButton";
import PrimaryLoadingButton from "../../components/PrimaryLoadingButton";
import { routes } from "../../routes";
import { useHandleRouteClick } from "../../routes/hooks";
import { AVAILABLE_AI_MODELS } from "../../utils/constants";

export default function AIModelDetail() {
  const theme = useTheme();
  const { aiModelId } = useParams();
  const handleRouteClick = useHandleRouteClick();
  const aiModel = AVAILABLE_AI_MODELS.find((aiModel) => String(aiModel.id) === aiModelId);

  const openCart = () => {
    handleRouteClick(routes.cartItems.path);
  };

  return (
    <Box display="flex" flexDirection="column" gap={0}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end" py={2} px={1}>
        <Stack direction="column" alignItems="flex-start" gap={1}>
          <Typography variant="h1">{aiModel?.title}</Typography>
          <BackButton onClick={() => handleRouteClick(routes.marketPlace.path, { activeTab: "ai_models" })}>Back to Solutions</BackButton>
        </Stack>
        <Box>
          <CustomTertiaryButton startIcon={<RiShoppingBag3Fill size={14} />} onClick={openCart}>
            Cart
          </CustomTertiaryButton>
        </Box>
      </Stack>
      <Box
        sx={{
          borderRadius: 2,
          // borderTop: "1px solid",
          //   borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
          //   background: theme.palette.dashboardContainer.containerBackground,
          background: theme.palette.background.paper,
          boxShadow: theme.palette.dashboardContainer.customCardInsetBoxShadow,
          p: 3,
          minHeight: "70vh",
        }}
      >
        <Box>
          <Typography variant="body2" color={theme.palette.primary.light}>
            Description
          </Typography>
          <CustomDivider />
          <Grid container spacing={2} my={2}>
            <Grid item xs={12} md={6}>
              <img width="100%" alt={String(aiModel?.id)} src="/solutions/aiModelDetail.png" />
            </Grid>
            <Grid item xs={12} md={6} display="flex" flexDirection="column" gap={2}>
              <Typography variant="h2" color={theme.palette.text.main}>
                {aiModel?.title}
              </Typography>
              <Typography variant="body4" color={theme.palette.text.captionColor}>
                Social media analysis enables organizations, businesses, and individuals to make data-driven decisions, improve marketing strategies,
                enhance customer engagement, identify opportunities or risks, and gain a deeper understanding of their target audience. Social media
                analysis enables organizations, businesses, and individuals to make data-driven decisions, improve marketing strategies, enhance
                customer engagement, identify opportunities or risks, and gain a deeper understanding of their target audience.
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 3, my: 2 }}>
                <Typography variant="h2">{aiModel?.price}</Typography>
                <PrimaryLoadingButton size="small" variant="contained" sx={{ ...theme.typography.bigButton }}>
                  Add To Cart
                </PrimaryLoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Typography variant="body2" color={theme.palette.primary.light}>
            Solution includes:
          </Typography>
          <CustomDivider />
          <Grid container spacing={2} my={2}>
            {aiModel?.solutions?.map((sol, idx) => {
              return (
                <Grid item xs={12} md={6} lg={4} key={`detail-${idx}`}>
                  <BorderedCard sx={{ height: "100%", display: "flex", flexDirection: "column", gap: 1.4, borderRadius: 2 }}>
                    <img width="100%" alt={String(sol.id)} src={sol.thumbnail} />
                    <Typography variant="h5" fontWeight={400}>
                      {sol.title}
                    </Typography>
                    <Typography variant="body4" color={theme.palette.text.captionColor}>
                      {sol.description}
                    </Typography>
                  </BorderedCard>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
