import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";
import PlaceIcon from "@mui/icons-material/Place";
import TopicIcon from "@mui/icons-material/Topic";
import BusinessIcon from "@mui/icons-material/Business";
import {
  Box,
  Button,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  capitalize,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { GrImage } from "react-icons/gr";
import { useAppSelector } from "../../../../../store/hooks";
import { ENTITIES_SUBTYPE, RISK_SCORE } from "../../../../../utils/constants";
import { sxTableCell, sxTableCellBody } from "../../../../../utils/sxStyle";
import EntityRadioFilterDropDown, { DataType } from "./EntityRadioFilterDropDown";

interface EntitiesTableOptions {
  [x: string]: any;
}

interface EntitiesTableProps {
  tableData: EntitiesTableOptions[];
  header: { id: string; label: string; searchEnable: boolean; type?: string; customIcon?: boolean; minWidth?: number }[];
  rows: number;
  pagination?: boolean;
  search?: boolean;
  editing?: boolean;
  type?: string;
  handleClick?(details: any): void | undefined;
}

export default function EntitiesTable({ tableData, header, rows, pagination, search, editing = false, type = "", handleClick }: EntitiesTableProps) {
  const theme = useTheme();

  const [page, setPage] = useState(1);
  const [searchValues, setSearchValues] = useState<{ [key: string]: string }>({});
  const { entitiesPage } = useAppSelector((state: any) => state.magicEdit);
  const { isUpdate, isMagicEdit, isCancel } = entitiesPage;
  const [updatedRiskScore, setUpdatedRiskScore] = useState<DataType | null>(null);
  const [updatedSubtype, setUpdatedSubtype] = useState<DataType | null>(null);
  const [capitalizedData, setCapitalizedData] = useState<EntitiesTableOptions[]>([]);
  console.log("Capitalized data :",capitalizedData)
  useEffect(() => {
    setPage(1);
  }, [tableData]);

  useEffect(() => {
    setUpdatedRiskScore(null);
    setUpdatedSubtype(null);
  }, [isMagicEdit, isCancel]);

  const handlePageChange = (e: any, value: number) => {
    setPage(value);
  };

  const handleSearchChange = (columnId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValues((prevSearchValues) => ({
      ...prevSearchValues,
      [columnId]: value,
    }));
  };

  useEffect(() => {
    const filteredData = tableData.filter((row: any) =>
      header.every((column) => {
        const searchValue = searchValues[column.id] || "";
        if (searchValue !== "") {
          return row[column.id]?.toLowerCase().includes(searchValue?.toLowerCase());
        } else {
          return row;
        }
      })
    );
    const capitalizedData = filteredData.map((row) => {
      const updatedRow: any = {};
      for (const key in row) {
        if (row.hasOwnProperty(key)) {
          updatedRow[key] = typeof row[key] === "string" && key !== "id" ? capitalize(row[key]) : row[key];
        }
      }
      return updatedRow;
    });
    setCapitalizedData(capitalizedData);
  }, [tableData, searchValues, header]);

  const btnStyle = (status: string) => {
    let statusField = status.toLowerCase();
    switch (statusField) {
      case "high":
        return {
          background: theme.palette.dashboardContainer.highStatusBg,
          color: theme.palette.text.highStatus,
          border: `1px solid ${theme.palette.text.highStatus}`,
        };
      case "neutral":
        return {
          background: theme.palette.dashboardContainer.neutralStatusBg,
          color: theme.palette.text.neutralStatus,
          border: `1px solid ${theme.palette.text.neutralStatus}`,
        };
      case "moderate":
        return {
          background: theme.palette.dashboardContainer.moderateStatusBg,
          color: theme.palette.text.moderateStatus,
          border: `1px solid ${theme.palette.text.moderateStatus}`,
        };
      case "extreme":
        return {
          background: theme.palette.dashboardContainer.extremeStatusBg,
          color: theme.palette.text.extremeStatus,
          border: `1px solid ${theme.palette.text.extremeStatus}`,
        };
      case "low":
        return {
          background: theme.palette.dashboardContainer.lightBgButton,
          color: theme.palette.text.lowStatus,
          border: `1px solid ${theme.palette.text.lowStatus}`,
        };
      case "default":
        break;
    }
  };

  const rowHandler = (row: any) => {
    if (handleClick) handleClick(row);
  };

  const iconStyle = {
    color: theme.palette.text.tableHeader,
    fontSize: "20px",
  };
  const handleValueChange = (value: string) => {
    // console.log("val", value);
  };
  const handleGetRiskScore = (data: DataType) => {
    setUpdatedRiskScore(data);
  };
  const handleGetSubType = (data: DataType) => {
    setUpdatedSubtype(data);
  };

  // Temporarily not showing dot
  const shouldShowDot = (row: any) => /*parseInt(row.id[row.id.length - 1]) % 2 === 0*/ false;

  const CustomIcon = (type: string) => {
    return (
      <Box
        sx={{
          width: "35px",
          minWidth: "35px",
          height: "35px",
          borderRadius: "50%",
          background: theme.palette.primary.tertiaryGradient,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {type === "Person" && <PersonIcon sx={iconStyle} />}
        {type === "Event" && <CalendarTodayIcon sx={iconStyle} />}
        {type === "Topic" && <TopicIcon sx={iconStyle} />}
        {type === "Organisation" && <BusinessIcon sx={iconStyle} />}
        {type === "Location" && <PlaceIcon sx={iconStyle} />}
        {!type && <GrImage style={{ ...iconStyle, fontSize: "18px" }} />}
      </Box>
    );
  };

  return (
    <Box>
      <Box
        sx={{
          background: theme.palette.background.darkCardBackground,
          border: theme.palette.additionalColors.databasecardBorder,
          px: 2,
          borderRadius: "8px",
        }}
      >
        <TableContainer sx={{ width: "100%" }}>
          <Table stickyHeader aria-label="sticky table" sx={{ backgroundColor: theme.palette.background.paper }}>
            <TableHead>
              <TableRow sx={{ fontSize: "14px" }}>
                {header.map((column, idx) => {
                  return (
                    <TableCell sx={{ ...sxTableCell, minWidth: column?.minWidth }} key={`gaurd-${idx}`}>
                      {column?.label}
                      <br />
                      {search && (
                        <TextField
                          variant="outlined"
                          size="small"
                          value={searchValues[column.id] || ""}
                          onChange={handleSearchChange(column.id)}
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              padding: 0,
                              border: `1px solid ${theme.palette.text.tableHeader}`,
                              borderRadius: "4px",
                            },
                          }}
                          disabled={!column?.searchEnable}
                          InputProps={{
                            sx: {
                              "&:hover fieldset": {
                                border: `1px solid ${theme.palette.text.tableHeader}!important`,
                              },
                              "&:focus-within fieldset, &:focus-visible fieldset": {
                                border: `1px solid ${theme.palette.text.tableHeader}!important`,
                              },
                            },
                          }}
                        />
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {capitalizedData &&
                capitalizedData.length > 0 &&
                capitalizedData.slice((page - 1) * rows, page * rows).map((row: any, idx) => (
                  <TableRow key={`tableData-${idx}`} sx={{ cursor: "pointer" }} onClick={() => rowHandler(row)}>
                    {header.map((column) => {
                      let btnStyleData = column?.type === "button" && btnStyle(row?.threat||"high");
                      return (
                        <TableCell sx={{ ...sxTableCellBody, color: theme.palette.text.titleLabel }} key={column.id}>
                          {column?.type ? (
                            editing ? (
                              <EntityRadioFilterDropDown
                                options={RISK_SCORE}
                                placeholder="Select Search Area"
                                onChange={handleValueChange}
                                sx={{ width: "75%" }}
                                endIcon={true}
                                textColor={theme.palette.text.titleLabel}
                                pageChange={page}
                                typeChange={type}
                                getValue={handleGetRiskScore}
                              />
                            ) : (
                              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", ml: shouldShowDot(row) ? "-18px" : "0px" }}>
                                {shouldShowDot(row) && (
                                  <Box
                                    sx={{ width: "8px", height: "8px", borderRadius: "50%", background: theme.palette.text.neutralStatus, mr: 1 }}
                                  />
                                )}
                                <Button
                                  sx={{
                                    textTransform: "capitalize",
                                    ...btnStyleData,
                                    width: "88px",
                                    height: "24px",
                                  }}
                                >
                                  {row?.threat||"high"}
                                </Button>
                              </Box>
                            )
                          ) : column?.customIcon ? (
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                              }}
                            >
                              {CustomIcon(row?.type)}
                              <span style={{ ...theme.typography.subtitle1, color: theme.palette.text.titleLabel, marginTop: "10px" }}>
                                {row[column.id]}
                              </span>
                            </Box>
                          ) : column?.id === "subtype" ? (
                            editing ? (
                              <EntityRadioFilterDropDown
                                options={ENTITIES_SUBTYPE}
                                column={column?.id}
                                placeholder="Select Search Area"
                                onChange={handleValueChange}
                                sx={{ width: "75%" }}
                                endIcon={true}
                                textColor={theme.palette.text.titleLabel}
                                pageChange={page}
                                typeChange={type}
                                getValue={handleGetSubType}
                              />
                            ) : (
                              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", ml: shouldShowDot(row) ? "-15.5px" : "0px" }}>
                                {shouldShowDot(row) && (
                                  <Box
                                    sx={{ width: "8px", height: "8px", borderRadius: "50%", background: theme.palette.text.neutralStatus, mr: 1 }}
                                  />
                                )}
                                <Typography variant="subtitle1" color={theme.palette.text.titleLabel}>
                                  {row[column.id] ? row[column.id] : "--"}
                                </Typography>
                              </Box>
                            )
                          ) : (
                            <Typography variant="subtitle1" color={theme.palette.text.titleLabel}>
                              {!row[column.id] || row[column.id] === " " ? "--" : row[column.id]}
                            </Typography>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              {capitalizedData && capitalizedData.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={Object.keys(header).length}
                    align="center"
                    sx={{
                      p: 10,
                      "&.MuiTableCell-root": {
                        borderBottom: "none",
                      },
                    }}
                  >
                    No Data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {pagination && (
        <Grid container spacing={2} px={4} py={2}>
          <Box
            sx={{
              mt: 5,
              mx: "auto",
              mr: "0px",
            }}
          >
            {capitalizedData.length / rows >= 1 && (
              <Pagination
                onChange={handlePageChange}
                page={page}
                count={Math.ceil(capitalizedData.length / rows)}
                size="small"
                sx={(theme) => ({
                  ...theme.palette.pagination,
                })}
              />
            )}
          </Box>
        </Grid>
      )}
    </Box>
  );
}
