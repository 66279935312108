import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import TokenService from "../../../axios/tokenservice";
import BorderedCard from "../../../components/BorderedCard";
import CustomDivider from "../../../components/CustomDivider";
import CustomSecondaryButton from "../../../components/CustomSecondaryButton";
import SubmitLoadingDeleteButton from "../../../components/SubmitLoadingDeleteButton";
import { logout } from "../../../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { deleteUser } from "../../../store/users/usersThunk";
import AccountDeleteModal from "./AccountDeleteModal";
import LogOutModal from "./LogOutModal";
import { toast } from "react-toastify";
export default function AccountManagement() {
  const theme = useTheme();
  const { isLoading } = useAppSelector((state) => state.auth);
  const {currentUser} = useAppSelector(state=>state.auth)
  //console.log(currentUser)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openLogOutDialog, setOpenLogOutDialog] = useState(false);


  const dispatch = useAppDispatch();


  const handleDelete = () => {
    setOpenDeleteDialog((prev) => !prev);
      const currentUser = TokenService.getCurrentUser();
      if (currentUser.is_admin){
        toast.info('Admin cannot delete its own account.')
        return 
      }
      
      const user_id = currentUser.id;
      console.log(user_id);

      dispatch(deleteUser(user_id));
  };
  const handleLogout = () => {
    dispatch(logout());
  }

  const toggleDeleteDialog = () => {
    setOpenDeleteDialog((prev) => !prev);
  };
  
  const toggleLogOutDialog = () => {
    setOpenLogOutDialog((prev) => !prev);
  };
  return (
    <BorderedCard title="Account Management">
      <Grid container spacing={2} my={2}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="subtitle1" color={theme.palette.text.titleLabel}>
              Log Out
            </Typography>
            <Typography variant="body6" color={theme.palette.text.subText}>
              You can safely log out from the current account and log back in later.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display="flex" justifyContent="flex-end">
          {!openLogOutDialog ? (
          <CustomSecondaryButton 
            sx={{ py: 0.5, width: "8rem" }}
            onClick={toggleLogOutDialog}
          >Log Out</CustomSecondaryButton>
          ):(
            <LogOutModal open={openLogOutDialog} handleToggle={toggleLogOutDialog} handleLogout={handleLogout}/>
          )}
        </Grid>
      </Grid>
      <CustomDivider />
      <Grid container spacing={2} my={2}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="subtitle1" color={theme.palette.text.titleLabel}>
              Account Deletion
            </Typography>
            <Typography variant="body6" color={theme.palette.text.subText}>
              By deleting your account, you delete all associated data, included all workspaces. You will not be able to reactivate this account
            </Typography>
          </Box>
        </Grid>
        
          {!openDeleteDialog ? (
          <Grid item xs={12} md={6} display="flex" justifyContent="flex-end">
            <Box>
              <SubmitLoadingDeleteButton
                isLoading={isLoading}
                onClick={toggleDeleteDialog}
                sx={{ py: 0.5, width: "8rem", background: theme.palette.error.text, color: theme.palette.error.btnText }}
              >
                Delete Account
              </SubmitLoadingDeleteButton>
            </Box>
          </Grid>
          ):(
            <AccountDeleteModal open={openDeleteDialog} handleToggle={toggleDeleteDialog} handleDelete={handleDelete}/>
          )}
        
      </Grid>
    </BorderedCard>
  );
}
