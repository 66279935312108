import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import TokenService from "../../axios/tokenservice";
import { analysisActions } from "../../store/analysis/analysisSlice";
import { getLatestQuery, getQueryById } from "../../store/analysis/analysisThunk";
import { getMyDetail } from "../../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { BASE_HOST, BASE_PROTOCOL_WS } from "../../utils/constants";
import SideBar from "./components/Sidebar";

export interface DashboardBaseLayoutProps {
  children?: React.ReactNode;
}

export default function DashboardBaseLayout({ children }: DashboardBaseLayoutProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const currentUser = TokenService.getCurrentUser();
  const { query } = useAppSelector((state: Record<string, any>) => state.analysisQuery);

  React.useEffect(() => {
    if ((currentUser === null || Object.keys(currentUser).length) === 0) {
      dispatch(getMyDetail());
    }
  }, []);

  useEffect(() => {
    if (query && Object.keys(query).length === 0) {
      dispatch(getLatestQuery())
        .then((res) => {
          if (res.payload.status === 200 && res.payload.data._id !== undefined) {
            dispatch(getQueryById(res?.payload?.data?._id));
          }
        })
        .catch((e) => toast.error("Query detail failed"));
    }
  }, []);

  React.useEffect(() => {
    const chatSocket = new WebSocket(`${BASE_PROTOCOL_WS}://${BASE_HOST}/ws/query/6557224424b2bce62fd3130f/`);

    chatSocket.onopen = () => {
      console.log("Connected to websocket");
    };

    chatSocket.onmessage = function (e) {
      const data = JSON.parse(e.data);
      if (data["type"] === "analytics_data") {
        if ("crawl_data" in data["message"]) {
          const analyticsData = data["message"]["crawl_data"];
          analysisActions.updateAnalyticsData(analyticsData);
        }
      }
    };

    chatSocket.onclose = function (e) {
      console.error("Chat socket closed unexpectedly", e);
    };

    chatSocket.onerror = (err: any) => {
      console.error("Socket encountered error: ", err.message, "Closing socket");
      chatSocket.close();
    };

    return () => {
      chatSocket.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <SideBar />
      <Box
        component="main"
        sx={{
          maxHeight: "96vh",
          overflowY: "auto",
          my: 1.5,
          mr: 1.5,
          p: 2,
          pb: "4rem",
          flexGrow: 1,
          borderRadius: "29px 29px 29px 29px",
          overflow: "hidden",
          background: theme.palette.dashboardContainer.backround,
          boxShadow: theme.palette.dashboardContainer.boxShadow,
          border: `2px solid ${theme.palette.dashboardContainer.borderColor}`,
          borderLeft: "none",
          position: "relative",
          [theme.breakpoints.down("md")]: {
            background: "none",
            boxShadow: "none",
            border: "none",
            maxWidth: "100%",
            p: 0,
            pb: "4rem",
            marginTop: "70px",
            marginLeft: "10px",
          },
        }}
      >
        {/* <Box
          sx={{
            position: "absolute",
            width: "11px",
            borderRadius: "50%",
            borderLeft: `2px solid ${theme.palette.additionalColors.border1}`,
            zIndex: "1",
            rotate: "38deg",
            top: "-2px",
            left: "3px",
            height: "32px",
            boxShadow: `10px 10px 0 ${theme.palette.dashboardContainer.firstThemeColor}`,
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          }}
        ></Box> */}
        <Container maxWidth={false} disableGutters sx={{ margin: "0", height: "100%" }}>
          {children}
        </Container>
      </Box>
    </Box>
  );
}
