import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PersonIcon from "@mui/icons-material/Person";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Box, Grid, Paper, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { TfiExport } from "react-icons/tfi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../../../components/BackButton";
import CustomIconButton from "../../../../components/CustomIconButton";
import CustomSecondaryButton from "../../../../components/CustomSecondaryButton";
import PrimaryLoadingButton from "../../../../components/PrimaryLoadingButton";
import { routes } from "../../../../routes";
import { useHandleRouteClick } from "../../../../routes/hooks";
import { getUserProfileDetail } from "../../../../store/analysis/analysisThunk";
import { useAppDispatch } from "../../../../store/hooks";
import { InfoDetails, entityIconSx } from "../Entities/SpecificEntity/components/EntityInfo";

interface InfluencerDetailProps {
  isDialog?: boolean;
  userId?: string;
}

export default function InfluencerDetail({ isDialog = false, userId = "" }: InfluencerDetailProps) {
  const theme = useTheme();
  const params = useParams();
  const dispatch = useAppDispatch();
  const handleRouteClick = useHandleRouteClick();
  const [infoData, setInfoData] = useState<InfoDetails>({});

  const backHandler = () => {
    handleRouteClick(`${routes.home.path}?tab=analysis&subtab=entities`);
  };

  console.log(infoData);
  useEffect(() => {
    const id = params.id ? params.id : userId;
    if (id) {
      dispatch(getUserProfileDetail(id))
        .then((res) => {
          if (res?.payload?.status === 200) {
            setInfoData(res?.payload?.data);
          } else {
            toast.error("Data fetch failed");
          }
        })
        .catch((e) => {
          toast.error("Data fetch failed");
        });
    }
  }, [params, userId]);

  return (
    <Grid container sx={{ position: "relative" }}>
      <Grid item xs={12}>
        {!isDialog && (
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "left",
                padding: "5px",
              }}
            >
              <Typography variant="h3" fontWeight="bold" sx={{ marginLeft: "10px", color: theme.palette.text.titleLabel }} display={"flex"}>
                {infoData?.name}
              </Typography>
              <BackButton onClick={backHandler} sx={{ pl: 1 }} />
            </Box>
            <Box display="flex" gap={3} px={1} height="40px">
              <PrimaryLoadingButton
                startIcon={<AutoFixHighIcon />}
                sx={{
                  ...theme.typography.bigButton,
                }}
              >
                Magic Edit
              </PrimaryLoadingButton>
              <CustomSecondaryButton
                variant="contained"
                sx={{
                  ...theme.typography.h6,
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
                startIcon={<TfiExport style={{ width: "12px", height: "12px", marginBottom: "5px" }} />}
              >
                Export Report
              </CustomSecondaryButton>
            </Box>
          </Box>
        )}

        <Paper
          sx={{
            background: theme.palette.background.default,
            boxShadow: theme.palette.dashboardContainer.insetBoxShadow,
            borderRadius: { md: "18px" },
            padding: { xs: "20px 10px", md: "35px" },
            height: "auto",
            overflowY: "scroll",
          }}
        >
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Box width={{ xs: "74%", sm: "74%", md: "87%", lg: "80%", xl: "50%" }}>
                  <Box display="flex" gap={2} mb={1.5}>
                    <CustomIconButton
                      sx={{
                        width: "48px",
                        height: "48px",
                        borderRadius: "50%",
                        border: "none",
                        background: theme.palette.primary.tertiaryGradient,
                        boxShadow: "none",
                        pointerEvents: "none",
                      }}
                    >
                      <PersonIcon sx={entityIconSx(theme)} />
                    </CustomIconButton>
                    <Typography variant="h2" color={theme.palette.text.titleLabel} mt={1.6}>
                      {infoData?.name}
                    </Typography>
                  </Box>
                  <Typography variant="body3" color={theme.palette.text.titleLabel}>
                    {infoData?.description}
                  </Typography>
                  <Box display="flex" my={3} gap={1}>
                    <TwitterIcon sx={{ color: theme.palette.primary.main }} />
                    <LinkedInIcon sx={{ color: theme.palette.primary.main }} />
                    <FacebookIcon sx={{ color: theme.palette.primary.main }} />
                  </Box>
                  {/* <Box display="flex" gap={2}>
                    {extraInfoData?.tag.map((data: string) => (
                      <Box
                        key={data}
                        sx={{
                          backgroundColor: theme.palette.primary.tertiary,
                          color: theme.palette.error.btnText,
                          px: 0.6,
                          borderRadius: "4px",
                        }}
                      >
                        <Typography variant="body5" color="#F5F9FF" sx={{ lineHeight: "18px" }}>
                          {data}
                        </Typography>
                      </Box>
                    ))}
                  </Box> */}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ borderRadius: 2, float: "right" }}>
                  <img src={infoData?.profile_image} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
