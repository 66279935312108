import { createSlice } from "@reduxjs/toolkit";

import {
  activateUser,
  addUserToWorkspace,
  createWorkspace,
  deactivateUser,
  deleteUserFromWorkspace,
  deleteWorkspace,
  getAllWorkspaceUsers,
  getWorkspaceDetail,
  getWorkspaceUser,
  getWorkspaces,
  removeAdmin,
  setAdmin,
  updateUserProfile,
  updateWorkspace,
} from "./workspaceThunk";

export interface WorkspaceInitialState {
  isLoading: boolean;
  workspaces: Record<string, any>[];
  workspaceUsers: Record<string, any>[];
}

const workspaceInitialState: WorkspaceInitialState = {
  isLoading: false,
  workspaces: [],
  workspaceUsers: [],
};

const workspaceSlice = createSlice({
  name: "workspace",
  initialState: workspaceInitialState,
  reducers: {},
  extraReducers: (builder) => {
    //List all Workspaces
    builder
      .addCase(getWorkspaces.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getWorkspaces.fulfilled, (state, action) => {
        state.isLoading = false;
        state.workspaces = action.payload.data;
      })
      .addCase(getWorkspaces.rejected, (state, action) => {
        state.isLoading = false;
      });

    //Get a user of a workspace
    builder
      .addCase(getWorkspaceUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getWorkspaceUser.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getWorkspaceUser.rejected, (state, action) => {
        state.isLoading = false;
      });

    // List all workspace Users
    builder
      .addCase(getAllWorkspaceUsers.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllWorkspaceUsers.fulfilled, (state, action) => {
        state.workspaceUsers = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getAllWorkspaceUsers.rejected, (state, action) => {
        state.isLoading = false;
      });
    //Add User to a Workspace
    builder
      .addCase(addUserToWorkspace.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addUserToWorkspace.fulfilled, (state, action) => {
        state.workspaceUsers = [...state.workspaceUsers, action.payload.data];
        state.isLoading = false;
      })
      .addCase(addUserToWorkspace.rejected, (state, action) => {
        state.isLoading = false;
      });

    //Update User Profile in Workpace
    builder
      .addCase(updateUserProfile.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        const user = state.workspaceUsers.filter((w) => w.id !== action.payload.data.id);
        state.workspaceUsers = [...user, action.payload.data];
        state.isLoading = false;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.isLoading = false;
      });
    //Create Workspace
    builder
      .addCase(createWorkspace.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createWorkspace.fulfilled, (state, action) => {
        state.workspaces = [...state.workspaces, action.payload.data];
        state.isLoading = false;
      })
      .addCase(createWorkspace.rejected, (state, action) => {
        state.isLoading = false;
      });
    //Update Workspace
    builder
      .addCase(updateWorkspace.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateWorkspace.fulfilled, (state, action) => {
        const workspace = state.workspaces.filter((w) => w.id === action.payload.data.id);
        //workspace[0].name = action.payload.data.name;
        state.isLoading = false;
      })
      .addCase(updateWorkspace.rejected, (state, action) => {
        state.isLoading = false;
      });
    //Delete Workspace
    builder
      .addCase(deleteWorkspace.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteWorkspace.fulfilled, (state, action) => {
        state.isLoading = false;
        state.workspaces = state.workspaces.filter((w) => w.id !== action.payload.id);
      })
      .addCase(deleteWorkspace.rejected, (state, action) => {
        state.isLoading = false;
      });

    // delete user from workspace
    builder
      .addCase(deleteUserFromWorkspace.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteUserFromWorkspace.fulfilled, (state, action) => {
        state.isLoading = false;
        state.workspaceUsers = state.workspaceUsers.map((w) => {
          if (w.id === action.payload.id) {
            return {
              ...w,
              is_active: false,
            };
          }
          return w;
        });
      })
      .addCase(deleteUserFromWorkspace.rejected, (state, action) => {
        state.isLoading = false;
      });

    // deactivate user from workspace
    builder
      .addCase(deactivateUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deactivateUser.fulfilled, (state, action) => {
        state.isLoading = false;

        state.workspaceUsers = state.workspaceUsers.map((w) => {
          if (w.id === action.payload) {
            return {
              ...w,
              is_active: false,
            };
          }
          return w;
        });
      })
      .addCase(deactivateUser.rejected, (state, action) => {
        state.isLoading = false;
      });

    // activate user from workspace
    builder
      .addCase(activateUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(activateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.workspaceUsers = state.workspaceUsers.map((w) => {
          if (w.id === action.payload) {
            return {
              ...w,
              is_active: true,
            };
          }
          return w;
        });
      })
      .addCase(activateUser.rejected, (state, action) => {
        state.isLoading = false;
      });

    // set admin
    builder
      .addCase(setAdmin.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(setAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log("action.payload", action.payload);
      })
      .addCase(setAdmin.rejected, (state, action) => {
        state.isLoading = false;
      });

    // remove admin
    builder
      .addCase(removeAdmin.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(removeAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log("action.payload", action.payload);
      })
      .addCase(removeAdmin.rejected, (state, action) => {
        state.isLoading = false;
      });

    // get workspace Detail
    builder
      .addCase(getWorkspaceDetail.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getWorkspaceDetail.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getWorkspaceDetail.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const workspaceAction = workspaceSlice.actions;

export default workspaceSlice;
