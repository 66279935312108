import { createSlice } from "@reduxjs/toolkit";
import { getEntities, getEntityDetailById } from "./analysisThunk";

export interface EntitiesType {
  id: {};
  key: string;
  name: string;
  type: string;
  subtype: string;
  description: null | string;
  location?: {
    latitude: number;
    longitude: number;
  };
}

export interface EntitiesInitialStateType {
  isLoading: boolean;
  allEntities: EntitiesType[];
}

const entitiesInitialState: EntitiesInitialStateType = {
  isLoading: false,
  allEntities: [],
};

export const entitiesSlice = createSlice({
  name: "entities",
  initialState: entitiesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEntities.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allEntities = action?.payload?.data;
      })
      .addCase(getEntities.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(getEntityDetailById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getEntityDetailById.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getEntityDetailById.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const entitiesActions = entitiesSlice.actions;
export default entitiesSlice;
