import { Box, Grid, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomFilterDrawer from "../../../../components/CustomFilterDrawer";
import { analysisQueryActions } from "../../../../store/analysis/analysisQuerySlice";
import { getQueryResult } from "../../../../store/analysis/analysisThunk";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { LCP_CORE_SERVICE_BASE_URL } from "../../../../utils/constants";
import Audio from "./components/Audios";
import FilterSection from "./components/FilterSection";
import Images from "./components/Images";
import Publication from "./components/Publications";
import Videos from "./components/Videos";

interface FeedSectionProps {
  isRefresh: boolean;
  removePageRefresh(): void;
}

export default function FeedSection({ isRefresh, removePageRefresh }: FeedSectionProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [params] = useSearchParams();

  const { feedFilter } = useAppSelector((state: any) => state.analysisQuery);
  const { query } = useAppSelector((state) => state.analysisQuery);

  const [activeTab, setActiveTab] = useState(state?.activeTab || "publication");
  const [view, setView] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [publicationData, setPublicationData] = useState<Record<string, any>>({});
  // const [videosFeed , setVidoesFeed] = useState<any>([]);

  const [loading, setLoading] = useState(true);
  const queryId = params.get("query") || query?.id;

  const getQueryData = (queryId: string) => {
    // dispatch(getQueryResult({ queryId, pageSize: 5, page: 1 })) // Uncomment this line to enable pagination
    dispatch(getQueryResult({ queryId }))
      .then((res) => {
        setLoading(false);
        if (res?.payload?.status === 200) {
          setPublicationData(res?.payload?.data);
          // console.log("publicationData", res.payload.data);
          removePageRefresh();
        } else {
          toast.error("Failed to get the data.");
        }
      })
      .catch((e) => {
        toast.error("Failed to get the data.");
        setLoading(false);
      });
  };

  const handleLoadMoreQueryData = () => {
    dispatch(getQueryResult({ nextPage: publicationData?.next_page.replace("http://lcpproto:8000", LCP_CORE_SERVICE_BASE_URL) }))
      .then((res) => {
        setLoading(false);
        if (res?.payload?.status === 200) {
          setPublicationData((prev) => {
            return { ...prev, ...res?.payload?.data, results: [...prev.results, ...res?.payload?.data?.results] };
          });
          removePageRefresh();
          // console.log("publicationData", res.payload.data);
        } else {
          toast.error("Failed to get the data.");
        }
      })
      .catch((e) => {
        toast.error("Failed to get the data.");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (queryId) {
      getQueryData(queryId);
    }
  }, [queryId]);

  useEffect(() => {
    if (queryId && isRefresh) {
      getQueryData(queryId);
    }
  }, [isRefresh]);

  // useEffect(() => {
  //   if (publicationData?.results?.length > 0) {
      
  //     // const videosData = publicationData?.results?.filter((feed: any) => feed?.videos !== null);
  //     // const imagesData = publicationData?.results?.flatMap((item: any) => (item.images ? item.images : []));
  //     // setVidoesFeed(videosData);
  //   }
  // }, [publicationData?.results]);

  const tabs = [
    {
      id: "publication",
      title: "Publications",
      disabled: false,
      component: (
        <Publication
          view={view}
          feedData={publicationData}
          loading={loading}
          drawerOpen={drawerOpen}
          isRefresh={isRefresh}
          loadMore={handleLoadMoreQueryData}
        />
      ),
    },
    {
      id: "image",
      title: "Images",
      disabled: false,
      component: <Images drawerOpen={drawerOpen} isRefresh={isRefresh} feedData={publicationData?.results} loading={loading} />,
    },
    {
      id: "video",
      title: "Videos",
      disabled: false,
      component: <Videos drawerOpen={drawerOpen} isRefresh={isRefresh} removePageRefresh={removePageRefresh} />,
    },
    {
      id: "audio",
      title: "Audios",
      disabled: false,
      component: <Audio drawerOpen={drawerOpen} isRefresh={isRefresh} removePageRefresh={removePageRefresh} />,
    },
  ];

  const getActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  const getView = (viewTab: boolean) => {
    setView(viewTab);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen((prev) => !prev);
  };

  const handleFilterApply = (data: any) => {
    dispatch(analysisQueryActions.addFeedFilter(data));
  };

  const handleReset = (data: any) => {
    dispatch(analysisQueryActions.addFeedFilter(data));
  };

  return (
    <Box sx={{ borderTop: "1px solid", borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1` }}>
      <Grid container spacing={2}>
        {drawerOpen && (
          <Grid item xs={5} lg={3}>
            <CustomFilterDrawer
              filterData={feedFilter}
              openDrawer={drawerOpen}
              closePlayCamerasDrawer={handleDrawerOpen}
              handleFilterApply={handleFilterApply}
              handleReset={handleReset}
            />
          </Grid>
        )}
        <Grid item xs={drawerOpen ? 7 : 12} lg={drawerOpen ? 9 : 12}>
          <FilterSection tabs={tabs} getActiveTab={getActiveTab} getView={getView} handleDrawerOpen={handleDrawerOpen} openDrawer={drawerOpen} />
          <Box>{tabs.find((tab) => tab.id === activeTab)?.component}</Box>
        </Grid>
      </Grid>
    </Box>
  );
}
