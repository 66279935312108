import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, FormControl, Typography, useTheme } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import CustomIconButton from "../../../components/CustomIconButton";
import CustomInputField from "../../../components/CustomInputField";
import PrimaryLoadingButton from "../../../components/PrimaryLoadingButton";
import { changePassword } from "../../../store/auth/authThunk";
import { useAppDispatch } from "../../../store/hooks";

export interface FormDataType {
  old_password: string;
  new_password1: string;
  new_password2: string;
}

const initialFormData: FormDataType = {
  old_password: "",
  new_password1: "",
  new_password2: "",
};

export interface ChangePasswordDialogProps {
  open: boolean;
  handleToggle: () => void;
}

const ChangePasswordDialog = ({ open, handleToggle }: ChangePasswordDialogProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [formData, setFormData] = React.useState(initialFormData);

  const handleInputChange = ({ fieldName, value }: { fieldName: string; value: any }) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const resetFormState = () => {
    setFormData(initialFormData);
    handleToggle();
  };

  const handleSubmit = () => {
    dispatch(changePassword(formData))
      .then((res) => {
        if (res.payload.status === 200) {
          resetFormState();
          toast.success("Password changed successfully!");
        } else {
          toast.error("Change password failed!");
        }
      })
      .catch((e) => toast.error("Change password failed!"));
  };

  return (
    <Dialog
      onClose={resetFormState}
      open={open}
      maxWidth="sm"
      PaperProps={{
        sx: {
          background: theme.palette.background.gradientLight1,
          borderRadius: "8px",
          py: 4,
          px: 3,
          width: "100%",
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography variant="h2" m="auto" color={theme.palette.text.titleLabel}>
          Change Password
        </Typography>
        <CustomIconButton onClick={handleToggle} sx={{ color: theme.palette.error.main }}>
          <CloseIcon />
        </CustomIconButton>
      </Box>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <FormControl fullWidth>
            <Typography variant="caption2" color={theme.palette.text.captionColor}>
              Password
            </Typography>
            <CustomInputField
              value={formData.old_password}
              required
              type="password"
              name="old_password"
              placeholder="Enter Current Password"
              size="small"
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange({ fieldName: e.target.name, value: e.target.value })}
              autoComplete="off"
            />
          </FormControl>
          <FormControl fullWidth>
            <Typography variant="caption2" color={theme.palette.text.captionColor}>
              New Password
            </Typography>
            <CustomInputField
              value={formData.new_password1}
              required
              type="password"
              name="new_password1"
              placeholder="Enter New Password"
              size="small"
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange({ fieldName: e.target.name, value: e.target.value })}
              autoComplete="off"
            />
          </FormControl>
          <FormControl fullWidth>
            <Typography variant="caption2" color={theme.palette.text.captionColor}>
              Confirm New Password
            </Typography>
            <CustomInputField
              value={formData.new_password2}
              required
              type="password"
              name="new_password2"
              placeholder="Enter confirm Password"
              size="small"
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange({ fieldName: e.target.name, value: e.target.value })}
              autoComplete="off"
            />
          </FormControl>
        </Box>
        <Box my={3} textAlign="center">
          <PrimaryLoadingButton sx={{ ...theme.typography.bigButton }} onClick={handleSubmit} isLoading={false}>
            Change Password
          </PrimaryLoadingButton>
        </Box>
        <Typography variant="subtitle2" mt={2} textAlign="center" color={theme.palette.text.titleLabel}>
          Have any isues?
          <NavLink
            to="/login"
            style={{
              color: theme.palette.primary.main,
              textDecoration: "none",
              fontWeight: theme.typography.fontWeightMedium,
              marginLeft: 4,
            }}
          >
            Contact Us
          </NavLink>
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordDialog;
