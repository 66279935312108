import { Dialog, DialogContent, useTheme } from "@mui/material";

interface LinkPopoverProps {
  children?: React.ReactNode;
  open: boolean;
  coords: number[];
  handleClose: () => void;
}

const ConnectionsPopover = ({ children, open, coords, handleClose }: LinkPopoverProps) => {
  const theme = useTheme();
  let topAdjustedCoord = coords[1] - 70;
  let leftAdjustedCoord = coords[0] - 30;

  return (
    <Dialog
      open={open}
      hideBackdrop
      onClose={handleClose}
      PaperProps={{
        sx: {
          background: (theme) => theme.palette.background.dark,
          borderRadius: "4px",
          textAlign: "center",
          border: theme.palette.dashboardContainer.customListBorder,
          p: 0.5,
          position: "absolute",
          top: topAdjustedCoord,
          left: leftAdjustedCoord,
        },
      }}
    >
      <DialogContent sx={{ p: 0.5 }}>{children}</DialogContent>
    </Dialog>
  );
};

export default ConnectionsPopover;
