import { Box, Typography, useTheme } from "@mui/material";
import CurrentPlanDetails from "./CurrentPlanDetails";
import OtherPlans from "./OtherPlans";

export default function PlanSection() {
  const theme = useTheme();

  return (
    <Box>
      <Typography variant="h2" mb={3} sx={{ color: theme.palette.text.titleLabel }}>
        Plan
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <CurrentPlanDetails />
        <OtherPlans />
      </Box>
    </Box>
  );
}
