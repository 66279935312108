import BusinessIcon from "@mui/icons-material/Business";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PersonIcon from "@mui/icons-material/Person";
import PlaceIcon from "@mui/icons-material/Place";
import TopicIcon from "@mui/icons-material/Topic";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Box, Grid, Typography, capitalize, useMediaQuery, useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import GoogleMapReact from "google-map-react";
import { GrImage } from "react-icons/gr";
import CustomIconButton from "../../../../../../components/CustomIconButton";
import { GOOGLE_MAP_REACT_API_KEY } from "../../../../../../utils/constants";

export interface InfoDetails {
  [x: string]: any;
}

const extraInfoData = {
  id: 1,
  created_at: "13.04.2023 14:07",
  name: "Anne Thompson",
  media_image: "/assets/entityuser.png",
  social_media_link: ["https://twitter.com/", "https://in.linkedin.com/", "https://www.facebook.com/"],
  description:
    "Anne Thompson is an American journalist, working for NBC News as Chief Environmental Affairs correspondent. She covers the Catholic Church and environmental and economic issues.",
  relevance: "97%",
  authenticity: "97%",
  tag: ["Influencer", "Non-official"],
  category: "social_media",
  location: "Atlanta, Georgia, USA",
};

export const entityIconSx = (theme: Theme) => {
  return {
    color: theme.palette.text.tableHeader,
    fontSize: "20px",
  };
};

interface CustomMarkerIconProps {
  [x: string]: any;
}

const CustomMarkerIcon = (props: CustomMarkerIconProps) => {
  console.log("props", props);
  const theme = useTheme();
  return (
    <Box>
      <PlaceIcon sx={{ color: theme.palette.primary.main }} />
    </Box>
  );
};

function createMapOptions(maps: any) {
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
      style: maps.ZoomControlStyle.SMALL,
    },
    zoomControl: false,
    fullscreenControl: false,
  };
}

export default function EntityInfo({ infoData }: InfoDetails) {
  const theme = useTheme();

  console.log("infoData", infoData);

  const imgSize = useMediaQuery("(min-width:1181px)") ? 138 : 80;

  const renderEntityIcon = (type: string) => {
    switch (type) {
      case "person":
        return <PersonIcon sx={entityIconSx(theme)} />;
      case "event":
        return <CalendarTodayIcon sx={entityIconSx(theme)} />;
      case "topic":
        return <TopicIcon sx={entityIconSx(theme)} />;
      case "organization":
        return <BusinessIcon sx={entityIconSx(theme)} />;
      case "location":
        return <PlaceIcon sx={entityIconSx(theme)} />;
      default:
        return <GrImage style={{ ...entityIconSx(theme), fontSize: "18px" }} />;
    }
  };

  const renderMarkers = (map: any, maps: any) => {
    const markerInstance = new maps.Marker({
      position: { lat: infoData?.location?.latitude, lng: infoData?.location?.longitude },
      icon: <PlaceIcon />,
      map,
      // title: "H",
      // label: {
      //   fontSize: "12px",
      //   color: theme.palette.text.nodeText,
      //   text: infoData?.location?.name,
      // },
      cursor: "pointer",
    });
    return [markerInstance];
  };

  const defaultProps = {
    center: {
      lat: infoData?.location?.latitude,
      lng: infoData?.location?.longitude,
    },
    zoom: 1,
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={9} md={9}>
          <Box width={{ xs: "74%", sm: "74%", md: "87%", lg: "80%", xl: "50%" }}>
            <Box display="flex" gap={2} mb={1.5}>
              <CustomIconButton
                sx={{
                  width: "48px",
                  height: "48px",
                  borderRadius: "50%",
                  border: "none",
                  background: theme.palette.primary.tertiaryGradient,
                  boxShadow: "none",
                  pointerEvents: "none",
                }}
              >
                {renderEntityIcon(infoData?.type?.toLowerCase())}
              </CustomIconButton>
              <Typography variant="h2" color={theme.palette.text.titleLabel} mt={1.6}>
                {infoData?.name}
              </Typography>
            </Box>
            <Typography variant="body3" color={theme.palette.text.titleLabel}>
              {infoData?.description ? infoData?.description : extraInfoData?.description}
            </Typography>
            <Box display="flex" my={3} gap={1}>
              <TwitterIcon sx={{ color: theme.palette.primary.main }} />
              <LinkedInIcon sx={{ color: theme.palette.primary.main }} />
              <FacebookIcon sx={{ color: theme.palette.primary.main }} />
            </Box>
            <Box display="flex" gap={2}>
              {extraInfoData?.tag.map((data: string) => (
                <Box
                  key={data}
                  sx={{
                    backgroundColor: theme.palette.primary.tertiary,
                    color: theme.palette.error.btnText,
                    px: 0.6,
                    borderRadius: "4px",
                  }}
                >
                  <Typography variant="body5" color="#F5F9FF" sx={{ lineHeight: "18px" }}>
                    {data}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3} md={3}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_MAP_REACT_API_KEY }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            options={(maps) => createMapOptions(maps)}
          ></GoogleMapReact>
          <Box sx={{ borderRadius: 2, float: "right" }}>
            {/* <img src="/assets/overviewmap.png" /> */}
            <Box display="flex" mt={1}>
              <PlaceIcon sx={{ color: theme.palette.primary.main }} />
              <Typography variant="subtitle3" sx={{ color: theme.palette.primary.main, mt: 1 }}>
                {capitalize(infoData?.location?.name || "")}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
