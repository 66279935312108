import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { HiMiniCursorArrowRipple } from "react-icons/hi2";
import CustomTertiaryButton from "../../../components/CustomTertiaryButton";
import RelevanceDialog, { LinkData } from "./RelevanceDialog";

interface RelevanceData {
  title: string;
  score: number;
  data: string[];
}

interface RelevanceDataProps {
  relevanceData: RelevanceData[];
  getData?: (data: LinkData) => void;
  editing?: boolean;
}

export default function Relevance({ relevanceData, getData, editing = false }: RelevanceDataProps) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const configureHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    const parentBox = event.currentTarget.parentElement?.parentElement;
    setAnchorEl(parentBox ? parentBox : event.currentTarget);
  };

  const [open, setOpen] = useState<boolean>(false);
  const [id, setId] = useState<string>("");

  useEffect(() => setOpen(anchorEl ? true : false), [anchorEl]);
  useEffect(() => setId(open ? "simple-popover" : ""), [open]);

  const closeDialogHandler = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <Grid container spacing={2} mt={1}>
      {relevanceData.map((rData, idx) => {
        const barColor =
          rData?.score >= 90
            ? theme.palette.text.neutralStatus
            : rData?.score < 90 && rData?.score >= 50
            ? theme.palette.text.moderateStatus
            : theme.palette.text.extremeStatus;
        return (
          <Grid item xs={6} lg={3} display="flex" key={`relevance-${idx}`}>
            <Box
              sx={
                editing
                  ? {
                      borderRadius: "8px",
                      border: "2px solid transparent",
                      flexFlow: "row wrap",
                      width: "100%",
                      backgroundImage:
                        anchorEl?.id === `${idx}`
                          ? theme.palette.additionalColors.cardActiveBorderImage
                          : theme.palette.additionalColors.cardDefaultBorderImage,
                      backgroundOrigin: "border-box",
                      backgroundClip: "content-box, border-box",
                      position: "relative",
                    }
                  : {
                      background: theme.palette.background.darkCardBackground,
                      borderRadius: "8px",
                      border: theme.palette.additionalColors.databasecardBorder,
                      flexFlow: "row wrap",
                      width: "100%",
                      position: "relative",
                    }
              }
            >
              <Box p={2}>
                <Box display="flex">
                  <Typography variant="h4" color={theme.palette.text.titleLabel}>
                    {rData?.title}
                  </Typography>
                  {rData?.score > 0 ? (
                    <Typography variant="h4" color={theme.palette.text.titleLabel} ml="auto">{`${rData?.score}%`}</Typography>
                  ) : (
                    <Box sx={{ width: "10px", height: "2px", background: theme.palette.primary.inactiveIcon, ml: "auto" }}></Box>
                  )}
                </Box>
                {rData?.score ? (
                  <Box>
                    <Box
                      sx={{
                        height: "6px",
                        width: "100%",
                        display: "flex",
                        borderRadius: "8px",
                        my: 2,
                        background: theme.palette.primary.tertiary,
                      }}
                    >
                      <Box sx={{ width: `${rData?.score}%`, background: barColor }}></Box>
                    </Box>
                    <Box display="flex" flexDirection="column" height={editing ? "6rem" : "none"}>
                      {rData?.data.map((subOpt, idx) => (
                        <Typography
                          variant="caption"
                          sx={{
                            fontWeight: 500,
                            color: barColor,
                            lineHeight: "20px",
                          }}
                          key={`subdata-${idx}`}
                        >
                          {subOpt}
                        </Typography>
                      ))}
                    </Box>
                    {editing && (
                      <>
                        <CustomTertiaryButton
                          variant="contained"
                          id={`${idx}`}
                          sx={{
                            ...theme.typography.h6,
                            position: "absolute",
                            bottom: 14,
                            right: 10,
                          }}
                          onClick={configureHandler}
                        >
                          Configure Results
                        </CustomTertiaryButton>
                        {anchorEl?.id === `${idx}` && (
                          <HiMiniCursorArrowRipple
                            size={30}
                            color={theme.palette.text.titleLabel}
                            style={{ position: "absolute", bottom: 0, right: 4 }}
                          />
                        )}
                        <RelevanceDialog anchor={anchorEl} id={id} open={open} getData={getData} handleClose={closeDialogHandler} />
                      </>
                    )}
                  </Box>
                ) : (
                  <Box display="flex" flexDirection="column" mt={2}>
                    <Typography variant="body4" color={theme.palette.primary.inactiveIcon}>
                      No results
                    </Typography>
                    <Typography variant="body4" color={theme.palette.primary.inactiveIcon} pt={1}>
                      Connect the following model:{" "}
                    </Typography>
                    <Typography variant="body4" color={theme.palette.primary.inactiveIcon}>
                      Fake Detection
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}
