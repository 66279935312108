import { Box, Grid, useTheme } from "@mui/material";
import React from "react";
import RelevantEntities from "../../../../Connections/Components/RelevantEntities";
import RiskScoreLevel from "../../../../Connections/Components/RiskScoreLevel";
import RiskyEntities from "../../../../Connections/Components/RiskyEntities";
import TopConnections from "../../../../Connections/Components/TopConnections";

interface AnalyticsProps {
  drawerOpen: boolean;
}

export default function Analytics({ drawerOpen }: AnalyticsProps) {
  const theme = useTheme();

  let cardStyle: React.CSSProperties = {
    width: "100%",
    background: theme.palette.background.darkCardBackground,
    border: theme.palette.additionalColors.databasecardBorder,
    padding: 2,
    borderRadius: 2,
    flexFlow: "row wrap",
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={5} md={drawerOpen ? 12 : 4} lg={drawerOpen ? 4 : 3} display="flex">
          <Box sx={cardStyle}>
            <RelevantEntities />
          </Box>
        </Grid>
        <Grid item sm={7} md={drawerOpen ? 12 : 8} lg={drawerOpen ? 8 : 9} display="flex">
          <Box sx={cardStyle}>
            <TopConnections />
          </Box>
        </Grid>
        <Grid item sm={12}>
          <Box sx={cardStyle}>
            <RiskScoreLevel />
          </Box>
        </Grid>
        <Grid item sm={12}>
          <Box sx={cardStyle}>
            <RiskyEntities />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
