import { Box, Grid, Link, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { MultiSelectValueType } from "../../../../components/CheckboxFilterButton";
import PrimaryLoadingButton from "../../../../components/PrimaryLoadingButton";
import { routes } from "../../../../routes";
import { useHandleRouteClick } from "../../../../routes/hooks";
import PipelineCreation from "./PipelineCreation";
import QuerySection, { QueryFormData } from "./QuerySection";

export default function SettingSection() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const [sourceType, setSourceType] = useState<string>("");
  const [canvasData, setCanvasData] = useState<QueryFormData>();
  const [model, setModels] = useState<MultiSelectValueType[]>([]);
  const [uploadedData, setUploadedData] = useState([]);

  const submitCanvasData = (formData: QueryFormData) => {
    setCanvasData(formData);
  };

  const getDataSources = (source: string) => {
    setSourceType(source);
  };

  const getSelectedModels = (models: MultiSelectValueType[]) => {
    setModels(models);
  };

  const handleNavigation = (id: string | number) => {
    handleRouteClick(routes.resultsOverview.path.replace(":id", id.toString()), { datasource: sourceType });
  };

  const getUploadedData = (data: any) => {
    setUploadedData(data);
  };

  return (
    <Grid container spacing={2} mt={3} sx={{ height: "100%", overflow: "scroll" }}>
      <Grid item xs={12} md={4}>
        <Typography variant="h2" pl={1}>
          1. Query
        </Typography>
        <QuerySection
          submitCanvasData={submitCanvasData}
          getDataSources={getDataSources}
          getSelectedModels={getSelectedModels}
          getUploadedData={getUploadedData}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          [theme.breakpoints.down("md")]: {
            mt: 5,
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="h2">2. Pipeline creation</Typography>
          <Typography variant="subtitle2">
            Create pipeline by linking data source and AI Model.{" "}
            <Link href="#" underline="none">
              View an example
            </Link>
          </Typography>
        </Box>
        <PipelineCreation canvasData={canvasData} models={model} sourceType={sourceType} uploadedData={uploadedData} />
        <PrimaryLoadingButton
          sx={{
            width: "32%",
            float: "right",
            mt: 2,
          }}
          onClick={() => handleNavigation(1)}
        >
          Analyze
        </PrimaryLoadingButton>
      </Grid>
    </Grid>
  );
}
