import { Box, Checkbox, Typography, useTheme } from "@mui/material";
import { CardData } from "./RecognationOverview";

interface ImageRecognationProps {
  resultsData: CardData;
  handleSelect(): void;
  select: boolean;
  checked: boolean;
}
export default function TextRecognationCard({ resultsData, select, checked, handleSelect }: ImageRecognationProps) {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", cursor: "pointer" }} onClick={handleSelect}>
      {select && (
        <Checkbox
          checked={checked}
          sx={{
            "&.MuiButtonBase-root.MuiCheckbox-root:hover": {
              background: "transparent",
            },
          }}
        />
      )}

      <Box
        sx={{
          background: theme.palette.dashboardContainer.backround,
          border: theme.palette.additionalColors.databasecardBorder,
          borderRadius: 2,
          flexFlow: "row wrap",
        }}
        width="100%"
        p={2}
      >
        <Box display="flex" width="100%">
          <Box width="70%">
            <Typography variant="body4" color={theme.palette.text.titleLabel}>
              {resultsData?.description}
            </Typography>
          </Box>
          <Box ml="auto">
            <Typography variant="subtitle1" sx={{ background: theme.palette.primary.tertiary, px: 2, py: 1, borderRadius: 1 }}>
              {resultsData?.type || "Noname"}
            </Typography>
            {resultsData?.entityName && (
              <Typography color={theme.palette.primary.light} sx={{ fontSize: "11px", fontWeight: 500, mt: 1 }}>
                Confidence score : {resultsData?.score}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
