import { Avatar, Box, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CheckboxFilterButton from "../../../components/CheckboxFilterButton";
import CustomDataTable from "../../../components/CustomDataTable";
import PrimaryLoadingButton from "../../../components/PrimaryLoadingButton";
import StatusButton from "../../../components/StatusButton";
import UiLoading from "../../../layouts/UiLoading";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAllWorkspaceUsers } from "../../../store/workspace/workspaceThunk";
import MoreUserOptionsTooltip from "./MoreUserOptionsTooltip";

export default function Users() {
  const theme = useTheme();
  const { workspaceId } = useParams();
  const { workspaceUsers, isLoading } = useAppSelector((state) => state.workspace);
  const [currentWorkspaceUsers, setCurrentWorkspaceUsers] = useState<Record<string, any>[]>([]);
  const { currentUser } = useAppSelector((state) => state.auth);
  const is_admin = currentUser ? currentUser.is_admin : false;
  const dispatch = useAppDispatch();
  const handleRouteClick = useHandleRouteClick();

  useEffect(() => {
    dispatch(getAllWorkspaceUsers({ group_id: workspaceId ? workspaceId : "0" })).then((res) => {
      console.log(res);
      if (res?.payload?.status === 200) {
        setCurrentWorkspaceUsers(res?.payload?.data);
      }
    });
  }, []);

  const toggleAddUserDialog = () => {
    handleRouteClick(routes.addUser.path.replace(":workspaceId", workspaceId ? workspaceId : "0"));
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "",
      width: 90,
      sortable: true,
    },
    {
      field: "profile_photo",
      headerName: "Photo",
      headerAlign: "center",
      width: 80,
      editable: false,
      sortable: false,

      align: "center",
      renderCell: (params) => {
        return <Avatar src={params.value} />;
      },
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      width: 250,
      editable: false,
      sortable: false,
      align: "center",
      flex: 1,
    },
    {
      field: "is_admin",
      headerName: "Role",
      headerAlign: "center",
      width: 100,
      editable: false,
      sortable: false,

      align: "center",
      valueGetter: (params) => (params.value ? "Admin" : "User"),
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      width: 350,
      editable: false,
      sortable: false,
      align: "center",
      flex: 1,
    },
    {
      field: "phone_no",
      headerName: "Phone Number",
      headerAlign: "center",
      sortable: false,
      align: "center",
      width: 250,
      flex: 1,
    },
    {
      field: "is_active",
      headerName: "Status",
      headerAlign: "center",
      sortable: false,
      width: 100,
      align: "center",
      renderCell: (params) => {
        return <StatusButton status={params.value ? "active" : "deactivated"} />;
      },
    },
    {
      field: "moreOptions",
      headerName: "",
      align: "center",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <MoreUserOptionsTooltip userId={params?.row?.id} details={params?.row} />
          </>
        );
      },
    },
  ];
  // const rows = [
  //   { id: 1, name: "Cristian Gonzales", role: "Admin", email: "c.gonzales@skylark.es", phoneNumber: "+1 347 567 2345", photo: "", status: "active" },
  // ];

  const roles = [
    { id: "all", text: "All" },
    { id: "admin", text: "Admin" },
    { id: "user", text: "User" },
  ];
  const handleRoleFilter = (value: any[]) => {
    if (value.length === 0 || value.length === 3 || value.length === 2) {
      setCurrentWorkspaceUsers(workspaceUsers);
    } else {
      const filteredUsers = workspaceUsers.filter((user) => value[0].id === (user.is_admin ? "admin" : "user"));
      setCurrentWorkspaceUsers(filteredUsers);
    }
  };

  return (
    <>
      {isLoading && <UiLoading height="100vh" />}
      {is_admin && (
        <>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center", position: "absolute", right: 0, top: 0 }}>
            <CheckboxFilterButton
              label="Role"
              onChange={(value) => {
                handleRoleFilter(value);
              }}
              options={roles}
              showSearch={false}
            />
            <PrimaryLoadingButton variant="contained" sx={{ ...theme.typography.regularButton, fontWeight: "bold" }} onClick={toggleAddUserDialog}>
              Add New User
            </PrimaryLoadingButton>
          </Box>
        </>
      )}

      {currentWorkspaceUsers.length === 0 ? (
        <Box>
          <CustomDataTable
            rows={currentWorkspaceUsers}
            columns={columns}
            columnVisibilityModel={{
              id: false,
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            checkboxSelection={false}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            disableVirtualization
            disableRowSelectionOnClick
            isCellEditable={() => false}
            rowSelection={false}
            disableEval
            pagination
            sx={{
              width: "100%",
              "& .MuiDataGrid-virtualScroller": {
                overflowX: "hidden",
              },
            }}
          />
        </Box>
      ) : (
        <Box>
          <CustomDataTable
            rows={currentWorkspaceUsers}
            columns={columns}
            columnVisibilityModel={{
              id: false,
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            checkboxSelection={false}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            disableVirtualization
            disableRowSelectionOnClick
            isCellEditable={() => false}
            rowSelection={false}
            disableEval
            pagination
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          />
        </Box>
      )}
    </>
  );
}
