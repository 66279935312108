import { Box, CardMedia, SxProps, Theme, Typography, useTheme } from "@mui/material";
import Typewriter from "../../../../../components/Typewriter";
import { GraphComponent } from "./GraphComponent";

function transformString(input:string) {
  return input.replace(/@\[(.*?)\]\(.*?\)/g, '$1');
}

interface ChatBoxProps {
  type: string;
  message?: { [key: string]: any};
  sx?: SxProps<Theme>;
  loader?: React.ReactElement;
  typing?: boolean;
  showUserIcons?: boolean;
  customSentBoxIcon?: React.ReactElement;
  customRecieveBoxIcon?: React.ReactElement;
}

export default function ChatBox({
  type,
  message,
  loader,
  typing = false,
  showUserIcons = true,
  customSentBoxIcon,
  customRecieveBoxIcon,
}: ChatBoxProps) {
  const theme = useTheme();
console.log("message", message);
  const profileStyle = {
    width: "36px",
    height: "36px",
    alignItems: type === "outgoing" ? "start" : "end",
    alignSelf: "end",
  };

  const msg = "Based on the context provided, I can give you the specifications of the Rafale jet, which is a 4.5 generation fighter aircraft developed by the French company Dassault Aviation.\n\nHere are some of the specifications of the Rafale:\n\n* Crew: 1 or 2\n* Length: 15.27 m\n* Wingspan: 10.8 m\n* Height: 5.34 m\n* Max Takeoff Weight: 24,500 kg\n* Powerplant: 2 Snecma M88-2 afterburning turbofans\n* Thrust: 7,500 kgf (73.5 kN) each\n* Max Speed: Mach 1.8 (1,912 km/h or 1,188 mph) at high altitude\n* Range: 3,700 km (2,300 mi)\n* Service Ceiling: 15,240 m (50,000 ft)\n* Armament:\n\t+ 1× 30 mm (1.18 in) GIAT 30/70 cannon\n\t+ Up to 9.5 tonnes (21,000 lb) of payload on 14 hardpoints, including:\n\t\t- Air-to-air missiles: MICA, Magic II, MBDA Meteor, ASRAAM\n\t\t- Air-to-ground missiles: SCALP-EG, Storm Shadow, AM39 Exocet, PGM 500 Hammer, Apache\n\t\t- Anti-ship missiles: Exocet AM39, Penguin Mk2\n\t\t- Bombs: AASM, Durandal, BLU-107, GBU-12, GBU-24, GBU-27, GBU-31, GBU-38, GBU-39, GBU-49, B61, Mark 82, Mark 83, Mark 84\n\t\t- Anti-radiation missiles: ARMAT, ALARM\n* Radar: RBE2 active electronically scanned array radar\n\nThe Rafale is known for its low radar cross-section (RCS), high maneuverability, and supercruise capabilities. It is equipped with advanced avionics and sensors, making it a formidable fighter aircraft in air-to-air and air-to-ground combat scenarios."

  return (
    <Box display="flex" alignItems="flex-end" sx={{ justifyContent: type === "outgoing" ? "end" : "start", gap: 1, my: 2 }}>
      {type === "incoming" &&
        showUserIcons &&
        (customRecieveBoxIcon || <CardMedia component="img" image="/assets/user.png" alt="profile" sx={profileStyle} />)}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          background: `${theme.palette.primary.tertiary}4D`,
          borderRadius: type === "outgoing" ? "20px 20px 0px 20px" : "20px 20px 20px 0",
          width: "70%",
          p: 2,
        }}
      >
        {message && (
          // <pre>
          <Typography variant="body4" color={theme.palette.text.titleLabel} style={{whiteSpace: 'pre-wrap'}}>
            {typing ? <Typewriter text={transformString(message?.message)} /> : transformString(message?.message)} 
          </Typography>
          //  </pre>
        )}
        {message && message?.component === "knowledge_graph" && <GraphComponent graph_data={message?.data} />}
        {loader}
        <Typography variant="body5" color={theme.palette.text.captionColor} sx={{ ml: type === "outgoing" ? "auto" : "0" }}>
          12:30
        </Typography>
      </Box>

      {type === "outgoing" &&
        showUserIcons &&
        (customSentBoxIcon || <CardMedia component="img" image={"/assets/profile.png"} alt="profile" sx={profileStyle} />)}
    </Box>
  );
}
