import { Box, Grid, Pagination, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getEntityCrawlData } from "../../../../../../store/analysis/analysisThunk";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import ModifiedPublicationCard from "../../../Feed/components/Publications/ModifiedPublicationCard";
interface OnlineActivityProps {
  entityId?: string;
}

export default function OnlineActivity({ entityId }: OnlineActivityProps) {
  const theme = useTheme();
  const params = useParams();
  const dispatch = useAppDispatch();
  const { entityCrawlData } = useAppSelector((state) => state.analysisConnections);

  React.useEffect(() => {
    let id = params.id || entityId;
    if (id) {
      dispatch(getEntityCrawlData(id));
    }
  }, [params]);

  const [page, setPage] = useState(1);

  const pageCount = 6;

  const handlePageChange = (e: any, value: number) => {
    setPage(value);
  };

  return (
    <Box>
      <Typography variant="h3" sx={{ fontSize: "22px", color: theme.palette.text.titleLabel }} pt={3}>
        Online Activity
      </Typography>
      <Grid container spacing={2} mt={0.5}>
        {entityCrawlData.slice((page - 1) * pageCount, page * pageCount).map((data: any, idx) => (
          <Grid key={`image-${idx}`} item xs={12} sm={6} lg={4} display="flex">
            <ModifiedPublicationCard cardData={data} view={"grid"} />
          </Grid>
        ))}
        <Grid container spacing={2} px={4} py={2}>
          <Box
            sx={{
              mt: 5,
              mx: "auto",
              mr: "0px",
            }}
          >
            {entityCrawlData.length / pageCount >= 1 && (
              <Pagination
                onChange={handlePageChange}
                page={page}
                count={Math.ceil(entityCrawlData.length / pageCount)}
                size="small"
                sx={(theme) => ({
                  ...theme.palette.pagination,
                })}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
