import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { getFeedsByEntityId } from "../../../../../../store/analysis/analysisThunk";
import { useAppDispatch } from "../../../../../../store/hooks";
import ModifiedPublicationCard from "../../../Feed/components/Publications/ModifiedPublicationCard";

interface RelatedPublicationsForEntityProps {
  entityId: string;
}
export default function RelatedPublicationsForEntity({ entityId }: RelatedPublicationsForEntityProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [relatedArticles, setRelatedAritcles] = useState<Record<string, any>[]>();
//   console.log("entityId ", entityId);
//   console.log("Related Articles :", relatedArticles);

  useEffect(() => {
    if (entityId) {
      dispatch(getFeedsByEntityId(entityId)).then((res) => {
        if (res?.payload?.status === 200) {
          setRelatedAritcles(res?.payload?.data?.crawl_data);
        }
      });
    }
  }, [entityId]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
        <Typography variant="h3" mt={2} color={theme.palette.text.titleLabel} sx={{ fontSize: "22px" }}>
          Related Publications
        </Typography>
      </Box>
      <Grid container spacing={2} mt={1}>
        {relatedArticles &&
          relatedArticles.map((data: any, idx: number) => (
            <Grid key={`image-${idx}`} item xs={12} md={4} display="flex">
              <ModifiedPublicationCard cardData={data?.crawlData} view={"grid"} />
            </Grid>
          ))}
      </Grid>
    </>
  );
}
