import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import CustomTab from "../../../../../components/CustomTab";
import Entities from "./component/Entities";

const tabs = [
  {
    id: "entities",
    title: "Entities",
    disabled: false,
    component: <Entities />,
  },
  {
    id: "publications",
    title: "Publications",
    disabled: false,
    component: <Entities />,
  },
];
export default function RiskSummary() {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };
  return (
    <Box>
      <Box display="flex">
        <Typography sx={{ fontSize: "22px", fontWeight: 600 }} color={theme.palette.text.titleLabel}>
          Risk Summary
        </Typography>
        <Box ml="auto" gap="12px">
          <CustomTab
            tabs={tabs}
            handleActiveTab={handleActiveTab}
            sx={{ border: `1px solid ${theme.palette.primary.tertiary}`, textAlign: "center", p: "1px" }}
            activeTab={activeTab}
          />
        </Box>
      </Box>
      {tabs.find((tab) => tab.id === activeTab)?.component}
    </Box>
  );
}
