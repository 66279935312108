import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Box, Grid, useTheme } from "@mui/material";
import { useState } from "react";
import CustomFilterDrawer from "../../../../components/CustomFilterDrawer";
import CustomIconButton from "../../../../components/CustomIconButton";
import CustomTab from "../../../../components/CustomTab";
import CustomTertiaryButton from "../../../../components/CustomTertiaryButton";
import { analysisQueryActions } from "../../../../store/analysis/analysisQuerySlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import DemographicsMap from "./Components/DemographicsMap";
import EntitiesMap from "./Components/EntitiesMap";

const hotspotsData = [
  { id: "A", count: 54, position: { lat: 55.683708, lng: 38.028615 }, status: "extreme", legend: "person" },
  { id: "B", count: 552, position: { lat: 46.454048, lng: 1.849527 }, status: "high", legend: "topic" },
  { id: "C", count: 4, position: { lat: 50.917913, lng: 9.165937 }, status: "neutral", legend: "event" },
  { id: "D", count: 140, position: { lat: 52.609411, lng: -8.12626 }, status: "moderate", legend: "location" },
  { id: "E", count: 5, position: { lat: 46.881829, lng: 12.836233 }, status: "low", legend: "organization" },
];

export default function MapSection() {
  const dispatch = useAppDispatch();

  const { mapFilter } = useAppSelector((state: any) => state.analysis);

  const tabs = [
    {
      id: "entities",
      title: "Entities",
      disabled: false,
      component: <EntitiesMap />,
    },
    {
      id: "demographics",
      title: "User Demographics",
      disabled: false,
      component: (
        <Box sx={{ height: "54vh", mt: 2 }}>
          <DemographicsMap hotspotsData={hotspotsData} showPopup={true} showZoomControl={true} defaultZoom={1} />
        </Box>
      ),
    },
  ];
  const theme = useTheme();

  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen((prev) => !prev);
  };

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleFilterApply = (data: any) => {
    dispatch(analysisQueryActions.addMapFilter(data));
  };

  const handleReset = (data: any) => {
    dispatch(analysisQueryActions.addMapFilter(data));
  };

  return (
    <Box sx={{ borderTop: "1px solid", borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1` }}>
      <Grid container spacing={2} pt={2}>
        {drawerOpen && (
          <Grid item xs={5} lg={3}>
            <CustomFilterDrawer
              filterData={mapFilter}
              openDrawer={drawerOpen}
              closePlayCamerasDrawer={handleDrawerOpen}
              handleFilterApply={handleFilterApply}
              handleReset={handleReset}
            />
          </Grid>
        )}
        <Grid item xs={drawerOpen ? 7 : 12} lg={drawerOpen ? 9 : 12}>
          <Box display="flex" gap={2} width={"100%"} mt={2}>
            {!drawerOpen && (
              <CustomTertiaryButton
                endIcon={<ChevronRightIcon />}
                sx={{
                  [theme.breakpoints.down("lg")]: {
                    display: "none",
                  },
                }}
                onClick={handleDrawerOpen}
              >
                Filters
              </CustomTertiaryButton>
            )}
            {!drawerOpen && (
              <CustomIconButton
                sx={{
                  width: "36px",
                  height: "36px",
                  color: theme.palette.text.default,
                  [theme.breakpoints.up("lg")]: {
                    display: "none",
                  },
                }}
                onClick={handleDrawerOpen}
              >
                <FilterAltIcon />
              </CustomIconButton>
            )}
            <CustomTab
              tabs={tabs}
              handleActiveTab={handleActiveTab}
              sx={{
                border: `1px solid ${theme.palette.primary.tertiary}`,
                textAlign: "center",
                borderRadius: "6px",
                p: "0 2px",
                alignItems: "center",
              }}
              activeTab={activeTab}
            />
          </Box>
          {tabs.find((tab) => tab.id === activeTab)?.component}
        </Grid>
      </Grid>
    </Box>
  );
}
