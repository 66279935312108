import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import CustomDivider from "../../../../components/CustomDivider";
import CustomIconButton from "../../../../components/CustomIconButton";
import CustomPopover from "../../../../components/CustomPopover";
import CustomSecondaryButton from "../../../../components/CustomSecondaryButton";
import CustomStatus from "../../../../components/CustomStatus";
import CustomTextButton from "../../../../components/CustomTextButton";
import { routes } from "../../../../routes";
import { useHandleRouteClick } from "../../../../routes/hooks";
import { DetailOptions } from "../AIContainer";
import ModelDetail from "./ModelDetails";

interface CardOption {
  cardData: DetailOptions;
}

export default function CustomAICard({ cardData }: CardOption) {
  const theme = useTheme();

  const handleRouteClick = useHandleRouteClick();

  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpenMoreOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreOptionsAnchorEl(event.currentTarget);
  };

  const handleCloseMoreOptions = () => {
    setMoreOptionsAnchorEl(null);
  };

  const handleClose = () => {
    setOpenModal((prev) => !prev);
  };

  const handleNavigation = (model: string, id: number) => {
    const modelName = model.split(" ").join("_").toLowerCase();
    let path = routes.modelTraining.path.replace(":model", modelName);
    path = path.replace(":id", String(id));
    handleRouteClick(path);
  };

  const isProcessing: boolean = cardData?.status === "processing";

  const handleViewResults = (id: number) => {
    handleRouteClick(routes.resultsOverview.path.replace(":id", id.toString()), { results: true });
  };

  return (
    <Box
      sx={{
        background: theme.palette.dashboardContainer.backround,
        border: theme.palette.additionalColors.databasecardBorder,
        p: 2,
        flexFlow: "row wrap",
        width: "100%",
        borderRadius: 2,
      }}
    >
      <Box display="flex">
        <Typography onClick={() => handleNavigation(cardData?.name, cardData?.id)} sx={{ cursor: "pointer", color: theme.palette.text.titleLabel }}>
          {cardData?.name}
        </Typography>
        <Box ml="auto" display="flex" gap={1.2}>
          <CustomStatus
            label={cardData.status}
            status={isProcessing ? "moderate" : "neutral"}
            sx={{ width: "88px", height: "30px", lineHeight: "50px" }}
          />
          {isProcessing && (
            <CustomIconButton sx={{ width: "30px", height: "30px" }}>
              <PauseOutlinedIcon sx={{ color: theme.palette.text.default, fontSize: "18px" }} />
            </CustomIconButton>
          )}
          <CustomIconButton onClick={handleOpenMoreOptions} sx={{ width: "30px", height: "30px" }}>
            <MoreHorizOutlinedIcon sx={{ color: theme.palette.text.default, fontSize: "18px" }} />
          </CustomIconButton>
          <CustomPopover open={Boolean(moreOptionsAnchorEl)} anchorEl={moreOptionsAnchorEl} onClose={handleCloseMoreOptions}>
            {isProcessing && (
              <CustomTextButton
                onClick={handleClose}
                startIcon={<InfoOutlinedIcon sx={{ color: theme.palette.text.default }} />}
                sx={{ color: theme.palette.text.titleLabel }}
              >
                View Details
              </CustomTextButton>
            )}
            {isProcessing && <CustomDivider />}
            <CustomTextButton onClick={() => {}} startIcon={<CloseIcon color="error" />} sx={{ color: theme.palette.text.titleLabel }}>
              {isProcessing ? "Stop & Delete" : "Delete Model"}
            </CustomTextButton>
          </CustomPopover>
        </Box>
      </Box>
      <Typography variant="body4" color={theme.palette.text.tableHeader}>
        {cardData?.createdAt}
      </Typography>
      <br />
      {!isProcessing && (
        <CustomSecondaryButton
          sx={{ ...theme.typography.regularButton, width: "131px", maxWidth: "131px", height: "30px", mt: 3 }}
          onClick={() => handleViewResults(cardData?.id)}
        >
          View Results
        </CustomSecondaryButton>
      )}
      {isProcessing && (
        <>
          <Box sx={{ height: "6px", width: "100%", display: "flex", borderRadius: "8px", my: 3, background: theme.palette.primary.tertiary }}>
            <Box sx={{ width: `${100 - 77}%`, background: theme.palette.text.neutralStatus }}></Box>
          </Box>
          <Box display="flex" justifyContent="space-between" width="100%" mt={-2.5}>
            <Typography variant="body4" color={theme.palette.text.titleLabel}>
              Training...
            </Typography>
            <Typography variant="body4" color={theme.palette.text.titleLabel}>
              {100 - 77}%
            </Typography>
          </Box>
        </>
      )}
      <ModelDetail open={openModal} handleClose={handleClose} details={cardData} />
    </Box>
  );
}
