import CloseIcon from "@mui/icons-material/Close";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import React from "react";
import BackButton from "../../../components/BackButton";
import CustomIconButton from "../../../components/CustomIconButton";
import CustomTertiaryButton from "../../../components/CustomTertiaryButton";
import PrimaryLoadingButton from "../../../components/PrimaryLoadingButton";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { sxTableCell } from "../../../utils/sxStyle";

export default function CartItems() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const handleBack = () => {
    handleRouteClick(routes.marketPlace.path);
  };

  const handleCheckoutProcess = () => {
    handleRouteClick(routes.paymentDetails.path, { plan: "pro", amt: 89.97, backPath: routes.cartItems.path, isCart: true });
  };

  const cartItem = [
    { id: 1, name: "Social Media Analysis", type: "Solution", price: 29.99 },
    { id: 2, name: "Social Media Analysis", type: "Solution", price: 29.99 },
    { id: 3, name: "Social Media Analysis", type: "Solution", price: 29.99 },
  ];

  const totalPay = cartItem.reduce((data: any, item: any) => data + item?.price, 0);

  return (
    <Box display="flex" flexDirection="column" height="100%" p={2}>
      <Box>
        <Typography variant="h1" color={theme.palette.text.titleLabel}>
          Cart (3)
        </Typography>
        <BackButton onClick={handleBack} />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          background: theme.palette.background.paper,
          boxShadow: theme.palette.dashboardContainer.customCardInsetBoxShadow,
          borderRadius: 4.5,
          px: { xs: 2, lg: 4, xl: 30 },
          mt: 2,
        }}
      >
        <TableContainer
          sx={{
            margin: "40px auto",
            width: "95%",
            border: `1px solid ${theme.palette.additionalColors.lightBorder}`,
            borderRadius: "8px",
            paddingX: "20px",
          }}
        >
          <Table sx={{ minWidth: { sm: 230, lg: 650 } }} aria-label="cart table">
            <TableHead>
              <TableRow sx={{ borderBottom: "1px solid #41527D" }}>
                <TableCell
                  sx={(theme) => ({
                    [theme.breakpoints.down("lg")]: {
                      fontSize: "14px",
                    },
                    ...sxTableCell,
                  })}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={(theme) => ({
                    [theme.breakpoints.down("lg")]: {
                      fontSize: "14px",
                    },
                    ...sxTableCell,
                  })}
                >
                  Type
                </TableCell>
                <TableCell
                  sx={(theme) => ({
                    [theme.breakpoints.down("lg")]: {
                      fontSize: "14px",
                    },
                    ...sxTableCell,
                  })}
                  align="center"
                >
                  Price
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cartItem.map((row) => (
                <React.Fragment key={row?.id}>
                  <TableRow key={row.type} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell align="left" sx={{ borderBottom: "1px solid #41527D" }}>
                      <Typography variant="body4" color={theme.palette.text.captionColor}>
                        {row?.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ borderBottom: "1px solid #41527D" }}>
                      <Typography variant="body4" color={theme.palette.text.captionColor}>
                        {row?.type}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: "1px solid #41527D" }}>
                      <Typography variant="body4" color={theme.palette.text.captionColor}>{`$ ${row?.price}`}</Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "1px solid #41527D", textAlign: "right" }}>
                      <CustomIconButton
                        variant="contained"
                        sx={{
                          background: theme.palette.dashboardContainer.closeButtonColor,
                          boxShadow: theme.palette.dashboardContainer.closeButtonBoxShadow,
                          borderRadius: "4px",
                          minWidth: theme.spacing(3),
                          width: theme.spacing(3),
                          height: theme.spacing(3),
                          padding: "6px",
                          color: theme.palette.error.main,
                        }}
                      >
                        <CloseIcon />
                      </CustomIconButton>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display={"flex"}
          justifyContent={"end"}
          flexDirection={"column"}
          px={5}
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              marginBottom: "20px",
            },
          })}
        >
          <Box display={"flex"} justifyContent={"end"} mt={2}>
            <Typography variant="body2" px={2} color={theme.palette.text.tableHeader}>
              Total to pay
            </Typography>
            <Typography variant="h1">$ {totalPay}</Typography>
          </Box>
          <Box display={"flex"} justifyContent={"end"} my={5} gap={2}>
            <CustomTertiaryButton onClick={handleBack} sx={{ px: "2em" }}>
              Continue Shopping
            </CustomTertiaryButton>
            <PrimaryLoadingButton
              onClick={handleCheckoutProcess}
              sx={{
                ...theme.typography.bigButton,
                px: "1em",
              }}
            >
              Proceed To Checkout
            </PrimaryLoadingButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
