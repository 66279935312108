import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CustomIconButton from "../../../components/CustomIconButton";
import CustomTertiaryButton from "../../../components/CustomTertiaryButton";
import SubmitLoadingDeleteButton from "../../../components/SubmitLoadingDeleteButton";

export interface DeleteQueryReportDialogProps {
  open: boolean;
  handleToggle: () => void;
  queryReportId: string | number | Array<string | number>;
}

export default function DeleteQueryReportDialog({ open, handleToggle, queryReportId }: DeleteQueryReportDialogProps) {
  const theme = useTheme();

  const handleDelete = () => {
    console.log(queryReportId);
  };

  return (
    <Dialog
      onClose={handleToggle}
      open={open}
      PaperProps={{
        sx: {
          background: theme.palette.background.gradientLight1,
          borderRadius: "8px",
          py: 4,
          px: 4,
        },
      }}
    >
      <Box display="flex" justifyContent="flex-end">
        <CustomIconButton onClick={handleToggle} sx={{ color: theme.palette.error.main }}>
          <CloseIcon />
        </CustomIconButton>
      </Box>
      <DialogContent sx={{ margin: 0, padding: 0, mt: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Box sx={{ mx: "auto" }}>
          <img height="110" src="/assets/cross_dialog_icon.png" />
        </Box>
        <Typography variant="h1" textAlign="center" color={theme.palette.text.titleLabel} my={3}>
          Confirm Delete
        </Typography>
        <Typography variant="body4" textAlign="center" color={theme.palette.text.captionColor}>
          Are you sure you want to delete the document? This action cannot be undone.
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            mt: 4,
            justifyContent: "center",
          }}
        >
          <CustomTertiaryButton sx={{ ...theme.typography.bigButton, minWidth: "8.5rem" }} onClick={handleToggle}>
            Cancel
          </CustomTertiaryButton>
          <SubmitLoadingDeleteButton isLoading={false} sx={{ minWidth: "8.5rem" }}>
            Delete
          </SubmitLoadingDeleteButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
