import { createSlice } from "@reduxjs/toolkit";
import {
  addGlobalRedFlags,
  deleteGlobalRedFlag,
  getGlobalRedFlags,
  getGlobalRedFlagsWithId,
  getLatestQuery,
  getQueries,
  getQueryAnalyticsData,
  getQueryDetail,
  getQueryResult,
  getQueryResultImages,
  getUserProfileDetail,
  saveQuery,
  saveQueryResult,
  getFeedsByEntityId,
} from "./analysisThunk";

export interface AnalysisInitialState {
  isLoading: boolean;
  showTranslatedFeed: boolean;
  analyticsData: {
    sources_count: number;
    results_count: number;
    mentions_count: number;
    entities_count: number;
    red_flags_count: number;
    threat_summary: Record<string, any>;
    sentiment_summary: Record<string, any>;
  };
  publicationData: any;
}

const analysisInitialState: AnalysisInitialState = {
  isLoading: false,
  showTranslatedFeed: false,
  analyticsData: {
    sources_count: 0,
    results_count: 0,
    mentions_count: 0,
    entities_count: 0,
    red_flags_count: 0,
    threat_summary: {
      "0": 0,
      "1": 0,
      "2": 0,
    },
    sentiment_summary: {
      "0": 0,
      "1": 0,
      "2": 0,
    },
  },
  publicationData: {},
};

const analysisSlice = createSlice({
  name: "analysis",
  initialState: analysisInitialState,
  reducers: {
    updateAnalyticsData(state, action) {
      state.analyticsData = action.payload;
    },
    toggleTranslatedFeed(state) {
      state.showTranslatedFeed = !state.showTranslatedFeed;
    },
  },
  extraReducers: (builder) => {
    // get feed query
    builder
      .addCase(getQueries.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getQueries.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getQueries.rejected, (state, action) => {
        state.isLoading = false;
      });
    // get feed query results
    builder
      .addCase(getQueryResult.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getQueryResult.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log(action);
      })
      .addCase(getQueryResult.rejected, (state, action) => {
        state.isLoading = false;
      });
    // save feed query
    builder
      .addCase(saveQuery.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(saveQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log(action);
      })
      .addCase(saveQuery.rejected, (state, action) => {
        state.isLoading = false;
      });
    // save feed query results
    builder
      .addCase(saveQueryResult.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(saveQueryResult.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log(action);
      })
      .addCase(saveQueryResult.rejected, (state, action) => {
        state.isLoading = false;
      });
    // get query detail
    builder
      .addCase(getQueryDetail.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getQueryDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.publicationData = action.payload;
        // console.log(action);
      })
      .addCase(getQueryDetail.rejected, (state, action) => {
        state.isLoading = false;
      });
    // get query analytics data
    builder
      .addCase(getQueryAnalyticsData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getQueryAnalyticsData.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log(action.payload);
        state.analyticsData = action.payload.data["crawl_data"];
      })
      .addCase(getQueryAnalyticsData.rejected, (state, action) => {
        state.isLoading = false;
      });
    builder
      .addCase(getLatestQuery.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getLatestQuery.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getLatestQuery.rejected, (state, action) => {
        state.isLoading = false;
      });
    builder
      .addCase(getUserProfileDetail.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUserProfileDetail.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getUserProfileDetail.rejected, (state, action) => {
        state.isLoading = false;
      });
    // get query result images
    builder
      .addCase(getQueryResultImages.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getQueryResultImages.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getQueryResultImages.rejected, (state, action) => {
        state.isLoading = false;
      });
    builder
      .addCase(addGlobalRedFlags.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addGlobalRedFlags.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addGlobalRedFlags.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(getGlobalRedFlags.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getGlobalRedFlags.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getGlobalRedFlags.rejected, (state, action) => {
        state.isLoading = false;
      });
    builder
      .addCase(getGlobalRedFlagsWithId.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getGlobalRedFlagsWithId.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getGlobalRedFlagsWithId.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(deleteGlobalRedFlag.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteGlobalRedFlag.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteGlobalRedFlag.rejected, (state, action) => {
        state.isLoading = false;
      });
    builder
      .addCase(getFeedsByEntityId.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getFeedsByEntityId.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getFeedsByEntityId.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const analysisActions = analysisSlice.actions;

export default analysisSlice;
