import { Typography, useTheme } from "@mui/material";

interface LinkDataProps {
  linkData: Record<string, any>;
}

const LinkPopoverContent = ({ linkData }: LinkDataProps) => {
  const theme = useTheme();
  const relation = linkData?.relation;
  return (
    <>
      <Typography variant="caption" color={theme.palette.text.titleLabel}>
        {relation.charAt(0).toUpperCase() + relation.slice(1)}
      </Typography>
    </>
  );
};

export default LinkPopoverContent;
