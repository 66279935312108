import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteAPI, getAPI } from "../../axios/utils";
import { AUTH_SERVICE_BASE_URL } from "../../utils/constants";

export const getUserSessions = createAsyncThunk("userSessionsSlice/getUserSessions", async () => {
  return getAPI(`${AUTH_SERVICE_BASE_URL}/auth/user-sessions/user-sessions/`);
});

export const endUserSession = createAsyncThunk("userSessionsSlice/endUserSession", async (sessionId: string) => {
  return deleteAPI(`${AUTH_SERVICE_BASE_URL}/auth/user-sessions/user-sessions/${sessionId}/`);
});
