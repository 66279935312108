import { Box, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import ContentUILoader from "../../../../../../components/ContentUILoader";
import CustomNoResults from "../../../../../../components/CustomNoResult";
import AudioCard from "./AudioCard";

const itemData = [
  {
    id: 1,
    title: "9 AM ET: Ukrainian pilots' plea, Biden's 'dictator' comments,   I-95 & more I-95 & more I-95 & moreI-95 & more I-95 & more I-95 & more",
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
    src: "https://download.samplelib.com/mp3/sample-3s.mp3",
  },
  {
    id: 2,
    title: "9 AM ET: Ukrainian pilots' plea, Biden's 'dictator' comments,  I-95 & more",
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
    src: "https://download.samplelib.com/mp3/sample-3s.mp3",
  },
  {
    id: 3,
    title: "9 AM ET: Ukrainian pilots' plea, Biden's 'dictator' comments,  I-95 & more",
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
    rows: 2,
    cols: 1,
    src: "https://download.samplelib.com/mp3/sample-3s.mp3",
  },
  {
    id: 4,
    title: "9 AM ET: Ukrainian pilots' plea, Biden's 'dictator' comments,  I-95 & more",
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
    src: "https://download.samplelib.com/mp3/sample-3s.mp3",
  },
  {
    id: 5,
    title: "9 AM ET: Ukrainian pilots' plea, Biden's 'dictator' comments,  I-95 & more",
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
    src: "https://download.samplelib.com/mp3/sample-3s.mp3",
  },
  {
    id: 6,
    title: "9 AM ET: Ukrainian pilots' plea, Biden's 'dictator' comments,  I-95 & more",
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
    rows: 2,
    cols: 1,
    src: "https://download.samplelib.com/mp3/sample-3s.mp3",
  },
  {
    id: 7,
    title: "9 AM ET: Ukrainian pilots' plea, Biden's 'dictator' comments,  I-95 & more",
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
    src: "https://download.samplelib.com/mp3/sample-3s.mp3",
  },
  {
    id: 8,
    title: "9 AM ET: Ukrainian pilots' plea, Biden's 'dictator' comments,  I-95 & more",
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
    src: "https://download.samplelib.com/mp3/sample-3s.mp3",
  },
  {
    id: 9,
    title: "9 AM ET: Ukrainian pilots' plea, Biden's 'dictator' comments,  I-95 & more",
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
    src: "https://download.samplelib.com/mp3/sample-3s.mp3",
  },
  {
    id: 10,
    title: "9 AM ET: Ukrainian pilots' plea, Biden's 'dictator' comments,  I-95 & more",
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
    src: "https://download.samplelib.com/mp3/sample-3s.mp3",
  },
  {
    id: 11,
    title: "9 AM ET: Ukrainian pilots' plea, Biden's 'dictator' comments,  I-95 & more",
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
    src: "https://download.samplelib.com/mp3/sample-3s.mp3",
  },
  {
    id: 12,
    title: "9 AM ET: Ukrainian pilots' plea, Biden's 'dictator' comments,  I-95 & more",
    created_at: "13.04.2023 14:07",
    media_image: "https://cdn.siasat.com/wp-content/uploads/2022/01/Twitter_Logo.jpeg",
    source_link: "www.twitter.com",
    relevance: "97%",
    authenticity: "97%",
    status: "moderate",
    image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/B774/production/_130246964_b8d3ea51f2ece8e875afcdf77c49869a9cf1fd3c.jpg",
    src: "https://download.samplelib.com/mp3/sample-3s.mp3",
  },
];

interface AudioProps {
  drawerOpen: boolean;
  isRefresh: boolean;
  removePageRefresh(): void;
}

export default function Audio({ drawerOpen, isRefresh, removePageRefresh }: AudioProps) {
  const matches = useMediaQuery("(min-width:1200px)") ? 2 : 4;

  let mergedColumnIds: number[] = [];

  for (let i = matches; i <= itemData.length; i += 10) {
    mergedColumnIds.push(i);
  }

  for (let i = 5; i <= itemData.length; i += 10) {
    mergedColumnIds.push(i);
  }

  useEffect(() => {
    setTimeout(() => {
      removePageRefresh();
    }, 1000);
  }, [isRefresh]);

  return (
    <>
      {isRefresh ? (
        <ContentUILoader type="audio" count={5} drawerOpen={drawerOpen} />
      ) : (
        <>
          {itemData.length > 0 ? (
            <Box sx={{ width: 1, mt: 3 }}>
              <Box display="grid" gridTemplateColumns="repeat(12, 4fr)" width="100%" gap={2}>
                {itemData.map((data, idx) => {
                  return (
                    <Box
                      key={`image-${idx}`}
                      gridColumn={{ xs: "span 12", sm: drawerOpen ? "span 12" : "span 6", lg: drawerOpen ? "span 6" : "span 6", xl: "span 4" }}
                      display={"flex"}
                    >
                      <AudioCard cardData={data} index={idx} />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ) : (
            <CustomNoResults />
          )}
        </>
      )}
    </>
  );
}
