import SaveAsIcon from "@mui/icons-material/SaveAs";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import BackButton from "../../../../components/BackButton";
import CustomDivider from "../../../../components/CustomDivider";
import FormikInputField from "../../../../components/FormikInputField";
import { routes } from "../../../../routes";
import { useHandleRouteClick } from "../../../../routes/hooks";

export default function Header() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const [content, setContent] = useState("New Custom AI Model");
  const [edit, setEdit] = useState(false);

  const handleBackNavigation = () => {
    handleRouteClick(routes.customAI.path);
  };

  const handleSave = (title: string) => {
    setEdit((prev) => !prev);
    if (title) {
      setContent(title);
    }
  };

  const handleEdit = () => {
    setEdit(true);
  };

  return (
    <Box>
      <Box px={1.5}>
        <Box display="flex" gap={1}>
          {!edit && (
            <Typography
              variant="h1"
              color={theme.palette.text.titleLabel}
              sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "30%" }}
            >
              {content}
            </Typography>
          )}
          {edit && (
            <Formik
              initialValues={{
                replyText: content,
              }}
              onSubmit={(values) => {
                handleSave(values.replyText);
                values.replyText = content;
              }}
            >
              {(props) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.handleSubmit();
                  }}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormikInputField
                    name="replyText"
                    placeholder="Type a Message.."
                    size="small"
                    variant="standard"
                    value={content}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    autoComplete="off"
                    sx={{
                      width: "30%",
                      "& .MuiInput-root": {
                        ...theme.typography.body4,
                        color: theme.palette.primary.inactiveIcon,
                      },
                      "&.MuiFormControl-root.MuiTextField-root .MuiInputBase-root": { background: "transparent", boxShadow: "none" },
                      "& .MuiInput-root:before": {
                        borderBottom: "none",
                      },
                      "& .MuiInput-root:after": {
                        borderBottom: "none",
                      },
                      "& .MuiInputBase-root": {
                        ":hover": {
                          ":before": {
                            borderBottom: "none",
                          },
                        },
                      },
                    }}
                    inputProps={{
                      style: {
                        height: "15px",
                        borderRadius: "0px 6px 6px 0px",
                        background: "transparent",
                        color: theme.palette.text.titleLabel,
                      },
                    }}
                    error={props.touched.replyText && Boolean(props.errors.replyText)}
                    helperText={props.touched.replyText && props.errors.replyText ? props.errors.replyText : null}
                  />
                  <Button type="submit" sx={{ minWidth: 0, p: 0 }}>
                    <SaveAsIcon sx={{ color: theme.palette.primary.main }} />
                  </Button>
                </form>
              )}
            </Formik>
          )}
          {!edit && <SaveAsIcon sx={{ color: theme.palette.primary.main }} onClick={handleEdit} />}
        </Box>
        <BackButton onClick={handleBackNavigation} />
      </Box>
      <CustomDivider width="100%" />
    </Box>
  );
}
