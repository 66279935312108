import ComputerIcon from "@mui/icons-material/Computer";
import MonitorIcon from "@mui/icons-material/Monitor";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import { Box, Typography, useTheme } from "@mui/material";

import React from "react";
import BorderedCard from "../../../components/BorderedCard";
import CustomDivider from "../../../components/CustomDivider";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getUserSessions } from "../../../store/userSessions/userSessionsThunk";
import SessionCard from "./SessionCard";

export default function Sessions() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { userSessions, isLoading } = useAppSelector((state) => state.userSessions);

  React.useEffect(() => {
    dispatch(getUserSessions());
  }, []);

  return (
    <BorderedCard>
      <Typography variant="h4" color={theme.palette.text.titleLabel} mb={0.2}>
        Sessions
      </Typography>
      <CustomDivider width="100%" />
      {!isLoading && userSessions.length > 0 ? (
        <>
          <Box>
            <Typography variant="caption1" color={theme.palette.primary.main} sx={{ float: "right", mt: 1.2, mb: 2 }}>
              Active Devices:
              {userSessions.filter((session) => session.is_active).length}
            </Typography>
            {userSessions.filter((session) => session.is_active).length > 0 && (
              <Box sx={{ width: "100%" }}>
                {userSessions
                  .filter((session) => session.is_active)
                  .map((session, index) => {
                    let deviceIcon = session?.device_type === "Mobile" ? <StayCurrentPortraitIcon /> : <MonitorIcon />;
                    return <SessionCard key={`active-${index}`} session={session} deviceIcon={deviceIcon} active={true} />;
                  })}
              </Box>
            )}
          </Box>
          <Box minHeight="2rem">
            <Typography variant="caption1" color={theme.palette.text.main} sx={{ float: "right", mt: 3, mb: 2 }}>
              Recent Devices: {userSessions.filter((session) => !session.is_active).length}
            </Typography>
            {userSessions.filter((session) => !session.is_active).length > 0 && (
              <Box sx={{ width: "100%" }}>
                {userSessions
                  .filter((session) => !session.is_active)
                  .map((session, index) => {
                    let deviceIcon =
                      session?.device_type === "Desktop" ? (
                        <ComputerIcon />
                      ) : session?.device_type === "Mobile" ? (
                        <StayCurrentPortraitIcon />
                      ) : (
                        <MonitorIcon />
                      );
                    return <SessionCard key={`recent-${index}`} session={session} deviceIcon={deviceIcon} active={false} />;
                  })}
              </Box>
            )}
          </Box>
        </>
      ) : (
        <Box sx={{ p: 2, textAlign: "center" }}>
          <Typography variant="caption">{isLoading ? "Loading..." : "No Sessions"}</Typography>
        </Box>
      )}
    </BorderedCard>
  );
}
