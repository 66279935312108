import { Box, CardMedia, Paper, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import CardTitle from "../components/CardTitle";
import Header from "../components/Header";
import { routes } from "../routes";

export interface TabRecord {
  id: string | number;
  title: string;
  disabled?: boolean;
  component: React.ReactNode;
}

export interface TabLayoutProps {
  children?: React.ReactNode;
  tabs: TabRecord[];
  subTab?: boolean;
  primarySubTab?: string;
  queryId?: string;
}

export default function TabLayout({ children, tabs, subTab, primarySubTab, queryId }: TabLayoutProps) {
  const theme = useTheme();
  const location = useLocation();

  const pathdata = Object.keys(routes).filter((routeKey) => routes[routeKey].path === location.pathname);
  const matchedTab = pathdata && routes[pathdata[0]];

  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = React.useState<TabRecord>(tabs[0]);

  // console.log("Query Id , ", queryId);
  useEffect(() => {
    if (!searchParams.get("tab")) {
      if (subTab && primarySubTab) {
        if (queryId) {
          setSearchParams({ tab: String(tabs[0].id), subtab: primarySubTab, query: queryId });
        } else {
          setSearchParams({ tab: String(tabs[0].id), subtab: primarySubTab });
        }
      } else {
        setSearchParams({ tab: String(tabs[0].id) });
      }
    }
  }, [searchParams]);

  const handleChangeActiveTab = (tabId: string | number) => {
    setActiveTab(tabs.find((tab) => tab.id === tabId) || tabs[0]);
    setSearchParams({ tab: String(tabId) });
  };

  const renderTabContent = (tabId: string | number) => {
    const firstTab: boolean = tabs[0].id === tabId;
    return (
      <Paper
        sx={{
          background: theme.palette.background.default,
          boxShadow: theme.palette.dashboardContainer.insetBoxShadow,
          borderRadius: firstTab ? "0 18px 18px 18px" : "18px",
          paddingTop: "0px",
          height: "100%",
          overflowY: "scroll",
          py: 2,
          px: 2,
          [theme.breakpoints.down("md")]: { boxShadow: "none" },
        }}
      >
        {tabs.find((tab) => tab.id === tabId)?.component}
      </Paper>
    );
  };

  return (
    <>
      <Box
        display="flex"
        position="relative"
        sx={{
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        }}
      >
        {tabs.map((tab) => {
          return (
            <React.Fragment key={tab.id}>
              <Paper
                onClick={() => {
                  handleChangeActiveTab(tab.id);
                }}
                sx={{
                  width: "12rem",
                  cursor: tab.id === searchParams.get("tab") ? "default" : "pointer",
                  borderRadius: "18px 18px 0px 0px",
                  boxShadow: "none",
                  backgroundColor: "transparent",
                  position: "relative",
                  backgroundImage: "none",
                  "&. MuiPaper-root": {
                    backgroundColor: "transparent",
                  },
                  textAlign: "center",
                }}
              >
                <CardMedia component="img" image="/assets/tabcurve.png" alt="Tab Image" />
                <CardTitle
                  isOpen={tab.id === searchParams.get("tab")}
                  title={tab.title}
                  sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "100%", ml: "-10px" }}
                />
                {tab.id === searchParams.get("tab") && (
                  <Box
                    sx={{
                      position: "absolute",
                      height: "1.5rem",
                      width: "93%",
                      top: "3.6rem",
                      background: theme.palette.background.default,
                    }}
                  ></Box>
                )}
              </Paper>
            </React.Fragment>
          );
        })}
        {matchedTab?.header && <Header sx={{ [theme.breakpoints.down("md")]: { display: "none" } }} showOptions={matchedTab?.header} />}
      </Box>
      <Box height="100%">{renderTabContent(activeTab.id)}</Box>
      {children}
    </>
  );
}
