import BusinessIcon from "@mui/icons-material/Business";
import CloseIcon from "@mui/icons-material/Close";
import Person from "@mui/icons-material/Person";
import { Box, Typography, useTheme } from "@mui/material";
import CustomCloseIconButton from "../../../../../components/CustomCloseIconButton";
import CustomStatus from "../../../../../components/CustomStatus";
import CustomTextButton from "../../../../../components/CustomTextButton";
import Classes from "../../../../../components/Scrollbar.module.css";

interface EntityProps {
  open: boolean;
  openDrawer(): void;
}

const connectionData = [
  { text: "Entity Name", type: "entity" },
  { text: "Entity Name", type: "entity" },
  { text: "Entity Name", type: "entity" },
  { text: "Entity Name", type: "entity" },
  { text: "Entity Name", type: "entity" },
  { text: "Entity Name", type: "entity" },
  { text: "Entity Name", type: "entity" },
  { text: "Entity Name", type: "entity" },
  { text: "Entity Name", type: "entity" },
  { text: "Entity Name", type: "entity" },
  { text: "Entity Name", type: "entity" },
  { text: "Entity Name", type: "entity" },
  { text: "Entity Name", type: "entity" },
  { text: "Entity Name", type: "entity" },
  { text: "Organization Name", type: "organization" },
  { text: "Organization Name", type: "organization" },
  { text: "Organization Name", type: "organization" },
  { text: "Organization Name", type: "organization" },
  { text: "Organization Name", type: "organization" },
  { text: "Organization Name", type: "organization" },
  { text: "Organization Name", type: "organization" },
  { text: "Organization Name", type: "organization" },
  { text: "Organization Name", type: "organization" },
  { text: "Organization Name", type: "organization" },
  { text: "Organization Name", type: "organization" },
];

export default function EntityConnections({ open, openDrawer }: EntityProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "300px",
        height: "100%",
        position: "absolute",
        overflow: "hidden",
        right: "16px",
        top: "216px",
        zIndex: 10000,
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        borderRadius: 2,
        p: 2,
      }}
    >
      <Box display="flex" gap={5}>
        <Typography variant="h3" fontSize="22px">
          Entity Name
        </Typography>
        <CustomCloseIconButton onClick={openDrawer} sx={{ ml: "auto" }}>
          <CloseIcon sx={{ width: "16px", height: "16px" }} />
        </CustomCloseIconButton>
      </Box>
      <Box mt={3} display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="body5" color={theme.palette.text.titleLabel}>
          Entity type: Person
        </Typography>
        <Typography variant="body5" color={theme.palette.text.titleLabel}>
          Entity Sub type: Goverment worker
        </Typography>
        <Typography variant="body5" color={theme.palette.text.titleLabel}>
          Location: USA
        </Typography>
      </Box>
      <CustomStatus label="high" status="high" sx={{ px: 4, height: "28px", mt: 2 }} />
      <Box display="flex" gap={2} mt={3}>
        <Typography variant="h4">Connections (319)</Typography>
        <CustomTextButton sx={{ ml: "auto" }}>View All</CustomTextButton>
      </Box>
      <Box
        mt={2}
        sx={{
          height: "50%",
          overflow: "auto",
        }}
        className={Classes.scroll_dark}
      >
        {connectionData.map((conData, idx) => (
          <Box display="flex" mb={1} gap={1} key={idx}>
            {conData?.type === "entity" && <Person sx={{ fontSize: "16px", color: theme.palette.text.tableHeader, mt: "-3px" }} />}
            {conData?.type === "organization" && <BusinessIcon sx={{ fontSize: "16px", color: theme.palette.text.tableHeader, mt: "-3px" }} />}

            <Typography variant="subtitle1" color={theme.palette.text.titleLabel}>
              {conData?.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
