import { Box, Link, Typography, useTheme } from "@mui/material";
import CustomDivider from "../../../../components/CustomDivider";
import QueryCardKeyValueText from "../../../../components/QueryCardKeyValueText";

interface PaymentDetailsProps {
  amount: number;
}

export default function PlanInfo({ amount }: PaymentDetailsProps) {
  const theme = useTheme();
  const InfoDetails = (label: string, value: string) => (
    <Box display="flex" gap={5}>
      <Typography variant="body3" color={theme.palette.text.captionColor}>
        {label}:
      </Typography>
      <Typography variant="subtitle1" color={theme.palette.text.titleLabel}>
        {value}
      </Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        background: theme.palette.background.gradientLight1,
        borderRadius: 2,
        boxShadow: theme.palette.background.serviceContainerShadow,
        p: 4,
        mt: 2,
      }}
    >
      <Typography variant="h2" color={theme.palette.text.titleLabel}>
        Plan information
      </Typography>
      <Box display="flex" flexDirection="column" gap={1.5} my={2}>
        <QueryCardKeyValueText keyName={"First payment date"} value={"Jule 17th, 2023"} />
        <QueryCardKeyValueText keyName={"Next payment date"} value={"Jule 17th, 2024"} />
        <QueryCardKeyValueText keyName={"Amount due"} value={`$${amount}`} />
      </Box>
      <CustomDivider width="100%" />
      <Box mt={3} display="flex" flexDirection="column" gap={2}>
        <Typography variant="body3" color={theme.palette.text.captionColor}>
          Your subscription will automatically renew 17.07.2024. You will be charged 300$ per year for Professional Plan. You can upgrade or cancel
          your plan at any time.
        </Typography>
        <Typography variant="body3" color={theme.palette.text.captionColor}>
          If you have any questions please contact us{" "}
          <Link href="#" underline="none">
            lowcode_support@uk
          </Link>
        </Typography>
      </Box>
      <Box width="100%" sx={{ display: "flex", background: theme.palette.background.paymentInfoBg, borderRadius: 2, p: 2, mt: 4 }}>
        <Typography variant="body1" color={theme.palette.text.titleLabel}>
          Total due:
        </Typography>
        <Typography variant="body1" color={theme.palette.text.titleLabel} ml="auto">
          ${amount}
        </Typography>
      </Box>
    </Box>
  );
}
