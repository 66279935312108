import { Box, Grid, Typography, useTheme } from "@mui/material";
import BorderedCard from "../../../components/BorderedCard";
import PrimaryLoadingButton from "../../../components/PrimaryLoadingButton";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { AVAILABLE_SOLUTIONS } from "../../../utils/constants";

export default function AvailableSolutions() {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const handleNavigation = (id: number) => {
    handleRouteClick(routes.specificSolution.path.replace(":id", String(id)));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Typography variant="h2" color={theme.palette.text.titleLabel}>
        Available Solutions
      </Typography>
      <Grid container spacing={3}>
        {AVAILABLE_SOLUTIONS.map((sol, idx) => {
          return (
            <Grid item xs={12} sm={6} lg={4} xl={2} key={`solution-${idx}`}>
              <BorderedCard
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.4,
                  p: 2,
                  background: theme.palette.background.cardBgColor,
                  borderRadius: 2,
                }}
              >
                <Box onClick={() => handleNavigation(sol?.id)} sx={{ cursor: "pointer", display: "flex", flexDirection: "column", gap: 1.4 }}>
                  <img width="100%" alt={String(sol.id)} src={sol.thumbnail} />
                  <Typography variant="caption1" color={theme.palette.primary.light}>
                    {sol.subtitle}
                  </Typography>
                  <Typography variant="h4" fontWeight={400}>
                    {sol.title}
                  </Typography>
                  <Typography variant="body4" color={theme.palette.text.captionColor}>
                    {sol.description}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 3 }}>
                  <Typography variant="h2">{sol.price}</Typography>
                  <PrimaryLoadingButton size="small" variant="contained" fullWidth sx={{ ...theme.typography.bigButton }}>
                    Add To Cart
                  </PrimaryLoadingButton>
                </Box>
              </BorderedCard>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
