import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Box, Checkbox, FormControlLabel, FormGroup, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { BsDownload } from "react-icons/bs";
import { MultiSelectValueType } from "../../../../components/CheckboxFilterButton";
import CustomDivider from "../../../../components/CustomDivider";
import CustomMultiselect from "../../../../components/CustomMultiselect";
import CustomSwitch from "../../../../components/CustomSwitch";
import CustomTab from "../../../../components/CustomTab";
import MultiChipInputField from "../../../../components/MultiChipInputField";
import PrimaryLoadingButton from "../../../../components/PrimaryLoadingButton";
import RadioFilterButton from "../../../../components/RadioFilterButton";
import { AI_Models_Option, CATEGORIES, DATA_SOURCES, SEARCH_AREA, SOCIAL_MEDIA_DATA_SOURCES } from "../../../../utils/constants";
import ImportDataSourceDialog from "./ImportDataSourceDialog";

const tabs = [
  {
    id: "select",
    title: "Select Data Source",
    disable: false,
    component: <Box>Select data</Box>,
  },
  {
    id: "import",
    title: "Import Data Source",
    disable: false,
    component: <Box>Import data</Box>,
  },
];

export interface QueryFormData {
  aiModels: MultiSelectValueType[];
  dataSource: string;
  data: any;
}

interface QueryProps {
  submitCanvasData(formData: QueryFormData): void;
  getDataSources(sources: string): void;
  getSelectedModels(models: MultiSelectValueType[]): void;
  getUploadedData(data: any): void;
  getSelectedDatabase(data: string): void;
}

interface SearchType {
  id: string;
  text: string;
  active: boolean;
}

export default function QuerySection({ submitCanvasData, getDataSources, getSelectedModels, getUploadedData, getSelectedDatabase }: QueryProps) {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [files, setFiles] = useState<File | null>(null);
  const [searchArea, setSearchArea] = useState<string>("");
  const [dataSource, setDataSource] = useState<string>("");
  const [dataType, setDataType] = useState<SearchType[]>(DATA_SOURCES);
  const [models, setModels] = useState<MultiSelectValueType[]>([]);
  const [entities, setEntities] = useState<MultiSelectValueType[]>([]);
  const [category, setCategory] = useState<string>("");
  const [keywords, setKeywords] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const handleToggle = () => setOpen(!open);

  const handleSearchArea = (value: string) => {
    setSearchArea(value);
  };
  const handleSelectDataSource = (value: string) => {
    setDataSource(value);
    getDataSources(value);
    setFiles(null);
  };
  const handleDataTypeChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    let updatedTypes = [...dataType];
    updatedTypes[index].active = event.target.checked;
    setDataType(updatedTypes);
  };
  const handleSelectModel = (value: MultiSelectValueType[]) => {
    setModels(value);
    if (value) {
      getSelectedModels(value);
    }
  };
  const handleSelectEntities = (value: MultiSelectValueType[]) => {
    setEntities(value);
  };
  const handleCategoryChange = (value: string) => {
    setCategory(value);
  };
  const handleKeywordsChange = (value: string[]) => {
    setKeywords(value);
  };
  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };
  const handleGetFile = (file: File | null) => {
    setFiles(file);
    getUploadedData(file);
  };
  const handleRemoveFiles = () => {
    setFiles(null);
    getUploadedData({});
    setDataSource("");
    getDataSources("");
  };
  const handleGetDatabase = (database: string) => {
    getSelectedDatabase(database);
  };

  return (
    <Box
      sx={{
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        height: activeTab === "select" ? "80%" : "100%",
        overflowY: "scroll",
        borderRadius: 2,
        mt: 1,
        py: 2,
        px: 3,
      }}
    >
      <CustomTab
        tabs={tabs}
        handleActiveTab={handleActiveTab}
        sx={{ border: `1px solid ${theme.palette.primary.tertiary}`, textAlign: "center", p: "1px" }}
        width="100%"
        height="32px"
        activeTab={activeTab}
      ></CustomTab>
      {activeTab === "select" ? (
        <>
          <Box mt={4}>
            <Typography variant="h4" mb={1} color={theme.palette.dashboardContainer.cardTitleColor}>
              Search area
            </Typography>
            <RadioFilterButton
              options={SEARCH_AREA}
              placeholder="Select Search Area"
              onChange={handleSearchArea}
              sx={{ width: "100%" }}
              endIcon={true}
              textColor={theme.palette.text.titleLabel}
            />
          </Box>
          <Box mt={4}>
            <Typography variant="h4" mb={1} color={theme.palette.dashboardContainer.cardTitleColor}>
              Data Source
            </Typography>
            {/* <CustomMultiselect
          label="Data source"
          onChange={(value) => handleSelectDataSource(value)}
          options={SOCIAL_MEDIA_DATA_SOURCES}
          showSearch={false}
          placeholder="Select Data Sources"
        /> */}
            <RadioFilterButton
              options={SOCIAL_MEDIA_DATA_SOURCES}
              placeholder="Select the type of your Data"
              onChange={handleSelectDataSource}
              sx={{ width: "100%" }}
              endIcon={true}
              textColor={theme.palette.text.titleLabel}
            />
          </Box>
          <Box mt={4}>
            <Typography variant="h4" mb={0.5} color={theme.palette.dashboardContainer.cardTitleColor} textTransform="none">
              Select the type of data*
            </Typography>
            <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
              {dataType.map((dataType, index) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={dataType.active}
                        onChange={(e) => handleDataTypeChange(e, index)}
                        name={dataType.text}
                        disableRipple={true}
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          "& .MuiSvgIcon-root": {
                            fill: (theme) => (dataType.active ? theme.palette.checkbox.default : theme.palette.checkbox.darkBorder),
                          },
                          "& .MuiButtonBase-root.MuiCheckbox-root": {
                            pl: "5px",
                          },
                          "&:hover .MuiSvgIcon-root": {
                            fill: (theme) =>
                              dataType.active
                                ? theme.palette.checkbox.default
                                : "linear-gradient(135deg, rgba(38, 46, 63, 0.70) 0%, rgba(29, 35, 48, 0.70) 100%)",
                            stroke: (theme) => (dataType.active ? "none" : theme.palette.checkbox.darkBorder),
                          },
                          "&:active .MuiSvgIcon-root": {
                            fill: (theme) => (dataType.active ? "#245785" : theme.palette.checkbox.darkBorder),
                          },
                        }}
                      />
                    }
                    label={
                      <Typography variant="subtitle2" color={(theme) => theme.palette.text.titleLabel}>
                        {dataType.text}
                      </Typography>
                    }
                  />
                );
              })}
            </FormGroup>
          </Box>
        </>
      ) : (
        <>
          <PrimaryLoadingButton
            sx={{
              ...theme.typography.bigButton,
              width: "100%",
              mt: 4,
              mb: 0,
              height: "2.5rem",
            }}
            onClick={handleToggle}
          >
            <BsDownload size="16px" style={{ marginRight: "4px" }} color={theme.palette.checkbox.darkBorder} />
            <Typography sx={{ ml: "4px" }}>Import Data Source</Typography>
          </PrimaryLoadingButton>
          {files && (
            <Box sx={{ display: "flex", gap: 1, border: theme.palette.additionalColors.databasecardBorder, p: 1, borderRadius: 2, mt: 2, mb: 3 }}>
              <InsertDriveFileIcon sx={{ color: theme.palette.text.tableHeader, fontSize: "18px" }} />
              <Typography variant="caption" color={theme.palette.text.captionColor} pt={0.4}>{`${
                Object.keys(files).length
              } files downloaded`}</Typography>
              <CloseIcon sx={{ width: "16px", height: "16px", ml: "auto", color: theme.palette.error.main }} onClick={handleRemoveFiles} />
            </Box>
          )}
        </>
      )}
      <Box mt={2.5} mb={4}>
        <Box display="flex" gap={1} mb={1}>
          <Typography variant="h4" color={theme.palette.text.tableHeader}>
            AI Model
          </Typography>
          <InfoOutlinedIcon sx={{ color: theme.palette.primary.main, fontSize: "16px" }} />
        </Box>
        <CustomMultiselect
          label="Query Type"
          onChange={(value) => handleSelectModel(value)}
          options={AI_Models_Option}
          showSearch={false}
          placeholder="Select AI Models"
        />
      </Box>
      <CustomDivider sx={{ width: "100%" }} />
      <Box mt={4} mb={1}>
        <Box display="flex" gap={1} mb={1}>
          <Typography variant="h4" color={theme.palette.text.tableHeader}>
            Search
          </Typography>
          <InfoOutlinedIcon sx={{ color: theme.palette.primary.main, fontSize: "16px" }} />
        </Box>
        {/* replace search box */}
        <CustomMultiselect
          label="Query Type"
          onChange={(value) => handleSelectEntities(value)}
          options={CATEGORIES}
          showSearch={false}
          placeholder="Search entities"
        />
        <Typography variant="body4" mt={0.5} color={theme.palette.text.captionColor}>
          To analyse two or more entities, write them with comma separated
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <InfoOutlinedIcon sx={{ color: theme.palette.primary.main, fontSize: "16px" }} />
        <FormControlLabel
          control={<CustomSwitch defaultChecked />}
          label="Link entities"
          labelPlacement="start"
          sx={{ "& .MuiFormControlLabel-label": { ...theme.typography.subtitle2, color: theme.palette.text.titleLabel, ml: "-5px" } }}
        />
      </Box>
      <Box
        sx={{
          color: theme.palette.text.titleLabel,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          my: 2,
          borderRadius: "2px",
          border: `1px solid`,
          borderColor: "rgba(176, 182, 193, 0.15)",
          height: "25px",
          width: "35px",
        }}
      >
        <Typography variant="body4">OR</Typography>
      </Box>
      <Box mt={3} mb={3}>
        <Typography variant="h4" mb={1} color={theme.palette.dashboardContainer.cardTitleColor}>
          Category
        </Typography>
        <RadioFilterButton
          options={CATEGORIES}
          placeholder="Select Category"
          onChange={(value) => handleCategoryChange(value)}
          sx={{ width: "100%" }}
          endIcon={true}
          textColor={theme.palette.text.titleLabel}
        />
      </Box>
      <Box mt={3} mb={3}>
        <Typography variant="h4" mb={1} color={theme.palette.dashboardContainer.cardTitleColor}>
          Keywords
        </Typography>
        <MultiChipInputField placeholder="Text here..." separator={","} onValueChange={handleKeywordsChange} />
      </Box>
      <ImportDataSourceDialog open={open} handleToggle={handleToggle} getFile={handleGetFile} getDatabase={handleGetDatabase} />
    </Box>
  );
}
