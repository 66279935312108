import Other from "@mui/icons-material/BrokenImage";
import BusinessIcon from "@mui/icons-material/Business";
import EventIcon from "@mui/icons-material/Event";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import Person from "@mui/icons-material/Person";
import TopicIcon from "@mui/icons-material/Topic";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

interface FilterLegendProps {
  items: boolean[];
  setItems: React.Dispatch<React.SetStateAction<boolean[]>>;
}
export default function FilterLegend({ items, setItems }: FilterLegendProps) {
  const theme = useTheme();
  const handleChange = (i: number) => {
    setItems((prevItems) => {
      const newItems = [...prevItems]; // Create a copy of the previous state array
      newItems[i] = !newItems[i]; // Toggle the boolean value at index i
      return newItems; // Return the updated array
    });
  };
  const legendData = [
    {
      text: "Person",
      icon: (
        <Person
          sx={{
            fontSize: "18px",
            transition: "color 0.3s ease",
            color: items[1] ? theme.palette.background.barGraphLight : theme.palette.text.tableHeader,
          }}
        />
      ),
    },
    {
      text: "Organization",
      icon: (
        <BusinessIcon
          sx={{
            fontSize: "18px",
            transition: "color 0.3s ease",
            color: items[4] ? theme.palette.background.barGraphLight : theme.palette.text.tableHeader,
          }}
        />
      ),
    },
    {
      text: "Location",
      icon: (
        <FmdGoodIcon
          sx={{
            fontSize: "18px",
            transition: "color 0.3s ease",
            color: items[0] ? theme.palette.background.barGraphLight : theme.palette.text.tableHeader,
          }}
        />
      ),
    },
    {
      text: "Event",
      icon: (
        <EventIcon
          sx={{
            fontSize: "18px",
            transition: "color 0.3s ease",
            color: items[3] ? theme.palette.background.barGraphLight : theme.palette.text.tableHeader,
          }}
        />
      ),
    },
    {
      text: "Topic",
      icon: (
        <TopicIcon
          sx={{
            fontSize: "18px",
            transition: "color 0.3s ease",
            color: items[2] ? theme.palette.background.barGraphLight : theme.palette.text.tableHeader,
          }}
        />
      ),
    },
    {
      text: "Other",
      icon: (
        <Other
          sx={{
            fontSize: "18px",
            transition: "color 0.3s ease",
            color: items[5] ? theme.palette.background.barGraphLight : theme.palette.text.tableHeader,
          }}
        />
      ),
    },
  ];

  return (
    <Box display="flex" gap={2}>
      <Typography variant="subtitle2" color={theme.palette.text.titleLabel}>
        Legend
      </Typography>
      <Box display="flex" gap={1} mt={"-3px"}>
        {legendData.map((data) => (
          <Box
            sx={{ cursor: "pointer" }}
            display="flex"
            gap={0.5}
            key={data?.text}
            onClick={() => {
              if (data?.text === "Location") {
                handleChange(0);
              }
              if (data?.text === "Person") {
                handleChange(1);
              }
              if (data?.text === "Topic") {
                handleChange(2);
              }
              if (data?.text === "Event") {
                handleChange(3);
              }
              if (data?.text === "Organization") {
                handleChange(4);
              }
              if (data?.text === "Other") {
                handleChange(5);
              }
            }}
          >
            <Box>{data?.icon}</Box>
            <Typography variant="body5" color={theme.palette.text.titleLabel} mt={0.7}>
              {data?.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
