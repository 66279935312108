import { createSlice } from "@reduxjs/toolkit";
import { getConnections, getEntityConnections, getEntityCrawlData, getTieredConnections } from "./analysisThunk";

export interface ConnectionsInitialStateType {
  isLoading: boolean;
  connections: Record<string, any>[];
  entityConnections: Record<string, any>[];
  entityCrawlData: Record<string, any>[];
  tieredConnections: Array<Record<string, any>[]>;
}

const connectionsInitialState: ConnectionsInitialStateType = {
  isLoading: false,
  connections: [],
  tieredConnections: [],
  entityConnections: [],
  entityCrawlData: [],
};

export const connectionsSlice = createSlice({
  name: "connections",
  initialState: connectionsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    // get connections
    builder
      .addCase(getConnections.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getConnections.fulfilled, (state, action) => {
        state.isLoading = false;
        state.connections = action.payload.data?.connections;
      })
      .addCase(getConnections.rejected, (state, action) => {
        state.isLoading = false;
      });

    // get connections
    builder
      .addCase(getEntityConnections.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getEntityConnections.fulfilled, (state, action) => {
        state.isLoading = false;
        state.entityConnections = action.payload.data?.connections;
      })
      .addCase(getEntityConnections.rejected, (state, action) => {
        state.isLoading = false;
      });

    // get tiered connections
    builder
      .addCase(getTieredConnections.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTieredConnections.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tieredConnections = JSON.parse(action.payload.data?.tiers);
      })
      .addCase(getTieredConnections.rejected, (state, action) => {
        state.isLoading = false;
      });

    // get entity crawl data
    builder
      .addCase(getEntityCrawlData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getEntityCrawlData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.entityCrawlData = action.payload.data?.crawl_data;
      })
      .addCase(getEntityCrawlData.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const connectionsActions = connectionsSlice.actions;
export default connectionsSlice;
