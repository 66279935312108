import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, InputAdornment, Stack, Typography, useTheme } from "@mui/material";
import { GridCallbackDetails, GridColDef, GridRowSelectionModel, GridValueGetterParams } from "@mui/x-data-grid";
import React from "react";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
import { LuArrowDownUp } from "react-icons/lu";
import { RiDeleteBin6Line } from "react-icons/ri";
import CustomDataTable from "../../../components/CustomDataTable";
import CustomIconButton from "../../../components/CustomIconButton";
import CustomInputField from "../../../components/CustomInputField";
import CustomTertiaryButton from "../../../components/CustomTertiaryButton";
import CustomTextButton from "../../../components/CustomTextButton";
import RadioFilterButton from "../../../components/RadioFilterButton";
import SubmitLoadingDeleteButton from "../../../components/SubmitLoadingDeleteButton";
import { formatDateTimeIntoCurrentTimeZone } from "../../../utils/helper";
import DeleteQueryReportDialog from "./DeleteQueryReportDialog";

const sortingFilterOptions = [
  {
    id: "new-first",
    text: "New First",
  },
  {
    id: "old-first",
    text: "Old First",
  },
  {
    id: "important-first",
    text: "Important First",
  },
];

export default function QueryReports() {
  const theme = useTheme();
  const [selectedItems, setSelectedItems] = React.useState<GridRowSelectionModel>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const columns: GridColDef[] = [
    { field: "id", headerName: "", width: 90, sortable: false },
    {
      field: "save",
      headerName: "",
      width: 90,
      sortable: false,
      renderCell: (params) => {
        let saved: boolean = false;
        const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
          saved = !saved;
          console.log(saved);
        };

        return <CustomTextButton onClick={onClick}>{saved ? <BsBookmarkFill /> : <BsBookmark />}</CustomTextButton>;
      },
    },
    {
      field: "reportName",
      headerName: "Report name",
      width: 150,
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "format",
      headerName: "Format",
      type: "number",
      width: 110,
      editable: false,
      sortable: false,
    },
    {
      field: "created",
      headerName: "Created",
      sortable: false,
      width: 160,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => formatDateTimeIntoCurrentTimeZone(new Date()),
    },
    {
      field: "downloadAndSave",
      headerName: "",
      width: 120,
      sortable: false,
      renderCell: (params) => {
        let saved: boolean = false;
        const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
          saved = !saved;
          console.log(saved);
        };

        return (
          <Stack direction="row" gap={2}>
            <CustomIconButton sx={{ color: theme.palette.text.default }}>
              <FiDownload />
            </CustomIconButton>
            <CustomIconButton sx={{ color: theme.palette.text.default }}>
              <RiDeleteBin6Line />
            </CustomIconButton>
          </Stack>
        );
      },
    },
  ];

  const rows = [
    { id: 1, type: "Report Type", reportName: "Report-02348-Connections", format: "PDF" },
    { id: 2, type: "Report Type", reportName: "Report-02348-Connections", format: "PDF" },
    { id: 3, type: "Report Type", reportName: "Report-02348-Connections", format: "PDF" },
    { id: 4, type: "Report Type", reportName: "Report-02348-Connections", format: "PDF" },
    { id: 5, type: "Report Type", reportName: "Report-02348-Connections", format: "PDF" },
    { id: 6, type: "Report Type", reportName: "Report-02348-Connections", format: "PDF" },
    { id: 7, type: "Report Type", reportName: "Report-02348-Connections", format: "PDF" },
    { id: 8, type: "Report Type", reportName: "Report-02348-Connections", format: "PDF" },
    { id: 9, type: "Report Type", reportName: "Report-02348-Connections", format: "PDF" },
    { id: 10, type: "Report Type", reportName: "Report-02348-Connections", format: "PDF" },
    { id: 11, type: "Report Type", reportName: "Report-02348-Connections", format: "PDF" },
    { id: 12, type: "Report Type", reportName: "Report-02348-Connections", format: "PDF" },
    { id: 13, type: "Report Type", reportName: "Report-02348-Connections", format: "PDF" },
    { id: 14, type: "Report Type", reportName: "Report-02348-Connections", format: "PDF" },
    { id: 15, type: "Report Type", reportName: "Report-02348-Connections", format: "PDF" },
  ];

  const toggleDelete = () => {
    setOpenDeleteDialog((prev) => !prev);
  };

  const handleSelectAll = () => {
    setSelectedItems(rows.map((row, index) => row.id));
  };

  const handleDeselectAll = () => {
    setSelectedItems([]);
  };

  return (
    <>
      <Box>
        {selectedItems.length > 0 && (
          <Box
            sx={{
              p: 2,
              borderRadius: 1,
              background: theme.palette.background.dark,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Typography variant="body3" color={theme.palette.text.captionColor}>
                {selectedItems.length} {selectedItems.length > 1 ? "items" : "item"} selected
              </Typography>
              <CustomTertiaryButton sx={{ ...theme.typography.smallButton }} onClick={handleSelectAll}>
                Select All
              </CustomTertiaryButton>
              <CustomTertiaryButton sx={{ ...theme.typography.smallButton }} onClick={handleDeselectAll}>
                Deselect
              </CustomTertiaryButton>
            </Box>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <CustomTertiaryButton sx={{ ...theme.typography.smallButton }}>Cancel</CustomTertiaryButton>
              <SubmitLoadingDeleteButton onClick={toggleDelete} size="small" isLoading={false} color="error">
                Delete
              </SubmitLoadingDeleteButton>
              <DeleteQueryReportDialog open={openDeleteDialog} handleToggle={toggleDelete} queryReportId={selectedItems} />
            </Box>
          </Box>
        )}
      </Box>
      <Grid container>
        <Grid item xs={6}>
          <CustomInputField
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
            placeholder="Search"
            size="small"
            variant="outlined"
            autoComplete="off"
            sx={{ width: { lg: "40%", md: "100%" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end" gap={2}>
          <RadioFilterButton label="Report Type" onChange={(value) => {}} options={sortingFilterOptions} defaultValue={sortingFilterOptions[0].id} />
          <RadioFilterButton
            label="Sorting"
            onChange={(value) => {}}
            options={sortingFilterOptions}
            startIcon={<LuArrowDownUp color={theme.palette.primary.main} size={16} />}
            defaultValue={sortingFilterOptions[0].id}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          py: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderTop: "1px solid",
          borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
          background: theme.palette.dashboardContainer.containerBackground,
        }}
      >
        <Box sx={{}}>
          <CustomDataTable
            onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails): void => {
              console.log("SelectedRow", rowSelectionModel, typeof rowSelectionModel);
              setSelectedItems(rowSelectionModel);
            }}
            rowSelectionModel={selectedItems}
            rows={rows}
            columns={columns}
            columnVisibilityModel={{
              id: false,
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            checkboxSelection
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            disableVirtualization
            disableRowSelectionOnClick
            isCellEditable={() => false}
            disableEval
            pagination
          />
        </Box>
      </Box>
    </>
  );
}
