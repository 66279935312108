import TopicIcon from "@mui/icons-material/Topic";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { IoDiamondOutline } from "react-icons/io5";
import CustomTooltip from "../../../components/CustomTooltip";
import RoundedButton from "../../../components/RoundedButton";
import { AI_MODELS, RISK_FACTORS, SOCIAL_MEDIA_DATA_SOURCES } from "../../../utils/constants";

export interface TabType {
  title: string;
  id: number | string;
  component: React.ReactNode;
}

const DataSource = () => {
  return (
    <Box display="flex" gap={1} flexWrap="wrap">
      {SOCIAL_MEDIA_DATA_SOURCES.map((item) => {
        return (
          <RoundedButton key={item.id}>
            <TopicIcon sx={{ fontSize: "20px" }} />
            &nbsp;&nbsp;
            {item.text}
          </RoundedButton>
        );
      })}
    </Box>
  );
};

const AIModels = () => {
  const theme = useTheme();

  return (
    <Box display="flex" gap={1} flexWrap="wrap">
      {AI_MODELS.map((item) => {
        return (
          <CustomTooltip
            key={item.id}
            title={
              <Box p={0.5}>
                <Typography variant="subtitle2" color={theme.palette.text.titleLabel} mb={0.1}>
                  {item.name}
                </Typography>
                <Typography variant="caption2" color={theme.palette.text.titleLabel}>
                  {item.description}
                </Typography>
              </Box>
            }
          >
            <div>
              <RoundedButton>
                <IoDiamondOutline />
                &nbsp;
                {item.name}
              </RoundedButton>
            </div>
          </CustomTooltip>
        );
      })}
    </Box>
  );
};

const RiskFactors = () => {
  return (
    <Box display="flex" gap={1} flexWrap="wrap">
      {RISK_FACTORS.map((item) => {
        return (
          <RoundedButton key={item.id} disabled={item.disabled}>
            {item.name}
          </RoundedButton>
        );
      })}
    </Box>
  );
};

const tabs: TabType[] = [
  {
    title: "Data Source",
    id: "data_source",
    component: <DataSource />,
  },
  {
    title: "AI Models",
    id: "ai_models",
    component: <AIModels />,
  },
  {
    title: "Risk Factor",
    id: "risk_factors",
    component: <RiskFactors />,
  },
];

export default function SolutionDialogTabs() {
  const theme = useTheme();
  const [activeTabId, setActiveTabId] = React.useState<string | number>(tabs[0].id);

  const renderTabContent = (tabId: string | number) => {
    return (
      <Box
        sx={{
          border: `2px solid ${theme.palette.additionalColors.border}`,
          borderRadius: 1,
          padding: 2,
          minHeight: "12rem",
          overflowY: "auto",
        }}
      >
        {tabs.find((tab) => tab.id === tabId)?.component}
      </Box>
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box
        sx={{
          borderRadius: "6px",
          border: `1px solid ${theme.palette.primary.dark}`,
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          gap: 2,
          width: "fit-content",
        }}
      >
        {tabs.map((tab) => {
          return (
            <Box
              onClick={() => setActiveTabId(tab.id)}
              sx={{
                cursor: "pointer",
                fontSize: "14px",
                whiteSpace: "nowrap",
                px: 3,
                py: 1,
                borderRadius: "4px",
                m: 0.2,
                background: activeTabId === tab.id ? theme.palette.primary.main : "transparent",
                color: activeTabId === tab.id ? theme.palette.background.default : theme.palette.text.titleLabel,
              }}
              key={tab.id}
            >
              {tab.title}
            </Box>
          );
        })}
      </Box>
      <Box sx={{ background: theme.palette.background.default }}>{renderTabContent(activeTabId)}</Box>
      {activeTabId === "risk_factors" && (
        <Box display="flex" alignItems="center" alignSelf="center" mt={2}>
          <img src="/exclamation_mark.png" style={{ width: "16px", height: "16px" }} />
          <Typography variant="caption1" color={theme.palette.text.captionColor} mt="1px" ml={1}>
            Some models are not available. To use this solution please buy it in the Marketplace
          </Typography>
        </Box>
      )}
    </Box>
  );
}
