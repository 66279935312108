import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import CSVUpload from "../../../../../components/CSVUploadNew";
import CustomCloseIconButton from "../../../../../components/CustomCloseIconButton";
import CustomDivider from "../../../../../components/CustomDivider";
import CustomTab from "../../../../../components/CustomTab";
import PrimaryLoadingButton from "../../../../../components/PrimaryLoadingButton";
import RadioFilterButton from "../../../../../components/RadioFilterButton";
import { DATA_SOURCES, FILE_FORMAT } from "../../../../../utils/constants";

interface ModalProps {
  open: boolean;
  handleModalOpen(): void;
}

export interface FormDataType {
  name: string;
}

const initialFormData: FormDataType = {
  name: "",
};

const tabs = [
  {
    id: "upload",
    title: "Upload data",
    disable: false,
    component: <Box>Upload data</Box>,
  },
  {
    id: "link",
    title: "Link database",
    disable: false,
    component: <Box>Upload data</Box>,
  },
];

export default function NewTestModal({ open, handleModalOpen }: ModalProps) {
  const theme = useTheme();

  const [formData, setFormData] = useState(initialFormData);
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [fileType, setFileType] = useState<string>();
  const [dataSource, setDataSource] = useState<string>("");

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleSources = (value: string) => {
    setDataSource(value);
  };

  const handleFile = (files: any) => {
    console.log(files);
  };

  const handleFileType = (value: string) => {
    setFileType(value);
  };

  const fileFormat = FILE_FORMAT.filter((file) => dataSource === file?.type);

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleModalOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          background: (theme) => theme.palette.background.default,
          borderRadius: "10px",
          width: "100%",
        },
      }}
    >
      <DialogContent sx={{ p: 4 }}>
        <Box display="flex" width="100%" justifyContent="space-between" mb={2}>
          <Typography sx={{ m: "auto" }}>New Test</Typography>
          <CustomCloseIconButton onClick={handleModalOpen} sx={{ marginLeft: { ml: "auto" } }}>
            <CloseIcon sx={{ width: "16px", height: "16px" }} />
          </CustomCloseIconButton>
        </Box>
        <CustomDivider width="100%" />
        <Box mt={5} mb={2}>
          <CustomTab
            tabs={tabs}
            handleActiveTab={handleActiveTab}
            sx={{ border: `1px solid ${theme.palette.primary.tertiary}`, textAlign: "center", p: "1px" }}
            width="100%"
            height="32px"
            activeTab={activeTab}
          ></CustomTab>
        </Box>
        <Box textAlign="center">
          <Typography variant="body4">You can upload and analyze your own data</Typography>
        </Box>
        <Box mt={4}>
          <Typography variant="caption2" color={theme.palette.text.captionColor}>
            Type of Data
          </Typography>
          <RadioFilterButton
            options={DATA_SOURCES}
            placeholder="Select the type of your Data"
            onChange={handleSources}
            sx={{ width: "100%" }}
            endIcon={true}
            textColor={theme.palette.text.titleLabel}
          />
        </Box>
        <Box mt={4}>
          <Typography variant="caption2" color={theme.palette.text.captionColor}>
            File Format
          </Typography>
          <RadioFilterButton
            options={fileFormat}
            placeholder="Select the file format"
            onChange={handleFileType}
            sx={{ width: "100%" }}
            endIcon={true}
            textColor={theme.palette.text.titleLabel}
          />
        </Box>
        <Box mt={3}>
          <CSVUpload getFile={handleFile} label="Select archive files" multiple={true} fileType={fileType} fileFormat={fileType} />
        </Box>
        <Box mt={2} textAlign="center">
          <Typography variant="body4">You can upload several files. Please make sure that your file have one format</Typography>
        </Box>
        <Box textAlign="center">
          <PrimaryLoadingButton
            sx={{
              ...theme.typography.bigButton,
              width: "50%",
              mt: 4,
            }}
          >
            Start Test
          </PrimaryLoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
