import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../../components/BackButton";
import CustomTertiaryButton from "../../../components/CustomTertiaryButton";
import StatusButton from "../../../components/StatusButton";
import SubmitLoadingDeleteButton from "../../../components/SubmitLoadingDeleteButton";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { activateUser, deactivateUser, getWorkspaceUser } from "../../../store/workspace/workspaceThunk";
import DeleteUserConfirmation from "./DeleteUserConfirmation";
import ModifyUserDetails from "./ModifyUserDetails";

export default function SpecificUser() {
  const { currentUser } = useAppSelector((state) => state.auth);
  const { isLoading } = useAppSelector((state) => state.workspace);
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();
  const dispatch = useAppDispatch();
  const { workspaceId, userId } = useParams();

  const [deleteModal, setDeleteModal] = useState(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [details, setDetails] = useState<Record<string, any>>({});
  const [statusBtnText, setStatusBtnText] = useState<string>("");

  useEffect(() => {
    dispatch(getWorkspaceUser({ id: userId, group_id: workspaceId })).then((res) => {
      const statusCode = res?.payload?.status;
      if (statusCode === 200) {
        setDetails(res.payload.data);
        setStatusBtnText(res.payload.data.is_active ? "Deactivate User" : "Activate User");
      }
      if (!statusCode) {
        handleRouteClick("404");
        return;
      }
      switch (statusCode) {
        case 404:
          handleRouteClick("404");
          break;
        case 403:
          toast.error("You are not authorized to view this page");
          handleRouteClick(`${routes.settings.path}`);
          break;
        default:
          break;
      }
    });
  }, []);

  useEffect(() => {
    setDetails(details);
    setStatusBtnText(details.is_active ? "Deactivate User" : "Activate User");
  }, [details]);

  const toggleEdit = () => {
    setIsEditable((prev) => !prev);
  };
  const handleBack = () => {
    handleRouteClick(routes.workspaceDetail.path.replace(":workspaceId", workspaceId ? workspaceId : "0"));
  };

  const handleDelete = () => {
    setDeleteModal((prev) => !prev);
  };
  const handleStatus = () => {
    if (details.is_active) {
      dispatch(deactivateUser({ id: userId })).then(() => {
        setDetails({ ...details, is_active: false });
        setStatusBtnText("Activate User");
      });
    } else {
      dispatch(activateUser({ id: userId })).then(() => {
        setDetails({ ...details, is_active: true });
        setStatusBtnText("Deactivate User");
      });
    }
  };

  return (
    <div>
      <Box display="flex" flexDirection="column" height="100%">
        <Stack direction="row" justifyContent="space-between" alignItems="center" py={2} px={1}>
          <Stack direction="column" alignItems="flex-start">
            <Stack direction="row" alignItems="flex-start" gap={3}>
              <Typography variant="h1" color={theme.palette.text.titleLabel}>
                {details?.name}
              </Typography>
              <StatusButton status={details?.is_active ? "active" : "deactivated"} />
            </Stack>

            <BackButton onClick={handleBack} sx={{ mt: "-3px" }} />
          </Stack>
          {currentUser?.is_admin && currentUser.id !== details.id ? (
            <Box>
              <Stack direction="row" gap={2}>
                <CustomTertiaryButton onClick={handleStatus} sx={{ py: 0.5, width: "8rem" }}>
                  {statusBtnText}
                </CustomTertiaryButton>
                <SubmitLoadingDeleteButton
                  isLoading={false}
                  sx={{ py: 0.5, background: theme.palette.error.text, color: theme.palette.error.btnText }}
                  onClick={handleDelete}
                >
                  Delete User
                </SubmitLoadingDeleteButton>
              </Stack>
            </Box>
          ) : (
            <></>
          )}
        </Stack>
        {/* {isLoading && <UiLoading height="100vh" />} */}
        <ModifyUserDetails isEditable={isEditable} toggleEdit={toggleEdit} />

        {deleteModal && <DeleteUserConfirmation open={deleteModal} handleDelete={handleDelete} userdetail={details} />}
      </Box>
    </div>
  );
}
