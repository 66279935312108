import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, InputAdornment, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import BackButton from "../../../../../../components/BackButton";
import CustomInputField from "../../../../../../components/CustomInputField";
import CustomRadialBar from "../../../../../../components/CustomRadial";
import PrimaryLoadingButton from "../../../../../../components/PrimaryLoadingButton";
import RadioFilterButton from "../../../../../../components/RadioFilterButton";
import { routes } from "../../../../../../routes";
import { useHandleRouteClick } from "../../../../../../routes/hooks";
import ImageRecognationCard from "../../../../../CustomAI/components/Settings/Results/ImageRecognationCard";
import EntityModal from "./EntityModal";

interface ResultData {
  [x: string]: any;
}

const categoryOptions = [
  {
    id: "all",
    text: "All",
  },
  {
    id: "pemenson",
    text: "Person",
  },
  {
    id: "car",
    text: "Car",
  },
  {
    id: "bus",
    text: "Bus",
  },
  {
    id: "cat",
    text: "Cat",
  },
  {
    id: "nature",
    text: "Nature",
  },
];

const resultData = [
  {
    type: "Person",
    entityName: "Women",
    image: "/assets/person.png",
    score: 78,
    count: 124,
  },
  {
    type: "Car",
    entityName: "Men",
    image: "/assets/person.png",
    score: 78,
    count: 89,
  },
  {
    type: "Bus",
    entityName: "Bus",
    image: "/assets/person.png",
    score: 78,
    count: 24,
  },
  {
    type: "Cat",
    entityName: "Cat",
    image: "/assets/person.png",
    score: 78,
    count: 6,
  },
  {
    type: "Nature",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 93,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 45,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 15,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 45,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 15,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 45,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 45,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 15,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 45,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 15,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 45,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 45,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 15,
  },
  {
    type: "",
    entityName: "Entity Name",
    image: "/assets/person.png",
    score: 78,
    count: 45,
  },
];

export default function RecognitionOverview() {
  const theme = useTheme();
  const { state } = useLocation();
  const handleRouteClick = useHandleRouteClick();

  const [category, setCategory] = useState<string>("all");
  const [imgModal, setImgModal] = useState<boolean>(false);
  const [currentImgId, setCurrentImgId] = useState<number>(0);

  const backHandler = () => {
    handleRouteClick(routes.home.path);
  };

  const overviewData: ResultData = { men: 67, women: 35, children: 12, unidentified: 5 };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
  };

  const handleCategory = (value: string) => {
    setCategory(value);
  };

  const handleOpen = (idx: number) => {
    setCurrentImgId(idx);
    handleImgModal();
  };
  const handleImgModal = () => {
    setImgModal((prev) => !prev);
  };

  return (
    <Grid container sx={{ display: "flex", flexFlow: "column", height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          px: "5px",
        }}
      >
        <Typography variant="h3" fontWeight="bold" sx={{ marginLeft: "10px", color: theme.palette.text.titleLabel }} display={"flex"}>
          {state?.title}
        </Typography>
        <PrimaryLoadingButton
          onClick={() => console.log("update")}
          varient="text"
          sx={{
            ...theme.typography.bigButton,
            ml: "auto",
          }}
        >
          Update Model
        </PrimaryLoadingButton>
      </Box>
      <Box>
        <BackButton onClick={backHandler} sx={{ pl: 1.2, mt: -1 }} />
      </Box>
      <Box
        sx={{
          background: theme.palette.background.darkCardBackground,
          border: theme.palette.additionalColors.databasecardBorder,
          borderRadius: 2,
          my: 2,
          p: 1,
          width: "100%",
          height: "12%",
        }}
      >
        <Box display="flex" justifyContent="center">
          {Object.keys(overviewData).map((category, idx) => (
            <Box
              key={`results-${idx}`}
              sx={{
                width: "80px",
                height: "100%",
              }}
            >
              <CustomRadialBar value={overviewData[category]} count={`${overviewData[category]}`} subTitle={category} />
            </Box>
          ))}
        </Box>
      </Box>

      <Box mt={1} height="65%">
        <Typography variant="body2" color={theme.palette.text.titleLabel} pb={1}>
          674 entity detected
        </Typography>
        <Box display="flex" mb={2}>
          <CustomInputField
            placeholder="Search"
            size="small"
            variant="outlined"
            autoComplete="off"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              "&.MuiFormControl-root.MuiTextField-root .MuiInputBase-root": {
                height: "36px",
              },
            }}
          />
          <RadioFilterButton
            label="Category"
            options={categoryOptions}
            onChange={handleCategory}
            defaultValue={category}
            sx={{ width: "150px", border: "none", ml: "auto" }}
          />
        </Box>

        <Box
          sx={{
            background: theme.palette.background.darkCardBackground,
            border: theme.palette.additionalColors.databasecardBorder,
            borderRadius: 2,
            pr: 2,
            height: "100%",
            overflow: "scroll",
          }}
        >
          <Grid container mt={3}>
            {resultData?.map((modelData, idx: number) => {
              return (
                <Grid item xs={4} sm={12 / 5} md={12 / 7} lg={12 / 9} key={`model-${idx}`} sx={{ pl: 2, pb: 4 }} onClick={() => handleOpen(idx)}>
                  <ImageRecognationCard resultsData={modelData} showScore={false} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      <EntityModal open={imgModal} handleModalOpen={handleImgModal} data={resultData} index={currentImgId} />
    </Grid>
  );
}
