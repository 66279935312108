import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { MultiSelectValueType } from "../../../../components/CheckboxFilterButton";
import CSVUpload from "../../../../components/CSVUploadNew";
import CustomMultiselect from "../../../../components/CustomMultiselect";
import CustomTab from "../../../../components/CustomTab";
import PrimaryLoadingButton from "../../../../components/PrimaryLoadingButton";
import RadioFilterButton from "../../../../components/RadioFilterButton";
import { AI_Models_Option, DATA_SOURCES, FILE_FORMAT } from "../../../../utils/constants";
import AddCanvasDialog from "./AddCanvasDialog";

const tabs = [
  {
    id: "upload",
    title: "Upload data",
    disable: false,
    component: <Box>Upload data</Box>,
  },
  {
    id: "link",
    title: "Link database",
    disable: false,
    component: <Box>Upload data</Box>,
  },
];
export interface QueryFormData {
  aiModels: MultiSelectValueType[];
  dataSource: string;
  data: any;
}

interface QueryProps {
  submitCanvasData(formData: QueryFormData): void;
  getDataSources(source: string): void;
  getSelectedModels(models: MultiSelectValueType[]): void;
  getUploadedData(data: any): void;
}

export default function QuerySection({ submitCanvasData, getDataSources, getSelectedModels, getUploadedData }: QueryProps) {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [files, setFile] = useState({});
  const [models, setModels] = useState<MultiSelectValueType[]>([]);
  const [dataSource, setDataSource] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [fileType, setFileType] = useState<string>();

  const handleSelectModel = (value: MultiSelectValueType[]) => {
    setModels(value);
    if (value) {
      getSelectedModels(value);
    }
  };

  const handleSources = (value: string) => {
    setDataSource(value);
    getDataSources(value);
    setFile({});
    getUploadedData({});
  };

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleFile = (files: any) => {
    setFile(files);
    getUploadedData(files);
  };

  const handleAddCanvas = () => {
    const formData = {
      aiModels: models,
      dataSource: dataSource,
      data: files,
    };
    submitCanvasData(formData);
    if (dataSource === "image") {
      handleModal();
    }
  };

  const handleModal = () => {
    setOpenModal((prev) => !prev);
  };

  const handleRemoveFiles = () => {
    setFile({});
  };

  const handleFileType = (value: string) => {
    setFileType(value);
  };

  const fileFormat = FILE_FORMAT.filter((file) => dataSource === file?.type);

  return (
    <Box
      sx={{
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        height: "100%",
        borderRadius: 2,
        mt: 1,
        p: 3,
      }}
    >
      <Typography variant="body1" color={theme.palette.text.titleLabel}>
        Choose the models that will be the base for your new model
      </Typography>
      <Box mt={3}>
        <Box display="flex" gap={1} mb={1}>
          <Typography variant="h4" color={theme.palette.text.tableHeader}>
            AI Model
          </Typography>
          <InfoOutlinedIcon sx={{ color: theme.palette.primary.main, fontSize: "16px" }} />
        </Box>
        <CustomMultiselect
          label="Query Type"
          onChange={(value) => handleSelectModel(value)}
          options={AI_Models_Option}
          showSearch={false}
          placeholder="Select AI Models"
        />
      </Box>
      <Box mt={5}>
        <Typography variant="body1" pb={3}>
          Add Data Source
        </Typography>
        <CustomTab
          tabs={tabs}
          handleActiveTab={handleActiveTab}
          sx={{ border: `1px solid ${theme.palette.primary.tertiary}`, textAlign: "center", p: "1px" }}
          width="100%"
          height="32px"
          activeTab={activeTab}
        ></CustomTab>
      </Box>
      <Box mt={4}>
        <Typography variant="caption2" color={theme.palette.text.captionColor}>
          Type of Data
        </Typography>
        <RadioFilterButton
          options={DATA_SOURCES}
          placeholder="Select the type of your Data"
          onChange={handleSources}
          sx={{ width: "100%" }}
          endIcon={true}
          textColor={theme.palette.text.titleLabel}
        />
      </Box>
      <Box mt={4}>
        <Typography variant="caption2" color={theme.palette.text.captionColor}>
          File Format
        </Typography>
        <RadioFilterButton
          options={fileFormat}
          placeholder="Select the file format"
          onChange={handleFileType}
          sx={{ width: "100%" }}
          endIcon={true}
          textColor={theme.palette.text.titleLabel}
        />
      </Box>
      <Box mt={3}>
        <CSVUpload getFile={handleFile} label="Select archive files" multiple={true} fileType={dataSource} fileFormat={fileType} />
      </Box>
      <Box mt={2}>
        <Typography variant="body4">You can upload several files. Please make sure that your file have one format</Typography>
      </Box>

      {files && Object.keys(files).length > 0 && (
        <Box sx={{ display: "flex", gap: 1, border: theme.palette.additionalColors.databasecardBorder, p: 1, borderRadius: 2, mt: 2 }}>
          <InsertDriveFileIcon sx={{ color: theme.palette.text.tableHeader, fontSize: "18px" }} />
          <Typography variant="caption" color={theme.palette.text.captionColor} pt={0.4}>{`${
            Object.keys(files).length
          } files downloaded`}</Typography>
          <CloseIcon sx={{ width: "16px", height: "16px", ml: "auto", color: theme.palette.error.main }} onClick={handleRemoveFiles} />
        </Box>
      )}
      <PrimaryLoadingButton
        sx={{
          mt: 4,
          width: "100%",
        }}
        onClick={handleAddCanvas}
      >
        Add Source To Canvas
      </PrimaryLoadingButton>
      <AddCanvasDialog handleClose={handleAddCanvas} open={openModal} canvasData={files || []} />
    </Box>
  );
}
