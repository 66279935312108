import { Box, useTheme } from "@mui/material";
import "leaflet/dist/leaflet.css";
import React from "react";
import FilterLegend from "../../../../Map/Components/FilterLegend";
import ScoreLegend from "../../../../Map/Components/ScoreLegend";
import NetworkGraph from "./NetworkGraph";

type GraphViewProps = {
  graphData: any[];
};

const GraphView = ({ graphData }: GraphViewProps) => {
  console.log(graphData);

  const theme = useTheme();
  const [items, setItems] = React.useState<boolean[]>([true, true, true, true, true, true]);

  return (
    <Box mt={2}>
      <Box
        width="100%"
        height="54vh"
        borderRadius={2}
        overflow="scroll"
        position="relative"
        sx={{ background: theme.palette.background.darkCardBackground, border: theme.palette.additionalColors.databasecardBorder }}
      >
        {graphData && graphData.length > 0 && <NetworkGraph items={items} connectionsData={graphData} />}
      </Box>
      <Box
        sx={{
          display: "flex",
          mt: 2,
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            gap: 1,
          },
        }}
      >
        <FilterLegend items={items} setItems={setItems} />
        <ScoreLegend sx={{ [theme.breakpoints.up("md")]: { ml: "auto" } }} />
      </Box>
    </Box>
  );
};

export default GraphView;
