import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAPI, patchAPI, postAPI } from "../../axios/utils";
import { AUTH_SERVICE_BASE_URL } from "../../utils/constants";

export const login = createAsyncThunk("auth/login", async (data: Record<string, any>, thunkAPI) => {
  try {
    return await postAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/token/`, {
      ...data,
      redirect_url: `${window.location.origin}/verify/email`,
    });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.response });
  }
});

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    return await postAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/logout/`);
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.response });
  }
});

export const register = createAsyncThunk("auth/register", async (data: Record<string, any>, thunkAPI) => {
  return postAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/register/`, {
    ...data,
    redirect_url: `${window.location.origin}/verify/email`,
  });
});

export const registerByAdmin = createAsyncThunk("auth/registerByAdmin", async (data: Record<string, any>, thunkAPI) => {
  return postAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/register/staff/`, {
    ...data,
  });
});

export const forgotPassword = createAsyncThunk("auth/forgotPassword", async (data: Record<string, any>, thunkAPI) => {
  return await postAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/request-reset-email/`, {
    ...data,
    redirect_url: `${window.location.origin}/reset-password/reset`,
  });
});

export const changePassword = createAsyncThunk("auth/changePassword", async (data: Record<string, any>) => {
  return postAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/change-password/`, data);
});

export const verifyEmail = createAsyncThunk("auth/verifyEmail", async (token: string) => {
  return await patchAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/verify/email/?token=${token}`);
});

export const resendVerificationEmail = createAsyncThunk("auth/resendVerificationEmail", async (data: Record<string, any>) => {
  return postAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/resend-verification-email/`, {
    ...data,
    redirect_url: `${window.location.origin}/verify/email`,
  });
});

// export const forgotPasswordTokenCheck = createAsyncThunk("auth/forgotPasswordTokenCheck", async (data: Record<string, any>, thunkAPI) => {
//   try {
//     return await getAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/password-reset/${data.uidb64}/${data.token}/`);
//   } catch (error: any) {
//     return thunkAPI.rejectWithValue({ error: error.response.data.error });
//   }
// });

export const forgotPasswordComplete = createAsyncThunk("auth/forgotPasswordComplete", async (data: Record<string, any>, thunkAPI) => {
  return await postAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/password-reset/${data.uidb64}/${data.token}/`, data);
});

export const getMyDetail = createAsyncThunk("auth/getMyDetail", async () => {
  return getAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/me/`);
});

export const updateUserDetail = createAsyncThunk("auth/updateUserDetail", async (data: Record<string, any>) => {
  if (!data?.profile_photo || data?.profile_photo === null) {
    // To set profile_picture as an empty string
    data.profile_photo = "";
  }

  return patchAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/me/`, data, {}, true);
});

export const getTimezoneList = createAsyncThunk("auth/getTimezoneList", async () => {
  return getAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/timezone-choices/`);
});
