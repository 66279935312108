import { Box, FormControl, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { FiUpload } from "react-icons/fi";
import { toast } from "react-toastify";
import BorderedCard from "../../../components/BorderedCard";
import CustomAutocomplete from "../../../components/CustomAutocomplete";
import CustomDivider from "../../../components/CustomDivider";
import CustomInputField from "../../../components/CustomInputField";
import CustomSecondaryButton from "../../../components/CustomSecondaryButton";
import CustomTextButton from "../../../components/CustomTextButton";
import StaticValueDisplay from "../../../components/StaticValueDisplay";
import { getTimezoneList, updateUserDetail } from "../../../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import classes from "../../../styles/AccountDetails.module.css";
import ChangePasswordDialog from "./ChangePasswordDialog";
export default function AccountDetails() {
  const theme = useTheme();
  const { timezonesList, currentUser } = useAppSelector((state) => state.auth);
  const [imgSrc, setImgSrc] = React.useState<string>();
  const [isEditable, setEditable] = React.useState<boolean>(false);
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = React.useState<boolean>(false);
  const [profileData, setProfileData] = React.useState<Record<string, any>>({});
  const [updatedProfile, setUpdatedProfile] = React.useState<Record<string, any>>({});
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(getTimezoneList()).then((res) => {
      if (res.payload?.status !== 200) {
        toast.error("Error in fetching timezone list");
      }
    });
  }, []);

  React.useEffect(() => {
    currentUser && setProfileData(currentUser);
  }, [currentUser]);

  const toggleOpenChangePasswordDialog = () => {
    setOpenChangePasswordDialog((prev) => !prev);
  };

  const handleTimeZone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { textContent: value } = e.target;
    setUpdatedProfile((prev) => ({
      ...prev,
      time_zone: value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    const fileUrl = URL.createObjectURL(file);
    setImgSrc(fileUrl);
    setUpdatedProfile((prev) => ({
      ...prev,
      profile_photo: file,
    }));
  };

  const handleEditSave = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (isEditable) {
      const buttonText = event.currentTarget.innerText;
      if (buttonText === "Save") {
        dispatch(updateUserDetail(updatedProfile)).then((res) => {
          if (res.payload.status === 200) {
            toast.success("Profile updated successfully");
            return;
          }
          toast.error("Error in updating profile");
        });

        setProfileData((prev) => ({
          ...prev,
          name: updatedProfile.name || profileData.name,
          email: updatedProfile.email || profileData.email,
          phone_no: updatedProfile.phone_no || profileData.phone_no,
          country: updatedProfile.country || profileData.country,
          time_zone: updatedProfile.time_zone || profileData.time_zone,
          profile_photo: updatedProfile.profile_photo || profileData.profile_photo,
        }));
      } else {
        setUpdatedProfile({});
      }
    }
    setEditable(!isEditable);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUpdatedProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <BorderedCard title="">
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4" color={theme.palette.text.titleLabel}>
          Account Details
        </Typography>
        {isEditable ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <CustomTextButton onClick={handleEditSave} sx={{ color: theme.palette.error.text }}>
              Cancel
            </CustomTextButton>
            <CustomTextButton onClick={handleEditSave}>Save</CustomTextButton>
          </Box>
        ) : (
          <CustomTextButton onClick={handleEditSave}>Edit</CustomTextButton>
        )}
      </Box>
      <CustomDivider />
      <>
        <Grid container mt={3}>
          <Grid
            item
            xs={12}
            sm={3}
            lg={2}
            sx={{
              margin: { xs: "auto", sm: "auto", md: 0 },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              position: "relative",
            }}
          >
            <input
              style={{ display: "none" }}
              id="profilePicture"
              name="profilePicture"
              className="profilePicture"
              type="file"
              onChange={handleFileChange}
              disabled={!isEditable}
            />
            <label
              htmlFor="profilePicture"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                width="100%"
                height="100%"
                src={imgSrc || profileData?.profile_photo || "/assets/profile.png"}
                alt="profile"
                className={classes.profileimg}
                style={{ opacity: isEditable ? 0.4 : 1 }}
              />
              {isEditable && (
                <Box sx={{ position: "absolute" }}>
                  <FiUpload size={32} />
                </Box>
              )}
            </label>
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            lg={9}
            sx={{
              margin: { sm: "auto", md: "auto 0 auto auto" },
            }}
          >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} sm={6} md={4}>
                {isEditable ? (
                  <FormControl fullWidth>
                    <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                      User Name
                    </Typography>
                    <CustomInputField
                      defaultValue={profileData?.name || "NA"}
                      required
                      name="name"
                      size="small"
                      placeholder="User Name"
                      onChange={handleInputChange}
                      autoComplete="off"
                      disabled={!isEditable}
                    />
                  </FormControl>
                ) : (
                  <StaticValueDisplay label="User Name" value={profileData["name"]} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {isEditable ? (
                  <FormControl fullWidth>
                    <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                      Email Address
                    </Typography>
                    <CustomInputField
                      defaultValue={profileData?.email || "NA"}
                      required
                      name="email"
                      size="small"
                      placeholder="Email Address"
                      onChange={handleInputChange}
                      autoComplete="off"
                      disabled={!isEditable}
                    />
                  </FormControl>
                ) : (
                  <StaticValueDisplay label="Email Address" value={profileData["email"]} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <StaticValueDisplay label="Role" value={profileData["is_admin"] ? "Admin" : "Staff"} readOnly={true} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {isEditable ? (
                  <FormControl fullWidth>
                    <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                      Phone Number
                    </Typography>
                    <CustomInputField
                      defaultValue={profileData?.phone_no || "NA"}
                      required
                      name="phone_no"
                      size="small"
                      placeholder="Phone Number"
                      onChange={handleInputChange}
                      autoComplete="off"
                      disabled={!isEditable}
                    />
                  </FormControl>
                ) : (
                  <StaticValueDisplay label="Phone Number" value={profileData["phone_no"]} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {isEditable ? (
                  <FormControl fullWidth>
                    <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                      Country
                    </Typography>
                    <CustomInputField
                      defaultValue={profileData.country || "NA"}
                      // required
                      name="country"
                      size="small"
                      placeholder="Country"
                      onChange={handleInputChange}
                      autoComplete="off"
                      disabled={!isEditable}
                    />
                  </FormControl>
                ) : (
                  <StaticValueDisplay label="Country" value={profileData["country"]} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {isEditable ? (
                  <FormControl fullWidth>
                    <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                      Timezone
                    </Typography>

                    <CustomAutocomplete
                      defaultValue={profileData?.time_zone || "NA"}
                      // required
                      name="time_zone"
                      size="small"
                      placeholder="Timezone"
                      onChange={handleTimeZone}
                      //autoComplete="off"
                      disabled={!isEditable}
                      options={timezonesList.map((v, index) => ({ label: v, id: parseInt(v) + 1 }))}
                      lableName="Timezone"
                    ></CustomAutocomplete>
                  </FormControl>
                ) : (
                  <StaticValueDisplay label="Timezone" value={profileData["time_zone"]} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <ChangePasswordDialog open={openChangePasswordDialog} handleToggle={toggleOpenChangePasswordDialog} />
          <CustomSecondaryButton size="small" sx={{ px: "12px" }} onClick={toggleOpenChangePasswordDialog}>
            Change Password
          </CustomSecondaryButton>
          <Box mt={2}>
            <Typography variant="caption2" color={theme.palette.text.titleLabel}>
              Enable the
            </Typography>
            &nbsp;
            <Typography variant="caption2" color={theme.palette.text.default}>
              Two-Factor Authentication
            </Typography>
            &nbsp;
            <Typography variant="caption2" color={theme.palette.text.titleLabel}>
              to protect your account
            </Typography>
          </Box>
        </Grid>
      </>
    </BorderedCard>
  );
}
