import { createAsyncThunk } from "@reduxjs/toolkit";

import { deleteAPI, getAPI, patchAPI, postAPI } from "../../axios/utils";
import { AUTH_SERVICE_BASE_URL } from "../../utils/constants";

export const createGroup = createAsyncThunk("users/createGroup", async (data: Record<string, any>, thunkAPI) => {
  return await postAPI(`${AUTH_SERVICE_BASE_URL}/core/groups/`, {
    ...data,
  });
});

export const groupsList = createAsyncThunk("users/groupsList", async () => {
  return await getAPI(`${AUTH_SERVICE_BASE_URL}/core/groups/`);
});

export const deleteGroup = createAsyncThunk("users/deleteGroup", async (groupId: number) => {
  return await deleteAPI(`${AUTH_SERVICE_BASE_URL}/core/groups/${groupId}/`);
});

export const deleteUser = createAsyncThunk("auth/deleteUser", async (userId: number) => {
  return patchAPI(`${AUTH_SERVICE_BASE_URL}/auth/accounts/staff/${userId}/soft-delete`);
});
