import { Box, Grid, Typography, useTheme } from "@mui/material";
import BorderedCard from "../../../components/BorderedCard";
import CustomDivider from "../../../components/CustomDivider";
import FeatureList from "../../../components/FeatureList";
import PlanButton from "../../../components/PlanButton";
import PrimaryLoadingButton from "../../../components/PrimaryLoadingButton";

export default function OtherPlans() {
  const theme = useTheme();

  return (
    <Box>
      <Typography variant="h4" sx={{ color: theme.palette.text.titleLabel }}>
        Other Plans
      </Typography>
      <CustomDivider />
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} mt={3}>
          <BorderedCard
            sx={{
              background: theme.palette.dashboardContainer.otherPlanCardBackground,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 2,
              minHeight: "22rem",
              height: "100%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <PlanButton type="basic">Basic</PlanButton>
              <Typography variant="body6" color={theme.palette.text.captionColor}>
                Quick start. You can buy any AI Models and Solutions
              </Typography>
              <Typography variant="h2" color={theme.palette.text.default}>
                Free
              </Typography>
              <Box>
                <FeatureList features={["1 AI Model", "1 Data Source"]} />
              </Box>
            </Box>
            <Box>
              <PrimaryLoadingButton sx={{ ...theme.typography.bigButton, width: "100%" }} variant="contained" disabled>
                Current
              </PrimaryLoadingButton>
            </Box>
          </BorderedCard>
        </Grid>
        <Grid item xs={12} md={4} mt={3}>
          <BorderedCard
            sx={{
              background: theme.palette.dashboardContainer.otherPlanCardBackground,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 2,
              minHeight: "22rem",
              height: "100%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <PlanButton type="pro">Pro</PlanButton>
              <Typography variant="body6" color={theme.palette.text.captionColor}>
                Optimal variant. Analyze everything, including your own data{" "}
              </Typography>
              <Typography variant="h2" color={theme.palette.text.default}>
                +300$/year
              </Typography>
              <Box>
                <FeatureList features={["All Data sources", "Own data sourses", "Workspace"]} />
              </Box>
            </Box>
            <Box>
              <PrimaryLoadingButton sx={{ ...theme.typography.bigButton, width: "100%" }} variant="contained">
                Upgrade
              </PrimaryLoadingButton>
            </Box>
          </BorderedCard>
        </Grid>
        <Grid item xs={12} md={4} mt={3}>
          <BorderedCard
            sx={{
              background: theme.palette.dashboardContainer.otherPlanCardBackground,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "space-between",
              minHeight: "22rem",
              height: "100%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <PlanButton type="enterprise">Enterprise</PlanButton>
              <Typography variant="body6" color={theme.palette.text.captionColor}>
                Customize your models with your own data{" "}
              </Typography>
              <Typography variant="h2" color={theme.palette.text.default}>
                +700$/year
              </Typography>
              <Box>
                <FeatureList features={["All Data sources", "Own data sourses", "Workspace", "Custom AI Models"]} />
              </Box>
            </Box>
            <Box>
              <PrimaryLoadingButton sx={{ ...theme.typography.bigButton, width: "100%" }} variant="contained">
                Upgrade
              </PrimaryLoadingButton>
            </Box>
          </BorderedCard>
        </Grid>
      </Grid>
    </Box>
  );
}
