import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CustomIconButton from "../../../components/CustomIconButton";
import PrimaryLoadingButton from "../../../components/PrimaryLoadingButton";
import SolutionDialogTabs from "./SolutionDialogTabs";

export interface SolutionDialogProps {
  open: boolean;
  handleToggle: () => void;
  solutionData: { id: string | number; title: string; thumbnail: string; description: string };
}

export default function SolutionDialog({ open, handleToggle, solutionData }: SolutionDialogProps) {
  const theme = useTheme();

  return (
    <Dialog
      onClose={handleToggle}
      open={open}
      PaperProps={{
        sx: {
          background: theme.palette.background.gradientLight1,
          borderRadius: "8px",
          py: 4,
          px: 3,
          minWidth: "50vw",
          minHeight: "50vh",
        },
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h1" m="auto" color={theme.palette.text.titleLabel}>
          {solutionData.title}
        </Typography>
        <CustomIconButton onClick={handleToggle} sx={{ color: theme.palette.error.main }}>
          <CloseIcon />
        </CustomIconButton>
      </Box>
      <DialogContent sx={{ margin: 0, padding: 0, mt: 4, display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="body2" color={theme.palette.additionalColors.light}>
              Solution
            </Typography>
            <Typography variant="body1" my={1} color={theme.palette.text.titleLabel}>
              About Solution
            </Typography>
            <Typography variant="body4" color={theme.palette.text.captionColor}>
              {solutionData.description}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <img alt="thumbnail" src={solutionData.thumbnail || "/skylarklabs_placeholder.png"} style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={12}>
            <SolutionDialogTabs />
          </Grid>
        </Grid>
        <Box mx="auto" my={3}>
          <PrimaryLoadingButton isLoading={false} sx={{ ...theme.typography.bigButton, width: "13rem" }}>
            Go To Analysis
          </PrimaryLoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
