import { Box, useTheme } from "@mui/material";
import GoogleMapReact from "google-map-react";
import { useCallback, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { GOOGLE_MAP_REACT_API_KEY } from "../../../../../utils/constants";
import PublicationInfo from "./PublicationInfo";
import Publications from "./Publications";
import ScoreLegend from "./ScoreLegend";

export interface HotspotProps {
  id: string;
  count: number;
  position: object;
  status: string;
  legend: string;
}
export interface DemographicsMapProps {
  hotspotsData: HotspotProps[];
  showLegend?: boolean;
  showPopup?: boolean;
  defaultZoom: number;
  showZoomControl?: boolean;
  showFullScreenControl?: boolean;
}

type TextPalette = {
  extremeStatus: string;
  highStatus: string;
  moderateStatus: string;
  neutralStatus: string;
  lowStatus: string;
};

const DemographicsMap = ({
  hotspotsData,
  showLegend = true,
  showPopup = false,
  defaultZoom,
  showZoomControl = false,
  showFullScreenControl = false,
}: DemographicsMapProps) => {
  const theme = useTheme();
  const [openDetails, setOpenDetails] = useState(false);
  const infoWindowRef = useRef<any>(null);

  const handleOpenDetails = () => {
    setOpenDetails((prev) => !prev);
  };

  const handleMarkerClick = useCallback(
    (map: any, maps: any, mark: any) => {
      if (infoWindowRef.current && !openDetails) {
        setOpenDetails(false);
      }

      if (infoWindowRef.current) {
        infoWindowRef.current.close();
      }

      const cityName = "City Name";
      const twitterPublications = 12;
      const instagramPublications = 8;
      const newsPublications = 4;
      const content = renderToString(
        <PublicationInfo
          cityName={cityName}
          twitterPublications={twitterPublications}
          instagramPublications={instagramPublications}
          newsPublications={newsPublications}
        />
      );

      const newInfoWindow = new maps.InfoWindow({
        content: content,
      });

      newInfoWindow.open(map, mark);

      infoWindowRef.current = newInfoWindow;

      // Using setTimeout to ensure that the DOM is updated
      setTimeout(() => {
        const publicationElement = document.getElementById("publicationsId");
        if (publicationElement) {
          publicationElement.addEventListener("click", handleOpenDetails);
        }
      }, 1000);
    },
    [infoWindowRef]
  );

  const defaultProps = {
    center: {
      lat: 55.683708,
      lng: 38.028615,
    },
    zoom: defaultZoom,
  };

  function createMapOptions(maps: any) {
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_BOTTOM,
        style: maps.ZoomControlStyle.SMALL,
      },
      zoomControl: showZoomControl,
      fullscreenControl: showFullScreenControl,
    };
  }

  const renderMarkers = (map: any, maps: any) => {
    let marker = hotspotsData.map((mark: any, i: number) => {
      const statusColor: any = `${mark?.status}Status`;
      const scale = mark.count.toString().length > 2 ? 25 : 15;
      const markerInstance = new maps.Marker({
        position: { lat: mark.position.lat, lng: mark.position.lng },
        icon: {
          path: maps.SymbolPath.CIRCLE,
          scale: scale,
          fillColor: `${theme.palette.text[statusColor as keyof TextPalette]}`,
          fillOpacity: 0.6,
          strokeWeight: 1,
          strokeColor: `${theme.palette.text[statusColor as keyof TextPalette]}`,
        },
        map,
        // title: "H",
        label: {
          fontSize: "12px",
          color: theme.palette.text.nodeText,
          text: `${mark.count}`,
        },
        cursor: showPopup ? "pointer" : "default",
      });

      if (showPopup) markerInstance.addListener("click", () => handleMarkerClick(map, maps, markerInstance));

      return markerInstance;
    });
    return marker;
  };

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAP_REACT_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        options={(maps) => createMapOptions(maps)}
      ></GoogleMapReact>
      {showLegend && <ScoreLegend sx={{ float: "right", mt: 2 }} />}
      {openDetails && <Publications openDrawer={handleOpenDetails} />}
    </Box>
  );
};

export default DemographicsMap;
