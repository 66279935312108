import { Box, Typography, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import BackButton from "../../../../components/BackButton";
import { routes } from "../../../../routes";
import { useHandleRouteClick } from "../../../../routes/hooks";
import PlanDetails from "./PlanDetails";

export default function DowngradeInfo() {
  const theme = useTheme();
  const { state } = useLocation();
  const handleRouteClick = useHandleRouteClick();

  const handleBack = () => {
    handleRouteClick(routes.settings.path);
  };

  const planName = state?.plan === "pro" ? "Basic" : "Professional";
  return (
    <Box p={2} display="flex" flexDirection="column" height="100%">
      <Box>
        <Typography variant="h1" color={theme.palette.text.titleLabel} sx={{ textTransform: "none" }}>
          Downgrade to {planName}
        </Typography>
        <BackButton onClick={handleBack} />
      </Box>
      <PlanDetails title={planName} plan={state?.plan} />
    </Box>
  );
}
