import { Box, CardMedia, Typography, useTheme } from "@mui/material";
import { IoIosCheckmark } from "react-icons/io";

export interface Workspace {
  id: number;
  name: string;
  member: number;
  plan: string;
  active: boolean;
}
interface WorkspaceMenuItemProps {
  workspace: Workspace;
  selectedWorkspaceId: number;
  open: boolean;
  itemClick: (item: any) => void;
}

const WorkspaceMenuItem = ({ workspace, selectedWorkspaceId, open, itemClick }: WorkspaceMenuItemProps) => {
  const theme = useTheme();

  const handleItemClick = (id: number) => {
    itemClick(workspace);
  };

  return (
    <Box
      id={`${workspace.id}`}
      aria-controls={open ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      sx={{
        display: "flex",
        alignItems: "center",

        my: 2,
        gap: 2,
        background:
          selectedWorkspaceId === workspace.id ? theme.palette.dashboardContainer.sessionCardActive : theme.palette.background.gradientLight1,
        p: 2,
        width: { xs: "100%", lg: "100%", xl: "100%" },
        borderRadius: "8px",
        border: theme.palette.dashboardContainer.workspaceMenuItemBorder,
      }}
      onClick={() => handleItemClick(workspace.id)}
    >
      <CardMedia component="img" image="/assets/workspace-icon.png" alt="info" sx={{ width: "48px", height: "48px" }} />
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="h4" sx={{ color: theme.palette.text.titleLabel }}>
          {workspace.name}
        </Typography>
        <Box display="flex" gap={1}>
          <Typography variant="body3" color={theme.palette.primary.inactiveIcon}>
            {workspace.member}
          </Typography>
          <Typography variant="body3" color={theme.palette.primary.inactiveIcon} sx={{ mt: "-3px" }}>
            .
          </Typography>
          <Typography variant="body3" color={theme.palette.primary.inactiveIcon}>
            {workspace.plan}
          </Typography>
        </Box>
      </Box>
      {selectedWorkspaceId === workspace.id && <IoIosCheckmark style={{ marginLeft: "auto" }} fontSize="30px" color={theme.palette.text.default} />}
    </Box>
  );
};

export default WorkspaceMenuItem;
