import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiUpload } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../../components/BackButton";
import BorderedCard from "../../../components/BorderedCard";
import CustomAutocomplete from "../../../components/CustomAutocomplete";
import CustomCheckbox from "../../../components/CustomCheckbox";
import CustomDivider from "../../../components/CustomDivider";
import CustomInputField from "../../../components/CustomInputField";
import CustomTertiaryButton from "../../../components/CustomTertiaryButton";
import PrimaryLoadingButton from "../../../components/PrimaryLoadingButton";
import UiLoading from "../../../layouts/UiLoading";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { getTimezoneList } from "../../../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addUserToWorkspace, getWorkspaceDetail, removeAdmin, setAdmin } from "../../../store/workspace/workspaceThunk";
import classes from "../../../styles/AccountDetails.module.css";
import { USER_PERMISSION, roles } from "../../../utils/constants";

export default function AddNewUser() {
  const { timezonesList } = useAppSelector((state) => state.auth);
  const { isLoading } = useAppSelector((state) => state.workspace);
  const { workspaceId } = useParams();
  const handleRouteClick = useHandleRouteClick();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [userPermission, setPermission] = useState<number[]>([]);
  const [role, setRole] = useState<string>(roles.user);
  const [imgSrc, setImgSrc] = React.useState<string>();
  const [updatedProfile, setUpdatedProfile] = React.useState<Record<string, any>>({});
  const [currWorkspace, setCurrWorkspace] = useState("");

  useEffect(() => {
    dispatch(getTimezoneList());
    dispatch(getWorkspaceDetail({ id: workspaceId })).then((res) => {
      if (res?.payload?.status === 200) {
        const currWorkspaceData = res.payload?.data;
        if (currWorkspaceData.name === "") {
          toast.error("No such workspace exists in the organization");
          handleRouteClick(`${routes.settings.path}`);
          return;
        } else {
          setCurrWorkspace(currWorkspaceData);
        }
      } else {
        // unauthorized user Toast will be shown acccording to axios
        handleRouteClick(`${routes.settings.path}?tabs=settings?subtab=workspace`);
        return;
      }
    });
  }, []);

  const handleCancel = () => {
    // re route to users Page
    setUpdatedProfile({});
    setPermission([]);
    setRole(roles.user);
    handleRouteClick(routes.workspaceDetail.path.replace(":workspaceId", workspaceId ? workspaceId : "0"));
  };

  const handleSave = () => {
    dispatch(addUserToWorkspace({ group_id: workspaceId ? workspaceId : "0", ...updatedProfile })).then((res) => {
      if (res?.payload?.status === 201) {
        if (updatedProfile.is_admin !== undefined) {
          if (updatedProfile.is_admin) dispatch(setAdmin({ id: res.payload.data.id }));
          else dispatch(removeAdmin({ id: res.payload.data.id }));
        }
        toast.success("User Added Successfully");
        //Re route to users page
        handleRouteClick(routes.workspaceDetail.path.replace(":workspaceId", workspaceId ? workspaceId : "0"));
        setUpdatedProfile({});
      }
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "role") {
      setRole(value);
      setUpdatedProfile((prev) => ({
        ...prev,
        is_admin: value === roles.admin ? true : false,
      }));
      console.log("updatedProfile :", updatedProfile);
      return;
    }
    setUpdatedProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTimeZone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { textContent: value } = e.target;
    setUpdatedProfile((prev) => ({
      ...prev,
      time_zone: value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    const fileUrl = URL.createObjectURL(file);
    setImgSrc(fileUrl);
    setUpdatedProfile((prev) => ({
      ...prev,
      profile_photo: file,
    }));
  };

  const handlePermission = (value: number) => {
    if (!userPermission.includes(value)) {
      console.log("userPermission", userPermission);
      setPermission([...userPermission, value]);
      setUpdatedProfile((prev) => ({
        ...prev,
        permissions: [...userPermission, value],
      }));
    } else {
      const restItem = userPermission.filter((p) => p !== value);
      setPermission(restItem);
      setUpdatedProfile((prev) => ({
        ...prev,
        permissions: restItem,
      }));
    }
  };

  return (
    <div>
      {isLoading && <UiLoading height="100vh" />}

      <Box display="flex" flexDirection="column" height="100%">
        <Stack direction="row" justifyContent="space-between" alignItems="center" py={2} px={1}>
          <Stack direction="column" alignItems="flex-start">
            <Stack direction="row" alignItems="flex-start" gap={3}>
              <Typography variant="h1" color={theme.palette.text.titleLabel}>
                Add New User
              </Typography>
            </Stack>

            <BackButton onClick={handleCancel} sx={{ mt: "-3px" }} />
          </Stack>
          <Stack direction="row" gap={2}>
            <CustomTertiaryButton onClick={handleCancel} sx={{ ...theme.typography.bigButton, minWidth: "8rem" }}>
              Cancel
            </CustomTertiaryButton>
            <PrimaryLoadingButton
              isLoading={false}
              variant="contained"
              // sx={{ py: 0.5, background: theme.palette.error.text, color: theme.palette.error.btnText }}
              sx={{ ...theme.typography.bigButton, minWidth: "8.5rem" }}
              onClick={handleSave}
            >
              Save
            </PrimaryLoadingButton>
          </Stack>
        </Stack>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            boxShadow: theme.palette.dashboardContainer.insetBoxShadow,
            background: theme.palette.dashboardContainer.containerBackground,
            borderRadius: "18px 18px 18px 18px",
            height: "100%",
            p: 3,
            mt: -1,
          }}
        >
          <BorderedCard title="">
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h4" color={theme.palette.text.titleLabel}>
                Personal info
              </Typography>
            </Box>
            <CustomDivider />
            <Grid container mt={3}>
              <Grid
                item
                xs={12}
                sm={3}
                lg={2}
                sx={{
                  margin: { xs: "auto", sm: "auto", md: 0 },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                <input
                  style={{ display: "none" }}
                  id="profilePicture"
                  name="profile_photo"
                  className="profilePicture"
                  type="file"
                  onChange={handleFileChange}
                />

                <label
                  htmlFor="profilePicture"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img width="100%" height="100%" src={imgSrc || "/assets/profile.png"} alt="profile" className={classes.profileimg} />

                  <Box sx={{ position: "absolute" }}>
                    <FiUpload size={32} />
                  </Box>
                </label>
              </Grid>
              <Grid
                item
                xs={12}
                sm={9}
                lg={9}
                sx={{
                  margin: { sm: "auto", md: "auto 0 auto auto" },
                }}
              >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                        User Name
                      </Typography>
                      <CustomInputField
                        defaultValue={""}
                        required
                        name="name"
                        size="small"
                        placeholder="User Name"
                        onChange={handleInputChange}
                        autoComplete="off"
                        sx={{ "& .MuiOutlinedInput-input": { ...theme.typography.caption } }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                        Email Address
                      </Typography>
                      <CustomInputField
                        defaultValue={""}
                        required
                        name="email"
                        size="small"
                        placeholder="Email Address"
                        onChange={handleInputChange}
                        autoComplete="off"
                        //   disabled=)}
                        sx={{ "& .MuiOutlinedInput-input": { ...theme.typography.caption } }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                        Role
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        {Object.keys(roles).map((key) => (
                          <RadioGroup onChange={handleInputChange} key={key} name="role" value={roles[key]}>
                            <FormControlLabel
                              value={roles[key]}
                              label={roles[key]}
                              control={
                                <Radio
                                  checked={role.toLowerCase() === roles[key].toLowerCase()}
                                  sx={{
                                    "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
                                      color: "#1A1B1E",
                                    },
                                    "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                                      color: "#2EA3FF",
                                    },
                                  }}
                                />
                              }
                              sx={{
                                "&.MuiFormControlLabel-root .MuiFormControlLabel-label": {
                                  ...theme.typography.caption,
                                  color: theme.palette.text.titleLabel,
                                  textTransform: "capitalize",
                                },
                              }}
                            />
                          </RadioGroup>
                        ))}
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                        Phone Number
                      </Typography>
                      <CustomInputField
                        defaultValue={""}
                        required
                        name="phone_no"
                        size="small"
                        placeholder="Phone Number"
                        onChange={handleInputChange}
                        autoComplete="off"
                        sx={{ "& .MuiOutlinedInput-input": { ...theme.typography.caption } }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                        Country
                      </Typography>
                      <CustomInputField
                        defaultValue={""}
                        required
                        name="country"
                        size="small"
                        placeholder="Country"
                        onChange={handleInputChange}
                        autoComplete="off"
                        sx={{ "& .MuiOutlinedInput-input": { ...theme.typography.caption } }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <Typography variant="caption2" color={(theme) => theme.palette.text.captionColor}>
                        Timezone
                      </Typography>
                      <CustomAutocomplete
                        defaultValue={""}
                        name="time_zone"
                        size="small"
                        placeholder="Timezone"
                        onChange={handleTimeZone}
                        options={timezonesList.map((v, index) => ({ label: v, id: parseInt(v) + 1 }))}
                        lableName="Timezone"
                        sx={{ "& .MuiOutlinedInput-input": { ...theme.typography.caption } }}
                      ></CustomAutocomplete>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </BorderedCard>
          <Box>
            <BorderedCard>
              <Typography variant="h4" color={theme.palette.text.titleLabel}>
                Permissions
              </Typography>
              <CustomDivider width="100%" />
              <Grid container mt={2}>
                {USER_PERMISSION?.map((permission) => {
                  const selected = userPermission?.includes(permission?.id);

                  return (
                    <Grid item xs={6} md={4} lg={12 / 5} key={permission?.id}>
                      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        <CustomCheckbox checked={selected} label={permission?.text} onChange={() => handlePermission(permission?.id)} />
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </BorderedCard>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
