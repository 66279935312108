import { Box, Typography, useTheme } from "@mui/material";
import CustomTertiaryButton from "../../../../components/CustomTertiaryButton";
import PrimaryLoadingButton from "../../../../components/PrimaryLoadingButton";

interface ChatSectionProps {
  isActiveChat: boolean;
  handleConnection(): void;
}

export default function ChatSection({ isActiveChat, handleConnection }: ChatSectionProps) {
  const theme = useTheme();
  return (
    <Box>
      {!isActiveChat && (
        <>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="semiBold">Didn’t Find an answer?</Typography>
            <Typography variant="body4" color={theme.palette.primary.inactiveIcon} sx={{ display: "inline-block", mt: 1 }}>
              Start live chat with Skylark Labs team to find an answer for all your questions!
            </Typography>
          </Box>
          <PrimaryLoadingButton
            onClick={handleConnection}
            sx={{
              ...theme.typography.smallButton,
              height: "20px",
              mt: 1,
            }}
          >
            Start Chat
          </PrimaryLoadingButton>
        </>
      )}
      {isActiveChat && (
        <>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="semiBold" color={theme.palette.text.titleLabel}>
              Active chat with Malek Smith
            </Typography>
            <Typography variant="body4" color={theme.palette.primary.inactiveIcon} sx={{ display: "inline-block", mt: 1 }}>
              You have a new message (1) from the support manager. If your question is resolved, please end the chat.
            </Typography>
          </Box>
          <Box display="flex" gap={1}>
            <CustomTertiaryButton
              onClick={() => {
                console.log("finish chat");
              }}
              sx={{
                ...theme.typography.smallButton,
                height: "20px",
                mt: 1,
              }}
            >
              Finish chat
            </CustomTertiaryButton>
            <PrimaryLoadingButton
              onClick={handleConnection}
              sx={{
                ...theme.typography.smallButton,
                height: "20px",
                mt: 1,
              }}
            >
              Continue Chat
            </PrimaryLoadingButton>
          </Box>
        </>
      )}
    </Box>
  );
}
