import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CustomIconButton from "../../../components/CustomIconButton";
import CustomTertiaryButton from "../../../components/CustomTertiaryButton";
import SubmitLoadingDeleteButton from "../../../components/SubmitLoadingAddButton";

export interface AccountDeleteModalProps {
  open: boolean;
  handleToggle: () => void;
  handleDelete: () => void ;
}

export default function AccountDeleteModal({ open, handleToggle, handleDelete }: AccountDeleteModalProps) {
  const theme = useTheme();

  

  return (
    <Dialog
      onClose={handleToggle}
      open={open}
      PaperProps={{
        sx: {
          background: theme.palette.background.gradientLight1,
          borderRadius: "8px",
          py: 4,
          px: 4,
        },
      }}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.039)",
        },
      }}
    >
      <Box display="flex" justifyContent="flex-end">
        <CustomIconButton onClick={handleToggle} sx={{ color: theme.palette.error.main }}>
          <CloseIcon />
        </CustomIconButton>
      </Box>
      <DialogContent sx={{ margin: 0, padding: 0, display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Box sx={{ my: 2, mx: "auto" }}>
          <img height="120" src="/assets/cross_dialog_icon.png" />
        </Box>
        <Typography variant="h1" textAlign="center" my={2} color={theme.palette.text.titleLabel}>
          Confirm Delete
        </Typography>
        <Typography variant="body4" textAlign="center" mt={1} color={theme.palette.text.captionColor}>
          Are you sure you want to delete the Account? This action cannot be undone.
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            mt: 4,
            justifyContent: "center",
          }}
        >
          <CustomTertiaryButton sx={{ ...theme.typography.bigButton, minWidth: "8.5rem" }} onClick={handleToggle}>
            Cancel
          </CustomTertiaryButton>
          <SubmitLoadingDeleteButton isLoading={false} onClick={handleDelete} color="error" sx={{ minWidth: "8.5rem" }}>
            Delete
          </SubmitLoadingDeleteButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
