import CloseIcon from "@mui/icons-material/Close";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MessageIcon from "@mui/icons-material/Message";
import { Box, Dialog, DialogContent, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { LuArrowDownUp } from "react-icons/lu";
import CustomIconButton from "../../../../../../../components/CustomIconButton";
import RadioFilterButton from "../../../../../../../components/RadioFilterButton";
import Classes from "../../../../../../../components/Scrollbar.module.css";

interface LikesData {
  profile: string;
  name: string;
  userId: string;
  risky: boolean;
}
interface DialogProps {
  open: boolean;
  handleClose: () => void;
  interactionData?: { likesData: LikesData[]; commentsData: LikesData[] };
  activeTabOpt: string;
}

const Interactions = ({ open, handleClose, interactionData, activeTabOpt }: DialogProps) => {
  const theme = useTheme();

  const [activeTab, setActiveTab] = useState<string>(activeTabOpt);

  useEffect(() => {
    setActiveTab(activeTabOpt);
  }, [activeTabOpt]);

  const activeTabData = activeTab === "likes" ? interactionData?.likesData : interactionData?.commentsData;

  const subType = [{ id: "risky-first", text: "Risky First" }];

  const handleActiveTab = () => {
    if (activeTab === "likes") {
      setActiveTab("comments");
    } else {
      setActiveTab("likes");
    }
  };

  const renderColumn = (data: any) => (
    <Box display="flex" gap={1} my={1.5} sx={{ border: data?.risky ? `1px solid ${theme.palette.error.text}` : "none", p: 1, borderRadius: 1 }}>
      <img src="/assets/profile.png" alt="profile" style={{ width: "36px", height: "36px", borderRadius: "50%" }} />
      <Box>
        <Typography variant="subtitle1" sx={{ textTransform: "capitalize", fontSize: "14px", color: theme.palette.text.titleLabel }}>
          {data?.name}
        </Typography>
        <Typography variant="body4" sx={{ textTransform: "capitalize", fontSize: "14px", color: theme.palette.text.tableHeader }} ml="auto">
          {data?.userId}
        </Typography>
      </Box>
    </Box>
  );

  const activeColor = theme.palette.text.default;
  const inactiveColor = theme.palette.text.tableHeader;

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          background: (theme) => theme.palette.background.default,
          borderRadius: "10px",
          width: "100%",
          textAlign: "center",
          minHeight: "50vh",
        },
      }}
    >
      <DialogContent sx={{ p: 4 }}>
        <Grid container>
          <Box display={"flex"} width="100%" mb={3}>
            <Typography variant="h2" color={theme.palette.text.titleLabel} sx={{ m: "auto", mr: 0 }}>
              Interactions
            </Typography>
            <CustomIconButton variant="contained" handleClick={() => {}} sx={{ ml: "auto" }}>
              <CloseIcon onClick={handleClose} sx={{ color: theme.palette.error.main }} />
            </CustomIconButton>
          </Box>
          <Box display="flex" mt={1} width="100%">
            <Box display="flex" gap={4} sx={{ color: activeTab === "likes" ? activeColor : inactiveColor }}>
              <CustomIconButton sx={{ width: "40px", height: "40px" }} onClick={handleActiveTab}>
                <FavoriteIcon sx={{ color: activeTab === "likes" ? activeColor : inactiveColor }} />
              </CustomIconButton>
              <Typography variant="h6" mt={1.8} ml={-1}>
                123 Likes
              </Typography>
              <CustomIconButton sx={{ width: "40px", height: "40px" }} onClick={handleActiveTab}>
                <MessageIcon sx={{ color: activeTab === "comments" ? activeColor : inactiveColor }} />
              </CustomIconButton>
              <Typography variant="h6" mt={1.8} ml={-1} sx={{ color: activeTab === "comments" ? activeColor : inactiveColor }}>
                16 Comments
              </Typography>
            </Box>
            <Box ml="auto" display="flex" gap={1.5}>
              <RadioFilterButton
                onChange={(value) => {}}
                options={subType}
                startIcon={<LuArrowDownUp color={theme.palette.primary.main} size={16} />}
                defaultValue={subType[0].id}
                sx={{ width: "140px" }}
              />
            </Box>
          </Box>
          <Box
            className={Classes.scroll_dark}
            mt={4}
            display="flex"
            width="100%"
            sx={{
              height: "40vh",
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
              },
            }}
          >
            <Box width="100%" pr={{ sm: 3 }}>
              {activeTabData?.map((list: any, idx: number) => {
                return (
                  idx % 2 === 0 && (
                    <Grid item xs={12} key={`hashtag-col1-${idx}`}>
                      {renderColumn(list)}
                    </Grid>
                  )
                );
              })}
            </Box>

            <Box width="100%" pl={{ sm: 3 }} mr={1}>
              {activeTabData?.map((list: any, index: number) => {
                return (
                  index % 2 !== 0 && (
                    <Grid item xs={12} key={`hashtag-col2-${index}`}>
                      {renderColumn(list)}
                    </Grid>
                  )
                );
              })}
            </Box>
          </Box>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Interactions;
