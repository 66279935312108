import BusinessIcon from "@mui/icons-material/Business";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloseIcon from "@mui/icons-material/Close";
import FlagIcon from "@mui/icons-material/Flag";
import PersonIcon from "@mui/icons-material/Person";
import PlaceIcon from "@mui/icons-material/Place";
import SearchIcon from "@mui/icons-material/Search";
import TopicIcon from "@mui/icons-material/Topic";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Dialog, DialogContent, FormControlLabel, Grid, InputAdornment, Typography, useTheme } from "@mui/material";
import CustomDivider from "../../../../../../../components/CustomDivider";
import CustomIconButton from "../../../../../../../components/CustomIconButton";
import CustomInputField from "../../../../../../../components/CustomInputField";
import CustomSwitch from "../../../../../../../components/CustomSwitch";
import Classes from "../../../../../../../components/Scrollbar.module.css";

interface DialogProps {
  open: boolean;
  handleClose(): void;
}

export default function PublicationDetails({ open, handleClose }: DialogProps) {
  const theme = useTheme();

  const statisticData = [
    {
      label: "Persons",
      count: 15,
      icon: <PersonIcon sx={{ color: theme.palette.text.moderateStatus, fontSize: "20px" }} />,
      desc: "Justin Trudeau, Glen VanHerck, Frank Kendall, Doug Wade, Jon Tester, Tom Kendall",
    },
    {
      label: "Organizations",
      count: 34,
      icon: <BusinessIcon sx={{ color: theme.palette.text.neutralStatus, fontSize: "20px" }} />,
      desc: "National Security Council, Chinese government, White House, Pentagon, NATO, FBI, CSI New York, Chinese government, CNN ",
    },
    { label: "Locations", count: 2, icon: <PlaceIcon sx={{ color: theme.palette.text.default, fontSize: "20px" }} />, desc: "China, USA" },
    {
      label: "Events",
      count: 13,
      icon: <CalendarTodayIcon sx={{ color: theme.palette.text.highStatus, fontSize: "20px" }} />,
      desc: "Russian troops took control of the Ukrainian region of Crimea in March 2014Russia’s annexation of Crimea, 2014President Yanukovych fled the country, February 2014 Minsk Accords",
    },
    {
      label: "Topics",
      count: 15,
      icon: <TopicIcon sx={{ color: theme.palette.primary.secondary, fontSize: "20px" }} />,
      desc: "Crimea annexation, European Union integration, Armed conflict, Minsk Accords",
    },
    {
      label: "Red flagged words",
      count: 15,
      icon: <FlagIcon sx={{ color: theme.palette.text.extremeStatus, fontSize: "20px" }} />,
      desc: "Terrorism, Weapon, Kill, Violence, Hate",
    },
  ];

  return (
    <Dialog
      maxWidth={"xl"}
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          background: (theme) => theme.palette.background.default,
          borderRadius: "10px",
          height: "100%",
        },
      }}
      sx={{ width: "100%" }}
    >
      <DialogContent sx={{ p: 4, overflowY: { lg: "clip", xs: "scroll" }, [theme.breakpoints.down("lg")]: { height: "100%", overflow: "scroll" } }}>
        <Grid container height="100%">
          <Box display={"flex"} width="100%" mb={3}>
            <Typography variant="h2" color={theme.palette.text.titleLabel}>
              Publication Details
            </Typography>
            <CustomIconButton variant="contained" handleClick={() => {}} sx={{ ml: "auto" }}>
              <CloseIcon onClick={handleClose} sx={{ color: theme.palette.error.main }} />
            </CustomIconButton>
          </Box>
          <Grid container spacing={6} display="flex" height="100%">
            <Grid item xs={12} md={4} sx={{ display: "flex" }}>
              <Box sx={{ border: theme.palette.additionalColors.databasecardBorder, borderRadius: 2, p: 2 }}>
                <CustomInputField
                  placeholder=" Search"
                  size="small"
                  variant="outlined"
                  autoComplete="off"
                  sx={{ width: "100%" }}
                  onChange={() => console.log("search")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography variant="h5" color={theme.palette.text.tableHeader} py={2}>
                  67 entity detected
                </Typography>
                <Box sx={{ height: { xs: "20vh", md: "60vh", xl: "auto" }, overflow: "scroll" }}>
                  {statisticData.map((detail, idx) => {
                    return (
                      <Box key={`Detail-${idx}`}>
                        <Box display="flex">
                          {detail?.icon}
                          <Typography
                            variant="subtitle2"
                            color={theme.palette.text.titleLabel}
                            sx={{ ml: 1, mt: 0.5 }}
                          >{`${detail?.label} (${detail?.count})`}</Typography>
                          <VisibilityIcon sx={{ color: theme.palette.text.default, fontSize: "16px", ml: "auto" }} />
                        </Box>
                        <Typography variant="body5" color={theme.palette.text.tableHeader}>
                          {detail?.desc}
                        </Typography>
                        {statisticData.length !== idx + 1 && <CustomDivider width="100%" sx={{ margin: "1em auto" }} />}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={8} sx={{ height: "100%" }}>
              <Box sx={{ display: "flex", width: "100%", border: theme.palette.additionalColors.databasecardBorder, borderRadius: 2, p: 2 }}>
                <Box>
                  <Typography variant="subtitle1" color={theme.palette.text.tableHeader} pb={1}>
                    The text was traslated to English
                  </Typography>
                  <Typography variant="h4" color={theme.palette.text.titleLabel}>
                    Change the language
                  </Typography>
                </Box>
                <Box ml="auto">
                  <FormControlLabel
                    control={<CustomSwitch defaultChecked />}
                    label="Show original language"
                    labelPlacement="start"
                    sx={{ "& .MuiFormControlLabel-label": { ...theme.typography.subtitle2, color: theme.palette.text.nodeText } }}
                  />
                </Box>
              </Box>
              <Box mt={2} p={1}>
                <Typography variant="body4" color={theme.palette.text.tableHeader} py={2}>
                  13.04.2023 14:07
                </Typography>
                <Typography variant="h2" color={theme.palette.text.titleLabel} py={2}>
                  Chinese spy balloon was able to transmit information back to Beijing
                </Typography>
              </Box>
              <Box
                mt={2}
                p={3}
                sx={{
                  width: "100%",
                  height: { xs: "68%", lg: "71%", xl: "74%" },
                  background: theme.palette.background.default,
                  boxShadow: theme.palette.dashboardContainer.insetBoxShadow,
                  overflow: "hidden",
                  borderRadius: 2,
                }}
              >
                <Box sx={{ height: "100%", overflowY: "auto", pb: 2 }} className={Classes.scroll_dark}>
                  <Typography variant="body6" color={theme.palette.text.titleLabel} pb={2}>
                    The Chinese spy balloon that transited the US earlier this year was able to capture imagery and collect some signals intelligence
                    from US military sites, a source familiar with the matter tells CNN. The balloon was able to transmit information back to Beijing
                    in real time, the source said, and the US government still does not know for sure whether the Chinese government could wipe the
                    balloon’s data as it received it. That raises questions about whether there is intelligence the balloon was able to gather that
                    the US still doesn’t know about. As CNN has reported, the US intelligence community last year developed a method of tracking what
                    it says is a fleet of these Chinese balloons operating across the globe, controlled by the Chinese military. The US also knew what
                    the balloon’s path would be and was able to protect sensitive sites and censor some signals before the balloon was able to pick
                    them up, officials have said. As CNN has reported, the US intelligence community last year developed a method of tracking what it
                    says is a fleet of these Chinese balloons operating across the globe, controlled by the Chinese military. The FBI is still
                    examining the balloon, but so far officials have been able to glean additional information about how the device worked, including
                    the algorithms used for the balloon’s software and how it is powered and designed. “A senior State Department official said in
                    February that, as the balloon floated across the US, it “was capable of conducting signals intelligence collection
                    operations.”Gen. Glen VanHerck, the commander of US Northern Command and NORAD, said at the time that the US “did not assess” that
                    the balloon “presented a significant collection hazard beyond what already exists in actionable technical means from the
                    Chinese.”The surveillance program, which includes a number of similar balloons, is in part run out of the small Chinese province
                    of Hainan, officials told CNN. The US does not know the precise size of the fleet of Chinese surveillance balloons, but sources
                    tell CNN that the program has conducted at least two dozen missions over at least five The US also knew what the balloon’s path
                    would be and was able to protect sensitive sites and censor some signals before the balloon was able to pick them up, officials
                    have said. As CNN has reported, the US intelligence community last year developed a method of tracking what it says is a fleet of
                    these Chinese balloons operating across the globe, controlled by the Chinese military. The FBI is still examining the balloon, but
                    so far officials have been able to glean additional information about how the
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
